import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
// import { Router, Route, useRouterHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import history from 'sbHistory';

// Layouts
import OdometersLayout from 'layouts/OdometersLayout/OdometersLayout';
import CompaniesLayout from 'layouts/CompaniesLayout/CompaniesLayout';
import SubscriptionsLayout from 'layouts/SubscriptionsLayout/SubscriptionsLayout';
// import TrackersLayout from 'layouts/TrackersLayout/TrackersLayout';
import BillingIssuesLayout from 'layouts/BillingIssuesLayout/BillingIssuesLayout';
import UsersLayout from 'layouts/UsersLayout/UsersLayout';
// import ResellersLayout from 'layouts/ResellersLayout/ResellersLayout';
import EquipmentLayout from 'layouts/EquipmentLayout/EquipmentLayout';
// import HubspotLayout from 'layouts/HubspotLayout/HubspotLayout';
import SimsLayout from 'layouts/SimsLayout/SimsLayout';
import MapLayout from 'layouts/MapLayout/MapLayout';
import ScriptsLayout from 'layouts/ScriptsLayout/ScriptsLayout';
import InAppLayout from 'layouts/InAppLayout/InAppLayout';
import SupportReportLayout from 'layouts/SupportReportLayout/SupportReportLayout';
import LoginLayout from 'layouts/LoginLayout/LoginLayout';

// Import
import store from 'store';

export const baseURL = '/';

// Sync dispatched route actions to the history
// const syncedHistory = syncHistoryWithStore(browserHistory, store);

export default (
  <Router history={history}>
    <Switch>
      {/* <Route path="trackers" component={TrackersLayout} /> */}
      <Route exact path="/" component={LoginLayout} />
      <Route path="/login" component={LoginLayout} />
      <InAppLayout>
        <Route path="/odometers" component={OdometersLayout} />
        <Route path="/companies" component={CompaniesLayout} />
        <Route path="/equipment" component={EquipmentLayout} />
        <Route path="/users" component={UsersLayout} />
        {/* <Route path="/resellers" component={ResellersLayout} /> */}
        <Route path="/subscriptions" component={SubscriptionsLayout} />
        <Route path="/billingIssues" component={BillingIssuesLayout} />
        {/* <Route path="/hubspot" component={HubspotLayout} /> */}
        <Route path="/sims" component={SimsLayout} />
        <Route path="/scripts" component={ScriptsLayout} />
        <Route path="/supportReport" component={SupportReportLayout} />
        {/* <Route path="map" component={MapLayout} /> */}
      </InAppLayout>
    </Switch>
  </Router>
);
