import * as ActionConstants from 'actions/ActionConstants';
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

// Reducers
import { reducer as ReduxForm } from 'redux-form';

import { Main } from './Main';
import { Admin } from './Admin';
import { Auth } from './Auth';
import { Company } from './Company';
import { Subscription } from './Subscription';
import { DocumentLayout } from './DocumentLayout';
import { DocumentCategory } from './DocumentCategory';
import { Dispatcher } from './Dispatcher';
import { Driver } from './Driver';
import { Notification } from './Notification';
import { SafetyOfficer } from './SafetyOfficer';
import { User } from './User';


// Combine Reducers
const appReducer = combineReducers({
  form: ReduxForm,
  Main,
  Auth,
  Company,
  Admin,
  Subscription,
  DocumentCategory,
  DocumentLayout,
  Dispatcher,
  Driver,
  Notification,
  SafetyOfficer,
  User,
  routing: routerReducer,
});

const rootReducer = (state = {}, action) => {
  if (action.type === ActionConstants.CLEAR_STATE) {
    return {
      routing: state.routing,
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
