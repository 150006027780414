import PropTypes from 'prop-types';
import React from 'react';
import Parse from 'parse';
import moment from 'moment';
import { Modal } from 'react-bootstrap';

import store from '../../../store';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';

// Components

// CSS
// import './CreatePlanModal.module.scss';

const getInitialState = (currency) => ({
  planName: '',
  internalName: '',
  costPerMonth: 0,
  currency: currency || 'cad',
  interval: 'month',
  intervalCount: 1,
  error: '',
  loading: false,
})

class CreatePlanModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.addPlan = this.addPlan.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.show && !nextProps.show) {
      this.setState(getInitialState(nextProps.currency));
    } else if (!this.props.show && nextProps.show) {
      this.setState({ ...this.state, currency: nextProps.currency });
    }
  }

  addPlan() {
    this.setState({ ...this.state, error: '', loading: true }, () => {
      Setters.createPlan(
        this.state.planName,
        this.state.internalName,
        this.state.currency,
        this.state.costPerMonth * 100,
        this.state.interval,
        this.state.intervalCount,
      ).then(() => {
        this.setState({ ...this.state, loading: false });
        this.props.hideModal();
      }).catch((error) => {
        this.setState({ ...this.state, error: error.message, loading: false });
      });
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={() => this.props.hideModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Custom Monthly Pan</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <div>
              <b>Plan Name</b>
              <br />
              <b><i>Make sure this is a PROFESSIONAL looking name. (Will show on Customer Invoice)</i></b>
              <br />
              Example:
              <br />
              "Switchboard Cloud Software"
              <br />
              "IFTA Module"
              <br />
              "BIT Trucking - Switchboard Plan"
              <br />
              <input value={this.state.planName} onChange={(e) => this.setState({ ...this.state, planName: e.target.value })} />
            </div>

            <br /><br />

            <div>
              <b>Internal Name</b>
              <br />
              Example: CLOUD - CA (3 YEAR), IFTA - US (Annual), BIT Trucking - GPS, Cloud (6 month billing)
              <br />
              <input value={this.state.internalName} onChange={(e) => this.setState({ ...this.state, internalName: e.target.value })} />
            </div>

            <br /><br />

            <div>
              <b>Billing Interval:</b>
              <br />
              Every &nbsp;
              <input
                type="number"
                step="1"
                value={this.state.intervalCount}
                onChange={(e) => this.setState({ ...this.state, intervalCount: e.target.value })}
              />
              <select value={this.state.interval} onChange={(e) => this.setState({ ...this.state, interval: e.target.value })}>
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </select>
            </div>

            <br /><br />

            <div>
              <b>Charge per unit (per {this.state.intervalCount} {this.state.interval}):</b>
              <br />
              <input
                type="number"
                step="0.01"
                value={this.state.costPerMonth}
                onChange={(e) => this.setState({ ...this.state, costPerMonth: e.target.value })}
              />
              <select value={this.state.currency} onChange={(e) => this.setState({ ...this.state, currency: e.target.value })}>
                <option value="cad">CAD</option>
                <option value="usd">USD</option>
              </select>
            </div>

            <br /><br />
            <button
              className={`buttonDefault`}
              onClick={() => this.addPlan()}
              disabled={this.state.loading}
            >
              Create Custom Plan
            </button>
            <span className="errorText">{this.state.error}</span>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

CreatePlanModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

export default CreatePlanModal;
