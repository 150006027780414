import Parse from 'parse';
import PropTypes from 'prop-types';
import React from 'react';
import { debounce } from 'debounce';

// API
import * as Setters from 'api/Setters';

// Components
import FileUploader from 'components/FileUploader/container/FileUploader';

// // Styles
// import './ContractFileUploader.module.scss';

function addDocument(documentFile, jobLink, documentCategory) {
  // ROTATION for constrained aspect ratio: https://github.com/MrRio/jsPDF/commit/b6f019ced0f31939878feaa0dca4f2b3c4c20b84
  // Fit to page: http://stackoverflow.duapp.com/questions/36472094/how-to-set-image-to-fit-width-of-the-page-using-jspdf
  const companyReadWriteACL = Helpers.getCompanyReadWriteACL();
  const parseFileName = documentCategory.get('type').concat('.pdf');
  const promise = new Promise((resolve, reject) => {
    if (/\.(jpe?g|png)$/i.test(documentFile.name)) {
      Helpers.scaleAndConvertToPDF(documentFile).then((dataURI) => {
        const base64 = dataURI.split('base64,')[1];
        const parseFile = new Parse.File(parseFileName, { base64 });
        resolve(addRecord('Document', { file: parseFile, jobLink, jobAction: jobLink.get('jobActions'), documentCategory, uploadedBy: Parse.User.current() }, companyReadWriteACL));
      });
    } else {
      const parseFile = new Parse.File(parseFileName, documentFile, documentFile.type);
      resolve(addRecord('Document', { file: parseFile, jobLink, jobAction: jobLink.get('jobActions'), documentCategory, uploadedBy: Parse.User.current() }, companyReadWriteACL));
    }
  });
  return promise;
}

class ContractFileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      debounceSave: null,
      disableButton: false,
      errorText: '',
    };
  }

  componentDidMount() {
    const debounceSave = debounce((file) => {
      // this.setState({ ...this.state, disableButton: true }, () => {
      //   this.setState({ ...this.state, disableButton: false });
      // });
      this.setState({ ...this.state, disableButton: true }, () => {
        const parseFile = new Parse.File('contract.pdf', file, file.type);

        const subscriptionContractObj = new Parse.Object('SubscriptionContract');
        subscriptionContractObj.set('subscription', this.props.subscriptionObj);
        subscriptionContractObj.set('contractFile', parseFile);
        try {
          subscriptionContractObj.save().then(() => {
            this.setState({ ...this.state, disableButton: false });
            this.props.refreshSubscription();
          });
        } catch (e) {
          console.log('contract upload error', e);
          this.setState({ ...this.state, errorText: e });
        }
      });
    }, 500);
    this.setState({ ...this.state, debounceSave });
  }

  render() {
    const { subscriptionObj } = this.props;
    return (
      <div>
        <div style={{ display: 'inline-block' }}>
          <div>
            <FileUploader
              inputId="contractUploader"
              label="Upload Contract"
              uploadHandler={(file) => {
                this.state.debounceSave(file);
              }}
              acceptedFileType="application/pdf"
              raised
              disabled={!this.props.subscriptionObj || this.state.disableButton}
            />
            {this.state.errorText &&
              <div>{this.state.errorText}</div>
            }
          </div>
        </div>
      </div>
    );
  }
}

ContractFileUploader.propTypes = {
  subscriptionObj: PropTypes.object.isRequired,
};

// const mapDispatchToProps = (dispatch) => ({
//   actions: bindActionCreators({
//     // fetchJobsIfNeeded,
//     fetchDocumentsForState,
//   }, dispatch),
// });

export default ContractFileUploader;
