import * as ActionConstants from './ActionConstants';
import history from 'sbHistory';
import * as Parse from 'api/Parse';
import store from 'store';

import * as Getters from '../api/Getters';

export function fetchingUsers() {
  return {
    type: ActionConstants.FETCH_USERS_INPROGRESS,
  };
}

export function fetchUsersSuccess(users) {
  return {
    type: ActionConstants.FETCH_USERS_SUCCESS,
    users,
  };
}

export function fetchUsersError(error) {
  return {
    type: ActionConstants.FETCH_USERS_ERROR,
    error,
  };
}

export function deleteUsers() {
  return {
    type: ActionConstants.DELETE_USERS,
  };
}

export function registerUser() {
  return {
    type: ActionConstants.REGISTER_USER_INPROGRESS,
  };
}

export function registerSuccess(user) {
  return {
    type: ActionConstants.REGISTER_USER_SUCCESS,
    user,
  };
}

export function registerError(error) {
  return {
    type: ActionConstants.REGISTER_USER_ERROR,
    error,
  };
}

export function fetchUsersForState(username, property, hideDisabled) {
  store.dispatch(fetchingUsers());
  Getters.getUsersByProperty(username, property, hideDisabled).then(
    (userParseObjArr) => {
      // Ensure cloud people come first
      const sortedUserParseObjArr = userParseObjArr.sort((a, b) => {

        if (a.get('userType') && b.get('userType')) {
          if (a.get('userType').indexOf(0) !== -1 || a.get('userType').indexOf(3) !== -1 || a.get('userType').indexOf(5) !== -1) {
            return -1;
          } else if (b.get('userType').indexOf(0) !== -1 || b.get('userType').indexOf(3) !== -1 || b.get('userType').indexOf(5) !== -1) {
            return 1;
          }
        }
        return 0;
      });
      store.dispatch(fetchUsersSuccess(sortedUserParseObjArr));
    },
    (err) => {
      store.dispatch(fetchUsersError(err));
    }
  );
}

export function deleteUsersForState() {
  store.dispatch(deleteUsers());
}

export function registerAdmin(registerValuesObject) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(registerUser());
    if (Parse.getCurrentUser()) {
      Parse.logout();
    }
    Parse.registerAdminToParse(registerValuesObject).then(
      parseUser => {
        Parse.login(registerValuesObject.emailAddress, registerValuesObject.password).then(
          user => {
            history.push('/documents');
            resolve(user);
            store.dispatch(registerSuccess(parseUser));
          },
          error => {
            reject(error);
            store.dispatch(registerError(error));
          }
        );
      },
      error => {
        reject(error);
        store.dispatch(registerError(error));
      }
    );
  });
  return promise;
}
