import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

// API
import * as Helpers from 'api/Helpers';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './ForgotPasswordModal.module.css';

class ForgotPasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      changeInProgress: false,
      passwordSent: false,
      error: undefined,
      disableConfirm: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setModalEmail = this.setModalEmail.bind(this);
  }

  componentDidMount() {
    if (this.props.email) {
      this.setModalEmail(this.props.email);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.email && (nextProps.email !== this.props.email)) {
      this.setModalEmail(nextProps.email);
    }
  }

  setModalEmail(email) {
    this.setState({ ...this.state, email });
  }

  changePassword() {
    this.setState({ ...this.state, changeInProgress: true }, () => {
      this.props.forgotPasswordFormHandler(this.state.email).then(
        () => {
          this.setState({ ...this.state, changeInProgress: false, passwordSent: true });
        },
        error => {
          this.setState({ ...this.state, changeInProgress: false, passwordSent: true, error });
        }
      );
    });
  }

  handleClose() {
    const newState = { ...this.state, changeInProgress: false, passwordSent: false, error: undefined };
    this.setState(newState);
    this.props.handleClose();
  }

  handleChange(e) {
    const { target } = e;
    const newState = { ...this.state };
    newState[target.id] = target.value;

    if (Helpers.isStringEmpty(newState[target.id])) {
      newState.disableConfirm = true;
    } else {
      newState.disableConfirm = false;
    }
    this.setState(newState);
  }

  render() {
    return (
      <Modal className={styles.forgotPasswordModal} show={this.props.show} onHide={this.props.handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password?</Modal.Title>
        </Modal.Header>

        <Modal.Body className="centerText">
          {!this.state.passwordSent &&
            <span>
              Send Password Reset Email to&nbsp;
              <input
                id="email"
                className={`input ${styles.input}`}
                value={this.state.email}
                placeholder="....."
                onChange={this.handleChange}
                autoComplete="off"
              />?
            </span>
          }
          {this.state.passwordSent && !this.state.error &&
            <span>A Password Reset Email has been sent to {this.state.email}!</span>
          }
          {this.state.error && this.state.error.code === 141 &&
            <div className={styles.error}>{this.state.error.message}</div>
          }
        </Modal.Body>

        <Modal.Footer>
          {!this.state.changeInProgress && !this.state.passwordSent &&
            <span>
              <button className={`buttonDefault closeButton`} onClick={this.handleClose}>Cancel</button>
              <button className={`buttonDefault`} onClick={this.changePassword} disabled={this.state.disableConfirm}>Confirm</button>
            </span>            
          }

          {this.state.changeInProgress && !this.state.passwordSent &&
            <LoadingIcon />
          }

          {this.state.passwordSent &&
            <button className={`buttonDefault closeButton`} onClick={this.handleClose}>Close</button>
          }
        </Modal.Footer>
      </Modal>
    );
  }
}

ForgotPasswordModal.propTypes = {
  forgotPasswordFormHandler: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  email: PropTypes.string,
};

export default ForgotPasswordModal;
