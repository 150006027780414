import PropTypes from 'prop-types';
import React from 'react';
import history from 'sbHistory';
import LazyLoad from 'react-lazy-load';

import { Table } from 'react-bootstrap';

// API
import * as Helpers from 'api/Helpers';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import CompanyTableRow from 'components/CompaniesTable/container/CompanyTableRow';
import ResellerCompanyModal from 'components/ResellerCompanyModal/container/ResellerCompanyModal';

// CSS
import styles from './CompaniesTable.module.scss';

class CompaniesTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      processing: true,
      resellerCompany: {
        show: false,
        company: null,
      }
    };
    this.nameSort = this.nameSort.bind(this);
    this.showResellerCompanyModal = this.showResellerCompanyModal.bind(this);
  }

  nameSort(company, nextCompany) {
    const comparatorA = company.name.toLowerCase();
    const comparatorB = nextCompany.name.toLowerCase();
    if (comparatorA > comparatorB) {
      return 1;
    } else if (comparatorA < comparatorB) {
      return -1;
    }
    return 0;
  }

  showResellerCompanyModal(company) {
    this.setState({ ...this.state, resellerCompany: { ...this.state.resellerCompany, show: true, company }});
  }

  render() {
    console.log(this.state);
    return (
      <div className="defaultTable">
        <Table responsive hover>
          <thead>
            <tr>
              <th>Company Name</th>
              {
                // <th>Admin</th>
                // <th>Number of Drivers</th>
              }
              <th>Created On</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {!this.props.fetched &&
              (
                <tr className={styles.loadingIconNoHover}>
                  <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
                </tr>
              )
            }
            {this.props.fetched && this.props.companies.length === 0 &&
              (
                <tr>
                  <td colSpan="7" className="emptyRowMessage">No Companies to Display</td>
                </tr>
              )
            }
            {this.props.fetched && this.props.companies.length > 0 &&
              this.props.companies.map((company) => (
                <CompanyTableRow
                  key={company.id}
                  company={company}
                  showResellerCompanyModal={this.showResellerCompanyModal}
                  hideResellerCompanyModal={() => this.setState({ ...this.state, resellerCompany: { ...this.state.resellerCompany, show: false } })}
                />
              ))
            }
          </tbody>
        </Table>
        <ResellerCompanyModal
          show={this.state.resellerCompany.show}
          company={this.state.resellerCompany.company}
          hideModal={() => this.setState({ ...this.state, resellerCompany: { ...this.state.resellerCompany, show: false } })}
          />
      </div>
    );
  }
}

CompaniesTable.propTypes = {
  companies: PropTypes.array.isRequired,
  fetched: PropTypes.bool.isRequired,
};

export default CompaniesTable;

