import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

// API
import * as Sim from 'api/Sim';
import * as Mapbox from 'api/Mapbox';
import * as Getters from 'api/Getters';

// Components
import DatePicker from 'components/DatePicker/container/DatePicker';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
// import './TwilioInfo.module.scss';

const initialState = () => ({
  simInfo: {},
  loading: false,
});

class TwilioInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.show && nextProps.show) {
      this.setState({
        ...this.state,
        loading: true,
      }, () => {
        Sim.getSimSessionInfo(nextProps.iccid)
          .then((simInfo) => {
            if (simInfo.locationEstimate) {
              Getters.getLocalGeocode(simInfo.locationEstimate.lon, simInfo.locationEstimate.lat).then((reverseGeocode) => {
                let address = '';
                console.log(reverseGeocode);
                if (reverseGeocode) {
                  address = `${reverseGeocode.name}, ${reverseGeocode.admin1Code.name}`;
                }
                this.setState({ ...this.state, simInfo: { ...simInfo, address }, loading: false });
              });
            } else {
              this.setState({ ...this.state, simInfo, loading: false });
            }
          });
      });
    } else if (!nextProps.show && this.props.show) {
      this.setState(initialState());
    }
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Sim Info
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            {this.state.loading &&
              <LoadingIcon />
            }
            {!this.state.loading && !this.state.simInfo.start &&
              <b>No Data Session found</b>
            }
            {!this.state.loading && this.state.simInfo.start &&
              <div>
                <b>Start: </b> {moment(this.state.simInfo.start).format('LLL')}
                <br /><br />
                <b>End: </b> {this.state.simInfo.end && moment(this.state.simInfo.start).format('LLL')}{!this.state.simInfo.end && <span>None</span>}
                <br /><br />
                <div>
                  <b>Location: </b>
                  <div>
                    {!this.state.simInfo.locationEstimate &&
                      <span>No Location Found</span>
                    }
                    {this.state.simInfo.locationEstimate &&
                      <div>
                        <span>{this.state.simInfo.address}</span>
                        <br />
                        <a
                          href={`https://maps.google.com/maps?q=${this.state.simInfo.locationEstimate.lat},${this.state.simInfo.locationEstimate.lon}&iwd=0&z=18`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="text-blue-sea" style={{ fontSize: '.8em', fontWeight: '400', textDecoration: 'underline' }}>
                            {`(${this.state.simInfo.locationEstimate.lat},  ${this.state.simInfo.locationEstimate.lon})`}
                          </div>
                        </a>
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

TwilioInfo.propTypes = {
  hideModal: PropTypes.func.isRequired,
  iccid: PropTypes.string.isRequired,
  show: PropTypes.bool,
};

export default TwilioInfo;
