import PropTypes from 'prop-types';
import React from 'react';
import Parse from 'parse';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { DatePicker, Card, CardActions, CardHeader, CardText, CardTitle, Table, TableHeader, TableRow, TableBody, TableRowColumn, TextField, Toggle } from 'material-ui';

import * as ParseAPI from 'api/Parse';
import { getELDCertifications, formatMilliTime } from 'api/ELD';

import store from '../../../store';
import * as Auth from 'api/Auth';
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';


// Components


// styles
import styles from './ELDDailyCertificationsModal.module.scss';

function getInitialState(eldDailyCertificationArr) {
  return {
    eldDailyCertificationArr: eldDailyCertificationArr || [],
    loadingSetDisableEldDailyCertification: false,
    error: '',
  };
}

class ELDDailyCertificationsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.refreshEldDailyCertifications = this.refreshEldDailyCertifications.bind(this);
    this.setDisableEldDailyCertification = this.setDisableEldDailyCertification.bind(this);
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.refreshEldDailyCertifications(nextProps);
    }
  }

  async refreshEldDailyCertifications(nextProps) {
    const eldDailyCertificationArr = await Getters.getEldDailyCertificationsForDriver(nextProps ? nextProps.driver.id : this.props.driver.id);
    this.setState(getInitialState(eldDailyCertificationArr));
  }

  async setDisableEldDailyCertification(eldDailyCertificationObj, disableBool) {
    const newState = { ...this.state };
    newState.loadingSetDisableEldDailyCertification = true;
    this.setState(newState);

    try {
      await Setters.setDisableEldDailyCertification(eldDailyCertificationObj.id, disableBool);

      const newState = { ...this.state };
      newState.loadingSetDisableEldDailyCertification = false;
      eldDailyCertificationObj.set('disabled', disableBool);
      this.setState(newState);
    } catch (e) {
      const newState = { ...this.state };
      newState.error = e;
      newState.loadingSetDisableEldDailyCertification = false;
      this.setState(newState);
    }
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>ELD Daily Certifications</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <Table
              selectable={false}
              multiSelectable={false}
            >
              <TableHeader
                displaySelectAll={false}
                adjustForCheckbox={false}
                enableSelectAll={false}
              >
                <TableRow>
                  <TableRowColumn style={{ textAlign: 'center', fontWeight: 500 }}>Date</TableRowColumn>
                  <TableRowColumn style={{ textAlign: 'center', fontWeight: 500 }}>Software/Firmware Version</TableRowColumn>
                  <TableRowColumn style={{ textAlign: 'center', fontWeight: 500 }}>HOS</TableRowColumn>
                  <TableRowColumn style={{ textAlign: 'center', fontWeight: 500 }}></TableRowColumn>
                </TableRow>
              </TableHeader>
              <TableBody
                displayRowCheckbox={false}
              >
                {this.state.eldDailyCertificationArr.map((eldDailyCertificationObj) => (
                  <TableRow key={eldDailyCertificationObj.id}>
                    <TableRowColumn >
                      {moment(eldDailyCertificationObj.get('startTimeUTC')).format('LLL')}
                    </TableRowColumn>
                    <TableRowColumn >
                      <div>
                        Software: <kbd>{eldDailyCertificationObj.get('driverSoftwareVersion')}</kbd>
                      </div>
                      <div>
                        Engine Module: <kbd>{eldDailyCertificationObj.get('engineModuleVersion')}</kbd>
                      </div>
                      <div>IMEI: <kbd>{eldDailyCertificationObj.get('deviceIMEI')}</kbd></div>
                    </TableRowColumn>
                    <TableRowColumn >
                      <br />
                      <div>Driving Time: {eldDailyCertificationObj.get('driveConsumed') && formatMilliTime(eldDailyCertificationObj.get('driveConsumed'))}</div>
                      <div>On Duty Time: {eldDailyCertificationObj.get('onDutyConsumed') && formatMilliTime(eldDailyCertificationObj.get('onDutyConsumed'))}</div>
                      <div>CA Cycle Remaining: {eldDailyCertificationObj.get('caOnDutyCycleHours') && formatMilliTime(eldDailyCertificationObj.get('caOnDutyCycleHours'))}</div>
                      <div>US Cycle Remaining: {eldDailyCertificationObj.get('usOnDutyCycleHours') && formatMilliTime(eldDailyCertificationObj.get('usOnDutyCycleHours'))}</div>
                      <div>
                        Notes:<br />
                        {<div><kbd>ID: {eldDailyCertificationObj.id}</kbd></div>}
                        {eldDailyCertificationObj.get('isAdverseDriver') && <div><kbd>Adverse Driving</kbd></div>}
                        {eldDailyCertificationObj.get('hosViolations') && eldDailyCertificationObj.get('hosViolations').length > 0 && <div><kbd>{eldDailyCertificationObj.get('hosViolations').length} Violations</kbd></div>}
                        {eldDailyCertificationObj.get('certified') && <div><kbd>Certified</kbd></div>}
                        {eldDailyCertificationObj.get('completed') && <div><kbd>Completed</kbd></div>}
                        <br />
                      </div>
                    </TableRowColumn>
                    <TableRowColumn >
                      {!eldDailyCertificationObj.get('disabled') &&
                        (
                          <button
                            className="buttonDefault yellowButton"
                            disabled={this.state.loadingSetDisableEldDailyCertification}
                            onClick={() => this.setDisableEldDailyCertification(eldDailyCertificationObj, true)}
                          >
                            Disable
                          </button>
                        )
                      }
                      {eldDailyCertificationObj.get('disabled') &&
                        (
                          <button
                            className="buttonDefault"
                            disabled={this.state.loadingSetDisableEldDailyCertification}
                            onClick={() => this.setDisableEldDailyCertification(eldDailyCertificationObj, false)}
                          >
                            Enable
                          </button>
                        )
                      }
                    </TableRowColumn>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div>
            {this.state.error &&
              (
                <div className={styles.error}>
                  {this.state.error}
                </div>
              )
            }
          </div>
          <div className={styles.selection}>
            <button className={`${styles.cancelButton} buttonDefault`} onClick={this.props.handleCloseModal} >Close</button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

ELDDailyCertificationsModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  user: PropTypes.object,
  show: PropTypes.bool,
};

export default ELDDailyCertificationsModal;
