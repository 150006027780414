import PropTypes from 'prop-types';
import React from 'react';
import { Marker } from 'react-mapbox-gl';

import styles from './ComponentStyles.module.scss';

function GPSPoint(props) {
  return (
    <Marker
      onMouseEnter={() => { if (props.handleMouseEnter) props.handleMouseEnter(); }}
      onMouseLeave={() => { if (props.handleMouseLeave) props.handleMouseLeave(); }}
      coordinates={props.coordinates}
    >
      <div><i className={`material-icons md-18 ${styles.gpsPoint} ${styles.marker}`} style={{ color: props.color }}>radio_button_checked</i></div>
    </Marker>
  );
}

GPSPoint.propTypes = {
  coordinates: PropTypes.object,
  color: PropTypes.string,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
};

export default GPSPoint;
