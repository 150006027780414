import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// Actions

// API
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';

// Components
import Title from 'components/LayoutTitle/view/Title';
import SimsTable from 'components/SimsTable/container/SimsTable';
import UploadSimFileModal from './UploadSimFileModal';
import DatePicker from 'components/DatePicker/container/DatePicker';
import ToggleSimModal from './ToggleSimModal';

// CSS
import styles from './SimsLayout.module.scss';

class SimsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment().toDate(),
      loading: false,
      simArr: [], // holds companies (all or active) based on filter dropdown view
      inputs: {
        companyName: '',
      },
      filter: {
        valueType: '',
        value: '',
      },
      showUploadSimFileModal: false,
      showToggleSimModal: false,
    };
    this.handleEntry = this.handleEntry.bind(this);
    this.getSimsForPlanChange = this.getSimsForPlanChange.bind(this);
    this.getSimArr = this.getSimArr.bind(this);
  }

  handleEntry(valueType, value) {
    this.setState({
      ...this.state,
      filter: {
        valueType,
        value,
      }
    }, () => {
      this.getSimArr();
    });
  }

  getSimArr() {
    this.setState({
      ...this.state,
      loading: true,
    }, () => {
      Getters.getSimArr(this.state.filter.valueType, this.state.filter.value).then((simArr) => {
        // Don't filter by duplicates
        // const filteredSimArr = [];
        // const iccidMap = {};
        // for (let i = 0; i < simArr.length; i++) {
        //   const simObj = simArr[i];
        //   if (simObj.get('iccid') && !iccidMap[simObj.get('iccid')]) {
        //     iccidMap[simObj.get('iccid')] = true;
        //     filteredSimArr.push(simObj);
        //   }
        // }
        this.setState({ ...this.state, loading: false, simArr: simArr.sort((a, b) => a.get('iccid') - b.get('iccid')) });
      });
    });
  }

  getSimsForPlanChange() {
    this.setState({ ...this.state, error: undefined }, () => {
      if (this.state.inputs.companyName) {
        this.setState({ ...this.state, loading: true }, () => {
          Getters.getSimsForPlanChange(this.state.inputs.companyName).then((simArr) => {
            const filteredSimArr = [];
            const iccidMap = {};
            for (let i = 0; i < simArr.length; i++) {
              const simObj = simArr[i];
              if (simObj.get('iccid') && !iccidMap[simObj.get('iccid')]) {
                iccidMap[simObj.get('iccid')] = true;
                filteredSimArr.push(simObj);
              }
            }
            this.setState({ ...this.state, loading: false, simArr: filteredSimArr.sort((a, b) => a.get('iccid') - b.get('iccid')) });
          }).catch((err) => {
            this.setState({ ...this.state, loading: false, error: err });
          });
        });
      } else {
        this.setState({ ...this.state, loading: false, error: { message: 'Please enter company name first' } });
      }
    });
  }

  render() {
    return (
      <div className={styles.SimsLayout}>
        <Title title="Sim">
          <span className="inlineBlock">
            <input
              type="text"
              className="buttonSpacedRight"
              onKeyPress={(e) => {
                if (e.key === 'Enter') this.handleEntry('companyName', e.target.value);
                else this.setState({ ...this.state, inputs: { ...this.state.inputs, companyName: e.target.value } })
              }}
              placeholder="Enter Company" />
            <input type="text" className="buttonSpacedRight" onKeyPress={(e) => e.key === 'Enter' && this.handleEntry('imei', e.target.value)} placeholder="Enter IMEI #" />
            <input type="text" className="buttonSpacedRight" onKeyPress={(e) => e.key === 'Enter' && this.handleEntry('iccid', e.target.value)} placeholder="Enter Sim #" />
            <input type="text" className="buttonSpacedRight" onKeyPress={(e) => e.key === 'Enter' && this.handleEntry('trackerEsn', e.target.value)} placeholder="Enter ESN #" />
            <DatePicker
              className="input"
              selected={moment(this.state.date).toDate()}
              placeholderText="Choose Date"
              onChange={(date) => this.setState({ ...this.state, date: moment(date).toDate() })}
            />
            <button
              className={'buttonDefault'}
              onClick={() => this.setState({ ...this.state, showUploadSimFileModal: true })}
            >
              Upload Sim File
            </button>
            &nbsp;
            <button
              className={'buttonDefault'}
              onClick={() => this.getSimsForPlanChange()}
            >
              Get Sims for Plan Change
            </button>
            &nbsp;
            <button
              className="buttonDefault"
              onClick={() => this.setState({ ...this.state, showToggleSimModal: true })}
            >
              Link SIM ICCID/IMEI
            </button>
            <ToggleSimModal
              show={this.state.showToggleSimModal}
              handleClose={() => this.setState({ ...this.state, showToggleSimModal: false })}
            />
          </span>
          {this.state.error &&
            (
              <div className='errorText'>
                {this.state.error.message}
              </div>
            )
          }
        </Title>
        <UploadSimFileModal
          hideModal={() => this.setState({ ...this.state, showUploadSimFileModal: false })}
          show={this.state.showUploadSimFileModal}
        />
        <SimsTable
          loading={this.state.loading} // pass it Company state to get action state
          simArr={this.state.simArr}
          date={this.state.date}
          refreshSimArr={this.getSimArr}
        />
      </div>
    );
  }
}

SimsLayout.propTypes = {
  Company: PropTypes.object.isRequired,
};

export default SimsLayout;
