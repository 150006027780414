import React from 'react';
import PropTypes from 'prop-types';
import { version } from '../../../package.json';

// Containers
import Login from 'components/Login/container/Login';

// CSS
import styles from './LoginLayout.module.css';

// Assets
const colourLogo = require('assets/images/colour-logo-and-text.png');

function getLogoURLFromCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(cname) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export default function (props) {
  document.title = 'Switchboard Internal Tool';
  const companyLogoUrl = getLogoURLFromCookie('SwitchboardCompanyLogoURL');

  return (
    <div className={styles.loginBackground}>
      <div className={styles.loginContent}>
        {!companyLogoUrl &&
          <div className={styles.logoContainer}>
            <a href="https://www.onswitchboard.com">
              <img className={styles.loginLogo} role="presentation" src={colourLogo} />
            </a>
          </div>
        }
        {companyLogoUrl &&
          <div className={styles.logoContainer}>
            <table><tbody><tr>
              <td>
                <a href="https://www.onswitchboard.com">
                  <img className={styles.loginLogo} role="presentation" src={colourLogo} />
                </a>
              </td>
              <td>
                <img className={styles.loginLogo} role="presentation" src={companyLogoUrl} />
              </td>
            </tr></tbody></table>
          </div>
        }
        <Login
          {...props}
        />
      </div>
    </div>
  );
}
