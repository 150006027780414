import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import TripInspectionType from 'api/Lists/TripInspectionType';

// Components
import DatePicker from 'components/DatePicker/container/DatePicker';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './EditUnitIdModal.module.scss';

const initialState = () => ({
  startDate: moment(),
  endDate: moment(),
  oldUnitId: '',
  newUnitId: '',
  loading: false,
  allowEdit: false,
  error: '',
});

class EditUnitIdModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState();
    this.handleInput = this.handleInput.bind(this);
    this.editUnitId = this.editUnitId.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.show && this.props.show) {
      this.setState(initialState());
    }
  }

  handleInput(date, type) {
    const newState = { ...this.state };
    if (type === 'startDate' || type === 'endDate') {
      if (type === 'startDate') {
        newState[type] = moment(date);
      } else if (type === 'endDate') {
        newState[type] = moment(date);
      }
    } else {
      newState[type] = date;
    }

    newState.error = '';

    if (newState.endDate.diff(newState.startDate, 'days') > 3) {
      newState.allowEdit = false;
      newState.error = 'Date range too large (greater than 2 days)';
    } else if (!this.state.startDate || !this.state.endDate) {
      newState.allowEdit = false;
    } else {
      newState.allowEdit = true;
    }

    this.setState(newState);
  }

  editUnitId() {
    this.setState({ ...this.state, loading: true }, () => {
      Setters.repairELDVehicleUnitId(
        this.props.driver.get('belongsToCompany').id,
        this.state.startDate.toDate(),
        this.state.endDate.toDate(),
        this.props.driver.id,
        this.state.oldUnitId,
        this.state.newUnitId
      ).then(() => {
        this.setState({ ...this.state, loading: false });
      }, (error) => {
        console.log(error);
        this.setState({ ...this.state, loading: false, error });
      });
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Unit Id
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <h5>Choose Time Period</h5>
            <div>
              <DatePicker
                className="input"
                selected={moment(this.state.startDate).toDate()}
                placeholderText="Start Date"
                showTimeSelect
                timeIntervals={15}
                dateFormat="MMM d, HH:mm"
                onChange={(date) => this.handleInput(date, 'startDate')}
              />
              &nbsp; to &nbsp;
              <DatePicker
                className="input"
                selected={moment(this.state.endDate).toDate()}
                placeholderText="End Date"
                showTimeSelect
                timeIntervals={15}
                dateFormat="MMM d, HH:mm"
                onChange={(date) => this.handleInput(date, 'endDate')}
              />
            </div>

            <br />

            <h5>Old/Incorrect Unit ID</h5>
            <input
              value={this.state.oldUnitId}
              onChange={(e) => this.handleInput(e.target.value, 'oldUnitId')}
            />

            <br />

            <h5>New Unit ID</h5>
            <input
              value={this.state.newUnitId}
              onChange={(e) => this.handleInput(e.target.value, 'newUnitId')}
            />

            <br />
            <br />

            <button
              className={'buttonDefault'}
              onClick={() => this.editUnitId()}
              disabled={!this.state.allowEdit || this.state.loading}
            >
              Save
            </button>

            <div className="errorText">
              {this.state.error && this.state.error.message}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

EditUnitIdModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  driver: PropTypes.object,
  show: PropTypes.bool,
};

export default EditUnitIdModal;
