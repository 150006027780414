import PropTypes from 'prop-types';
import React from 'react';
import { Marker } from 'react-mapbox-gl';

// CSS
import styles from './ComponentStyles.module.scss';

function VehicleMarker(props) {
  const vehicleLocationObj = props.vehicleObj.get('vehicleLocation');

  const markerStyle = `${styles.vehicle}`;
  return (
    <Marker
      coordinates={[vehicleLocationObj.get('location').longitude, vehicleLocationObj.get('location').latitude]}
      offset={[0, -7]}
    >
      <div
        className={markerStyle}
        onClick={props.handleOnClick}
      >
        <i className={`material-icons md-36 ${styles.vehicleMarker}`}>local_shipping</i>
      </div>
    </Marker>
  );
}

VehicleMarker.propTypes = {
  vehicleObj: PropTypes.object.isRequired,
  active: PropTypes.bool,
  handleOnClick: PropTypes.func,
};

export default VehicleMarker;
