import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Actions

// API
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';

// Components
import Title from 'components/LayoutTitle/view/Title';
import InvoicesTable from 'components/InvoicesTable/container/InvoicesTable';
import ExpiredSubscriptionsTable from 'components/ExpiredSubscriptionsTable/container/ExpiredSubscriptionsTable';
import SubscriptionIssuesTable from 'components/SubscriptionIssuesTable/SubscriptionIssuesTable';
import TrialSubscriptionsTable from 'components/TrialSubscriptionsTable/container/TrialSubscriptionsTable';

// CSS
// import './InvoicesLayout.module.scss';

class BillingIssuesLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
    };
  }

  render() {
    return (
      <div>

        <AppBar position="static">
          <Tabs value={this.state.tabValue} onChange={(e, newValue) => this.setState({ ...this.state, tabValue: newValue })} aria-label="simple tabs example">
            <Tab label="Past Due Invoices" />
            <Tab label="Billing Count Mismatch" />
            {/* <Tab label="Expired Subscriptions" />  not that useful */}
            <Tab label="Trial Subscriptions" />
            {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
        </AppBar>
        <br />

        {this.state.tabValue === 0 &&
          (
            <div>
              <span>
                <input type="text" className="buttonSpacedRight" onKeyPress={(e) => this.getInvoices(e, 'number', e.target.value)} placeholder="Enter Invoice #" />
                <input type="text" className="buttonSpacedRight" onKeyPress={(e) => this.getInvoices(e, 'receipt_number', e.target.value)} placeholder="Enter Receipt #" />
              </span>
              <InvoicesTable />
            </div>
          )
        }

        {this.state.tabValue === 1 &&
          (
            <div>
              {/* <span>
                <input type="text" className="buttonSpacedRight" onKeyPress={(e) => this.getInvoices(e, 'number', e.target.value)} placeholder="Enter Invoice #" />
                <input type="text" className="buttonSpacedRight" onKeyPress={(e) => this.getInvoices(e, 'receipt_number', e.target.value)} placeholder="Enter Receipt #" />
              </span> */}
              <SubscriptionIssuesTable />
            </div>
          )
        }
        {this.state.tabValue === 2 &&
          (
            <div>
              <ExpiredSubscriptionsTable />
            </div>
          )
        }
        {this.state.tabValue === 3 &&
          (
            <div>
              <TrialSubscriptionsTable />
            </div>
          )
        }

      </div>
    );
  }
}

BillingIssuesLayout.propTypes = {
  Company: PropTypes.object.isRequired,
};

export default BillingIssuesLayout;
