import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';

// Actions
import { fetchCompaniesForState, deleteCompaniesForState } from 'actions/Company';

// API
import * as Auth from 'api/Auth';
import * as Setters from 'api/Setters';

// Styles
import styles from './styles.module.scss';

// Components
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, TextField, Toggle } from 'material-ui';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';


const initialState = () => ({
  companyName: '',
  selectedCompany: undefined,
  error: '',
  loadingCompany: false,
  assignInProgress: false,
});

class AssignDeviceToAnotherCompanyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState();

    this.handleCompanyEntry = this.handleCompanyEntry.bind(this);
    this.handleSelectCompany = this.handleSelectCompany.bind(this);
    this.assignToCompany = this.assignToCompany.bind(this);
  }

  handleCompanyEntry(e) {
    if (e.key === 'Enter') {
      this.setState({ ...this.state, loadingCompany: true });
      Auth.callSecuredParseFunction('internal:getSubscriptionByCompanyName',
        {
          returnAsParse: true,
          parseClassName: 'Subscription',
          companyName: e.target.value,
          hideHidden: true,
        }
      ).then((subscriptionArr) => {
        this.setState({ ...this.state, subscriptionArr, fetched: true, loadingCompany: false });
      });
    }
  }


  handleSelectCompany(index) {
    this.setState({ ...this.state, selectedCompany: this.state.subscriptionArr[index].get('company') });
  }

  assignToCompany() {
    this.setState({ ...this.state, error: null, assignInProgress: true });
    const dataObj = {
      returnAsParse: true,
      imei: this.props.simObj.get('imei'),
      iccid: this.props.simObj.get('iccid'),
      trackerEsn: this.props.simObj.get('trackerEsn'),
      companyId: this.state.selectedCompany.id,
      value: this.state.selectedCompany.id,
      valueType: 'belongsToCompany',
      reason: `${moment().format('lll')}: Swap from ${this.props.simObj.get('belongsToCompany').get('name')} to ${this.state.selectedCompany.get('name')}`
    };

    Auth.callSecuredParseFunction('_internal:setSimForCompany', dataObj).then((response) => {
      this.setState({ ...this.state, selectedCompany: null, assignInProgress: false });
      this.props.handleClose();
    }).catch((err) => {
      console.log(err);
      this.setState({ ...this.state, error: err.message, assignInProgress: false });
    });
  }

  render() {
    const { handleModalChoice, handleClose, show } = this.props;
    return (
      <Modal
        bsSize="lg"
        backdrop="static"
        show={show}
        onHide={handleClose}
      >

        <Modal.Header closeButton>
          <Modal.Title>Assign Device to Another Company</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <input
            type="text"
            className="buttonSpacedRight"
            onKeyPress={this.handleCompanyEntry}
            placeholder="Enter Company Name" />
          <Table
            multiSelectable={false}
            onRowSelection={this.handleSelectCompany}
          >
            <TableBody
              deselectOnClickaway={false}
            >
              {this.state.loadingCompany &&
                (
                  <LoadingIcon />
                )
              }
              {this.state.subscriptionArr &&
                this.state.subscriptionArr.filter((subscriptionObj) => subscriptionObj.get('company')).map((subscriptionObj) => (
                  <TableRow
                    key={subscriptionObj.id}
                    selected={this.state.selectedCompany && this.state.selectedCompany.id === subscriptionObj.get('company').id}
                  >
                    <TableRowColumn>{subscriptionObj.get('company').get('name')}</TableRowColumn>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>

          <br />
          <br />

          <div className="errorText">
            {this.state.error}
          </div>

          <div className={styles.choices}>
            <button
              className="closeButton"
              onClick={() => {
                handleClose();
              }}
              disabled={this.state.disableButton}
            >
              Cancel
            </button>
            <button
              className="buttonDefault"
              onClick={() => {
                this.assignToCompany();
              }}
              disabled={!this.state.selectedCompany && !this.state.assignInProgress}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>

      </Modal >
    );
  }
}

AssignDeviceToAnotherCompanyModal.propTypes = {
  handleModalChoice: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  simObj: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  Company: PropTypes.object.isRequired,
};

// const mapDispatchToProps = (dispatch) => ({
//   actions: bindActionCreators({
//     // fetchJobsIfNeeded,
//     fetchDocumentsForState,
//   }, dispatch),
// });

export default (AssignDeviceToAnotherCompanyModal);
