import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, Toggle, TextField } from 'material-ui';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import * as Sim from 'api/Sim';

// Components
import SimRow from './SimRow';
import TwilioInfo from '../../TwilioInfo/container/TwilioInfo';

// CSS
import styles from './SimsList.module.scss';

const ratePlanNameSidObj = {
  WP61b24eaf5a7bdf5289ae68b86535c6e1: 'PAYG',
  WP7653e2d708d5bf16985c84550625f654: 'NOROAM PAYG',
};

class SimsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: {
        tabletUuid: '',
        trackerEsn: '',
        imei: '',
        iccid: '',
        reason: '',
        ratePlanSid: 'WP7653e2d708d5bf16985c84550625f654',
      },
      simArr: [],
      simDataArr: [],
      filteredSimDataArr: [],
      addError: '',
      setSimInProgress: false,
      twilioInfo: {
        show: false,
        iccid: undefined,
      },
      activeCheck: {
        active: 0,
        processed: 0,
      },
      showActiveSimsBool: false,
      showOldLastOnlineDate: false,
      showRecentLastOnlineDate: false,
    };
    this.refreshCompanySims = this.refreshCompanySims.bind(this);
    this.toggleSim = this.toggleSim.bind(this);
    this.updateSimRatePlan = this.updateSimRatePlan.bind(this);
    this.setSimIdentifier = this.setSimIdentifier.bind(this);
    this.filterSimDataArr = this.filterSimDataArr.bind(this);
  }

  componentDidMount() {
    this.refreshCompanySims();
  }

  setSimIdentifier(simObj, type, value) {
    this.setState({ ...this.state, saving: true });
    if (
      type &&
      value &&
      simObj &&
      simObj.get('iccid') &&
      simObj.get('belongsToCompany')
    ) {
      Setters.setSimForCompany(
        value,
        type,
        simObj.get('iccid'),
        simObj.get('belongsToCompany').id,
        `setSimForCompany, setSimIdentifier: ${type}: ${value}`
      ).then((updatedSimObj) => {
        simObj.set(type, value);
        this.setState({ ...this.state, saving: false });
        return simObj;
      }).catch((err) => {
        console.log(err);
      });
    } else {
      this.setState({ ...this.state, saving: false });
    }
  }

  async refreshCompanySims() {
    Getters.getCompanySims(this.props.companyId).then(async (simArr) => {
      const simDataArr = simArr.map((simObj) => ({
        simObj,
        status: null,
        lastOnline: null,
        simVendor: null,
        ratePlanName: null,
      }));
      this.filterSimDataArr(simDataArr);
      const promises = [];
      const newSimDataArr = [];
      for (let i = 0; i < simDataArr.length; i++) {
        const simDataObj = simDataArr[i];
        const promise = new Promise((resolve) => {
          Sim.getSimInfo(simDataObj.simObj.get('iccid')).then((infoObj) => {

            simDataArr[i].status = infoObj.status;
            simDataArr[i].lastOnline = infoObj.lastOnline;
            simDataArr[i].simVendor = infoObj.simVendor;
            simDataArr[i].ratePlanName = infoObj.ratePlanName;

            newSimDataArr.push(simDataArr[i]);
            resolve();
          }).catch(() => { resolve() });
        });
        promises.push(promise);
      }
      await Promise.all(promises);

      const sortedSimDataArr = newSimDataArr.sort((a, b) => {
        if (a.simObj && a.status === 'active' && b.status !== 'active') {
          return -1;
        } else if (a.simObj && a.status !== 'active' && b.status === 'active') {
          return 1;
        } else if (a.lastOnline && b.lastOnline) {
          return moment(a.lastOnline).isAfter(moment(b.lastOnline)) ? -1 : 1;
        } else if (a.lastOnline && !b.lastOnline) {
          return -1;
        } else if (!a.lastOnline && b.lastOnline) {
          return 1;
        }
        return 0;
      });

      return this.setState({ ...this.state, simDataArr: sortedSimDataArr, filteredSimDataArr: sortedSimDataArr });
    });
  }

  async toggleSim(type, isActive) {
    await this.setState({ ...this.state, addError: '', setSimInProgress: false });
    if (!this.state.inputs.iccid) {
      return this.setState({ ...this.state, addError: 'Need ICCID', setSimInProgress: false });
    }

    if (!this.state.inputs.trackerEsn && !this.state.inputs.imei && !this.state.inputs.tabletUuid) {
      return this.setState({ ...this.state, addError: 'Need atleast one of IMEI, tabletUuid, or trackerEsn', setSimInProgress: false });
    }

    if (!this.state.inputs.reason || this.state.inputs.reason.length < 3) {
      return this.setState({ ...this.state, addError: 'Need reason', setSimInProgress: false });
    }

    this.setState({ ...this.state, addError: '', setSimInProgress: true }, () => {
      Setters.toggleSimForCompany(
        this.props.companyId,
        isActive,
        this.state.inputs.reason,
        this.state.inputs.ratePlanSid,
        this.state.inputs.iccid,
        this.state.inputs.imei,
        this.state.inputs.trackerEsn,
        this.state.inputs.tabletUuid,
      ).then((response) => {
        this.setState({ ...this.state, setSimInProgress: false }, () => {
          this.refreshCompanySims();
        });
      }, (error) => {
        if (error && error.message) {
          this.setState({ ...this.state, addError: error.message, setSimInProgress: false });
        } else if (error) {
          this.setState({ ...this.state, addError: error, setSimInProgress: false });
        } else {
          this.setState({ ...this.state, setSimInProgress: false });
        }
        this.refreshCompanySims();
      });
    });
  }

  updateSimRatePlan() {
    if (!this.state.inputs.iccid) {
      return this.setState({ ...this.state, addError: 'Need ICCID', setSimInProgress: false });
    }

    this.setState({ ...this.state, addError: '', setSimInProgress: true }, () => {
      Setters.updateSimRatePlan(
        this.props.companyId,
        this.state.inputs.iccid,
        'iccid',
        this.state.ratePlanSid
      ).then((response) => {
        this.setState({ ...this.state, setSimInProgress: false }, () => {
          this.refreshCompanySims();
        });
      }, (error) => {
        if (error && error.message) {
          this.setState({ ...this.state, addError: error.message, setSimInProgress: false });
        } else if (error) {
          this.setState({ ...this.state, addError: error, setSimInProgress: false });
        } else {
          this.setState({ ...this.state, setSimInProgress: false });
        }
        this.refreshCompanySims();
      });
    });
  }

  setSimIdentifier(simObj, type, value) {
    this.setState({ ...this.state, saving: true });
    if (
      type &&
      value &&
      simObj &&
      simObj.get('iccid') &&
      simObj.get('belongsToCompany')
    ) {
      Setters.setSimForCompany(
        value,
        type,
        simObj.get('iccid'),
        simObj.get('belongsToCompany').id,
        `setSimForCompany, setSimIdentifier: ${type}: ${value}`
      ).then((updatedSimObj) => {
        simObj.set(type, value);
        this.setState({ ...this.state, saving: false });
        return simObj;
      }).catch((err) => {
        console.log(err);
      });
    } else {
      this.setState({ ...this.state, saving: false });
    }
  }

  toggleTwilioInfoModal(show, iccid) {
    const newState = { ...this.state };
    newState.twilioInfo.show = show;
    newState.twilioInfo.iccid = iccid;
    this.setState(newState);
  }

  filterSimDataArr(simDataArr) {
    const filteredSimArr = (simDataArr || this.state.simDataArr).filter(
      (simDataObj) => {
        if (this.state.showOldLastOnlineDate &&
          (simDataObj.lastOnline && moment(simDataObj.lastOnline).isAfter(moment().subtract(60, 'days')))
        ) {
          return false;
        }

        if (this.state.showActiveSimsBool && simDataObj.status !== 'active') {
          return false;
        }

        if (this.state.showRecentLastOnlineDate &&
          (!simDataObj.lastOnline || moment(simDataObj.lastOnline).isBefore(moment().subtract(60, 'days')))
        ) {
          return false;
        }

        return true;
      }
    );

    this.setState({ ...this.state, filteredSimDataArr: filteredSimArr });
  }

  render() {
    return (
      <div>
        <TwilioInfo
          iccid={this.state.twilioInfo.iccid}
          show={this.state.twilioInfo.show}
          hideModal={() => this.toggleTwilioInfoModal(false)}
        />
        <div className={styles.enterSim}>
          <TextField
            hintText="Tablet UUID"
            floatingLabelText="UUID"
            onChange={(event, newValue) => this.setState({ ...this.state, inputs: { ...this.state.inputs, tabletUuid: newValue } })}
            value={this.state.inputs.tabletUuid}
          />
          <TextField
            hintText="Tracker ESN"
            floatingLabelText="Tracker ESN (Calamp Only)"
            onChange={(event, newValue) => this.setState({ ...this.state, inputs: { ...this.state.inputs, trackerEsn: newValue } })}
            value={this.state.inputs.trackerEsn}
          />
          <TextField
            hintText="Device IMEI"
            floatingLabelText="IMEI"
            onChange={(event, newValue) => this.setState({ ...this.state, inputs: { ...this.state.inputs, imei: newValue } })}
            value={this.state.inputs.imei}
          />
          <TextField
            hintText="Sim ICCID"
            floatingLabelText="Sim ICCID"
            onChange={(event, newValue) => this.setState({ ...this.state, inputs: { ...this.state.inputs, iccid: newValue } })}
            value={this.state.inputs.iccid}
          />
          &nbsp;
          <TextField
            hintText="Enter Reason"
            floatingLabelText="Reason"
            onChange={(event, newValue) => this.setState({ ...this.state, inputs: { ...this.state.inputs, reason: newValue } })}
            value={this.state.inputs.reason}
          />
          <br />
          <br />
          Plan: &nbsp;
          <select value={this.state.inputs.ratePlanSid} onChange={(e) => this.setState({ ...this.state, inputs: { ...this.state.inputs, ratePlanSid: e.target.value } })}>
            {Object.keys(ratePlanNameSidObj).map((ratePlanSid) => (
              <option value={ratePlanSid}>{ratePlanNameSidObj[ratePlanSid]}</option>
            ))}
          </select>
          <br />
          <br />
          {/* <button
            className={`${styles.startButton} buttonDefault`}
            onClick={() => this.toggleSim('iccid', true)}
            disabled={this.state.setSimInProgress}
          >
            Activate ICCID
          </button>
          &nbsp;
          <button
            className={`${styles.startButton} buttonDefault`}
            onClick={() => this.toggleSim('imei', true)}
            disabled={this.state.setSimInProgress}
          >
            Activate IMEI
          </button>
          &nbsp;
          <button
            className={`${styles.startButton} buttonDefault`}
            onClick={() => this.toggleSim('trackerEsn', true)}
            disabled={this.state.setSimInProgress}
          >
            Activate ESN
          </button>
          <br />
          <br />
          <button
            className={`${styles.startButton} buttonDefault`}
            onClick={() => this.toggleSim('iccid', false)}
            disabled={this.state.setSimInProgress}
          >
            Deactivate ICCID
          </button>
          &nbsp;
          <button
            className={`${styles.startButton} buttonDefault`}
            onClick={() => this.toggleSim('imei', false)}
            disabled={this.state.setSimInProgress}
          >
            Deactivate IMEI
          </button>
          &nbsp;
          <button
            className={`${styles.startButton} buttonDefault`}
            onClick={() => this.toggleSim('trackerEsn', false)}
            disabled={this.state.setSimInProgress}
          >
            Deactive ESN
          </button>
          <br />
            <br /> */}
          <button
            className={`${styles.startButton} buttonDefault`}
            onClick={() => this.toggleSim(undefined, true)}
            disabled={this.state.setSimInProgress}
          >
            Activate
          </button>
          &nbsp;
          <button
            className={`${styles.startButton} buttonDefault`}
            onClick={() => this.toggleSim(undefined, false)}
            disabled={this.state.setSimInProgress}
          >
            Deactivate
          </button>
          <br />
          <button
            className={`${styles.startButton} buttonDefault`}
            onClick={() => this.updateSimRatePlan()}
            disabled={this.state.setSimInProgress}
          >
            Update ICCID Rate Plan
          </button>
          {this.state.addError &&
            <div className={styles.error}>
              {this.state.addError}
            </div>
          }
        </div>
        <Table
          selectable={false}
          multiSelectable={false}
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          ><TableRow>
              <TableRowColumn style={{ width: '37%' }}>
                <b>Device ID & ICCID</b>
                <br />
                <br />
                IMEI/Tablet - {`${this.state.filteredSimDataArr.filter((simDataObj) => (simDataObj.status === 'active' && !simDataObj.simObj.get('trackerEsn') && simDataObj.simObj.get('imei'))).length}`} active
                <br />
                ESN/Tracker - {`${this.state.filteredSimDataArr.filter((simDataObj) => (simDataObj.status === 'active' && simDataObj.simObj.get('trackerEsn'))).length}`} active
                <br />
                Unknown - {`${this.state.filteredSimDataArr.filter((simDataObj) =>
                (
                  simDataObj.status === 'active' &&
                  !simDataObj.simObj.get('imei') &&
                  !simDataObj.simObj.get('trackerEsn')
                )
                ).length}`} active
                <br />
                Total Active - {`${this.state.filteredSimDataArr.filter((simDataObj) => (simDataObj.status === 'active')).length}`}
                <br />
                Total SIMs - {`${this.state.filteredSimDataArr.length}`}
              </TableRowColumn>
              <TableRowColumn style={{ width: '33%' }}>
                <div>
                  <input
                    type="checkbox"
                    id="checkboxActiveSims"
                    checked={this.state.showActiveSimsBool}
                    onChange={() => {
                      this.setState({ ...this.state, showActiveSimsBool: !this.state.showActiveSimsBool }, () => {
                        this.filterSimDataArr();
                      })
                    }}
                  />
                  <label htmlFor="checkbox"> Active SIMs</label>
                </div>

                <div>
                  <input
                    type="checkbox"
                    id="checkboxShowOld"
                    checked={this.state.showOldLastOnlineDate}
                    onChange={() => {
                      this.setState({ ...this.state, showOldLastOnlineDate: !this.state.showOldLastOnlineDate }, () => {
                        this.filterSimDataArr();
                      })
                    }}
                  />
                  <label htmlFor="checkboxShowOld"> Old Last Online (Before 60 Days)</label>
                </div>

                <div>
                  <input
                    type="checkbox"
                    id="checkboxShowRecent"
                    checked={this.state.showRecentLastOnlineDate}
                    onChange={() => {
                      this.setState({ ...this.state, showRecentLastOnlineDate: !this.state.showRecentLastOnlineDate }, () => {
                        this.filterSimDataArr();
                      })
                    }}
                  />
                  <label htmlFor="checkboxShowRecent"> Recent Last Online (Within 60 Days)</label>
                </div>

                <br />
                SIMs - {`${this.state.filteredSimDataArr.length}`}
                <br />
                IMEI/Tablet - {`${this.state.filteredSimDataArr.filter((simDataObj) => (!simDataObj.simObj.get('trackerEsn') && simDataObj.simObj.get('imei'))).length}`}
                <br />
                ESN/Tracker - {`${this.state.filteredSimDataArr.filter((simDataObj) => (simDataObj.simObj.get('trackerEsn'))).length}`}
                <br />
                Unknown - {`${this.state.filteredSimDataArr.filter((simDataObj) =>
                (
                  !simDataObj.simObj.get('imei') &&
                  !simDataObj.simObj.get('trackerEsn')
                )).length}`
                }
              </TableRowColumn>
              <TableRowColumn style={{ width: '20%' }}>Notes</TableRowColumn>
              <TableRowColumn style={{ width: '10%' }}>

              </TableRowColumn>
            </TableRow></TableHeader>
          <TableBody
            displayRowCheckbox={false}
          >
            {this.state.filteredSimDataArr.map((simDataObj) => (
              <SimRow
                simObj={simDataObj.simObj}
                simDataObj={simDataObj}
                showTwilioInfo={(iccid) => this.toggleTwilioInfoModal(true, iccid)}
                setSimIdentifier={this.setSimIdentifier}
                setActiveCheck={this.setActiveCheck}
                saving={this.state.saving}
                refreshCompanySims={this.refreshCompanySims}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

}

SimsList.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default SimsList;
