import PropTypes from 'prop-types';
import React, { useState } from 'react';
import moment from 'moment';
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, TextField, Toggle } from 'material-ui';

import styles from './Subscription.module.scss';

import ChargeSingleInvoiceModal from '../container/ChargeSingleInvoiceModal';

function Subscription(props) {
  const [showChargeSingleInvoiceModal, setShowChargeSingleInvoiceModal] = useState(0);

  return (
    <div>
      <div>
        <table className={styles.table}>
          {props.subscriptionBillingDescription &&
            <tr>
              <td>Billing Company Name: &nbsp; </td>
              <td>
                {props.subscriptionBillingDescription}
                {/* <input value={props.subscriptionBillingDescription} onChange={(e) => props.handleChangeSubscriptionBillingInfo('subscriptionBillingDescription', e.target.value)} />
                &nbsp; */}
                {/* <button
                  className={'buttonDefault'}
                  onClick={() => props.updateCustomerBillingDescription()}
                  disabled={props.disableButton}
                >
                  Save
                </button> */}
              </td>
            </tr>
          }
          {props.subscriptionBillingEmail &&
            <tr>
              <td>Billing Email: &nbsp; </td>
              <td>
                <input value={props.subscriptionBillingEmail} onChange={(e) => props.handleChangeSubscriptionBillingInfo('subscriptionBillingEmail', e.target.value)} />
                &nbsp;
                <button
                  className={'buttonDefault'}
                  onClick={() => props.updateCustomerBillingEmail()}
                  disabled={props.disableButton}
                >
                  Save
                </button>
              </td>
            </tr>
          }
          {props.subscriptionEndDate &&
            <tr>
              <td>
                Next Subscription Billing At: &nbsp;
              </td>
              <td>
                <b>{moment(props.subscriptionEndDate).format('LL')}</b>
              </td>
            </tr>
          }
          {props.subscriptionCCLastFour &&
            (
              <tr>
                <td>Payment Method: &nbsp; </td>
                <td>
                  <span>
                    ••••<b>{props.subscriptionCCLastFour}</b>
                    {`, Postal/Zip: ${props.ccPostalCode}`}
                    &nbsp;
                    {props.subscriptionCCexpYear &&
                      (
                        <span className={moment().isAfter(moment(`${props.subscriptionCCexpYear}-${props.subscriptionCCexpMonth}`, 'YYYY-M')) && 'errorText'}>
                          {`Expiry: ${props.subscriptionCCexpMonth}/${props.subscriptionCCexpYear}`}
                        </span>
                      )
                    }
                  </span>
                  &nbsp;
                  <button
                    className={'buttonDefault'}
                    onClick={() => props.handleShowConfirmDeleteCCModal()}
                    disabled={props.disableButton}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            )
          }
          {!props.subscriptionCCLastFour &&
            <tr>
              <td>Last Four CC: &nbsp; </td>
              <td>
                <button
                  className={`${styles.startButton} buttonDefault`}
                  onClick={() => props.openCCModal()}
                  disabled={props.showCCModal || props.disableButton}
                >
                  <span>Add CC Card</span>
                </button>
              </td>
            </tr>
          }
          {props.subscriptionObj && props.subscriptionObj.get('customerId') !== undefined &&
            <tr>
              <td>Customer Account ID: &nbsp; </td>
              <td><b>{props.subscriptionObj.get('customerId')}</b></td>
            </tr>
          }
          {props.subscriptionObj && props.subscriptionObj.get('subscriptionId') !== undefined &&
            <tr>
              <td>Customer Subscription ID: &nbsp; </td>
              <td><b>{props.subscriptionObj.get('subscriptionId')}</b></td>
            </tr>
          }
          {props.invoicePrefix !== undefined &&
            <tr>
              <td>Customer Invoice Prefix: &nbsp; </td>
              <td><b>{props.invoicePrefix}</b></td>
            </tr>
          }
          {props.subscriptionAccountBalance !== undefined &&
            <tr>
              <td>Account Balance (Owing): &nbsp;</td>
              <td>
                <input
                  type="number"
                  step="0.01"
                  value={props.subscriptionAccountBalance}
                  onChange={(e) => props.handleChangeSubscriptionBillingInfo('subscriptionAccountBalance', e.target.value)}
                />
                <button
                  className={'buttonDefault'}
                  onClick={() => props.handleSaveSubscriptionBillingInfo('subscriptionAccountBalance')}
                  disabled={props.disableButton}
                >
                  Save
                </button>
              </td>
            </tr>
          }
        </table>
      </div>

      <div>
        Invoice Footer (WILL SHOW ON CUSTOMER INVOICE) &nbsp;
        <textarea
          value={props.invoiceFooter}
          onChange={(e) => props.handleChangeSubscriptionBillingInfo('invoiceFooter', e.target.value)}
          style={{ fontSize: '0.7em', minHeight: '10em' }}
        />
        <button
          className={`buttonDefault`}
          onClick={() => props.handleSaveSubscriptionBillingInfo('invoiceFooter', props.invoiceFooter)}
          disabled={props.disableButton}
        >
          <span>Save</span>
        </button>
      </div>
      <br />
      <br />
      <b>Reseller</b> &nbsp;
      {props.resellerCompanyArr && props.resellerCompanyArr.length > 0 &&
        <select value={props.subscriptionObj.get('company') && props.subscriptionObj.get('company').get('promoCode')} onChange={(e) => { props.handleChangeReseller(e.target.value) }}>
          <option>Select Partner</option>
          {props.resellerCompanyArr.map((resellerCompany) =>
            <option key={resellerCompany.id} value={resellerCompany.get('promoCode')}>{resellerCompany.get('name')}</option>
          )}
        </select>
      }
      <br />
      <br />
      <b>Upcoming Invoice</b> &nbsp;
      <button
        className={'buttonDefault'}
        onClick={() => setShowChargeSingleInvoiceModal(!showChargeSingleInvoiceModal)}
      >
        Charge Single Invoice
      </button>
      <ChargeSingleInvoiceModal
        show={showChargeSingleInvoiceModal}
        // handleModalChoice={(confirmBool, reason, name, countObj) => confirmBool ? this.handleSaveSubscription(reason, name, countObj) : this.setState({ ...this.state, showConfirmSaveSubscriptionModal: false })}
        handleClose={() => { setShowChargeSingleInvoiceModal(!showChargeSingleInvoiceModal); props.refreshSubscription(); }}
        subscriptionObj={props.subscriptionObj}
      />
      <br />
      <Table
        selectable={false}
        multiSelectable={false}
      >
        <TableHeader
          displaySelectAll={false}
          adjustForCheckbox={false}
          enableSelectAll={false}
        ><TableRow>
            <TableRowColumn style={{ width: '30%' }}><b>Description</b></TableRowColumn>
            <TableRowColumn style={{ width: '15%' }}><b>Quantity</b></TableRowColumn>
            <TableRowColumn style={{ width: '25%' }}><b>Unit Amount</b></TableRowColumn>
            <TableRowColumn style={{ width: '25%' }}><b>Total Amount</b></TableRowColumn>
            <TableRowColumn style={{ width: '15%' }}></TableRowColumn>
          </TableRow></TableHeader>
        <TableBody
          displayRowCheckbox={false}
        >
          <TableRow>
            <TableRowColumn style={{ width: '30%' }}>
              <input value={props.subscriptionAddInvoiceItem.description} onChange={(e) => props.handleChangeAddInvoiceItem('description', e.target.value)} />
            </TableRowColumn>
            <TableRowColumn style={{ width: '15%' }}>
              <input style={{ width: '100%' }} type="number" value={props.subscriptionAddInvoiceItem.quantity} onChange={(e) => props.handleChangeAddInvoiceItem('quantity', e.target.value)} />
            </TableRowColumn>
            <TableRowColumn style={{ width: '25%' }}>
              {props.currency &&
                <span>{props.currency.toUpperCase()} &nbsp;</span>
              }
              {!props.currency &&
                <select value={props.subscriptionAddInvoiceItem.currency} onChange={(e) => props.handleChangeAddInvoiceItem('currency', e.target.value)}>
                  <option value="cad">CAD</option>
                  <option value="usd">USD</option>
                </select>
              }
              <input
                style={{ width: '50%' }}
                type="number"
                value={props.subscriptionAddInvoiceItem.dollar_amount}
                onChange={(e) => props.handleChangeAddInvoiceItem('dollar_amount', e.target.value)}
              />
            </TableRowColumn>
            <TableRowColumn style={{ width: '25%' }}>
              {props.subscriptionAddInvoiceItem.currency.toUpperCase()} ${((props.subscriptionAddInvoiceItem.dollar_amount * props.subscriptionAddInvoiceItem.quantity)).toFixed(2)}
            </TableRowColumn>
            <TableRowColumn style={{ width: '15%' }} >
              <button
                className={'buttonDefault'}
                onClick={() => {
                  props.handleAddInvoiceItem();
                }}
                disabled={props.savingInvoiceItem}
              >
                Add
              </button>
            </TableRowColumn>
          </TableRow>
          {props.subscriptionUpcomingInvoiceItems &&
            props.subscriptionUpcomingInvoiceItems.filter((upcomingInvoiceItem) =>
              // if invoiceItem is for another subscription, don't show (for customers with multiple subscriptions, ie. Vecima reseller)
              // If no plan, then is a general invoice item for the customer
              (!upcomingInvoiceItem.plan || (upcomingInvoiceItem.subscription === (props.subscriptionObj && props.subscriptionObj.get('subscriptionId'))))
            ).map((invoiceItem) => (
              <TableRow key={invoiceItem.id}>
                <TableRowColumn style={{ width: '30%' }}>{invoiceItem.description}</TableRowColumn>
                <TableRowColumn style={{ width: '15%' }}>{invoiceItem.quantity}</TableRowColumn>
                <TableRowColumn style={{ width: '25%' }}>{invoiceItem.currency.toUpperCase()} ${((invoiceItem.unit_amount || (invoiceItem.amount / invoiceItem.quantity)) / 100).toFixed(2)}</TableRowColumn>
                <TableRowColumn style={{ width: '25%' }}>{invoiceItem.currency.toUpperCase()} ${(((invoiceItem.unit_amount || (invoiceItem.amount / invoiceItem.quantity)) / 100) * invoiceItem.quantity).toFixed(2)}</TableRowColumn>
                <TableRowColumn style={{ width: '15%' }}>
                  {
                    (!invoiceItem.plan && !invoiceItem.subscription) &&
                    <button
                      className={'buttonDefault'}
                      onClick={() => {
                        props.handleDeleteInvoiceItem(invoiceItem);
                      }}
                      disabled={props.savingInvoiceItem}
                    >
                      Delete
                    </button>
                  }
                </TableRowColumn>
              </TableRow>
            )
            )}
        </TableBody>
      </Table>
      {
        props.subscriptionAddInvoiceItem.error &&
        <div className="errorText">
          {props.subscriptionAddInvoiceItem.error}
        </div>
      }
      <br />
      <br />
      <b>Previous Invoices</b>
      <br />
      <Table
        selectable={false}
        multiSelectable={false}
      >
        <TableHeader
          displaySelectAll={false}
          adjustForCheckbox={false}
          enableSelectAll={false}
        ><TableRow>
            <TableRowColumn style={{ width: '16%' }}><b>Invoice</b></TableRowColumn>
            <TableRowColumn style={{ width: '14%' }}><b>Date</b></TableRowColumn>
            <TableRowColumn style={{ width: '34%' }}><b>Details</b></TableRowColumn>
            <TableRowColumn style={{ width: '16%' }}><b>Charge</b></TableRowColumn>
            <TableRowColumn style={{ width: '10%' }}><b>Status</b></TableRowColumn>
            <TableRowColumn style={{ width: '10%' }}><b></b></TableRowColumn>
          </TableRow></TableHeader>
        <TableBody
          displayRowCheckbox={false}
        >
          {props.subscriptionInvoices && props.subscriptionInvoices.filter((subscriptionInvoice) => {
            return (subscriptionInvoice && subscriptionInvoice.amount_due !== 0);
          }).map((subscriptionInvoice) => (
            <TableRow key={subscriptionInvoice.id}>
              <TableRowColumn style={{ width: '16%' }}>
                <a href={subscriptionInvoice.hosted_invoice_url || subscriptionInvoice.invoice_pdf} target="_blank">
                  {subscriptionInvoice.number}
                  <br />
                  {subscriptionInvoice.receipt_number || 'No Receipt'}
                </a>
              </TableRowColumn>
              <TableRowColumn style={{ width: '14%' }}>{moment.unix(subscriptionInvoice.date || subscriptionInvoice.created).format('ll')}</TableRowColumn>
              <TableRowColumn style={{ width: '34%' }}>
                <div style={{ padding: '0.5em' }}>
                  {subscriptionInvoice.lines && subscriptionInvoice.lines.data && subscriptionInvoice.lines.data.length > 0 && subscriptionInvoice.lines.data.map((invoiceLineObj) => (
                    <div key={invoiceLineObj.id}>
                      {invoiceLineObj.plan &&
                        <span>
                          {invoiceLineObj.quantity} x {invoiceLineObj.plan.nickname}
                        </span>
                      }
                      {!invoiceLineObj.plan &&
                        <span>
                          {invoiceLineObj.quantity} x {invoiceLineObj.description}
                        </span>
                      }
                    </div>
                  ))}
                </div>
              </TableRowColumn>
              <TableRowColumn style={{ width: '16%' }}>
                {subscriptionInvoice.currency.toUpperCase()} ${(subscriptionInvoice.amount_due / 100).toFixed(2)}
              </TableRowColumn>
              <TableRowColumn style={{ width: '10%' }}>
                <div>
                  {subscriptionInvoice.status.toUpperCase()}
                </div>
              </TableRowColumn>
              <TableRowColumn style={{ width: '10%' }}>
                <div style={{ paddingTop: '1em' }}>
                  {!subscriptionInvoice.paid && subscriptionInvoice.status !== 'void' &&
                    // { !subscriptionInvoice.paid && props.subscriptionCCLastFour &&
                    <button
                      className={'buttonDefault'}
                      onClick={() => {
                        props.payInvoice(subscriptionInvoice.id);
                      }}
                      disabled={props.payingVoidingInvoice}
                    >
                      Pay
                    </button>
                  }
                </div>
                <div style={{ paddingTop: '1em', paddingBottom: '1em' }}>
                  {!subscriptionInvoice.paid && subscriptionInvoice.status !== 'void' && subscriptionInvoice.status !== 'draft' &&
                    // { !subscriptionInvoice.paid && props.subscriptionCCLastFour &&
                    <button
                      className={'buttonDefault'}
                      onClick={() => {
                        props.showConfirmVoidInvoiceModal(
                          {
                            email: props.subscriptionBillingEmail,
                            companyName: props.subscriptionBillingDescription,
                            invoiceDate: subscriptionInvoice.created && moment.unix(subscriptionInvoice.created).toDate(),
                            amountDue: (subscriptionInvoice.amount_due / 100).toFixed(2),
                            invoiceId: subscriptionInvoice.id,
                          },
                        )
                      }}
                      disabled={props.payingVoidingInvoice}
                    >
                      Void
                    </button>
                  }
                </div>
              </TableRowColumn>
            </TableRow>
          ))}
          {props.subscriptionInvoices.length > 0 &&
            <TableRow><div className="centerText">
              <button className="buttonDefault" onClick={() => props.getMoreInvoices()}>Get More Invoices</button>
            </div></TableRow>
          }
        </TableBody>
      </Table>
      {
        props.payInvoiceError &&
        <div className="errorText">
          {props.payInvoiceError}
        </div>
      }
    </div >
  );
}

Subscription.propTypes = {
  subscriptionObj: PropTypes.object.isRequired,
  subscriptionInvoices: PropTypes.array,
  subscriptionUpcomingInvoiceItems: PropTypes.array,
  subscriptionEndDate: PropTypes.object,
  subscriptionBillingEmail: PropTypes.string,
  subscriptionCCLastFour: PropTypes.string,
  ccStateProvince: PropTypes.string,
  ccCountry: PropTypes.string,
  ccPostalCode: PropTypes.string,
  subscriptionAccountBalance: PropTypes.number,
  handleChangeSubscriptionBillingInfo: PropTypes.func.isRequired,
  handleSaveSubscriptionBillingInfo: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
  savingAddSubscriptionItem: PropTypes.bool,
  payInvoice: PropTypes.func.isRequired,
  voidInvoice: PropTypes.func.isRequired,
  payingVoidingInvoice: PropTypes.bool,
  resellerCompanyArr: PropTypes.array,
  handleChangeReseller: PropTypes.func.isRequired,
};

export default Subscription;
