import Parse from 'parse';
import * as Auth from '../api/Auth';

export function start(parseAppId, parseJSKey, parseServerURL) {
  Parse.serverURL = parseServerURL;
  Parse.initialize(parseAppId, parseJSKey);
}

export function login(email, password) {
  const formattedEmail = email.toLowerCase();
  return Parse.User.logIn(formattedEmail, password);
}

export function logout() {
  return Parse.User.logOut();
}

export function getCurrentUser() {
  if (!Parse.User.current()) {
    return null;
  }
  return Parse.User.current();
}

export function getPointer(parseClass, objectId) {
  const pointer = new Parse.Object(parseClass);
  pointer.id = objectId;
  return pointer.toPointer();
}

export function subscribeToNotifications(eventHandler) {
  const userQuery = new Parse.Query(Parse.User);
  userQuery.equalTo('objectId', Parse.User.current().id);
  const dispatcherQuery = new Parse.Query('Dispatcher');
  dispatcherQuery.matchesQuery('user', userQuery);
  const notificationQuery = new Parse.Query('Notification');
  notificationQuery.matchesQuery('recipientId', dispatcherQuery);
  notificationQuery.include(['senderId']);
  notificationQuery.descending('createdAt');
  const subscription = notificationQuery.subscribe();
  subscription.on('create', object => eventHandler(object));
  return subscription;
}

export function unsubscribeFromLivequery(subscription) {
  subscription.unsubscribe();
}

export function sendPush(parseUserArray, message) {
  const parseUserArrayLength = parseUserArray.length;
  const userIdArray = [];
  for (let i = 0; i < parseUserArrayLength; i++) {
    if (parseUserArray[i].id) {
      userIdArray.push(parseUserArray[i].id);
    }
  }
  return Auth.callSecuredParseFunction('sendPush', { userIdArray, message });
}

export function registerAdminToParse(registerValuesObject) {
  const promise = new Promise((resolve, reject) => {
    const user = new Parse.User();
    user.set('username', registerValuesObject.emailAddress);
    user.set('password', registerValuesObject.password);
    user.signUp().then(
      parseUser => {
        const { emailAddress, firstName, lastName, companyName, promoCode } = registerValuesObject;
        Auth.callSecuredParseFunction('registerNewCompanyAndUser', { emailAddress, firstName, lastName, companyName, userId: parseUser.id, promoCode }).then(
          updatedParseUser => resolve(updatedParseUser),
          error => reject(error)
        );
      },
      error => reject(error)
    );
  });
  return promise;
}
