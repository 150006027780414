import PropTypes from 'prop-types';
import React from 'react';
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, TextField, Toggle } from 'material-ui';

// API
import * as Getters from 'api/Getters';

class ActivePlansList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePlanProductArr: [],
    };

    this.refreshPlans = this.refreshPlans.bind(this);
    this.getProductForPlanToState = this.getProductForPlanToState.bind(this);
  }

  componentDidMount() {
    if (this.props.activePlans.length > 0) {
      this.refreshPlans(this.props.activePlans);
    }
  }

  componentWillReceiveProps(nextProps) {
    let shouldRefreshPlans = false;
    if (this.state.activePlanProductArr.length !== nextProps.activePlans.length) {
      shouldRefreshPlans = true;
    } else {
      for (let i = 0; i < nextProps.activePlans.length; i++) {
        if (
          this.state.activePlanProductArr[i].stripePlanId !== nextProps.activePlans[i].stripePlanId
          || this.state.activePlanProductArr[i].quantity !== nextProps.activePlans[i].quantity
          || this.state.activePlanProductArr[i].active !== nextProps.activePlans[i].active
        ) {
          shouldRefreshPlans = true;
        }
      }
    }

    if (shouldRefreshPlans) this.refreshPlans(nextProps.activePlans);
  }

  async getProductForPlanToState() {
    const newActivePlanProductArr = [];
    for (let i = 0; i < this.state.activePlanProductArr.length; i++) {
      const activePlanProductObj = this.state.activePlanProductArr[i];
      const stripeProduct = await Getters.getStripeProduct(activePlanProductObj.productId);
      newActivePlanProductArr.push({
        ...activePlanProductObj,
        stripeProduct,
      });
    }
    this.setState({ ...this.state, activePlanProductArr: newActivePlanProductArr });
  }

  refreshPlans(activePlanArr) {
    this.setState({ ...this.state, activePlanProductArr: [] }, () => {
      const newState = { ...this.state, activePlanProductArr: [] };
      for (let i = 0; i < activePlanArr.length; i++) {
        const subscriptionPlanObj = activePlanArr[i];
        newState.activePlanProductArr.push({
          ...subscriptionPlanObj,
        });
      }
      this.setState(newState, () => {
        this.getProductForPlanToState();
      });
    });
  }


  render() {
    return (
      <Table
        selectable={false}
        multiSelectable={false}
      >
        <TableHeader
          displaySelectAll={false}
          adjustForCheckbox={false}
          enableSelectAll={false}
        ><TableRow>
            <TableRowColumn style={{ width: '44%', textAlign: 'center', fontWeight: 500 }}>Invoice Name (Internal Plan)</TableRowColumn>
            <TableRowColumn style={{ width: '10%', textAlign: 'center', fontWeight: 500 }}>Active</TableRowColumn>
            <TableRowColumn style={{ width: '12%', textAlign: 'center', fontWeight: 500 }}>Quantity</TableRowColumn>
            <TableRowColumn style={{ width: '18%', textAlign: 'center', fontWeight: 500 }}>Price / Interval</TableRowColumn>
            <TableRowColumn style={{ width: '16%', textAlign: 'center', fontWeight: 500 }}>Total</TableRowColumn>
          </TableRow></TableHeader>
        <TableBody
          displayRowCheckbox={false}
        >
          {this.state.activePlanProductArr.map((activePlanProductObj) => (
            <TableRow key={activePlanProductObj.stripePlanId}>
              <TableRowColumn style={{ width: '44%' }}>
                {activePlanProductObj.stripeProduct && activePlanProductObj.stripeProduct.name}
                <br />
                ({activePlanProductObj.name})
              </TableRowColumn>
              <TableRowColumn style={{ width: '10%' }}>
                <Toggle
                  onToggle={(event, newValue) => this.props.handleChangeSubscription(activePlanProductObj.stripePlanId, 'active', newValue)}
                  toggled={activePlanProductObj.active}
                />
              </TableRowColumn>
              <TableRowColumn style={{ width: '12%' }}>
                <TextField
                  id={activePlanProductObj.stripePlanId}
                  type="number"
                  onChange={(event, newValue) => this.props.handleChangeSubscription(activePlanProductObj.stripePlanId, 'quantity', parseInt(newValue))}
                  value={activePlanProductObj.quantity}
                />
              </TableRowColumn>
              <TableRowColumn style={{ textAlign: 'right', width: '18%' }}>
                ${activePlanProductObj.amount ? (activePlanProductObj.amount / 100).toFixed(2) : '0.00'}
                /
                {activePlanProductObj.intervalCount} {activePlanProductObj.interval}
              </TableRowColumn>
              <TableRowColumn style={{ textAlign: 'right', width: '16%' }}>${activePlanProductObj.amount ? ((activePlanProductObj.amount / 100) * activePlanProductObj.quantity).toFixed(2) : '0.00'} {activePlanProductObj.currency.toUpperCase()}</TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}


ActivePlansList.propTypes = {
  handleChangeSubscription: PropTypes.func,
  activePlans: PropTypes.array,
};

export default ActivePlansList;
