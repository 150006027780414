import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, TextField, Toggle } from 'material-ui';

// API
import * as Setters from 'api/Setters';
import * as Getters from 'api/Getters';

// Components
import CreatePlanModal from './CreatePlanModal';

class AddPlanModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        name: '',
        currency: props.currency || 'cad',
        amount: '',
      },
      currencySetFromProps: false,
      stripeSubscriptionPlanArr: [],
      showCreatePlanModalBool: false,
      disablePlanButtoms: false,
    };

    this.refreshSubscriptionPlans = this.refreshSubscriptionPlans.bind(this);
    this.deactivatePlan = this.deactivatePlan.bind(this);
  }

  componentDidMount() {
    if (
      this.state.filter.currency !== this.props.currency
      && this.props.currency && !this.state.currencySetFromProps
    ) {
      this.setState({ ...this.state, filter: { ...this.state.filter, currency: this.props.currency }, currencySetFromProps: true });
    }
    this.refreshSubscriptionPlans();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.currency && !prevState.currencySetFromProps) {
      return {
        ...prevState,
        filter: {
          ...prevState.filter,
          currency: nextProps.currency,
        },
        currencySetFromProps: true,
      };
    }

    return null;
  }

  async refreshSubscriptionPlans() {
    const stripeSubscriptionPlanArr = await Getters.getSubscriptionPlans();
    this.setState({ ...this.state, stripeSubscriptionPlanArr });
  }

  deactivatePlan(stripePlanId) {
    this.setState({ ...this.state, disablePlanButtons: true });
    Setters.editStripePlan(stripePlanId, {
      active: false,
    }).then(() => {
      this.refreshSubscriptionPlans()
      this.setState({ ...this.state, disablePlanButtons: false });
    }, (error) => {
      console.log(error);
      this.setState({ ...this.state, disablePlanButtons: false });
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={() => this.props.hideModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Billing Plans</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <table>
              <tr>
                <td>
                  <div className="inlineBlock">
                    FILTER: &nbsp;
                  </div>
                  <div className="inlineBlock">
                    <input
                      type="text"
                      className="buttonSpacedRight"
                      value={this.state.filter.name}
                      onChange={(e) => { this.setState({ ...this.state, filter: { ...this.state.filter, name: e.target.value } }) }}
                      placeholder="Internal Name"
                    />
                    <input
                      type="number"
                      className="buttonSpacedRight"
                      value={this.state.filter.amount}
                      onChange={(e) => { this.setState({ ...this.state, filter: { ...this.state.filter, amount: e.target.value } }) }}
                      placeholder="Price"
                    />
                    <select value={this.state.filter.currency} onChange={(e) => this.setState({ ...this.state, filter: { ...this.state.filter, currency: e.target.value } })}>
                      <option value="cad">CAD</option>
                      <option value="usd">USD</option>
                    </select>
                  </div>
                </td>
                <td>
                  {/* <button
                    className={`buttonDefault floatRight`}
                    onClick={() => this.setState({ ...this.state, showCreatePlanModalBool: true })}
                  >
                    Create Custom Plan
                  </button> */}
                </td>
              </tr>
            </table>
          </div>
          <div>
            <Table
              selectable={false}
              multiSelectable={false}
              height="300px"
            >
              <TableHeader
                displaySelectAll={false}
                adjustForCheckbox={false}
                enableSelectAll={false}
              ><TableRow>
                  <TableRowColumn style={{ width: '25%' }}>Plan</TableRowColumn>
                  <TableRowColumn style={{ width: '15%' }}>Interval</TableRowColumn>
                  <TableRowColumn style={{ width: '15%' }}>Price (Currency)</TableRowColumn>
                  <TableRowColumn style={{ width: '40%' }}></TableRowColumn>
                </TableRow></TableHeader>
              <TableBody
                displayRowCheckbox={false}
              >
                {this.state.stripeSubscriptionPlanArr.filter((subscriptionPlan) => {
                  if (!subscriptionPlan.name) return false;
                  if (!subscriptionPlan.active) return false;
                  if (this.state.filter.name && !subscriptionPlan.name.toLowerCase().includes(this.state.filter.name.toLowerCase())) return false;
                  if (this.state.filter.currency && !subscriptionPlan.currency.includes(this.state.filter.currency)) return false;
                  if (this.state.filter.amount && subscriptionPlan.amount !== (parseFloat(this.state.filter.amount) * 100)) return false;
                  return true;
                }).map((subscriptionPlan) => (
                  <TableRow key={subscriptionPlan.stripePlanId}>
                    <TableRowColumn style={{ width: '25%' }}>
                      <div>
                        {subscriptionPlan.name}
                      </div>
                      <div>
                        ({subscriptionPlan.nickname})
                      </div>
                    </TableRowColumn>
                    <TableRowColumn style={{ width: '15%' }}>{subscriptionPlan.intervalCount} {subscriptionPlan.interval}</TableRowColumn>
                    <TableRowColumn style={{ width: '15%' }}>${subscriptionPlan.amount / 100} {subscriptionPlan.currency.toUpperCase()}</TableRowColumn>
                    <TableRowColumn style={{ width: '40%' }}>
                      <button
                        className={`buttonDefault`}
                        onClick={() => this.props.addSubscriptionPlan(subscriptionPlan)}
                        disabled={this.state.disablePlanButtons}
                      >
                        Add For Customer
                      </button>
                      &nbsp;
                      <button
                        className={`buttonDefault redButton`}
                        onClick={() => this.deactivatePlan(subscriptionPlan.stripePlanId)}
                        disabled={this.state.disablePlanButtons}
                      >
                        Delete Plan
                      </button>
                    </TableRowColumn>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <CreatePlanModal
            hideModal={async () => {
              await this.refreshSubscriptionPlans();
              this.setState({ ...this.state, showCreatePlanModalBool: false })
            }}
            show={this.state.showCreatePlanModalBool}
            currency={this.props.currency}
          />
        </Modal.Body>
      </Modal>
    );
  }
}


AddPlanModal.propTypes = {
  show: PropTypes.bool.isRequired,
  addSubscriptionPlan: PropTypes.func.isRequired,
  subscriptionPlans: PropTypes.array,
  refreshSubscriptionPlans: PropTypes.array,
};

export default AddPlanModal;
