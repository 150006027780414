import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import history from 'sbHistory';
import LazyLoad from 'react-lazy-load';

import { Table } from 'react-bootstrap';

// API
import * as Helpers from 'api/Helpers';
import * as Setters from 'api/Setters';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import VehicleRow from 'components/EquipmentTable/VehicleRow';
import TrailerRow from 'components/EquipmentTable/TrailerRow';

// CSS
import styles from 'components/EquipmentTable/EquipmentTable.module.scss';

class EquipmentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.hideUnhideEquipment = this.hideUnhideEquipment.bind(this);
  }

  hideUnhideEquipment(equipmentObj, className, hideBool) {
    equipmentObj.set('isHidden', hideBool);
    Setters.hideUnhideEquipment(equipmentObj.id, className, hideBool);
    this.setState({ ...this.state });
  }

  render() {
    return (
      <div className="defaultTable">
        <Table responsive hover>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Unit Id / Company</th>
              <th>Weigh Station Bypass</th>
              <th>Driver</th>
              <th>Last Updated</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {!this.props.fetched &&
              <tr className={styles.loadingIconNoHover}>
                <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
              </tr>
            }
            {this.props.fetched && this.props.vehicleArr.length === 0 && this.props.trailerArr.length === 0 &&
              <tr>
                <td colSpan="7" className="emptyRowMessage">No Equipment</td>
              </tr>
            }
            {this.props.fetched && this.props.vehicleArr.length > 0 &&
              this.props.vehicleArr.sort((a, b) => moment(a.get('updatedAt')).isAfter(moment(b.get('updatedAt')))).sort((a, b) => a.get('unitId') - b.get('unitId')).map((vehicleObj) =>
                <VehicleRow
                  vehicleObj={vehicleObj}
                  hideUnhideEquipment={this.hideUnhideEquipment}
                  key={vehicleObj.id}
                />
              )
            }
            {this.props.fetched && this.props.trailerArr.length > 0 &&
              this.props.trailerArr.sort((a, b) => moment(a.get('updatedAt')).isAfter(moment(b.get('updatedAt')))).sort((a, b) => a.get('unitId') - b.get('unitId')).map((trailerObj) =>
                <TrailerRow
                  trailerObj={trailerObj}
                  hideUnhideEquipment={this.hideUnhideEquipment}
                  key={trailerObj.id}
                />
              )
            }
          </tbody>
        </Table>
      </div>
    );
  }
}

EquipmentTable.propTypes = {
  vehicleArr: PropTypes.array.isRequired,
  trailerArr: PropTypes.array.isRequired,
  fetched: PropTypes.bool,
};

export default EquipmentTable;
