import PropTypes from 'prop-types';
import React from 'react';
import Parse from 'parse';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import store from '../../store';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';

// Components
import DatePicker from 'components/DatePicker/container/DatePicker';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

const initialState = () => ({
  loading: false,
  file: null,
});

class UploadSimFileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState();
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.uploadHandler = this.uploadHandler.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.show && this.props.show) {
      this.setState(initialState());
    }
  }

  onChangeHandler = (event) => {
    const file = event.target.files[0];
    this.setState({ ...this.state, file });
  }

  uploadHandler = () => {
    if (!this.state.file) return this.setState({ ...this.state, loading: false, error: 'Select File' });
    this.setState({ ...this.state, loading: true, error: '' }, () => {
      Setters.uploadSIMIMEISidLinks(this.state.file).then(() => {
        this.setState({ ...this.state, loading: false });
      }).catch((error) => {
        console.log(error);
        this.setState({ ...this.state, loading: false, error: error.message });
      });
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Upload Sim File
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <input 
              type="file" 
              name="file" 
              onChange={this.onChangeHandler}
            />

            <br />

            <button
              className={'buttonDefault'}
              onClick={this.uploadHandler}
              disabled={this.state.loading}
            >
              Upload
            </button>

            { this.state.error &&
              <div className="errorText">
                <br />
                { this.state.error }
              </div>
            }

          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

UploadSimFileModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

export default UploadSimFileModal;
