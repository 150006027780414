import axios from 'axios';
import store from 'store';
import L from 'mapbox.js';
import Parse from 'parse';
import polyline from 'polyline';
import * as ActionConstants from 'actions/ActionConstants';
import * as Auth from '../api/Auth';

import * as Helpers from 'api/Helpers';
import { queryCompanyObjects } from 'api/Getters';

// http://www.flatuicolorpicker.com/category/all
function getColour(index) {
  const colourArray = [
    '#446CB3',
    '#03C9A9',
    '#674172',
    '#F1A9A0',
    '#D24D57',
    '#E67E22',
    '#F5D76E',
    '#1E824C',
    '#2C3E50',
    '#DCC6E0',
  ];
  if (index) {
    if (index < colourArray.length) {
      return colourArray[index];
    }
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`; // random
  }
  return '#ABB7B7'; // gray
}

function initializeMapbox(store) {
  Auth.callSecuredParseFunction('getMapboxToken').then((response) => {
    store.dispatch({ type: ActionConstants.INITIALIZE_MAPBOX, token: response.token });
  });
}

function getToken() {
  return (process.env.MAPBOX_PUBLIC_TOKEN || 'pk.eyJ1Ijoic3dpdGNoYm9hcmQiLCJhIjoiY2l4OWl4YWc5MDAyZTJ0cWY2ZnphYm9hdSJ9.lugyfpqxdpfo_1eGWr_FFA');
}

function getReverseGeocode(longitude, latitude, returnAllData) {
  const promise = new Promise((resolve, reject) => {
    console.log(longitude);
    console.log(latitude);
    axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json`, {
      params: {
        access_token: store.getState().Main.mapboxToken,
      },
    }).then((response) => {
      if (response && response.data.features && response.data.features[0]) {
        if (returnAllData) {
          resolve(response.data.features[0]);
        } else {
          resolve(response.data.features[0].place_name);
        }
      } else {
        resolve(undefined);
      }
    });
  });
  return promise;
}

// function getOptimizedTripFromSequenceArray(longLatSequenceArray) {
//   const promise = new Promise((resolve, reject) => {
//     for (let i = 0; i < longLatSequenceArray.length; i++) {
//
//     }
//
//
//   });
//   return promise;
// }

function getGeocode(addressString, dataOnly) {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('getGeocode', { addressString, dataOnly }).then(
      result => {
        resolve(result);
      },
      error => {
        console.log(error);
        reject(error);
      }
    );
  });
  return promise;
}

function test() {
  getReverseGeocode(-122.4194, 37.7749).then(
    result => {
      console.log(result);
    }
  );
}

export {
  getToken,
  getColour,
  getGeocode,
  getReverseGeocode,
  initializeMapbox,
  test,
};
