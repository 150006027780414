import PropTypes from 'prop-types';
import React from 'react';

// Actions
import { requireAuth } from '../../actions/Auth';
import { initializeServices } from '../../actions/Main';

// Components
import Header from 'components/Header/container/Header';

// CSS
import styles from './InAppLayout.module.scss';

function InAppLayout(props) {
  requireAuth();
  initializeServices();

  return (
    <div>
      <Header pageLocation={props.location} />
      <div className={styles.inAppContent}>
        {props.children}
      </div>
    </div>
  );
}

InAppLayout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};

export default InAppLayout;
