import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, Toggle, TextField } from 'material-ui';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import * as Helpers from 'api/Helpers';

// Components
// import SimRow from './SimRow';
// import TwilioInfo from '../../TwilioInfo/container/TwilioInfo';

// CSS

class DriversList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      objArr: [],
      activeCheck: {
        active: 0,
        processed: 0,
      }
    };
    this.refreshArr = this.refreshArr.bind(this);
    this.setActiveCheck = this.setActiveCheck.bind(this);
  }

  componentDidMount() {
    this.refreshArr();
  }


  refreshArr() {
    Getters.getDriversByCompany(this.props.companyId).then((objArr) => {
      this.setState({ ...this.state, objArr });
    });
  }

  setActiveCheck(activeBool, resetBool) {
    const newState = { ...this.state };
    if (resetBool) {
      newState.activeCheck.active = 0;
      newState.activeCheck.processed = 0;
    } else if (activeBool) {
      newState.activeCheck.active += 1;
      newState.activeCheck.processed += 1;
    } else {
      newState.activeCheck.processed += 1;
    }
    this.setState(newState);
  }

  generateCsv() {
    var lineArray = ['Driver,IMEI,Vehicle Unit ID, ELD Hardware ID,Last Active'];

    for (let i = 0; i < this.state.objArr.length; i++) {
      const obj = this.state.objArr[i];
      const driver = Helpers.toTitleCase(obj.get('user_fullName'));
      const imei = obj.get('deviceIMEI');
      const vehicleUnitId = obj.get('latestELDEvent') && obj.get('latestELDEvent').get('vehicleUnitId');
      const eldHardwareSerial = obj.get('latestELDEvent') && obj.get('latestELDEvent').get('eldHardwareSerial');
      const latestEldEvent = obj.get('latestELDEvent') && obj.get('latestELDEvent').get('eventDateTime') && moment(obj.get('latestELDEvent').get('eventDateTime')).format('YYYY-MMMM-DD');
      lineArray.push([driver, imei, vehicleUnitId, eldHardwareSerial, latestEldEvent].join(','));
    }

    var csvContent = lineArray.join('\n');
    Helpers.createCsvFile('Drivers - ' + moment().format('YYYY-MM-DD'), csvContent, true);
  }

  render() {
    const driversCreatedLastMonth = this.state.objArr.filter((obj) => moment(obj.get('createdAt')).isAfter(moment().subtract(1, 'month').startOf('month')))

    const totalUniqueIMEIArr = [...new Set(this.state.objArr.filter((obj) => obj.get('deviceIMEI') && obj.get('deviceIMEI').length > 5).map((obj) => obj.get('deviceIMEI')))];
    const lastThreeMonthsImeiArr = this.state.objArr.filter((obj) => (
      obj.get('deviceIMEI') && obj.get('deviceIMEI').length > 5 &&
      obj.get('latestELDEvent') && moment(obj.get('latestELDEvent').get('eventDateTime')).isAfter(moment().subtract(3, 'months'))
    )).map((obj) => obj.get('deviceIMEI'));
    const lastThreeMonthsUniqueImeiArr = [...new Set(lastThreeMonthsImeiArr)];

    const totalUniqueELDHardwareSerialArr = [...new Set(this.state.objArr.filter((obj) => obj.get('latestELDEvent') && obj.get('deviceIMEI') !== 'NO_SERIAL' && obj.get('latestELDEvent').get('eldHardwareSerial')).map((obj) => obj.get('latestELDEvent').get('eldHardwareSerial')))];
    const lastThreeMonthsELDHardwareSerialArr = this.state.objArr.filter((obj) => (
      obj.get('latestELDEvent') && moment(obj.get('latestELDEvent').get('eventDateTime')).isAfter(moment().subtract(3, 'months')) &&
      obj.get('latestELDEvent').get('eldHardwareSerial')
    )).map((obj) => obj.get('latestELDEvent').get('eldHardwareSerial'));
    const lastThreeMonthsUniqueELDHardwareSerialArr = [...new Set(lastThreeMonthsELDHardwareSerialArr)];

    return (
      <div>
        <button className={`buttonDefault`} onClick={() => this.generateCsv()}>Download CSV</button>
        <br />
        <Table
          selectable={false}
          multiSelectable={false}
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          ><TableRow>
              <TableRowColumn style={{ width: '25%' }}>
                <b>Driver</b>
                <br />
                Created Last Month: {driversCreatedLastMonth.length}
              </TableRowColumn>
              <TableRowColumn style={{ width: '25%' }}>
                <b>IMEI</b>
                <br />
                Total: {totalUniqueIMEIArr.length}
                <br />
                Last 3 Months: {lastThreeMonthsUniqueImeiArr.length}
              </TableRowColumn>
              <TableRowColumn style={{ width: '25%' }}>
                <b>Vehicle / ELD Hardware</b>
                <br />
                Total: {totalUniqueELDHardwareSerialArr.length}
                <br />
                Last 3 Months: {lastThreeMonthsUniqueELDHardwareSerialArr.length}
              </TableRowColumn>
              <TableRowColumn style={{ width: '25%' }}>
                <b>Latest ELD Event</b>
                <br />
                Last Month:
                {
                  this.state.objArr.filter((obj) => (
                    obj.get('latestELDEvent') && moment(obj.get('latestELDEvent').get('eventDateTime')).isAfter(moment().subtract(1, 'months'))
                  )).length
                }
                <br />
                Last 3 Months:
                {
                  this.state.objArr.filter((obj) => (
                    obj.get('latestELDEvent') && moment(obj.get('latestELDEvent').get('eventDateTime')).isAfter(moment().subtract(3, 'months'))
                  )).length
                }
              </TableRowColumn>
            </TableRow></TableHeader>
          <TableBody
            displayRowCheckbox={false}
          >
            {this.state.objArr.map((obj) => (
              <TableRow>
                <TableRowColumn style={{ width: '25%' }}>
                  {Helpers.toTitleCase(obj.get('user_fullName'))}
                  {obj.get('user_phoneNumber') &&
                    <div>
                      <i>{obj.get('user_phoneNumber')}</i>
                    </div>
                  }
                </TableRowColumn>
                <TableRowColumn style={{ width: '25%' }}>
                  {obj.get('deviceIMEI')}
                </TableRowColumn>
                <TableRowColumn style={{ width: '25%' }}>
                  {obj.get('latestELDEvent') && `${obj.get('latestELDEvent').get('vehicleUnitId')} |`} {obj.get('latestELDEvent') && obj.get('latestELDEvent').get('eldHardwareSerial')}
                </TableRowColumn>
                <TableRowColumn style={{ width: '25%' }}>
                  {obj.get('latestELDEvent') && obj.get('latestELDEvent').get('eventDateTime') && moment(obj.get('latestELDEvent').get('eventDateTime')).format('lll')}
                </TableRowColumn>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

}

DriversList.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default DriversList;
