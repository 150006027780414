import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

// API
import * as Helpers from 'api/Helpers';

// Styles
import styles from './ConfirmSubscriptionChangeModal.module.scss';

const initialState = (props) => ({
  reason: '',
  name: '',
  count: {
    eldHardware: props.subscriptionObj && props.subscriptionObj.get('billingEldHardwareCount') || 0,
    tablet: props.subscriptionObj && props.subscriptionObj.get('billingTabletCount') || 0,
    vision: props.subscriptionObj && props.subscriptionObj.get('billingVisionCount') || 0,
    assetTracker: props.subscriptionObj && props.subscriptionObj.get('billingAssetTrackerCount') || 0,
  },
  disableButton: false,
  error: '',
});

class ConfirmSubscriptionChangeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState(props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show && !this.props.show) {
      this.setState(initialState(nextProps));
    }
  }

  handleConfirm() {
    this.setState({ ...this.state, error: '', disableButton: true }, () => {
      if (!Helpers.compressWhitespace(this.state.name) || !Helpers.compressWhitespace(this.state.reason)) {
        return this.setState({ ...this.state, error: 'Please enter a reason and name', disableButton: false });
      }
      this.props.handleModalChoice(
        true,
        this.state.reason,
        this.state.name,
        this.state.count,
      );
    });
  }

  render() {
    const { handleModalChoice, handleClose, show } = this.props;
    return (
      <Modal backdrop="static" show={show} onHide={handleClose}>

        <Modal.Header closeButton>
          <Modal.Title>Confirm Subscription change</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Reason for Change &nbsp;
          <textarea
            value={this.state.reason}
            onChange={(e) => this.setState({ ...this.state, reason: e.target.value })}
            style={{ minHeight: '10em' }}
          />

          <br />
          <br />

          Your Name &nbsp;
          <textarea
            value={this.state.name}
            onChange={(e) => this.setState({ ...this.state, name: e.target.value })}
          />

          Total Current ELD Hardware Billing:
          <br />
          <input
            type="number"
            value={this.state.count.eldHardware}
            onChange={(e) => {
              this.setState({ ...this.state, count: { ...this.state.count, eldHardware: parseInt(e.target.value || 0) } })
            }}
          />

          <br />
          <br />

          Total Tablet Billing:
          <br />
          <input
            type="number"
            value={this.state.count.tablet}
            onChange={(e) => this.setState({ ...this.state, count: { ...this.state.count, tablet: parseInt(e.target.value || 0) } })}
          />

          <br />
          <br />

          Total Current Vision Billing:
          <br />
          <input
            type="number"
            value={this.state.count.vision}
            onChange={(e) => this.setState({ ...this.state, count: { ...this.state.count, vision: parseInt(e.target.value || 0) } })}
          />

          <br />
          <br />

          Total Current Asset Tracker Billing:
          <br />
          <input
            type="number"
            value={this.state.count.assetTracker}
            onChange={(e) => this.setState({ ...this.state, count: { ...this.state.count, assetTracker: parseInt(e.target.value || 0) } })}
          />

          <br />
          <br />

          <div className="errorText">
            {this.state.error}
          </div>

          <div className={styles.choices}>
            <button
              className="closeButton"
              onClick={() => {
                handleClose(false, this.state.reason, this.state.name);
              }}
              disabled={this.state.disableButton}
            >
              Cancel
            </button>
            <button
              className="buttonDefault"
              onClick={() => {
                this.handleConfirm();
              }}
              disabled={this.state.disableButton}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>

      </Modal>
    );
  }
}

ConfirmSubscriptionChangeModal.propTypes = {
  handleModalChoice: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  subscriptionObj: PropTypes.object,
  show: PropTypes.bool.isRequired,
};

// const mapDispatchToProps = (dispatch) => ({
//   actions: bindActionCreators({
//     // fetchJobsIfNeeded,
//     fetchDocumentsForState,
//   }, dispatch),
// });

export default connect(undefined, undefined)(ConfirmSubscriptionChangeModal);
