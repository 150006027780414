import React from 'react';
import Parse from 'parse';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { DatePicker, Toggle, SelectField, TextField, MenuItem } from 'material-ui';

// Components

// API
import * as Setters from 'api/Setters';

// styles
import styles from './_modal.module.scss';

function getInitialState() {
  return {
    accountInfo: {
      imei: '',
      imeiConfirm: '',
      iccid: '',
      iccidConfirm: '',
    },
    subscriptionNoteObj: {
      name: '',
      reason: '',
    },
    loading: false,
  };
}

class ToggleSimModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.handleChangeInfo = this.handleChangeInfo.bind(this);
    this.handleActivateDeactivate = this.handleActivateDeactivate.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.setState(getInitialState());
    }
  }

  handleChangeInfo(property, value, subscriptionNoteObjBool) {
    const newState = { ...this.state };
    if (subscriptionNoteObjBool) {
      newState.subscriptionNoteObj[property] = value;
    } else {
      newState.accountInfo[property] = value;
    }
    this.setState(newState);
  }

  handleActivateDeactivate(activateBool) {
    this.setState({ ...this.state, error: undefined, loading: true }, (newState) => {
      if (this.state.accountInfo.imei !== this.state.accountInfo.imeiConfirm) {
        this.setState({ ...this.state, error: 'imei does not match', loading: true });
        return;
      }
      Setters.setSimForCompany(
        this.state.accountInfo.imei,
        'imei',
        this.state.accountInfo.iccid,
      ).then(() => {
        this.setState({ ...this.state, loading: false });
        this.props.handleClose();
      }, (error) => {
        this.setState({ ...this.state, error: error && error.message, loading: false });
        console.log(error);
      });
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Link IMEI/ICCID</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={styles.inputs}>
            <TextField
              floatingLabelText="Tablet IMEI"
              onChange={(event, newValue) => this.handleChangeInfo('imei', newValue)}
              value={this.state.accountInfo.imei}
            />
            <TextField
              floatingLabelText="Confirm Tablet IMEI"
              onChange={(event, newValue) => this.handleChangeInfo('imeiConfirm', newValue)}
              value={this.state.accountInfo.imeiConfirm}
            />
            <br />
            <TextField
              floatingLabelText="Sim ICCID"
              onChange={(event, newValue) => this.handleChangeInfo('iccid', newValue)}
              value={this.state.accountInfo.iccid}
            />
            <TextField
              floatingLabelText="Confirm Sim ICCID"
              onChange={(event, newValue) => this.handleChangeInfo('iccidConfirm', newValue)}
              value={this.state.accountInfo.iccidConfirm}
            />
          </div>
          <div>
            {this.state.error &&
              <div className={styles.error}>
                {this.state.error}
              </div>
            }
          </div>
          <div className={styles.selection}>
            <button className={`${styles.cancelButton} buttonDefault`} onClick={this.props.handleClose} >Close</button>
            <button
              className={`${styles.resetButton} buttonDefault`}
              onClick={() => this.handleActivateDeactivate(true)}
              disabled={
                this.state.loading ||
                (this.state.accountInfo.imei !== this.state.accountInfo.imeiConfirm) ||
                (this.state.accountInfo.iccid !== this.state.accountInfo.iccidConfirm)
              }
            >
              Link
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

export default ToggleSimModal;
