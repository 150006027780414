import PropTypes from 'prop-types';
import React from 'react';
import history from 'sbHistory';
import LazyLoad from 'react-lazy-load';

import { Table } from 'react-bootstrap';

// API
import * as Helpers from 'api/Helpers';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import SimRow from 'components/SimsTable/container/SimRow';

// CSS
import styles from './SimsTable.module.scss';

class SimsTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeCheck: {
        active: 0,
        processed: 0,
      }
    };

    this.setActiveCheck = this.setActiveCheck.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      (this.props.loading && !nextProps.loading) ||
      (this.props.date !== nextProps.date)
    ) {
      this.setActiveCheck(null, true);
    }
  }

  setActiveCheck(activeBool, resetBool) {
    const newState = { ...this.state };
    if (resetBool) {
      newState.activeCheck.active = 0;
      newState.activeCheck.processed = 0;
    } else if (activeBool) {
      newState.activeCheck.active += 1;
      newState.activeCheck.processed += 1;
    } else {
      newState.activeCheck.processed += 1;
    }
    this.setState(newState);
  }

  render() {
    return (
      <div className="defaultTable">
        <Table responsive hover>
          <thead>
            <tr>
              <th>
                Sim
                {!this.props.loading && this.props.simArr.length > 0 &&
                  <div>
                    <span>(Total: {this.props.simArr.length})</span>
                    <br />
                    <span>(Active: {this.state.activeCheck.active} / Processed: {this.state.activeCheck.processed})</span>
                  </div>
                }
              </th>
              <th>Company</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.loading &&
              <tr className={styles.loadingIconNoHover}>
                <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
              </tr>
            }
            {!this.props.loading && this.props.simArr.length === 0 &&
              <tr>
                <td colSpan="7" className="emptyRowMessage">No Sims to Display</td>
              </tr>
            }
            {!this.props.loading && this.props.simArr.length > 0 &&
              this.props.simArr.map((simObj) =>
                <SimRow
                  key={simObj.id}
                  simObj={simObj}
                  setActiveCheck={this.setActiveCheck}
                  date={this.props.date}
                  refreshSimArr={this.props.refreshSimArr}
                />
              )
            }
          </tbody>
        </Table>
      </div>
    );
  }
}

SimsTable.propTypes = {
  simArr: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  refreshSimArr: PropTypes.func.isRequired,
};

export default SimsTable;

