import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { DatePicker, Card, CardActions, CardHeader, CardText, CardTitle, Table, TableHeader, TableRow, TableBody, TableRowColumn, TextField, Toggle } from 'material-ui';
import { Line } from 'rc-progress';

// API
import * as Auth from 'api/Auth';
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import * as Helpers from 'api/Helpers';

// Components
import AddPlanModal from './AddPlanModal';
import ConfirmSubscriptionChangeModal from './ConfirmSubscriptionChangeModal';

// const findChangedNonZeroSubscriptionPlans = (originalSubscriptionPlans, newSubscriptionPlans) => {
//   const changedNonZeroSubscriptionPlans = [];
//   for (let i = 0; i < newSubscriptionPlans.length; i++) {
//     if (
//       (newSubscriptionPlans[i].active && newSubscriptionPlans[i].quantity > 0) ||
//       (newSubscriptionPlans[i].active !== originalSubscriptionPlans[i].active || newSubscriptionPlans[i].quantity !== originalSubscriptionPlans[i].quantity)
//     ) {
//       changedNonZeroSubscriptionPlans.push(newSubscriptionPlans[i]);
//     }
//   }
//   return changedNonZeroSubscriptionPlans;
// };

// function checkAccountInfo(accountInfo) {
//   const promise = new Promise((resolve, reject) => {
//     let error = '';
//     let subscriptionHasChanged = false;
//     const activeSubscriptions = accountInfo.subscriptionPlans.filter((subscriptionPlan) => subscriptionPlan.active);
//     if (activeSubscriptions.length > 0) {
//       const currency = activeSubscriptions[0].currency;
//       for (let i = 0; i < activeSubscriptions.length; i++) {
//         if (activeSubscriptions[i].currency !== currency) {
//           error = 'plans can only be in one currency';
//         }
//       }
//     }

//     if (accountInfo.trialEnd) {
//       subscriptionHasChanged = true;
//     } else {
//       for (let i = 0; i < accountInfo.subscriptionPlans.length; i++) {
//         if (accountInfo.subscriptionPlans[i].active !== accountInfo.originalSubscriptionPlans[i].active || accountInfo.subscriptionPlans[i].quantity !== accountInfo.originalSubscriptionPlans[i].quantity) {
//           subscriptionHasChanged = true;
//         }
//       }
//     }
//     if (error !== '') reject(error);
//     resolve(subscriptionHasChanged);
//   });
//   return promise;
// }

class CustomerSubscriptionCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveSubscriptionState: {
        showConfirmSaveSubscriptionModal: false,
        sendEmailNotification: false,
        savingInProgress: false,
        savingProgress: 0,
        error: '',
      },
      addPlanToSubscriptionState: {
        showModal: false,
        stripeSubscriptionId: '',
      },
      planArr: [],
      modifiedPlanArr: [],
    };

    this.addSubscriptionPlan = this.addSubscriptionPlan.bind(this);
    this.updateSubscriptionPlan = this.updateSubscriptionPlan.bind(this);
    this.handleSaveSubscription = this.handleSaveSubscription.bind(this);
  }

  async handleSaveSubscription(reason, name, countObj) {
    const {
      handleSaveSubscriptionObjProperty,
      refreshCustomerSubscriptions,
      stripeSubscriptionDataObj,
      subscriptionObj
    } = this.props;

    this.setState({ ...this.state, disableButton: true, loadingProgress: 0, error: '' });

    try {
      this.setState({ ...this.state, saveSubscriptionState: { ...this.state.saveSubscriptionState, savingProgress: 10 } });
      const updatedNote = `${moment().format('ll')} - ${name}: ${reason}\n` + subscriptionObj.get('note');
      const companyId = subscriptionObj.get('company').id;
      const objPropertySavePromises = [
        handleSaveSubscriptionObjProperty(subscriptionObj, 'note', updatedNote),
        handleSaveSubscriptionObjProperty(subscriptionObj, 'billingEldHardwareCount', countObj.eldHardware),
        handleSaveSubscriptionObjProperty(subscriptionObj, 'billingTabletCount', countObj.tablet),
        handleSaveSubscriptionObjProperty(subscriptionObj, 'billingVisionCount', countObj.vision),
        handleSaveSubscriptionObjProperty(subscriptionObj, 'billingAssetTrackerCount', countObj.assetTracker),
      ];
      Promise.all(objPropertySavePromises).then(() => {
        Getters.checkActiveCountBillingMatchBool(companyId);
      });
      this.setState({ ...this.state, saveSubscriptionState: { ...this.state.saveSubscriptionState, savingProgress: 40 } });
      await Auth.callSecuredParseFunction('subscribeCustomerToPlans', {
        returnAsParse: true,
        customerId: subscriptionObj.get('customerId'),
        subscriptionId: stripeSubscriptionDataObj.subscriptionId,
        planItemArray: stripeSubscriptionDataObj.planArr,
        trialEnd: stripeSubscriptionDataObj.trialEnd,
        companyObjectId: subscriptionObj.get('company').id,
        sendEmailNotification: this.state.sendEmailNotification,
      });
      this.setState({
        ...this.state,
        saveSubscriptionState: {
          ...this.state.saveSubscriptionState,
          showConfirmSaveSubscriptionModal: false,
          savingInProgress: false,
        },
      });

      refreshCustomerSubscriptions();
    } catch (e) {
      console.log(e);
      this.setState({
        ...this.state,
        saveSubscriptionState: {
          ...this.state.saveSubscriptionState,
          showConfirmSaveSubscriptionModal: false,
          savingInProgress: false,
          error: e.message,
        },
      });
    }
  }

  addSubscriptionPlan(planObj) {
    this.setState({ ...this.state, addPlanToSubscriptionState: { ...this.state.addPlanToSubscriptionState, showModal: false } });
    const updatedSubscriptionObj = { ...this.props.stripeSubscriptionDataObj };
    // updatedSubscriptionObj.planArr.push({
    //   name,
    //   stripePlanId,
    //   currency,
    //   active,
    //   amount,
    //   metadata,
    //   intervalCount,
    //   interval,
    //   productId,
    //   quantity,
    // });
    updatedSubscriptionObj.planArr.push({
      ...planObj,
      quantity: 1,
    });

    this.props.updateLocalSubscription(updatedSubscriptionObj);
  }

  updateSubscriptionPlan(planId, property, value) {
    let index;
    const planArr = this.props.stripeSubscriptionDataObj.planArr;
    const newPlanArr = [...planArr];
    for (let i = 0; i < planArr.length; i++) {
      const planObj = planArr[i];
      if (planObj.stripePlanId === planId) {
        index = i;
        break;
      }
    }

    let updatedPlanObj = { ...planArr[index] };
    updatedPlanObj[property] = value;

    newPlanArr[index] = { ...updatedPlanObj };

    this.setState({ ...this.state, addPlanToSubscriptionState: { ...this.state.addPlanToSubscriptionState, showModal: false } });
    const updatedSubscriptionDataObj = { ...this.props.stripeSubscriptionDataObj };
    // updatedSubscriptionObj.planArr.push({
    //   name,
    //   stripePlanId,
    //   currency,
    //   active,
    //   amount,
    //   metadata,
    //   intervalCount,
    //   interval,
    //   productId,
    //   quantity,
    // });
    updatedSubscriptionDataObj.planArr = newPlanArr;

    this.props.updateLocalSubscription(updatedSubscriptionDataObj);
  }

  render() {
    /*
      {
        subscriptionId,
        cancelAtPeriodEnd,
        status,
        planArr: [
          {
            name,
            stripePlanId,
            currency,
            active,
            amount,
            metadata,
            intervalCount,
            interval,
            productId,
            amount,
            quantity,
          }
        ],
      }
    */
    const { stripeSubscriptionDataObj } = this.props;

    return (
      <Card>
        <CardTitle
          title={(
            <div>
              {stripeSubscriptionDataObj.subscriptionId}
              &nbsp;
              <kbd>{stripeSubscriptionDataObj.status}</kbd>
              {stripeSubscriptionDataObj.cancelAtPeriodEnd &&
                <kbd>Cancel At Period End</kbd>
              }
            </div>
          )}
        />

        <Table
          selectable={false}
          multiSelectable={false}
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          >
            <TableRow>
              <TableRowColumn style={{ width: '44%', textAlign: 'center', fontWeight: 500 }}>Invoice Name (Internal Plan)</TableRowColumn>
              <TableRowColumn style={{ width: '10%', textAlign: 'center', fontWeight: 500 }}>Active</TableRowColumn>
              <TableRowColumn style={{ width: '12%', textAlign: 'center', fontWeight: 500 }}>Quantity</TableRowColumn>
              <TableRowColumn style={{ width: '18%', textAlign: 'center', fontWeight: 500 }}>Price / Interval</TableRowColumn>
              <TableRowColumn style={{ width: '16%', textAlign: 'center', fontWeight: 500 }}>Total</TableRowColumn>
            </TableRow>
          </TableHeader>
          <TableBody
            displayRowCheckbox={false}
          >
            {stripeSubscriptionDataObj.planArr.map((planObj) => {
              return (
                <TableRow key={planObj.stripePlanId}>
                  <TableRowColumn style={{ width: '44%' }}>
                    {(planObj.product && planObj.product.name) || planObj.name}
                    <br />
                    ({planObj.nickname})
                  </TableRowColumn>
                  <TableRowColumn style={{ width: '10%' }}>
                    <Toggle
                      onToggle={(event, newValue) => this.updateSubscriptionPlan(planObj.stripePlanId, 'active', newValue)}
                      toggled={planObj.active}
                    />
                  </TableRowColumn>
                  <TableRowColumn style={{ width: '12%' }}>
                    <TextField
                      id={planObj.stripePlanId}
                      type="number"
                      onChange={(event, newValue) => this.updateSubscriptionPlan(planObj.stripePlanId, 'quantity', parseInt(newValue))}
                      value={planObj.quantity}
                    />
                  </TableRowColumn>
                  <TableRowColumn style={{ textAlign: 'right', width: '18%' }}>
                    ${planObj.amount ? (planObj.amount / 100).toFixed(2) : '0.00'}
                    /
                    {planObj.intervalCount} {planObj.interval}
                  </TableRowColumn>
                  <TableRowColumn style={{ textAlign: 'right', width: '16%' }}>
                    ${planObj.amount ? ((planObj.amount / 100) * planObj.quantity).toFixed(2) : '0.00'} {planObj.currency.toUpperCase()}
                  </TableRowColumn>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <AddPlanModal
          show={this.state.addPlanToSubscriptionState.showModal}
          hideModal={() => this.setState({
            ...this.state,
            addPlanToSubscriptionState: {
              ...this.state.addPlanToSubscriptionState,
              showModal: false,
              stripeSubscriptionId: '',
            }
          })}
          addSubscriptionPlan={(planObj) => this.addSubscriptionPlan(planObj)}
          currency={this.props.currency}
        />

        <hr />

        {(this.props.stripeSubscriptionDataObj.trialEnd || !this.props.stripeSubscriptionDataObj.subscriptionId) &&
          <DatePicker
            autoOk
            floatingLabelText="Billing Date"
            onChange={(event, newValue) => {
              const newStripeSubscriptionDataObj = { ...this.props.stripeSubscriptionDataObj };
              newStripeSubscriptionDataObj.trialEnd = newValue;
              this.props.updateLocalSubscription(newStripeSubscriptionDataObj);
            }}
            value={moment(this.props.stripeSubscriptionDataObj.trialEnd || undefined).toDate()}
          />
        }
        <br />

        <table style={{ margin: '0 auto' }}>
          <tr>
            <td style={{ padding: '0.1em' }}>
              <button
                className={`buttonDefault`}
                onClick={() => this.setState({
                  ...this.state,
                  addPlanToSubscriptionState: {
                    ...this.state.addPlanToSubscriptionState,
                    showModal: true,
                    stripeSubscriptionId: stripeSubscriptionDataObj.id,
                  },
                })}
              >
                Add Plan to Subscription
              </button>
            </td>

            <td style={{ padding: '0.1em' }}>
              <button
                className={`buttonDefault`}
                onClick={() => this.setState({ ...this.state, saveSubscriptionState: { ...this.state.saveSubscriptionState, showConfirmSaveSubscriptionModal: true } })}
                disabled={this.state.disableSaveButton}
              >
                <span>Save</span>
              </button>
              <ConfirmSubscriptionChangeModal
                show={this.state.saveSubscriptionState.showConfirmSaveSubscriptionModal}
                handleModalChoice={(confirmBool, reason, name, countObj) => confirmBool ? this.handleSaveSubscription(reason, name, countObj) : this.setState({ ...this.state, showConfirmSaveSubscriptionModal: false })}
                handleClose={() => this.setState({ ...this.state, saveSubscriptionState: { ...this.state.saveSubscriptionState, showConfirmSaveSubscriptionModal: false } })}
                subscriptionObj={this.props.subscriptionObj}
              />
            </td>

            <td style={{ padding: '0.1em', width: '200px' }}>
              <Toggle
                label="Send Email Notification"
                labelPosition="left"
                onToggle={() => this.setState({
                  ...this.state,
                  saveSubscriptionState: {
                    ...this.state.saveSubscriptionState,
                    sendEmailNotification: !this.state.saveSubscriptionState.sendEmailNotification
                  }
                })}
                labelStyle={{ fontSize: '0.7em' }}
                toggled={this.state.saveSubscriptionState.sendEmailNotification}
                disabled={this.state.saveSubscriptionState.savingInProgress}
              />
            </td>
          </tr>

        </table>
        {this.state.saveSubscriptionState.savingInProgress &&
          (
            <div style={{ margin: '2em' }}>
              <Line
                percent={this.state.saveSubscriptionState.savingProgress}
                strokeWidth="1"
                trailWidth="1"
                strokeColor="#56d8d8"
                trailColor="#D3D3D3"
              />
            </div>
          )
        }
        <br />
        {this.state.saveSubscriptionState.error &&
          (
            <div className="errorText">
              {this.state.saveSubscriptionState.error}
              <br />
            </div>
          )
        }
      </Card>
    );
  }
}

CustomerSubscriptionCard.propTypes = {
  subscriptionObj: PropTypes.object.isRequired,
  stripeSubscriptionDataObj: PropTypes.object.isRequired,
  billingInfo: PropTypes.object.isRequired,
  handleChangeSubscriptionObj: PropTypes.array,
  isAOBRD: PropTypes.bool,
  handleChangeIsAOBRD: PropTypes.func.isRequired,
};

export default CustomerSubscriptionCard;
