import PropTypes from 'prop-types';
import React from 'react';
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, Toggle, TextField } from 'material-ui';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';

// Components
import ELDRow from './ELDRow';
import moment from 'moment';

// CSS
// import './ELDList.module.scss';

class ELDList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eldHardwareArr: [],
    };
    this.refreshCompanyELD = this.refreshCompanyELD.bind(this);
  }

  componentDidMount() {
    this.refreshCompanyELD();
  }

  refreshCompanyELD() {
    Getters.getEldHardwareForCompany(this.props.company).then((eldHardwareArr) => {
      const eldHardwareSerialArr = [];
      const filteredEldHardwareArr = [];
      for (let i = 0; i < eldHardwareArr.length; i++) {
        const eldHardware = eldHardwareArr[i];
        const serial = eldHardware.get('serial');
        if (eldHardwareSerialArr.indexOf(serial) === -1) {
          eldHardwareSerialArr.push(serial);
          filteredEldHardwareArr.push(eldHardware);
        }
      }
      this.setState({ ...this.state, eldHardwareArr: filteredEldHardwareArr.sort((a, b) => (a.get('serial') - b.get('serial'))) });
    });
  }


  render() {
    return (
      <div>
        <Table
          selectable={false}
          multiSelectable={false}
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          ><TableRow>
              <TableRowColumn>ELD Hardware Serial (Total: <b>{this.state.eldHardwareArr.length}</b>)</TableRowColumn>
              <TableRowColumn>Last Updated (Within 3 months: <b>
                {
                  this.state.eldHardwareArr.filter((eldHardware) =>
                    eldHardware.get('lastCheckIn') && moment(eldHardware.get('lastCheckIn')
                    ).isSameOrAfter(moment().subtract(3, 'months'))).length
                }
              </b>)</TableRowColumn>
            </TableRow></TableHeader>
          <TableBody
            displayRowCheckbox={false}
          >
            {this.state.eldHardwareArr.map((eldHardware) => (
              <ELDRow
                eldHardware={eldHardware}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

}

ELDList.propTypes = {
  company: PropTypes.object.isRequired,
};

export default ELDList;
