// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v0PyChY7oopF6Odov5Ay {}\n\n.Ai1idmPXA73VxC1l8ntv {\n  .RJyDmch2bXyWI5B0Lfoh {\n    width: 18em !important;\n    text-align: center;\n  }\n}", "",{"version":3,"sources":["webpack://./app/components/Login/view/ForgotPasswordModal.module.css"],"names":[],"mappings":"AAAA,uBAAU;;AAEV;EACE;IACE,sBAAsB;IACtB,kBAAkB;EACpB;AACF","sourcesContent":[".colours {}\n\n.forgotPasswordModal {\n  .input {\n    width: 18em !important;\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colours": "v0PyChY7oopF6Odov5Ay",
	"forgotPasswordModal": "Ai1idmPXA73VxC1l8ntv",
	"input": "RJyDmch2bXyWI5B0Lfoh"
};
export default ___CSS_LOADER_EXPORT___;
