import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';

// Components
import DatePicker from 'components/DatePicker/container/DatePicker';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './EditDriverOdometersModal.module.scss';

const initialState = () => ({
  date: moment().toDate(),
  eldEvents: [],
  loading: false,
});

class EditDriverOdometersModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState();
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.show && nextProps.show) {
      this.handleDateChange(this.state.date);
    } else if (!nextProps.show && this.props.show) {
      this.setState(initialState());
    }
  }

  handleDateChange(date) {
    this.setState({ ...this.state, date: moment(date).toDate(), loading: true }, () => {
      if (date) {
        Getters.getEldEventsForDriver(this.props.driver, moment(date).toDate()).then((eldEvents) => {
          let error = false;
          // if (eldEvents.length > 3 && eldEvents[0] === eldEvents[Math.round(eldEvents.length / 2, 0)] && eldEvents[Math.round(eldEvents.length / 2, 0)] === eldEvents[eldEvents.length - 1]) {
          //   error = true;
          // }
          const filteredEldEvents = eldEvents.filter((eldEvent) => eldEvent.get('totalVehicleKm'));
          if (filteredEldEvents.length > 1) {
            this.setState({ ...this.state, eldEvents: filteredEldEvents, error, loading: false });
          } else if (eldEvents.length > 1) {
            this.setState({ ...this.state, eldEvents, error, loading: false });
          } else {
            this.setState({ ...this.state, eldEvents, error, loading: false });
          }
        }, (error) => {
          console.log(error);
          this.setState({ ...this.state, loading: false });
        });
      } else {
        this.setState({ ...this.state, loading: false });
      }
    });
  }

  saveEldEvent(eldEvent, totalVehicleKm, type, allDayBool) {
    this.setState({ ...this.state, loading: true }, () => {
      Setters.editEldEventOdometer(eldEvent.id, parseFloat(totalVehicleKm), type, allDayBool, parseFloat(this.state.eldEvents[0].get('totalVehicleKm')), parseFloat(this.state.eldEvents[this.state.eldEvents.length - 1].get('totalVehicleKm'))).then(() => {
        this.setState({ ...this.state, loading: false });
      }, (error) => {
        console.log(error);
        this.setState({ ...this.state, loading: false });
      });
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Odometer
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <div>
              <DatePicker
                className="input"
                selected={moment(this.state.date).toDate()}
                placeholderText="Choose Date"
                onChange={(date) => this.handleDateChange(date)}
              />
            </div>

            <br />

            <div>
              {this.state.loading &&
                <tr className={styles.loadingIconNoHover}>
                  <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
                </tr>
              }
              {!this.state.loading && this.state.eldEvents.length < 2 &&
                <div className={styles.error}>
                  <div>No Odometers</div>
                </div>
              }
              {!this.state.loading && this.state.eldEvents.length >= 2 &&
                <div className={this.state.error ? styles.error : ''}>
                  <div>
                    <b>Start Odometer:</b> &nbsp;
                    <input
                      value={this.state.eldEvents[0].get('totalVehicleKm')}
                      type="number"
                      onChange={(e) => {
                        this.state.eldEvents[0].set('totalVehicleKm', e.target.value);
                        this.setState({ ...this.state, eldEvents: this.state.eldEvents });
                      }}
                    />
                    <button
                      className={'buttonDefault'}
                      onClick={() => this.saveEldEvent(this.state.eldEvents[0], this.state.eldEvents[0].get('totalVehicleKm'), 'start')}
                    >
                      Save
                    </button>

                  </div>
                  <div>
                    <b>End Odometer:</b> &nbsp;
                    <input
                      value={this.state.eldEvents[this.state.eldEvents.length - 1].get('totalVehicleKm')}
                      type="number"
                      onChange={(e) => {
                        this.state.eldEvents[this.state.eldEvents.length - 1].set('totalVehicleKm', e.target.value);
                        this.setState({ ...this.state, eldEvents: this.state.eldEvents });
                      }}
                    />
                    <button
                      className={'buttonDefault'}
                      onClick={() => this.saveEldEvent(this.state.eldEvents[this.state.eldEvents.length - 1], this.state.eldEvents[this.state.eldEvents.length - 1].get('totalVehicleKm'), 'end')}
                    >
                      Save
                    </button>
                  </div>

                  <br />
                  <br />

                  <button
                    className={'buttonDefault'}
                    onClick={() => {
                      this.saveEldEvent(this.state.eldEvents[0], this.state.eldEvents[0].get('totalVehicleKm'), 'start')
                      this.saveEldEvent(this.state.eldEvents[this.state.eldEvents.length - 1], this.state.eldEvents[this.state.eldEvents.length - 1].get('totalVehicleKm'), 'end')
                    }}
                  >
                    Save Start & End
                  </button>
                </div>
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

EditDriverOdometersModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  driver: PropTypes.object,
  show: PropTypes.bool,
};

export default EditDriverOdometersModal;
