import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import history from 'sbHistory';
import LazyLoad from 'react-lazy-load';
import { Table } from 'react-bootstrap';

// API
import * as Helpers from 'api/Helpers';
import * as Setters from 'api/Setters';
import * as Getters from 'api/Getters';
import * as Sim from 'api/Sim';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from 'components/SubscriptionsTable/container/SubscriptionsRow.module.scss';

class SubscriptionsRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eldHardwareCount: this.props.subscription.get('activeEldHardwareCount') || 0,
      eldHardwareCountDate: this.props.subscription.get('activeEldHardwareCountDate') || null,
      eldHardwareCountLoading: false,
      sim: {
        simCount: this.props.subscription.get('activeSimCount') || 0,
        tabletSimCount: this.props.subscription.get('activeTabletSimCount') || 0,
        assetTrackerSimCount: this.props.subscription.get('activeAssetTrackerSimCount') || 0,
        simCountDate: this.props.subscription.get('activeSimCountDate') || null,
        simCountLoading: false,
      },
      vehicles: {
        activeVehicleLastThreeMonthsCount: this.props.subscription.get('activeVehicleLastThreeMonthsCount') || 0,
        activeVehicleLastMonthCount: this.props.subscription.get('activeVehicleLastMonthCount') || 0,
        activeVehicleCountDate: this.props.subscription.get('activeVehicleCountDate') || 0,
        activeVehicleCountLoading: false,
      },
      subscriptionArr: [],
    };

    this.getSims = this.getSims.bind(this);
    this.getActiveEldHardware = this.getActiveEldHardware.bind(this);
    this.getPlans = this.getPlans.bind(this);
    this.checkAndGetActiveVehiclesCountObjForCompany = this.checkAndGetActiveVehiclesCountObjForCompany.bind(this);
    // this.refreshSims = this.refreshSims.bind(this);
    // this.refreshEldHardware = this.refreshEldHardware.bind(this);
  }

  componentDidMount() {
    // this.getSims();
    // this.getActiveEldHardware();
    this.getPlans();
  }

  getSims() {
    this.setState({ ...this.state, sim: { ...this.state.sim, simCountLoading: true } });
    Getters.getAndRefreshSimsForCompany(this.props.subscription.get('company')).then((simArr) => {
      this.setState({
        ...this.state,
        sim: {
          ...this.sim,
          simCount: simArr.filter((simObj) => simObj.get('isActive')).length,
          tabletSimCount: simArr.filter((simObj) => simObj.get('isActive') && !simObj.get('trackerEsn')).length,
          assetTrackerSimCount: simArr.filter((simObj) => simObj.get('isActive') && simObj.get('trackerEsn')).length,
          simCountDate: moment().toDate(),
          simCountLoading: false
        }
      });
    }, (error) => {
      console.log(error);
      this.setState({ ...this.state, sim: { ...this.state.sim, simCountLoading: false } });
    });
  }

  getActiveEldHardware() {
    this.setState({ ...this.state, eldHardwareCountLoading: true });
    Getters.getActiveEldHardwareForCompany(this.props.subscription.get('company')).then((activeEldHardwareArr) => {
      this.setState({
        ...this.state,
        eldHardwareCount: activeEldHardwareArr.length,
        eldHardwareCountDate: moment().toDate(),
        eldHardwareCountLoading: false,
      });
    });
  }

  getPlans() {
    Getters.getCustomerSubscriptions(this.props.subscription.id).then((subscriptionArr) => {
      this.setState({ ...this.state, subscriptionArr });
    });
  }

  checkAndGetActiveVehiclesCountObjForCompany() {
    this.setState({ ...this.state, vehicles: { ...this.state.vehicles, activeVehicleCountLoading: true } });
    Getters.checkAndGetActiveVehiclesCountObjForCompany(this.props.subscription.get('company')).then((countObj) => {
      this.setState({
        ...this.state,
        vehicles: {
          activeVehicleLastMonthCount: countObj.activeVehicleLastMonthCount.length,
          activeVehicleLastThreeMonthsCount: countObj.activeVehicleLastThreeMonthsCount.length,
          activeVehicleCountDate: moment().toDate(),
          activeVehicleCountLoading: false,
        },
      });
    });
  }

  render() {
    return (
      <tr>
        <td>{this.props.subscription.get('company') &&
          <div>
            <div><b>{this.props.subscription.get('company').get('name')}</b></div>
            <div>{this.props.subscription.get('company').get('dotNumber') && this.props.subscription.get('company').get('dotNumber')}</div>
            <div>{this.props.subscription.get('company').get('promoCode') && <span>Code: {this.props.subscription.get('company').get('promoCode')}</span>}</div>
            <div>Created: {this.props.subscription.get('createdAt') && moment(this.props.subscription.get('createdAt')).format('MMM DD, YYYY')}</div>
          </div>
        }</td>
        <td>{
          <div>
            {this.props.subscription.get('eldModule') && <div>ELD</div>}
            {this.props.subscription.get('mapModule') && <div>Map</div>}
            {this.props.subscription.get('iftaModule') && <div>IFTA</div>}
            {this.props.subscription.get('driverBehaviourModule') && <div>Driver Behaviour</div>}
            {this.props.subscription.get('visionModule') && <div>Vision</div>}
            {this.props.subscription.get('dispatchModule') && <div>Dispatch</div>}
          </div>
        }</td>
        <td>
          <div>
            {!this.state.subscriptionArr &&
              <div className={styles.error}>
                Error with Subscription
                <br />
                <i>(Usually Unpaid Invoices)</i>
              </div>
            }
            {this.state.subscriptionArr && this.state.subscriptionArr.map((subscriptionObj) => {
              return (
                <div>
                  <hr />
                  <b className={subscriptionObj.status !== 'active' && styles.error}>Status: {subscriptionObj.status}</b>
                  {subscriptionObj.cancelAtPeriodEnd && <div className={styles.error}>Cancel At Period End</div>}
                  {
                    subscriptionObj.planArr.flat(1).filter((subscriptionPlanObj) => subscriptionPlanObj.active).map((subscriptionPlanObj) => {
                      return (
                        <div key={`${this.props.subscription.id} ${subscriptionPlanObj.name}`}>
                          {subscriptionPlanObj.name} x {subscriptionPlanObj.quantity}
                        </div>
                      );
                    })
                  }
                  <hr />
                </div>
              );
            })}
          </div>
        </td>
        <td>
          <div>
            <div>
              {!this.state.eldHardwareCountLoading &&
                <span>
                  ELD Hardware (Last 3 Months): <b>{this.state.eldHardwareCount}</b>
                  ,&nbsp;
                  Updated: <b>{this.state.eldHardwareCountDate && moment(this.state.eldHardwareCountDate).format('MMM DD, YYYY')}</b>
                  <button className={`buttonDefault ${styles.refreshButton}`} onClick={this.getActiveEldHardware}>
                    <i className={'material-icons'}>refresh</i>
                  </button>
                </span>
              }
              {this.state.eldHardwareCountLoading &&
                <LoadingIcon />
              }

              <br />

              {!this.state.vehicles.activeVehicleCountLoading &&
                <span>
                  Active Vehicles (Last Month): <b>{this.state.vehicles.activeVehicleLastMonthCount}</b>
                  ,&nbsp;
                  Updated: <b>{this.state.vehicles.activeVehicleCountDate && moment(this.state.vehicles.activeVehicleCountDate).format('MMM DD, YYYY')}</b>
                  <br />
                  Active Vehicles (Last 3 Months): <b>{this.state.vehicles.activeVehicleLastThreeMonthsCount}</b>
                  ,&nbsp;
                  Updated: <b>{this.state.vehicles.activeVehicleCountDate && moment(this.state.vehicles.activeVehicleCountDate).format('MMM DD, YYYY')}</b>
                  <button className={`buttonDefault ${styles.refreshButton}`} onClick={this.checkAndGetActiveVehiclesCountObjForCompany}>
                    <i className={'material-icons'}>refresh</i>
                  </button>
                </span>
              }
              {this.state.vehicles.activeVehicleCountLoading &&
                <LoadingIcon />
              }

              <br />

              {!this.state.sim.simCountLoading &&
                <div>
                  <div>
                    Tablet: <b>{this.state.sim.tabletSimCount}</b>
                    ,&nbsp;
                    Updated: <b>{this.state.sim.simCountDate && moment(this.state.sim.simCountDate).format('MMM DD, YYYY')}</b>
                  </div>
                  <div>
                    Asset Tracker: <b>{this.state.sim.assetTrackerSimCount}</b>
                    ,&nbsp;
                    Updated: <b>{this.state.sim.simCountDate && moment(this.state.sim.simCountDate).format('MMM DD, YYYY')}</b>
                    <button className={`buttonDefault ${styles.refreshButton}`} onClick={this.getSims}>
                      <i className={'material-icons'}>refresh</i>
                    </button>
                  </div>
                </div>
              }
              {this.state.sim.simCountLoading &&
                <LoadingIcon />
              }

              {this.props.subscription.get('activeCountBillingMatchBool') !== undefined &&
                <div>
                  <br />
                  Billing / Active Count Match: <span className={this.props.subscription.get('activeCountBillingMatchBool') ? styles.true : styles.false}>{`${this.props.subscription.get('activeCountBillingMatchBool')}`}</span>
                </div>
              }
            </div>
          </div>
        </td>
        <td>
          <button className="buttonDefault" onClick={() => this.props.editSubscription(this.props.subscription)}>Edit Subscription</button>
          <br />
          <br />
          {this.props.subscription.get('company').get('isHidden') && <button className="buttonDefault" onClick={() => this.props.hideUnhideCompany(this.props.subscription.get('company'), false)}>Show Company</button>}
          {!this.props.subscription.get('company').get('isHidden') && <button className="buttonDefault yellowButton" onClick={() => this.props.hideUnhideCompany(this.props.subscription.get('company'), true)}>Hide Company</button>}
        </td>
      </tr>
    );
  }
}

SubscriptionsRow.propTypes = {
  editSubscription: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired,
};

export default SubscriptionsRow;
