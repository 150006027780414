import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, TextField, Toggle } from 'material-ui';

import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';

class ResellerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionCustomPlans: [],
      saving: false,
    };

    this.updateSubscriptionResellerPlanAllowanceValue = this.updateSubscriptionResellerPlanAllowanceValue.bind(this);
  }

  componentDidMount() {
    // Get existing special plans
    // Allow editing # for special plans
    Promise.all([
      Getters.getSubscriptionCustomPlans(),
      Getters.getSubscriptionResellerPlanAllowances(this.props.subscriptionObj.id),
    ]).then((results) => {
      const subscriptionCustomPlanArr = results[0];
      const subscriptionResellerPlanAllowanceArr = results[1];

      const subscriptionCustomPlans = [];
      for (let i = 0; i < subscriptionCustomPlanArr; i++) {
        const subscriptionCustomPlan = subscriptionCustomPlanArr[i];
        const customPlanObj = {
          subscriptionCustomPlan,
          subscriptionResellerPlanAllowance: undefined,
        };
        for (let j = 0; j < subscriptionResellerPlanAllowanceArr.length; j++) {
          const subscriptionResellerPlanAllowance = subscriptionResellerPlanAllowance[j];
          if (subscriptionCustomPlan.id === subscriptionResellerPlanAllowance.get('subscriptionCustomPlan').id) {
            customPlanObj.subscriptionResellerPlanAllowance = subscriptionResellerPlanAllowance;
            customPlanObj.amountAvailable = subscriptionResellerPlanAllowance.get('amountAvailable');
            break;
          }
        }
        subscriptionCustomPlans.push(customPlanObj);
      }
      this.setState({ ...this.state, subscriptionCustomPlans });
    });
  }

  updateSubscriptionResellerPlanAllowanceValue(subscriptionCustomPlanObj, property, value) {
    if (subscriptionCustomPlanObj.get('subscriptionResellerPlanAllowanceObj')) {
      subscriptionResellerPlanAllowanceObj.set(property, value);
    }
    subscriptionCustomPlanObj[property] = value;
    this.setState({ ...this.setState });
  }

  render()  {
    return (
      <div>
        <b>Amount Remaining for Special Plans</b>
        <br />
        <Table
          selectable={false}
          multiSelectable={false}
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          ><TableRow>
            <TableRowColumn style={{ width: '50%' }}><b>Description</b></TableRowColumn>
            <TableRowColumn style={{ width: '50%' }}><b>Amount Remaining</b></TableRowColumn>
          </TableRow></TableHeader>
          <TableBody
            displayRowCheckbox={false}
          >
              { this.state.subscriptionCustomPlans.map((subscriptionCustomPlanObj, index) => {
                <TableRow>
                  <TableRowColumn style={{ width: '50%' }}>
                    { subscriptionCustomPlanObj.get('subscriptionCustomPlan').get('description') }
                  </TableRowColumn>
                  <TableRowColumn style={{ width: '50%' }}>
                    <input 
                      type="number" 
                      value={subscriptionCustomPlanObj.amountAvailable} 
                      onChange={(e) => this.updateSubscriptionResellerPlanAllowanceValue(subscriptionCustomPlanObj, 'amountAvailable', e.target.value)} 
                    />
                    <button
                      className={'buttonDefault'}
                      onClick={() => {
                        this.setState({ ...this.state, saving: true });
                        Setters.updateSubscriptionResellerPlanAllowanceAmountAvailable(subscriptionCustomPlanObj).then((updatedSubscriptionResellerPlanAllowanceObj) => {
                          const updatedSubscriptionCustomPlans = this.state.subscriptionCustomPlans;
                          updatedSubscriptionCustomPlans[index] = updatedSubscriptionResellerPlanAllowanceObj;
                          this.setState({ ...this.state, subscriptionCustomPlans: updatedSubscriptionCustomPlans, saving: true });
                        });
                      }}
                      disabled={props.savingAddInvoiceItem}
                    >
                      Save
                    </button>
                  </TableRowColumn>
                </TableRow>
              })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

ResellerList.propTypes = {
  subscriptionObj: PropTypes.object.isRequired,
  payingInvoice: PropTypes.bool,
};

export default ResellerList;
