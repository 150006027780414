import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'debounce';

// API
import * as Setters from 'api/Setters';

// // Styles
// import './SubscriptionNote.module.scss';


const placeholderContractNotesString = (
  `
Type of Contract (Yearly/Monthly/etc.): 
Term of Contract + Start Date: 
Enterprise Package Pricing: 
Software Pricing: 
Discount Notes: 
Termination Fee:`);

class SubscriptionNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      debounceSave: null,
      disableButton: false,
    };
  }

  componentDidMount() {
    const debounceSave = debounce((property, value) => {
      // this.setState({ ...this.state, disableButton: true }, () => {
      //   this.setState({ ...this.state, disableButton: false });
      // });
      this.setState({ ...this.state, disableButton: true }, () => {
        Setters.saveSubscriptionObjProperty(this.props.subscriptionObj, property, value).then(() => {
          this.setState({ ...this.state, disableButton: false });
        });
      });
    }, 500);
    if (this.props.subscriptionObj && this.props.subscriptionObj.get('note')) {
      const note = this.props.subscriptionObj.get('note');
      const index = note.indexOf(placeholderContractNotesString);
      if (index !== -1) {
        const beginningString = note.substr(0, index);
        const endString = note.substr(index + placeholderContractNotesString.length, note.length);
        this.props.subscriptionObj.set(
          'note',
          beginningString + endString,
        );
        Setters.saveSubscriptionObjProperty(this.props.subscriptionObj, 'note', beginningString + endString);
      }
    }
    this.setState({ ...this.state, debounceSave });
  }

  render() {
    const { subscriptionObj, companyReseller } = this.props;
    return (
      <div>
        Deal Notes & Account Manager &nbsp;
        <textarea
          value={(subscriptionObj && subscriptionObj.get('note'))}
          onChange={(e) => {
            subscriptionObj.set('note', e.target.value);
            this.setState({ ...this.state });
            this.state.debounceSave('note', subscriptionObj.get('note'));
          }}
          style={{ fontSize: '0.7em', minHeight: '10em' }}
        />

        {/* <div>
          <b>Upload Contract:</b>
          <br />
          <input type="file" onChange={(e) => this.runFileUploadScript(e.target.files[0])}></input>
        </div> */}
      </div>
    );
  }
}

SubscriptionNote.propTypes = {
  subscriptionObj: PropTypes.object.isRequired,
  companyReseller: PropTypes.object.isRequired,
};

// const mapDispatchToProps = (dispatch) => ({
//   actions: bindActionCreators({
//     // fetchJobsIfNeeded,
//     fetchDocumentsForState,
//   }, dispatch),
// });

export default connect(undefined, undefined)(SubscriptionNote);
