/*
 * This file contains general getter functions useful throughout the app
 * Please list functions in alphabetical order and comment well unless
 * the function listing order depends on requisite functions (ex. define function before used)
 */

import Parse from 'parse';
import Axios from 'axios';
import moment from 'moment';
import store from '../store';

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

function compressWhitespace(string) {
  // Given a string/text, find out if it is just all whitespace. If so, shrink it down to ''
  const finalString = string.replace(/^\s+/, '').replace(/\s+$/, '');
  return finalString;
}

function concatenateStringsInParseObjectsArray(array, key, repeatBool) {
  let concatenatedStrings = '';
  let stringIterable = array;
  if (!repeatBool) {
    stringIterable = new Set(array.map((parseObject) => parseObject.get(key)));
  }
  for (const string of stringIterable) {
    concatenatedStrings += `${string}, `;
  }
  concatenatedStrings = concatenatedStrings.substring(0, concatenatedStrings.length - 2);
  return concatenatedStrings;
}

function createCsvFile(filename, content, downloadBool) {
  if (!downloadBool) {
    const csvContent = `data:text/csv;charset=utf-8,${content}`;
    return csvContent;
  }

  const encodedUri = `data:text/csv;charset=utf-8,${encodeURIComponent(content)}`;
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  const formattedFilename = filename;
  if (filename.substr(filename.length - 4) !== '.csv') formattedFilename.concat(`${filename}.csv`);
  link.setAttribute('download', `${filename}.csv`);
  document.body.appendChild(link); // Required for FF
  return link.click();
}

function doParseObjectArraysIntersect(arr1, arr2) {
  let shorterArr = arr1;
  let longerArr = arr2;
  if (arr1.length > arr2.length) {
    shorterArr = arr2;
    longerArr = arr1;
  }
  const shorterArrLen = shorterArr.length;
  for (let i = 0; i < shorterArrLen; i++) {
    if (longerArr.map((x) => x.id).indexOf(shorterArr[i].id) !== -1) {
      return true;
    }
  }
  return false;
}

function findIndexOfObjArr(arr, key, value) {
  // search array of objects to see if key  === value. return index of object
  const arrLen = arr.length;
  for (let i = 0; i < arrLen; i++) {
    if (arr[i][key] === value) {
      return i;
    }
  }
  return -1;
}

function formatDate(date, format) {
  // date must be a valid date format
  const dateISO = date.toISOString();
  if (format === 'YYYY-MM-DD') {
    return dateISO.slice(0, 10);
  }
  return 0;
}

function formatNotificationObject(messageOrParseObject, fromSinchBool) {
  if (fromSinchBool) {
    const text = messageOrParseObject.substring(2, messageOrParseObject.length - 11);
    const notificationObject = JSON.parse(text);
    notificationObject.read = false;
    notificationObject.createdAt = moment(new Date());
    return notificationObject;
  }
  let text = messageOrParseObject.get('text');
  text = text.substring(2, text.length - 11);
  const notificationObject = JSON.parse(text);
  notificationObject.read = messageOrParseObject.get('read');
  notificationObject.createdAt = moment(messageOrParseObject.get('createdAt'));
  return notificationObject;
}

function generatePassword() {
  const promise = new Promise((resolve, reject) => {
    Axios.post('/generate/password').then(
      response => resolve(response.data),
      error => reject(error)
    );
  });
  return promise;
}

function getArrayOfInternalParseObjects(parseArray, internalParseObjectProperty) {
  const parseArrayLength = parseArray.length;
  const internalObjectArray = [];
  for (let i = 0; i < parseArrayLength; i++) {
    internalObjectArray.push(parseArray[i].get(internalParseObjectProperty));
  }
  return internalObjectArray;
}

function toTitleCase(str) {
  if (!str) return '';
  try {
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  } catch (e) {
    console.log(e);
    return str;
  }
}

function getCompanyReadWriteACL() {
  const companyACL = new Parse.ACL();
  companyACL.setRoleWriteAccess(store.getState().Company.company.id, true);
  companyACL.setRoleReadAccess(store.getState().Company.company.id, true);
  return companyACL;
}

function getModules() {
  return [
    {
      property: 'eldModule',
      label: 'Cloud Software',
    },
    {
      property: 'iftaModule',
      label: 'IFTA Module',
    },
    {
      property: 'mapModule',
      label: 'Map Module',
    },
    {
      property: 'driverBehaviourModule',
      label: 'Speeding Idling Reports',
    },
    {
      property: 'dispatchModule',
      label: 'Dispatch Module',
    },
    {
      property: 'visionModule',
      label: 'Switchboard Vision',
    },
    {
      property: 'driverDocumentModule',
      label: 'Driver Documents',
    },
    {
      property: 'safetyRemindersModule',
      label: 'Alerts/Reminders Module',
    },
  ];
}

function getSubscriptionModuleObjArr(subscriptionObj) {
  const moduleArr = getModules();
  const subscriptionModuleArr = [];

  for (let i = 0; i < moduleArr.length; i++) {
    const moduleProperty = moduleArr[i].property;
    subscriptionModuleArr.push({
      module: moduleProperty,
      isActive: subscriptionObj.get(moduleProperty),
    });
  }

  return subscriptionModuleArr;
}

function getNotificationTimeSinceEpoch() {
  return `${moment().format('x')}${Parse.User.current().id}`;
}

function isActiveDriver(driver, activeDriverArr) {
  // see if driver belongs to activeDriverArr
  const activeDriverLen = activeDriverArr.length;
  for (let i = 0; i < activeDriverLen; i++) {
    if (driver.id === activeDriverArr[i].id) {
      return true;
    }
  }
  return false;
}

function isAdmin() {
  return Parse.User.current().toJSON().userType.indexOf(0) > -1;
}

function isAlphanumeric(string) {
  return !/[^a-zA-Z0-9]/g.test(string);
}

function isDispatchersDriver(dispatcherUserId, driver) {
  // Function that finds whether a given driver belongs to the dispatcher
  const dispatchers = driver.toJSON().dispatchers;
  if (dispatchers) {
    const dispatchersLen = dispatchers.length;
    for (let i = 0; i < dispatchersLen; i++) {
      const dispatcher = dispatchers[i];
      if (dispatcherUserId === dispatcher.objectId) {
        return true;
      }
    }
  }
  return false;
}

function isObjectEmpty(object) {
  return object && Object.keys(object).length === 0 && object.constructor === Object;
}

function isStringEmpty(string) {
  // determine if this string exists or not
  return !(string && string !== '' && string.length > 0 && compressWhitespace(string) !== '');
}

function isInvalidEmailFormat(email) {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

function openDocumentLink(url) {
  if (store.getState().Main.env === 'prod') {
    return window.open(`${url.replace(/^http:\/\//i, 'https://')}`, '');
  }
  return window.open(url, '');
}

function getEnv() {
  return store.getState().Main.env;
}

function prefixExists(companyPrefix) {
  // checks to see if the prefix specified already exists
  const companyQuery = new Parse.Query('Company');
  companyQuery.equalTo('prefix', companyPrefix.toLowerCase());

  const promise = new Promise((resolve, reject) => {
    companyQuery.first().then(
      () => resolve(true),
      () => reject(false),
    );
  });
  return promise;
}

function removeSpecialCharacters(string) {
  // Remove all special characters from a string. If keepExceptions is true, keep letters, numbers, and whitespace
  return string.replace(/[^a-zA-Z0-9]/g, '');
}

function rotateArray(arr, reverseBool) {
  const rotatedArray = arr;
  if (reverseBool) {
    rotatedArray.unshift(rotatedArray.pop());
  } else {
    rotatedArray.push(rotatedArray.shift());
  }
  return rotatedArray;
}


/**
 * @memberof module:Helpers
 * @param {*} milli
 * @returns
 */
function convertMillisecondsToHours(milli) {
  return milli / 3600000;
}

/**
 * @memberof module:Helpers
 * @param {*} hours
 * @returns
 */
function convertHoursToMilliseconds(hours) {
  return hours * 3600000;
}


// function scaleAndConvertToPDF(pictureFile) {
//   const promise = new Promise((resolve, reject) => {
//     const reader = new FileReader();
//
//     reader.addEventListener('load', () => {
//       const doc = new JsPDF('p', 'pt', 'letter');
//       const img = new Image;
//       img.src = reader.result;
//       let imageRotateValue = 0;
//       const pointsPerPixel = 0.75;
//       let imageScaleFactor;
//       // if (img.width > img.height) {
//       //   imageRotateValue = 90;
//       // }
//       // letter size is 612 x 792 pts (8.5 x 11)
//       if ((img.width / img.height) > (612 / 792)) {
//         // Constrained by width
//         // Scale width to width of PDF
//         imageScaleFactor = ((612 / pointsPerPixel) / (img.width)) * pointsPerPixel;
//       } else {
//         // Constrained by height
//         imageScaleFactor = ((792 / pointsPerPixel) / (img.height)) * pointsPerPixel;
//       }
//       doc.addImage({
//         imageData: img.src,
//         angle: imageRotateValue,
//         x: 0,
//         y: 0,
//         w: img.width * imageScaleFactor,
//         h: img.height * imageScaleFactor,
//       });
//       resolve(doc.output('datauristring'));
//     }, false);
//
//     reader.readAsDataURL(pictureFile);
//   });
//   return promise;
// }

function setParseObjectToKeyValues(parseObject, keyValuesObject) {
  const keyArray = Object.keys(keyValuesObject);
  const keyArrayLen = keyArray.length;
  for (let i = 0; i < keyArrayLen; i++) {
    parseObject.set(keyArray[i], keyValuesObject[keyArray[i]]);
  }
  return parseObject;
}

function stringReplaceAll(string, search, replacement) {
  return string.replace(new RegExp(search, 'g'), replacement);
}

function toDataURL(image) {
  console.log(image);
  // Create an empty canvas element
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;

  // Copy the image contents to the canvas
  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0);

  // Get the data-URL formatted image
  // Firefox supports PNG and JPEG. You could check img.src to
  // guess the original format, but be aware the using "image/jpg"
  // will re-encode the image.
  const dataURL = canvas.toDataURL('image/jpg');
  console.log(dataURL);

  return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
}

export {
  capitalizeFirstLetter,
  compressWhitespace,
  concatenateStringsInParseObjectsArray,
  convertMillisecondsToHours,
  convertHoursToMilliseconds,
  createCsvFile,
  doParseObjectArraysIntersect,
  findIndexOfObjArr,
  formatDate,
  formatNotificationObject,
  getModules,
  getSubscriptionModuleObjArr,
  getCompanyReadWriteACL,
  getArrayOfInternalParseObjects,
  getNotificationTimeSinceEpoch,
  generatePassword,
  isActiveDriver,
  isAdmin,
  toTitleCase,
  isAlphanumeric,
  isDispatchersDriver,
  getEnv,
  isObjectEmpty,
  isStringEmpty,
  isInvalidEmailFormat,
  openDocumentLink,
  prefixExists,
  removeSpecialCharacters,
  rotateArray,
  // scaleAndConvertToPDF,
  setParseObjectToKeyValues,
  stringReplaceAll,
  toDataURL,
};
