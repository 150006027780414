// http://londoncycle.alexrieux.fr/
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import ReactMapboxGl, { ZoomControl } from 'react-mapbox-gl';

// Components
import VehicleMarker from 'components/Map/view/components/VehicleMarker';
import GPSPoint from 'components/Map/view/components/GPSPoint';
import GPSDot from 'components/Map/view/components/GPSDot';

// API
import * as Mapbox from 'api/Mapbox';

const Map = ReactMapboxGl({
  accessToken: Mapbox.getToken(),
});

class VehicleMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: [-98.467324, 49.069434],
      zoom: [2.9],
      clickMarker: null,
    };
  }

  render() {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        { this.props.Main.mapboxToken &&
          <Map
            style="mapbox://styles/mapbox/dark-v9"
            movingMethod="easeTo"
            zoom={this.state.zoom}
            center={this.state.center}
            containerStyle={{ height: '100%', width: '100%' }}
            onDblClick={(map, clickEvent) => {
              this.setState({ ...this.state, clickMarker: { lng: clickEvent.lngLat.lng, lat: clickEvent.lngLat.lat } });
              this.props.handleLocationSelect(clickEvent.lngLat.lng, clickEvent.lngLat.lat);
            }}
          >

            <ZoomControl />

            { this.state.clickMarker &&
              <GPSPoint coordinates={this.state.clickMarker} />
            }

            { this.props.vehicleArr &&
              this.props.vehicleArr.map((vehicle, index) => {
                if (vehicle.get('vehicleLocation') && vehicle.get('vehicleLocation').get('location')) {
                  return (
                    <VehicleMarker
                      key={`${vehicle.id}${index}`}
                      vehicleObj={vehicle}
                      handleOnClick={() => this.props.getVehicleLocations(vehicle)}
                    />
                  );
                }
                return null;
              })
            }

            { this.props.vehicleLocationArr &&
              this.props.vehicleLocationArr.map((vehicleLocation, index) => (
                <GPSDot
                  key={`${vehicleLocation.id}${index}`}
                  vehicleLocation={vehicleLocation}
                />
              ))
            }

          </Map>
        }
      </div>
    );
  }
}

VehicleMap.propTypes = {
  vehicleArr: PropTypes.array,
  vehicleLocationArr: PropTypes.array,
  Main: PropTypes.object.isRequired,
  handleLocationSelect: PropTypes.func.isRequired,
  getVehicleLocations: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { Main } = state;
  return ({
    Main,
  });
};

export default connect(mapStateToProps)(VehicleMap);
