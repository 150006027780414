import PropTypes from 'prop-types';
import React from 'react';
import Parse from 'parse';
import { Modal } from 'react-bootstrap';
import { DatePicker, Toggle, SelectField, TextField, MenuItem } from 'material-ui';

import * as ParseAPI from 'api/Parse';
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import * as Auth from 'api/Auth';

import store from '../../../store';


// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// styles
import styles from './Modal.module.scss';
import ThenPromise from 'promise';

function getInitialState() {
  return {
    vendorIntegrationUserObj: null,
    resellerCompanyObj: null,
    resellerObj: null,
    loading: false,
  };
}

class ResellerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.handleChangeReseller = this.handleChangeReseller.bind(this);
    this.createResellerCompany = this.createResellerCompany.bind(this);
    this.updateVendorIntegrationUserObj = this.updateVendorIntegrationUserObj.bind(this);
    this.refreshReseller = this.refreshReseller.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show && !this.props.show && nextProps.user.id) {
      this.refreshReseller(nextProps.user);
    }
    if (!nextProps.show && this.props.show) {
      this.setState(getInitialState());
    }
  }

  createResellerCompany() {
    if (!this.state.promoCodeInput) {
      this.setState({ ...this.state, error: 'Need Reseller Code/ID' });
    } else {
      this.setState({ ...this.state, loading: true, error: '' });
      Auth.callSecuredParseFunction('_internal:createResellerCompany', {
        returnAsParse: true,
        parseClassName: 'ResellerCompany',
        companyId: this.props.company.id,
        promoCode: this.state.promoCodeInput,
        name: this.props.company.get('name'),
      }).then((resellerCompanyObj) => {
        this.setState({ ...this.state, resellerCompanyObj, loading: false });
        Setters.setResellerForCompany(this.props.company.id, resellerCompanyObj && resellerCompanyObj.id);
      }).catch((error) => {
        this.setState({ ...this.state, loading: false });
      });
    }
  }

  handleChangeReseller(property, value) {
    const newState = { ...this.state };
    newState.accountInfo[property] = value;
    this.setState(newState);
  }

  async refreshReseller(user) {
    const userId = user.id;
    this.setState({ ...this.state, loading: true }, async () => {
      let vendorIntegrationUserObj;
      let resellerObj;
      let resellerCompanyObj;
      try {
        vendorIntegrationUserObj = await Getters.getVendorIntegrationUserFromUserId(userId);
      } catch (e) {
        console.log(e);
      }
      try {
        resellerObj = await Getters.getReseller(userId);
      } catch (e) {
        console.log(e);
      }
      try {
        resellerCompanyObj = await Auth.callSecuredParseFunction('_internal:getResellerCompany', {
          returnAsParse: true,
          parseClassName: 'ResellerCompany',
          companyId: user.get('belongsToCompany').id,
        });
      } catch (e) {
        console.log(e);
      }
      this.setState({ ...this.state, resellerCompanyObj, resellerObj, vendorIntegrationUserObj, user, loading: false });
    });
  }

  async updateVendorIntegrationUserObj(property, value) {
    if (this.state.vendorIntegrationUserObj) {
      this.state.vendorIntegrationUserObj.set(property, value);
      Setters.editVendorIntegrationUser(this.props.user.id, property, value);
      this.setState({ ...this.state, vendorIntegrationUserObj: this.state.vendorIntegrationUserObj });
    } else {
      const vendorIntegrationUserObj = new Parse.Object('VendorIntegrationUser');
      vendorIntegrationUserObj.set(property, value);
      Setters.editVendorIntegrationUser(this.props.user.id, property, value);
      this.setState({ ...this.state, vendorIntegrationUserObj });
    }
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reseller</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.loading &&
            <div>
              <LoadingIcon />
            </div>
          }
          {!this.state.loading &&
            (
              <div className={styles.inputs}>
                <span>Wialon Username</span> &nbsp;
                <input
                  type="text"
                  value={this.state.vendorIntegrationUserObj && this.state.vendorIntegrationUserObj.get('wialonUsername')}
                  placeholder="....."
                  onChange={(e) => this.updateVendorIntegrationUserObj('wialonUsername', e.target.value)}
                />

                <hr />

                {!this.state.resellerCompanyObj &&
                  <div>
                    <span>Reseller ID</span> &nbsp;
                    <input
                      type="text"
                      value={this.state.promoCodeInput}
                      placeholder="....."
                      onChange={(e) => this.setState({ ...this.state, promoCodeInput: e.target.value })}
                    />
                    <button className="buttonDefault" onClick={() => this.createResellerCompany()} >Enable Reseller For Company</button>
                  </div>
                }
                {this.state.resellerCompanyObj &&
                  (
                    <div>
                      <Toggle
                        label="Enable User as Reseller"
                        onToggle={(event, newValue) => {
                          this.setState({ ...this.state, loading: true });
                          Setters.toggleResellerForUser(this.props.user.id, newValue).then((resellerObj) => {
                            const user = this.state.user;
                            if (newValue === true) {
                              user.revert();
                              user.add('userType', 5);
                              this.setState({ ...this.state, resellerObj, user, loading: false });
                            } else {
                              user.revert();
                              user.remove('userType', 5);
                              this.setState({ ...this.state, resellerObj: null, user, loading: false });
                            }
                          });
                        }}
                        toggled={this.state.user && this.state.user.get('userType').indexOf(5) !== -1}
                      />
                      {this.state.user && this.state.user.get('userType').indexOf(5) !== -1 && this.state.resellerObj &&
                        (
                          <div>
                            {/* { props.resellerCompanyArr.length > 0 &&
                              <select value={this.state.resellerObj.get('promoCode')} onChange={(e) => { props.handleChangeReseller(e.target.value) }}>
                                <option>Select Partner</option>
                                { props.resellerCompanyArr.map((resellerCompany) => 
                                  <option key={resellerCompany.id} value={resellerCompany.get('promoCode')}>{ resellerCompany.get('name') }</option>
                                )}
                              </select>
                            } */}

                            {/* <Toggle
                              label="Charge Reseller CC"
                              onToggle={(event, newValue) => {
                                // Setters.setResellerProperty(this.state.resellerObj.id, 'apiVehicleLocation', newValue).then((reseller) => {
                                //   if (newValue === true) {
                                //       this.state.resellerObj.set('apiVehicleLocation', reseller.get('apiVehicleLocation'));
                                //   } else {
                                //       this.state.resellerObj.unset('apiVehicleLocation');
                                //   }
                                //   this.setState({ ...this.state });
                                // });
                              }}
                              toggled={this.state.resellerObj && !!this.state.resellerObj.get('customerId')}
                            /> */}

                            <Toggle
                              label="ELD Module"
                              onToggle={(event, newValue) => {
                                this.setState({ ...this.state, loading: true });
                                Setters.setResellerProperty(this.state.resellerObj.id, 'eldModule', newValue).then((reseller) => {
                                  if (newValue === true) {
                                    this.state.resellerObj.set('eldModule', newValue);
                                  } else {
                                    this.state.resellerObj.unset('eldModule');
                                  }
                                  this.setState({ ...this.state, loading: false });
                                });
                              }}
                              toggled={this.state.resellerObj && !!this.state.resellerObj.get('eldModule')}
                            />

                            <Toggle
                              label="Map Module"
                              onToggle={(event, newValue) => {
                                this.setState({ ...this.state, loading: true });
                                Setters.setResellerProperty(this.state.resellerObj.id, 'mapModule', newValue).then((reseller) => {
                                  if (newValue === true) {
                                    this.state.resellerObj.set('mapModule', newValue);
                                  } else {
                                    this.state.resellerObj.unset('mapModule');
                                  }
                                  this.setState({ ...this.state, loading: false });
                                });
                              }}
                              toggled={this.state.resellerObj && !!this.state.resellerObj.get('mapModule')}
                            />

                            <Toggle
                              label="IFTA Module"
                              onToggle={(event, newValue) => {
                                this.setState({ ...this.state, loading: true });
                                Setters.setResellerProperty(this.state.resellerObj.id, 'iftaModule', newValue).then((reseller) => {
                                  if (newValue === true) {
                                    this.state.resellerObj.set('iftaModule', newValue);
                                  } else {
                                    this.state.resellerObj.unset('iftaModule');
                                  }
                                  this.setState({ ...this.state, loading: false });
                                });
                              }}
                              toggled={this.state.resellerObj && !!this.state.resellerObj.get('iftaModule')}
                            />
                          </div>
                        )
                      }
                    </div>
                  )
                }
              </div>
            )
          }
          <div>
            {this.state.error &&
              <div className={styles.error}>
                {this.state.error}
              </div>
            }
          </div>
          <div className={styles.selection}>
            <button className={`${styles.cancelButton} buttonDefault`} onClick={this.props.hideModal} >Cancel</button>
            <button className={`${styles.resetButton} buttonDefault`} onClick={this.handleReset}>Save</button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

ResellerModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  user: PropTypes.object,
  show: PropTypes.bool,
};

export default ResellerModal;
