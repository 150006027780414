import PropTypes from 'prop-types';
import React from 'react';
import history from 'sbHistory';
import LazyLoad from 'react-lazy-load';

import { Table } from 'react-bootstrap';

// API
import * as Helpers from 'api/Helpers';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import VehicleOdometersRow from 'components/CompanyOdometersTable/container/VehicleOdometersRow';

// CSS
import styles from './VehiclesTable.module.scss';

class VehiclesTable extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="defaultTable">
        <Table responsive hover>
          <thead>
            <tr>
              <th>Vehicle</th>
              <th>Company</th>
              <th>ELD Hardware Serial/Version</th>
              <th>Odometers</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.loading &&
              <tr className={styles.loadingIconNoHover}>
                <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
              </tr>
            }
            {!this.props.loading && this.props.vehicles.length === 0 &&
              <tr>
                <td colSpan="7" className="emptyRowMessage">No Vehicle to Display</td>
              </tr>
            }
            {!this.props.loading && this.props.vehicles.length > 0 &&
              this.props.vehicles.map((vehicle) =>
                <VehicleOdometersRow key={vehicle.id} vehicle={vehicle} />
              )
            }
          </tbody>
        </Table>
      </div>
    );
  }
}

VehiclesTable.propTypes = {
  vehicles: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default VehiclesTable;

