import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// API
import * as Getters from 'api/Getters';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './SwitchboardUsageDetails.module.scss';

class SwitchboardUsageDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eldHardwareCount: null,
      eldHardwareCountDate: null,
      eldHardwareCountLoading: false,
      sim: {
        simCount: null,
        tabletSimCount: null,
        assetTrackerSimCount: null,
        simCountDate: null,
        simCountLoading: false,
      },
      vehicles: {
        activeVehicleLastThreeMonthsCount: null,
        activeVehicleLastMonthCount: null,
        activeVehicleCountDate: null,
        activeVehicleCountLoading: false,
      },
      activeCountBillingMatchBool: undefined,
      switchboardSubscriptionObj: null,
    };

    this.getSims = this.getSims.bind(this);
    this.getActiveEldHardware = this.getActiveEldHardware.bind(this);
    this.getPlans = this.getPlans.bind(this);
    this.checkAndGetActiveVehiclesCountObjForCompany = this.checkAndGetActiveVehiclesCountObjForCompany.bind(this);
  }

  async componentDidMount() {
    if (this.props.switchboardSubscriptionObj) {
      this.setState({
        ...this.state,
        eldHardwareCount: this.props.switchboardSubscriptionObj.get('activeEldHardwareCount') || 0,
        eldHardwareCountDate: this.props.switchboardSubscriptionObj.get('activeEldHardwareCountDate') || null,
        eldHardwareCountLoading: false,
        sim: {
          simCount: this.props.switchboardSubscriptionObj.get('activeSimCount') || 0,
          tabletSimCount: this.props.switchboardSubscriptionObj.get('activeTabletSimCount') || 0,
          assetTrackerSimCount: this.props.switchboardSubscriptionObj.get('activeAssetTrackerSimCount') || 0,
          simCountDate: this.props.switchboardSubscriptionObj.get('activeSimCountDate') || null,
          simCountLoading: false,
        },
        vehicles: {
          activeVehicleLastThreeMonthsCount: this.props.switchboardSubscriptionObj.get('activeVehicleLastThreeMonthsCount') || 0,
          activeVehicleLastMonthCount: this.props.switchboardSubscriptionObj.get('activeVehicleLastMonthCount') || 0,
          activeVehicleCountDate: this.props.switchboardSubscriptionObj.get('activeVehicleCountDate') || 0,
          activeVehicleCountLoading: false,
        },
        activeCountBillingMatchBool: this.props.switchboardSubscriptionObj.get('activeCountBillingMatchBool'),
        switchboardSubscriptionObj: this.props.switchboardSubscriptionObj,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.switchboardSubscriptionObj) {
      this.setState({
        ...this.state,
        eldHardwareCount: nextProps.switchboardSubscriptionObj.get('activeEldHardwareCount') || 0,
        eldHardwareCountDate: nextProps.switchboardSubscriptionObj.get('activeEldHardwareCountDate') || null,
        eldHardwareCountLoading: false,
        sim: {
          simCount: nextProps.switchboardSubscriptionObj.get('activeSimCount') || 0,
          tabletSimCount: nextProps.switchboardSubscriptionObj.get('activeTabletSimCount') || 0,
          assetTrackerSimCount: nextProps.switchboardSubscriptionObj.get('activeAssetTrackerSimCount') || 0,
          simCountDate: nextProps.switchboardSubscriptionObj.get('activeSimCountDate') || null,
          simCountLoading: false,
        },
        vehicles: {
          activeVehicleLastThreeMonthsCount: nextProps.switchboardSubscriptionObj.get('activeVehicleLastThreeMonthsCount') || 0,
          activeVehicleLastMonthCount: nextProps.switchboardSubscriptionObj.get('activeVehicleLastMonthCount') || 0,
          activeVehicleCountDate: nextProps.switchboardSubscriptionObj.get('activeVehicleCountDate') || 0,
          activeVehicleCountLoading: false,
        },
        activeCountBillingMatchBool: nextProps.switchboardSubscriptionObj.get('activeCountBillingMatchBool'),
        switchboardSubscriptionObj: nextProps.switchboardSubscriptionObj
      });
    }
  }

  getSims() {
    this.setState({ ...this.state, sim: { ...this.state.sim, simCountLoading: true } });
    Getters.getAndRefreshSimsForCompany(this.state.switchboardSubscriptionObj.get('company')).then((simArr) => {
      this.setState({
        ...this.state,
        sim: {
          ...this.sim,
          simCount: simArr.filter((simObj) => simObj.get('isActive')).length,
          tabletSimCount: simArr.filter((simObj) => simObj.get('isActive') && !simObj.get('trackerEsn')).length,
          assetTrackerSimCount: simArr.filter((simObj) => simObj.get('isActive') && simObj.get('trackerEsn')).length,
          simCountDate: moment().toDate(),
          simCountLoading: false
        }
      });
    }, (error) => {
      console.log(error);
      this.setState({ ...this.state, sim: { ...this.state.sim, simCountLoading: false } });
    });
  }

  getActiveEldHardware() {
    this.setState({ ...this.state, eldHardwareCountLoading: true });
    Getters.getActiveEldHardwareForCompany(this.state.switchboardSubscriptionObj.get('company')).then((activeEldHardwareArr) => {
      this.setState({
        ...this.state,
        eldHardwareCount: activeEldHardwareArr.length,
        eldHardwareCountDate: moment().toDate(),
        eldHardwareCountLoading: false,
      });
    });
  }

  getPlans() {
    Getters.getCustomerSubscriptions(this.state.switchboardSubscriptionObj.id).then((subscriptionArr) => {
      this.setState({ ...this.state, subscriptionArr });
    });
  }

  checkAndGetActiveVehiclesCountObjForCompany() {
    this.setState({ ...this.state, vehicles: { ...this.state.vehicles, activeVehicleCountLoading: true } });
    Getters.checkAndGetActiveVehiclesCountObjForCompany(this.state.switchboardSubscriptionObj.get('company')).then((countObj) => {
      this.setState({
        ...this.state,
        vehicles: {
          activeVehicleLastMonthCount: countObj.activeVehicleLastMonthCount.length,
          activeVehicleLastThreeMonthsCount: countObj.activeVehicleLastThreeMonthsCount.length,
          activeVehicleCountDate: moment().toDate(),
          activeVehicleCountLoading: false,
        },
      });
    });
  }

  render() {
    return (
      <div>
        {this.props.switchboardSubscriptionObj &&
          (
            <div>
              {!this.state.eldHardwareCountLoading &&
                (
                  <span>
                    ELD Hardware (Last 3 Months): <b>{this.state.eldHardwareCount}</b>
                    ,&nbsp;
                    Updated: <b>{this.state.eldHardwareCountDate && moment(this.state.eldHardwareCountDate).format('MMM DD, YYYY')}</b>
                    <button className={`buttonDefault ${styles.refreshButton}`} onClick={this.getActiveEldHardware}>
                      <i className={'material-icons'}>refresh</i>
                    </button>
                  </span>
                )
              }
              {this.state.eldHardwareCountLoading &&
                <LoadingIcon />
              }

              <br />

              {!this.state.vehicles.activeVehicleCountLoading &&
                (
                  <span>
                    Active Vehicles (Last Month): <b>{this.state.vehicles.activeVehicleLastMonthCount}</b>
                    ,&nbsp;
                    Updated: <b>{this.state.vehicles.activeVehicleCountDate && moment(this.state.vehicles.activeVehicleCountDate).format('MMM DD, YYYY')}</b>
                    <br />
                    Active Vehicles (Last 3 Months): <b>{this.state.vehicles.activeVehicleLastThreeMonthsCount}</b>
                    ,&nbsp;
                    Updated: <b>{this.state.vehicles.activeVehicleCountDate && moment(this.state.vehicles.activeVehicleCountDate).format('MMM DD, YYYY')}</b>
                    <button className={`buttonDefault ${styles.refreshButton}`} onClick={this.checkAndGetActiveVehiclesCountObjForCompany}>
                      <i className={'material-icons'}>refresh</i>
                    </button>
                  </span>
                )
              }
              {this.state.vehicles.activeVehicleCountLoading &&
                <LoadingIcon />
              }

              <br />

              {!this.state.sim.simCountLoading &&
                (
                  <div>
                    <div>
                      Tablet: <b>{this.state.sim.tabletSimCount}</b>
                      ,&nbsp;
                      Updated: <b>{this.state.sim.simCountDate && moment(this.state.sim.simCountDate).format('MMM DD, YYYY')}</b>
                    </div>
                    <div>
                      Asset Tracker: <b>{this.state.sim.assetTrackerSimCount}</b>
                      ,&nbsp;
                      Updated: <b>{this.state.sim.simCountDate && moment(this.state.sim.simCountDate).format('MMM DD, YYYY')}</b>
                      <button className={`buttonDefault ${styles.refreshButton}`} onClick={this.getSims}>
                        <i className={'material-icons'}>refresh</i>
                      </button>
                    </div>
                  </div>
                )
              }
              {this.state.sim.simCountLoading &&
                <LoadingIcon />
              }

              {this.state.activeCountBillingMatchBool !== undefined &&
                (
                  <div>
                    <br />
                    Billing / Active Count Match: <span className={this.state.activeCountBillingMatchBool ? styles.true : styles.false}>{`${this.state.activeCountBillingMatchBool}`}</span>
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    );
  }
}

SwitchboardUsageDetails.propTypes = {
  switchboardSubscriptionObj: PropTypes.object,
  switchboardSubscriptionObjId: PropTypes.string,
};

export default SwitchboardUsageDetails;

