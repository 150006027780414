import * as ActionConstants from 'actions/ActionConstants';
import * as Helpers from 'api/Helpers';

const initialState = {
  subscriptions: [],
  fetched: true,
};

function destroySubscriptions(stateSubscriptions, subscriptionsToBeDeleted) {
  const pSubscriptions = stateSubscriptions;
  if (stateSubscriptions) {
    const subscriptionsToBeDeletedLen = subscriptionsToBeDeleted.length;
    for (let i = 0; i < subscriptionsToBeDeletedLen; i++) {
      const index = Helpers.findIndexOfObjArr(stateSubscriptions, 'id', subscriptionsToBeDeleted[i].id);
      pSubscriptions.splice(index, 1);
    }
  }
  return pSubscriptions;
}

function addSubscriptions(stateSubscriptions, subscriptionsToBeAdded) {
  const newSubscriptions = [].concat(stateSubscriptions);
  newSubscriptions.unshift(...subscriptionsToBeAdded);
  return newSubscriptions;
}

export function Subscription(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.INITIALIZE_STRIPE:
      return {
        ...state,
        status: action.type,
        stripePublishableKey: action.stripePublishableKey,
        error: {},
      };
    case ActionConstants.FETCH_SUBSCRIPTIONS_INPROGRESS:
      return {
        ...state,
        status: action.type,
        fetched: false,
      };
    case ActionConstants.FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        status: action.type,
        subscriptions: action.subscriptions,
        fetched: true,
        error: undefined,
      };
    case ActionConstants.FETCH_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        subscriptions: [],
        status: action.type,
        error: action.error,
        fetched: false,
      };
    case ActionConstants.DELETE_SUBSCRIPTIONS:
      return initialState;
    case ActionConstants.ADD_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        status: action.type,
        updated: action.subscriptions,
        subscriptions: addSubscriptions(state.subscriptions, action.subscriptions),
      };
    case ActionConstants.ADD_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    case ActionConstants.DESTROY_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        status: action.type,
        subscriptions: destroySubscriptions(state.subscriptions, action.subscriptions),
        updated: action.subscriptions,
      };
    default:
      return state;
  }
}
