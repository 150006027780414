import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import moment from 'moment';
import { debounce } from 'debounce';
import { Modal } from 'react-bootstrap';

import store from '../../../store';

// API
import * as Auth from 'api/Auth';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import * as Setters from 'api/Setters';

// Components
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import StripeCheckoutModal from './StripeCheckoutModal';
import EditSubscriptionModalView from '../view/EditSubscriptionModal';

// CSS
// import styles from './EditSubscriptionModal.module.css';

function getCompanyReseller(companyObj, resellerCompanyArr) {
  if (resellerCompanyArr) {
    for (let i = 0; i < resellerCompanyArr.length; i++) {
      const resellerCompany = resellerCompanyArr[i];
      if (resellerCompany.get('promoCode') === companyObj.get('promoCode')) {
        return resellerCompany;
      }
    }
  }
  return null;
}

function getInitialState(subscriptionObj) {
  if (!subscriptionObj) {
    return {
      showStripeCheckout: false,
      showConfirmDeleteCCModal: false,
      showConfirmPayUpcomingInvoiceModal: false,
      showConfirmSaveSubscriptionModal: false,
      disableButton: false,
      error: '',
      loadingProgress: 0,
      showCCModal: false,
      accountInfo: {
        isAOBRD: false,
        originalSubscriptionPlans: [],
        subscriptionPlans: [],
        taxPercent: null,
        subscriptionInvoices: [],
        subscriptionUpcomingInvoiceItems: [],
        subscriptionAddInvoiceItem: {
          description: '',
          currency: 'cad',
          dollar_amount: 0,
          quantity: 0,
          error: '',
        },
        companyReseller: null,
      },
      sendEmailNotification: false,
      voidInvoice: {
        invoiceId: null,
        showConfirmModal: false,
      },
      payInvoice: {
        error: '',
      },
      payingVoidingInvoice: false,
      activeTab: 'subscriptionPlans',
      savingAddInvoiceItem: false,
    };
  }
  return {
    showStripeCheckout: false,
    showConfirmDeleteCCModal: false,
    showConfirmPayUpcomingInvoiceModal: false,
    showConfirmSaveSubscriptionModal: false,
    disableButton: false,
    error: '',
    loadingProgress: 0,
    savingMinSpeedKm: false,
    accountInfo: {
      subscriptionObj,
      isAOBRD: false,
      ctpatEnabled: false,
      handleChangeShowEldCsvDownload: false,
      companyIdForSubscription: subscriptionObj.get('company') && subscriptionObj.get('company').id,
      originalSubscriptionPlans: [],
      subscriptionPlans: [],
      taxPercent: null,
      subscriptionInvoices: [],
      subscriptionUpcomingInvoiceItems: [],
      subscriptionAddInvoiceItem: {
        description: '',
        currency: 'cad',
        dollar_amount: 0,
        quantity: 0,
        error: '',
      },
    },
    resellerCompanyArr: [],
    sendEmailNotification: false,
    voidInvoice: {
      invoiceDataObj: {},
      showConfirmModal: false,
    },
    payInvoice: {
      error: '',
    },
    payingVoidingInvoice: false,
    savingAddInvoiceItem: false,
  };
}

class EditSubscriptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState(props.subscription);
    this.refreshSubscription = this.refreshSubscription.bind(this);
    this.refreshSubscriptionPlans = this.refreshSubscriptionPlans.bind(this);
    this.openCCModal = this.openCCModal.bind(this);
    this.closeCCModal = this.closeCCModal.bind(this);
    this.handleSaveCC = this.handleSaveCC.bind(this);
    this.handleChangeReseller = this.handleChangeReseller.bind(this);
    this.handleChangeSubscription = this.handleChangeSubscription.bind(this);
    this.saveSubscriptionModules = this.saveSubscriptionModules.bind(this);
    this.handleChangeAddInvoiceItem = this.handleChangeAddInvoiceItem.bind(this);
    this.handleAddInvoiceItem = this.handleAddInvoiceItem.bind(this);
    this.handleDeleteInvoiceItem = this.handleDeleteInvoiceItem.bind(this);
    this.handleChangeSubscriptionBillingInfo = this.handleChangeSubscriptionBillingInfo.bind(this);
    this.handleSaveSubscriptionBillingInfo = this.handleSaveSubscriptionBillingInfo.bind(this);
    this.handleSaveSubscriptionObjProperty = this.handleSaveSubscriptionObjProperty.bind(this);
    this.showConfirmVoidInvoiceModal = this.showConfirmVoidInvoiceModal.bind(this);
    this.payInvoice = this.payInvoice.bind(this);
    this.voidInvoice = this.voidInvoice.bind(this);
    this.deleteCustomerSources = this.deleteCustomerSources.bind(this);
    this.payUpcomingInvoice = this.payUpcomingInvoice.bind(this);
    this.getMoreInvoices = this.getMoreInvoices.bind(this);
  }

  componentDidMount() {
    Getters.getResellerCompanies().then((resellerCompanyArr) => {
      this.setState({ ...this.state, resellerCompanyArr });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.show && this.props.show) {
      this.setState({ ...getInitialState() });
    }
    if (nextProps.show && nextProps.subscription) {
      if (nextProps.subscription && !nextProps.subscription.get('customerId')) {
        Setters.createStripeCustomer(nextProps.subscription.get('company')).then(updatedSubscriptionObj => {
          this.setState({ ...this.state, accountInfo: { ...this.state.accountInfo, subscriptionObj: updatedSubscriptionObj } }, () => {
            this.refreshSubscriptionPlans();
            this.refreshSubscription();
          });
        });
      } else {
        this.setState({ ...this.state, accountInfo: { ...this.state.accountInfo, subscriptionObj: nextProps.subscription } }, () => {
          this.refreshSubscriptionPlans();
          this.refreshSubscription();
        });
      }
    }
  }

  getMoreInvoices() {
    const subscriptionObj = this.state.accountInfo.subscriptionObj;
    const lastInvoice = this.state.accountInfo.subscriptionInvoices[this.state.accountInfo.subscriptionInvoices.length - 1];
    Getters.getCustomerInvoices(subscriptionObj.id, lastInvoice.id).then((additionalInvoices) => {
      this.setState({
        ...this.state,
        accountInfo: {
          ...this.state.accountInfo,
          subscriptionInvoices: [].concat(...this.state.accountInfo.subscriptionInvoices, additionalInvoices),
        }
      });
    });
  }


  refreshSubscriptionPlans() {
    Promise.all([
      Getters.getCustomerSubscriptionDate(this.state.accountInfo.subscriptionObj.id),
    ]).then((response) => {
      const subscriptionEndDate = response[0] && moment.unix(response[0]);

      this.setState({
        ...this.state,
        accountInfo: {
          ...this.state.accountInfo,
          subscriptionEndDate,
        },
      });
    });
  }

  refreshSubscription() {
    const subscriptionObj = this.state.accountInfo.subscriptionObj;
    Promise.all([
      Getters.getCustomerInvoices(subscriptionObj.id),
      Getters.getUpcomingInvoiceItems(subscriptionObj.id),
      Getters.getCustomerBillingInfo(subscriptionObj.id),
      Getters.getCustomerSubscriptionContracts(subscriptionObj.id),
    ]).then((response) => {
      const subscriptionInvoices = response[0] || [];
      const subscriptionUpcomingInvoiceItems = response[1] || [];
      const billingInfo = response[2];
      const subscriptionContractArr = response[3];
      const subscriptionCCLastFour = billingInfo.lastFour;
      const subscriptionCCexpMonth = billingInfo.expMonth;
      const subscriptionCCexpYear = billingInfo.expYear;
      const subscriptionAccountBalance = billingInfo.accountBalance && billingInfo.accountBalance / 100;
      const invoicePrefix = billingInfo.invoicePrefix;
      const taxPercent = billingInfo.taxPercent;
      const currency = billingInfo.currency;
      const ccStateProvince = billingInfo.stateProvince;
      const ccCountry = billingInfo.country;
      const ccPostalCode = billingInfo.postalCode;
      const invoiceFooter = billingInfo.invoiceSettings && billingInfo.invoiceSettings.footer;
      const subscriptionBillingEmail = billingInfo.billingEmail;
      const subscriptionBillingDescription = billingInfo.billingDescription;
      this.setState({
        ...this.state,
        accountInfo: {
          ...this.state.accountInfo,
          isAOBRD: this.state.accountInfo.subscriptionObj.get('company').get('aobrdSetting') && this.state.accountInfo.subscriptionObj.get('company').get('aobrdSetting').get('aobrdEnabled'),
          ctpatEnabled: this.state.accountInfo.subscriptionObj.get('company').get('ctpat'),
          handleChangeShowEldCsvDownload: this.state.accountInfo.subscriptionObj.get('company').get('handleChangeShowEldCsvDownload'),
          subscriptionInvoices,
          subscriptionUpcomingInvoiceItems,
          subscriptionAddInvoiceItem: {
            currency: currency || 'cad',
            description: '',
            dollar_amount: 0,
            quantity: 0,
            error: '',
          },
          subscriptionCCLastFour,
          subscriptionCCexpMonth,
          subscriptionCCexpYear,
          subscriptionAccountBalance,
          subscriptionBillingEmail,
          subscriptionBillingDescription,
          subscriptionContractArr,
          currency,
          taxPercent,
          ccStateProvince,
          ccCountry,
          ccPostalCode,
          invoicePrefix,
          invoiceFooter,
          companyReseller: getCompanyReseller(subscriptionObj.get('company'), this.state.resellerCompanyArr),
        }
      });
    }, (error) => {
      console.log(error);
      if (error.message && error.message.includes('No such customer: ')) {
        Setters.createStripeCustomer(this.props.subscription.get('company'), undefined, true).then(updatedSubscriptionObj => {
          this.setState({ ...this.state, accountInfo: { ...this.state.accountInfo, subscriptionObj: updatedSubscriptionObj } }, () => {
            this.refreshSubscription();
          });
        });
      }
    });
  }

  openCCModal() {
    this.setState({ ...this.state, showCCModal: true });
  }

  closeCCModal() {
    return this.setState({ ...this.state, showCCModal: false });
  }

  handleSaveCC(token) {
    if (this.state.accountInfo.subscriptionObj.get('customerId')) {
      const company = this.state.accountInfo.subscriptionObj.get('company');
      return Setters.addCCToCustomer(company, token).then(() => {
        this.refreshSubscription(this.props);
        return this.closeCCModal();
      });
    } else {
      const company = this.state.accountInfo.subscriptionObj.get('company');
      return Setters.createStripeCustomer(company, token).then(() => {
        this.refreshSubscription(this.props);
        return this.closeCCModal();
      });
    }
  }

  deleteCustomerSources() {
    this.setState({ ...this.state, disableButton: true, error: '' }, async () => {
      Setters.deleteCustomerSources(this.state.accountInfo.subscriptionObj.id).then(() => {
        this.setState({ ...this.state, disableButton: false, error: '' });
      });
    });
  }

  handleChangeSubscriptionBillingInfo(property, value) {
    const accountInfo = { ...this.state.accountInfo };
    accountInfo[property] = value;
    this.setState({
      ...this.state,
      accountInfo,
    });
  }

  handleSaveSubscriptionBillingInfo(property) {
    this.setState({ ...this.state, disableButton: true }, () => {
      const promises = [];
      if (property === 'taxPercent') {
        promises.push(Setters.saveSubscriptionTaxPercent(this.state.accountInfo.subscriptionObj, this.state.accountInfo.taxPercent));
      } else if (property === 'subscriptionAccountBalance') {
        promises.push(Setters.setCustomerAccountBalance(this.state.accountInfo.subscriptionObj.id, this.state.accountInfo.subscriptionAccountBalance * 100));
      } else if (property === 'invoiceFooter') {
        promises.push(Setters.saveSubscriptionInvoiceFooter(this.state.accountInfo.subscriptionObj.id, this.state.accountInfo.invoiceFooter));
      }
      Promise.all(promises).then(() => {
        this.setState({ ...this.state, disableButton: false });
      }, (err) => {
        console.log(err);
      });
    });
  }

  handleSaveSubscriptionObjProperty(subscriptionObj, property, value) {
    this.setState({ ...this.state, disableButton: true }, () => {
      Setters.saveSubscriptionObjProperty(subscriptionObj, property, value).then(() => {
        subscriptionObj.set(property, value);
        this.setState({ ...this.state, disableButton: false, accountInfo: { ...this.state.accountInfo, subscriptionObj } });
      });
    });
  }

  handleChangeAddInvoiceItem(property, value) {
    const subscriptionAddInvoiceItem = { ...this.state.accountInfo.subscriptionAddInvoiceItem };
    subscriptionAddInvoiceItem[property] = value;
    this.setState({
      ...this.state,
      accountInfo: {
        ...this.state.accountInfo,
        subscriptionAddInvoiceItem,
      },
    });
  }

  handleAddInvoiceItem() {
    this.setState({
      ...this.state,
      accountInfo: {
        ...this.state.accountInfo,
        subscriptionAddInvoiceItem: {
          ...this.state.accountInfo.subscriptionAddInvoiceItem,
          error: '',
        },
      },
      savingInvoiceItem: true,
    }, () => {
      Setters.addSubscriptionItem(
        this.state.accountInfo.subscriptionObj.id,
        this.state.accountInfo.subscriptionAddInvoiceItem.currency,
        this.state.accountInfo.subscriptionAddInvoiceItem.description,
        this.state.accountInfo.subscriptionAddInvoiceItem.quantity,
        (this.state.accountInfo.subscriptionAddInvoiceItem.dollar_amount * 100).toFixed(0),
      ).then((newInvoiceItem) => {
        const subscriptionUpcomingInvoiceItems = [].concat(...this.state.accountInfo.subscriptionUpcomingInvoiceItems);
        subscriptionUpcomingInvoiceItems.push(newInvoiceItem);
        this.setState({
          ...this.state,
          accountInfo: {
            ...this.state.accountInfo,
            subscriptionAddInvoiceItem: {
              description: '',
              currency: this.state.accountInfo.currency || 'cad',
              dollar_amount: 0,
              quantity: 0,
            },
            subscriptionUpcomingInvoiceItems: subscriptionUpcomingInvoiceItems,
          },
          savingInvoiceItem: false,
        });
      }).catch(error => {
        console.log(error);
        this.setState({
          ...this.state,
          accountInfo: {
            ...this.state.accountInfo,
            subscriptionAddInvoiceItem: {
              ...this.state.accountInfo.subscriptionAddInvoiceItem,
              error: error.message,
            },
          },
          savingInvoiceItem: false,
        });
      });
    });
  }

  handleDeleteInvoiceItem(invoiceItem) {
    this.setState({ ...this.state, savingInvoiceItem: true }, () => {
      Setters.deleteSubscriptionItem(invoiceItem).then(() => {
        const subscriptionUpcomingInvoiceItems = [].concat(...this.state.accountInfo.subscriptionUpcomingInvoiceItems);
        for (let i = 0; i < subscriptionUpcomingInvoiceItems.length; i++) {
          if (subscriptionUpcomingInvoiceItems[i].id === invoiceItem.id) {
            subscriptionUpcomingInvoiceItems.splice(i, 1);
          }
        }
        this.setState({
          ...this.state,
          accountInfo: {
            ...this.state.accountInfo,
            subscriptionUpcomingInvoiceItems,
          },
          savingInvoiceItem: false,
        });
      }, (error) => {
        this.setState({ ...this.state, savingInvoiceItem: false, error: error.message });
      });
    });
  }

  payInvoice(invoiceId) {
    this.setState({ ...this.state, payingVoidingInvoice: true, payInvoice: { ...this.state.payInvoice, error: '' } }, () => {
      Setters.payInvoice(this.state.accountInfo.subscriptionObj.id, invoiceId).then(() => {
        this.refreshSubscription(this.props);
        this.setState({ ...this.state, payingVoidingInvoice: false });
      }, (error) => {
        this.setState({ ...this.state, payingVoidingInvoice: false, payInvoice: { ...this.state.payInvoice, error: error.message } });
      });
    });
  }

  showConfirmVoidInvoiceModal(invoiceDataObj) {
    this.setState({ ...this.state, voidInvoice: { showConfirmModal: true, invoiceDataObj } });
  }

  voidInvoice(reason) {
    this.setState({ ...this.state, payingVoidingInvoice: true }, () => {

      Setters.sendVoidSlackMessage(
        this.state.voidInvoice.invoiceDataObj.companyName,
        this.state.voidInvoice.invoiceDataObj.invoiceDate,
        this.state.voidInvoice.invoiceDataObj.invoiceId,
        this.state.voidInvoice.invoiceDataObj.amountDue,
        reason,
      ).then(() => {
        this.setState({ ...this.state, voidInvoice: { ...this.state.voidInvoice, showConfirmModal: false, invoiceDataObj: {} } });
        // Setters.voidInvoice(this.state.accountInfo && this.state.accountInfo.subscriptionObj.id, this.state.voidInvoice.invoiceId).then(() => {
        // this.refreshInvoices();
      });

      // Setters.voidInvoice(this.state.accountInfo.subscriptionObj.id, this.state.voidInvoice.invoiceId).then(() => {
      //   this.refreshSubscription(this.props);
      //   this.setState({ ...this.state, payingVoidingInvoice: false, voidInvoice: { showConfirmModal: false, invoiceId: null } });
      // }, (error) => {
      //   console.log(error);
      // });
    });
  }

  // changeSubscription() {
  //   if (this.state.accountInfo.monthlyFee && isNaN(this.state.accountInfo.monthlyFee)) {
  //     this.setState({ ...this.state, error: 'Need to enter a valid monthly fee' });
  //     return;
  //   }
  //   if (this.state.accountInfo.subscriptionObj && !this.state.accountInfo.subscriptionObj.get('customerId')) {
  //     this.setState({ ...this.state, showStripeCheckout: true, disableButton: true });
  //   } else {
  //     Auth.callSecuredParseFunction('checkSubscription', { customerId: this.state.accountInfo.subscriptionObj.get('customerId') }).then((checkObj) => {
  //       if (checkObj.active) {
  //         this.updateSubscription();
  //       } else {
  //         this.setState({ ...this.state, showStripeCheckout: true, disableButton: true });
  //       }
  //     });
  //   }
  // }

  handleChangeSubscription(stripePlanId, property, value) {
    const newState = { ...this.state };
    if (property === 'trialEnd') {
      newState.accountInfo.trialEnd = value;
    } else {
      for (let i = 0; i < this.state.accountInfo.subscriptionPlans.length; i++) {
        if (stripePlanId === this.state.accountInfo.subscriptionPlans[i].stripePlanId) {
          newState.accountInfo.subscriptionPlans[i][property] = value;
          if (property === 'active') {
            if (value === true && newState.accountInfo.subscriptionPlans[i].quantity === 0) {
              newState.accountInfo.subscriptionPlans[i].quantity = 1;
            } else if (value === false) {
              newState.accountInfo.subscriptionPlans[i].quantity = 0;
            }
          }
          break;
        }
      }
    }
    this.setState(newState);
  }

  saveSubscriptionModules() {
    Setters.saveSubscriptionModules(this.state.accountInfo.subscriptionObj);
  }

  handleChangeReseller(promoCode) {
    const subscriptionObj = this.state.accountInfo.subscriptionObj;
    let resellerCompanyObj;
    if (subscriptionObj.get('company')) {
      if (promoCode) {
        subscriptionObj.get('company').set('promoCode', promoCode);
        for (let i = 0; i < this.state.resellerCompanyArr.length; i++) {
          if (this.state.resellerCompanyArr[i].get('promoCode') === promoCode) {
            resellerCompanyObj = this.state.resellerCompanyArr[i];
            break;
          }
        }
        Setters.setResellerForCompany(subscriptionObj.get('company').id, resellerCompanyObj && resellerCompanyObj.id);
      } else {
        subscriptionObj.get('company').unset('promoCode');
        Setters.setResellerForCompany(subscriptionObj.get('company').id, null);
      }
    }
    this.setState({ ...this.state, accountInfo: { ...this.state.accountInfo, companyReseller: resellerCompanyObj } });
  }

  deleteCustomerSources() {
    this.setState({ ...this.state, showConfirmDeleteCCModal: false, disableButton: true }, () => {
      Setters.deleteCustomerSources(this.state.accountInfo.subscriptionObj.id).then(() => {
        this.refreshSubscription(this.props);
        this.setState({ ...this.state, disableButton: false });
      });
    });
  }

  payUpcomingInvoice() {
    this.setState({ ...this.state, showConfirmPayUpcomingInvoiceModal: false, disableButton: true }, () => {
      Setters.payUpcomingInvoice(this.state.accountInfo.subscriptionObj.get('customerId')).then(() => {
        this.refreshSubscription(this.props);
        this.setState({ ...this.state, disableButton: false });
      });
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={() => this.props.hideModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{
            this.state.accountInfo.subscriptionObj &&
            this.state.accountInfo.subscriptionObj.get('company') &&
            this.state.accountInfo.subscriptionObj.get('company').get('name')
          }</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <EditSubscriptionModalView
              error={this.state.error}
              handleSaveSubscription={() => this.setState({ ...this.state, showConfirmSaveSubscriptionModal: true })}
              accountInfo={this.state.accountInfo}
              handleChangeSubscription={this.handleChangeSubscription}
              disableButton={this.state.disableButton}
              openCCModal={this.openCCModal}
              showCCModal={this.state.showCCModal}
              activeTab={this.state.activeTab}
              handleChangeSubscriptionBillingInfo={this.handleChangeSubscriptionBillingInfo}
              handleSaveSubscriptionBillingInfo={this.handleSaveSubscriptionBillingInfo}
              handleSaveSubscriptionObjProperty={this.handleSaveSubscriptionObjProperty}
              handleChangeAddInvoiceItem={this.handleChangeAddInvoiceItem}
              handleAddInvoiceItem={this.handleAddInvoiceItem}
              handleDeleteInvoiceItem={this.handleDeleteInvoiceItem}
              moduleArr={Helpers.getModules()}
              savingInvoiceItem={this.state.savingInvoiceItem}
              payInvoice={this.payInvoice}
              showConfirmVoidInvoiceModal={this.showConfirmVoidInvoiceModal}
              payingVoidingInvoice={this.state.payingVoidingInvoice}
              payInvoiceError={this.state.payInvoice.error}
              sendEmailNotification={this.state.sendEmailNotification}
              toggleSendEmailNotification={() => this.setState({ ...this.state, sendEmailNotification: !this.state.sendEmailNotification })}
              handleChangeSubscriptionObj={(property, value) => {
                if (this.state.accountInfo.subscriptionObj) {
                  this.state.accountInfo.subscriptionObj.set(property, value);
                  this.saveSubscriptionModules();
                  this.setState(this.state);
                }
              }}
              updateCustomerBillingEmail={() => {
                this.setState({ ...this.state, disableButton: true }, () => {
                  Setters.updateCustomerBillingEmail(
                    this.state.accountInfo.subscriptionObj.get('company'),
                    this.state.accountInfo.subscriptionBillingEmail
                  ).then(() => {
                    this.setState({ ...this.state, disableButton: false });
                  });
                });
              }}
              handleSaveSubscriptionObj={(property, value) => {
                this.setState({ ...this.state, disableButton: true }, () => {
                  Setters.saveSubscriptionObjProperty(this.state.accountInfo.subscriptionObj, property, value).then(() => {
                    this.state.accountInfo.subscriptionObj.set(property, value);
                    this.setState({ ...this.state, disableButton: false });
                  });
                });
              }}
              handleChangeIsAOBRD={(e, isAOBRD) => {
                this.state.accountInfo.subscriptionObj.get('company').get('aobrdSetting').set('aobrdEnabled', isAOBRD); // for ui updating purposes
                this.setState({ ...this.state, accountInfo: { ...this.state.accountInfo, isAOBRD } }, () => {
                  Setters.setAOBRDForCompany(this.state.accountInfo.subscriptionObj.get('company').id, isAOBRD).catch(error => {
                    console.log(error);
                  });
                });
              }}
              handleChangeCtpat={(e, ctpatEnabled) => {
                this.state.accountInfo.subscriptionObj.get('company').set('ctpat', ctpatEnabled); // for ui updating purposes
                this.setState({ ...this.state, accountInfo: { ...this.state.accountInfo, ctpatEnabled } }, () => {
                  Setters.setCtpatForCompany(this.state.accountInfo.subscriptionObj.get('company').id, ctpatEnabled).catch(error => {
                    console.log(error);
                  });
                });
              }}
              handleChangeShowEldCsvDownload={(e, showEldCsvDownload) => {
                this.state.accountInfo.subscriptionObj.get('company').set('showEldCsvDownload', showEldCsvDownload); // for ui updating purposes
                this.setState({ ...this.state, accountInfo: { ...this.state.accountInfo, showEldCsvDownload } }, () => {
                  Setters.setBoolForCompany(this.state.accountInfo.subscriptionObj.get('company').id, 'showEldCsvDownload', showEldCsvDownload).catch(error => {
                    console.log(error);
                  });
                });
              }}
              handleChangeUseOdometerOffset={(e, useOdometerOffset) => {
                this.state.accountInfo.subscriptionObj.get('company').set('useOdometerOffset', useOdometerOffset); // for ui updating purposes
                this.setState({ ...this.state, accountInfo: { ...this.state.accountInfo, useOdometerOffset } }, () => {
                  Setters.setBoolForCompany(this.state.accountInfo.subscriptionObj.get('company').id, 'useOdometerOffset', useOdometerOffset).catch(error => {
                    console.log(error);
                  });
                });
              }}
              handleChangeAllowAutomatedSIMReset={(e, allowAutomatedSIMReset) => {
                this.state.accountInfo.subscriptionObj.get('company').set('allowAutomatedSIMReset', allowAutomatedSIMReset); // for ui updating purposes
                this.setState({ ...this.state, accountInfo: { ...this.state.accountInfo, allowAutomatedSIMReset } }, () => {
                  Setters.setBoolForCompany(this.state.accountInfo.subscriptionObj.get('company').id, 'allowAutomatedSIMReset', allowAutomatedSIMReset).catch(error => {
                    console.log(error);
                  });
                });
              }}
              handleChangeStartSubscription={(e, startSubscription) => {
                this.state.accountInfo.subscriptionObj.set('startSubscription', startSubscription); // for ui updating purposes
                Setters.saveStartSubscription(this.state.accountInfo.subscriptionObj).catch(error => {
                  console.log(error);
                });
                this.setState(this.state); // for ui updating purposes
              }}
              handleChangeEnableCompanyLinks={(e, enableCompanyLinks) => {
                this.state.accountInfo.subscriptionObj.get('company').set('enableCompanyLinks', enableCompanyLinks); // for ui updating purposes
                Setters.saveEnableCompanyLinks(this.state.accountInfo.subscriptionObj.get('company').id, enableCompanyLinks).catch(error => {
                  console.log(error);
                });
                this.setState(this.state); // for ui updating purposes
              }}
              handleChangeGpsFrequencyMillis={(gpsFrequencyMillis) => {
                this.state.accountInfo.subscriptionObj.get('company').set('gpsFrequencyMillis', gpsFrequencyMillis); // for ui updating purposes
                if (!gpsFrequencyMillis) {
                  Setters.saveGpsFrequencyMillis(this.state.accountInfo.subscriptionObj.get('company').id, gpsFrequencyMillis).catch(error => {
                    console.log(error);
                  });
                }
                this.setState(this.state); // for ui updating purposes
              }}
              handleChangeIsClosed={(e, isClosed) => {
                this.state.accountInfo.subscriptionObj.set('isClosed', isClosed); // for ui updating purposes
                Setters.saveIsClosed(this.state.accountInfo.subscriptionObj.get('company').id, isClosed).catch(error => {
                  console.log(error);
                });
                this.setState(this.state); // for ui updating purposes
              }}
              handleChangeIsClosedReason={(isClosedReason) => {
                this.state.accountInfo.subscriptionObj.set('isClosedReason', isClosedReason); // for ui updating purposes
                Setters.saveIsClosedReason(this.state.accountInfo.subscriptionObj.get('company').id, isClosedReason).catch(error => {
                  console.log(error);
                });
                this.setState(this.state); // for ui updating purposes
              }}
              handleChangeTab={(tab) => {
                this.setState({ ...this.state, activeTab: tab });
              }}
              resellerCompanyArr={this.state.resellerCompanyArr}
              refreshSubscriptionPlans={this.refreshSubscriptionPlans}
              handleChangeReseller={this.handleChangeReseller}
              handleShowConfirmDeleteCCModal={() => this.setState({ ...this.state, showConfirmDeleteCCModal: true })}
              handleShowConfirmPayUpcomingInvoiceModal={() => this.setState({ ...this.state, showConfirmPayUpcomingInvoiceModal: true })}
              getMoreInvoices={this.getMoreInvoices}
              refreshSubscription={this.refreshSubscription}
            />
            <StripeCheckoutModal
              currency={this.state.accountInfo.currency}
              closeCCModal={this.closeCCModal}
              handleStripeToken={this.handleSaveCC}
              showCheckout={this.state.showCCModal}
              Subscription={this.props.Subscription}
            />
            <ConfirmModal
              show={this.state.showConfirmDeleteCCModal}
              handleModalChoice={(confirmBool) => confirmBool ? this.deleteCustomerSources() : this.setState({ ...this.state, showConfirmDeleteCCModal: false })}
              handleClose={() => this.setState({ ...this.state, showConfirmDeleteCCModal: false })}
            />
            <ConfirmModal
              show={this.state.showConfirmPayUpcomingInvoiceModal}
              handleModalChoice={(confirmBool) => confirmBool ? this.payUpcomingInvoice() : this.setState({ ...this.state, showConfirmPayUpcomingInvoiceModal: false })}
              handleClose={() => this.setState({ ...this.state, showConfirmPayUpcomingInvoiceModal: false })}
            />
            <ConfirmModal
              show={this.state.voidInvoice.showConfirmModal}
              handleModalChoice={(confirmBool, reason) => confirmBool ? this.voidInvoice(reason) : this.setState({ ...this.state, voidInvoice: { showConfirmModal: false } })}
              handleClose={() => this.setState({ ...this.state, voidInvoice: { showConfirmModal: false } })}
              showReason
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

EditSubscriptionModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired,
  Subscription: PropTypes.object,
  show: PropTypes.bool,
  resellerCompanyArr: PropTypes.array,
};

export default EditSubscriptionModal;
