import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// API
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import DatePicker from 'components/DatePicker/container/DatePicker';

// CSS
import styles from './VehicleOdometersRow.module.scss';

class VehicleOdometersRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      odometerReadings: [],
      loadingOdos: false,
    };

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    Getters.getVehicleLastActiveDate(this.props.vehicle).then((date) => {
      this.handleDateChange(date);
    });
  }

  handleDateChange(date) {
    this.setState({ ...this.state, date: moment(date).toDate(), loadingOdos: true }, () => {
      if (date) {
        Getters.getOdometersForVehicle(this.props.vehicle, moment(date).toDate()).then((odometerReadings) => {
          let error = false;
          if (odometerReadings.length > 3 && odometerReadings[0] === odometerReadings[Math.round(odometerReadings.length / 2, 0)] && odometerReadings[Math.round(odometerReadings.length / 2, 0)] === odometerReadings[odometerReadings.length - 1]) {
            error = true;
          }
          this.setState({ ...this.state, odometerReadings, error, loadingOdos: false });
        }, (error) => {
          this.setState({ ...this.state, loadingOdos: false });
        });
      } else {
        this.setState({ ...this.state, loadingOdos: false });
      }
    });
  }

  render() {
    return (
      <tr>
        <td><b>Unit Id - </b>{this.props.vehicle.get('unitId')}, <b>VIN - </b>{this.props.vehicle.get('vin')}</td>
        <td>{this.props.vehicle.get('belongsToCompany').get('name')}</td>
        <td>{this.props.vehicle.get('eldHardware') &&
          <span><b>Serial - </b>{this.props.vehicle.get('eldHardware').get('serial')}, <b>Version - </b>{this.props.vehicle.get('eldHardware').get('softwareVersion')}</span>
        }</td>
        <td>
          <div className={styles.alignMiddle}>
            <DatePicker className="input" selected={moment(this.state.date).toDate()} placeholderText="Choose Date" onChange={(date) => this.handleDateChange(date)} />
          </div>
          &nbsp;
          <div className={styles.alignMiddle}>
            {this.state.loadingOdos &&
              <tr className={styles.loadingIconNoHover}>
                <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
              </tr>
            }
            {!this.state.loadingOdos && this.state.odometerReadings.length < 3 &&
              <div className={styles.error}>
                <div>No Odometers</div>
              </div>
            }
            {!this.state.loadingOdos && this.state.odometerReadings.length > 3 &&
              <div className={this.state.error ? styles.error : ''}>
                <div>{this.state.odometerReadings[0]}</div>
                <div>{this.state.odometerReadings[Math.round(this.state.odometerReadings.length / 2, 0)]}</div>
                <div>{this.state.odometerReadings[this.state.odometerReadings.length - 1]}</div>
              </div>
            }
          </div>
        </td>
      </tr>
    );
  }
}

VehicleOdometersRow.propTypes = {
  vehicle: PropTypes.object.isRequired,
};

export default VehicleOdometersRow;

