import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Table, TableHeader, TableRow, TableBody, TableRowColumn, TextField, Toggle } from 'material-ui';

// API
import * as Setters from 'api/Setters';

// Components
import ContractFileUploader from '../container/ContractFileUploader';

class ContractsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  render() {
    return (
      <div>
        <h4>
          <div className="inline">
            <div className="inlineBlock">
              Contracts &nbsp;
            </div>
            <div className="inlineBlock">
              <ContractFileUploader
                subscriptionObj={this.props.subscriptionObj}
                refreshSubscription={this.props.refreshSubscription}
              />
            </div>
          </div>
        </h4>
        <div>
          <Table
            maxHeight="200px"
          >
            <TableHeader>
              <TableRow>
                <TableRowColumn style={{ width: '66%' }}>File</TableRowColumn>
                <TableRowColumn style={{ width: '34%' }}>Uploaded At</TableRowColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {this.props.subscriptionContractArr && this.props.subscriptionContractArr.filter((subscriptionContractObj) => {
                return subscriptionContractObj.get('contractFile');
              }).map((subscriptionContractObj) => (
                <TableRow key={subscriptionContractObj.id}>
                  <TableRowColumn style={{ width: '66%' }}>
                    <a href={subscriptionContractObj.get('contractFile').url()} target="_blank" rel="noreferrer">
                      {subscriptionContractObj.get('contractFile').name()}
                    </a>
                  </TableRowColumn>
                  <TableRowColumn style={{ width: '34%' }}>
                    {moment(subscriptionContractObj.get('createdAt')).format('ll')}
                  </TableRowColumn>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}


ContractsList.propTypes = {
  subscriptionContractArr: PropTypes.func.isRequired,
  subscriptionObj: PropTypes.func.isRequired,
};

export default ContractsList;
