import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
} from 'material-ui';

// API
import * as Setters from 'api/Setters';
import { compressWhitespace } from 'api/Helpers';

// Styles
import styles from './styles.module.scss';

function getInitialState() {
  return {
    user: {},
    addingInProgress: false,
    disableAdd: true,
    errorString: undefined,
  };
}

class RegisterCloudUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.handleAddNewCompanyUser = this.handleAddNewCompanyUser.bind(this);
    this.handleChangeInfo = this.handleChangeInfo.bind(this);
  }

  handleChangeInfo(property, value) {
    const newState = { ...this.state };
    newState.user[property] = value;
    if (newState.user.firstName && newState.user.lastName && newState.user.emailAddress && newState.user.emailAddressConfirm && newState.user.password && newState.user.passwordConfirm) {
      if (newState.user.password !== newState.user.passwordConfirm) {
        newState.errorString = 'Passwords do not match';
        newState.disableAdd = true;
      } else if (newState.user.emailAddress !== newState.user.emailAddressConfirm) {
        newState.errorString = 'Email Addresses do not match';
        newState.disableAdd = true;
      } else if (newState.user.password.length < 8) {
        newState.errorString = 'Password must be at least 8 characters long';
        newState.disableAdd = true;
      } else {
        newState.errorString = undefined;
        newState.disableAdd = false;
      }
    } else {
      newState.disableAdd = true;
    }

    this.setState(newState);
  }

  handleAddNewCompanyUser() {
    this.setState({ ...this.state, disableAdd: true });

    Setters.registerNewUserToCompany(
      this.state.user.firstName,
      this.state.user.lastName,
      compressWhitespace(this.state.user.emailAddress).toLowerCase(),
      this.state.user.password,
      this.props.company.id,
    ).then(() => {
      this.setState(getInitialState());
      this.setState({ ...this.state, disableAdd: false });
    }).catch((err) => {
      if (err.message === 202 && err.code === 141) {
        // email exists
        this.setState({ ...this.state, disableAdd: false, errorString: "This email has already been used" });
      } else {
        this.setState({ ...this.state, disableAdd: false, errorString: err.message || err });
      }
    });
  }

  render() {
    const { state, props } = this;

    return (
      <div>
        <b>Register Cloud Account</b>
        <br />
        <br />
        <TextField
          floatingLabelText="First Name"
          onChange={(event, newValue) => this.handleChangeInfo('firstName', newValue)}
          value={state.user.firstName}
        />
        <TextField
          floatingLabelText="Last Name"
          onChange={(event, newValue) => this.handleChangeInfo('lastName', newValue)}
          value={state.user.lastName}
        />
        <br />
        <TextField
          floatingLabelText="Email Address"
          onChange={(event, newValue) => this.handleChangeInfo('emailAddress', newValue)}
          value={state.user.emailAddress}
        />
        <TextField
          floatingLabelText="Re-Enter Email"
          onChange={(event, newValue) => this.handleChangeInfo('emailAddressConfirm', newValue)}
          value={state.user.emailAddressConfirm}
        />
        <br />
        <TextField
          floatingLabelText="Password"
          onChange={(event, newValue) => this.handleChangeInfo('password', newValue)}
          type="password"
          value={state.user.password}
        />
        <TextField
          floatingLabelText="Confirm Password"
          onChange={(event, newValue) => this.handleChangeInfo('passwordConfirm', newValue)}
          type="password"
          value={state.user.passwordConfirm}
        />
        <br />
        <br />
        {state.errorString &&
          <div className={styles.error}>
            {state.errorString}
          </div>
        }
        <br />
        <button
          className={`${styles.startButton} buttonDefault`}
          onClick={this.handleAddNewCompanyUser}
          disabled={state.disableAdd}
        >
          Register
        </button>

      </div>
    );
  }
}

RegisterCloudUser.propTypes = {
  company: PropTypes.object.isRequired,
};

export default RegisterCloudUser;
