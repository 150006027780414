import PropTypes from 'prop-types';
import React from 'react';
import { Marker } from 'react-mapbox-gl';

// CSS
import styles from './ComponentStyles.module.scss';

function GPSDot(props) {
  const markerStyle = `${styles.vehicle} ${styles.marker}`;
  return (
    <Marker
      coordinates={[props.vehicleLocation.get('location').longitude, props.vehicleLocation.get('location').latitude]}
    >
      <div className={markerStyle}>
        <i className={`material-icons md-36 ${styles.marker}`}>lens</i>
      </div>
    </Marker>
  );
}

GPSDot.propTypes = {
  vehicleLocation: PropTypes.object.isRequired,
  active: PropTypes.bool,
  handleOnClick: PropTypes.func,
};

export default GPSDot;
