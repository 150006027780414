import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// API
import * as Helpers from 'api/Helpers';
import * as Setters from 'api/Setters';

// Actions

// Components
import ErrorModal from 'components/ErrorModal/ErrorModal';
import DeleteXButton from 'components/DeleteXButton/container/DeleteXButton';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import LoadingModal from 'components/LoadingModal/container/LoadingModal';

// CSS
import styles from './EquipmentRow.module.scss';

class VehicleRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingWSB: false,
      error: ''
    };

    this.toggleBypass = this.toggleBypass.bind(this);
  }

  componentDidMount() {

  }

  toggleBypass(enabledBool, albertaBool, ontarioBool, usaBool) {
    const vehicleObj = this.props.vehicleObj;
    const companyObj = vehicleObj && vehicleObj.get('belongsToCompany');
    const subscriptionObj = companyObj && companyObj.get('subscription');
    const addressObj = companyObj && companyObj.get('address');
    this.setState({ ...this.state, loadingWSB: true }, () => {
      Setters.handleWSBVehicleSubscription(
        companyObj && companyObj.id,
        subscriptionObj && subscriptionObj.id,
        addressObj && addressObj.id,
        vehicleObj && vehicleObj.id,
        enabledBool,
        {
          alberta: (albertaBool && enabledBool) ? 'Subscriber' : 'Inactive',
          ontario: (ontarioBool && enabledBool) ? 'Subscriber' : 'Inactive',
          usa: (usaBool && enabledBool) ? 'Subscriber' : 'Inactive',
        }
      ).then(() => {
        this.setState({ ...this.state, loadingWSB: false });
      }, (error) => {
        console.log(error);
        this.setState({ ...this.state, loadingWSB: false, error: error.message });
      });
    });
  }

  render() {
    const vehicleObj = this.props.vehicleObj;
    const weighStationBypassVehicle = vehicleObj && vehicleObj.get('weighStationBypassVehicle');

    return (
      <tr className={styles.userRow}>
        <td>
          {vehicleObj.get('unitId')}
          <br />
          {vehicleObj.get('belongsToCompany') && vehicleObj.get('belongsToCompany').get('name')}
        </td>
        <td>
          Bypass Status:&nbsp;<b>{weighStationBypassVehicle && weighStationBypassVehicle.get('enabled') ? 'Enabled' : 'Disabled'}</b>
          {weighStationBypassVehicle && weighStationBypassVehicle.get('enabled') &&
            (
              <div>
                Bypass Type:&nbsp;
                {weighStationBypassVehicle.get('preclearOntario') && <span style={{ paddingRight: '0.3em' }}><b>Ontario</b></span>}
                {weighStationBypassVehicle.get('preclearAlberta') && <span style={{ paddingRight: '0.3em' }}><b>Alberta</b></span>}
                {weighStationBypassVehicle.get('preclearUSA') && <span style={{ paddingRight: '0.3em' }}><b>USA</b></span>}
                {
                  !weighStationBypassVehicle.get('preclearOntario')
                  && !weighStationBypassVehicle.get('preclearAlberta')
                  && !weighStationBypassVehicle.get('preclearUSA') &&
                  <span>Unknown</span>
                }
              </div>
            )
          }
          <div>

            {weighStationBypassVehicle && weighStationBypassVehicle.get('enabled') && weighStationBypassVehicle.get('isSubscriptionProduction') &&
              <button className="buttonDefault yellowButton" disabled={this.state.loadingWSB} onClick={() => this.toggleBypass(false)}>Disable Bypass</button>
            }
            {(!weighStationBypassVehicle || weighStationBypassVehicle.get('disabled') || !weighStationBypassVehicle.get('isSubscriptionProduction')) &&
              (
                <button
                  className="buttonDefault"
                  disabled={this.state.loadingWSB}
                  onClick={() => this.toggleBypass(true, false, false, true)}
                >
                  Enable USA Only Bypass
                </button>
              )
            }
            &nbsp;
            {(!weighStationBypassVehicle || weighStationBypassVehicle.get('disabled') || !weighStationBypassVehicle.get('isSubscriptionProduction')) &&
              (
                <button
                  className="buttonDefault"
                  disabled={this.state.loadingWSB}
                  onClick={() => this.toggleBypass(true, true, false, false)}
                >
                  Enable Alberta Bypass
                </button>
              )
            }
            &nbsp;
            {(!weighStationBypassVehicle || weighStationBypassVehicle.get('disabled') || !weighStationBypassVehicle.get('isSubscriptionProduction')) &&
              (
                <button
                  className="buttonDefault"
                  disabled={this.state.loadingWSB}
                  onClick={() => this.toggleBypass(true, false, true, true)}
                >
                  Enable USA + ON Bypass
                </button>
              )
            }
          </div>
        </td>
        <td>
          {vehicleObj.get('drivers') && vehicleObj.get('drivers').map((driverObj) => (
            <div>
              {driverObj.get('user_fullName')}
            </div>
          ))}
        </td>
        <td>
          <b>Vehicle:</b> {moment(vehicleObj.get('updatedAt')).format('LL')}
          {vehicleObj.get('visionVehicle') && vehicleObj.get('visionVehicle').get('lastCheckInDateTime') &&
            (
              <div>
                <b>Dashcam:</b> {moment(vehicleObj.get('visionVehicle').get('lastCheckInDateTime')).format('LL')}
              </div>
            )
          }
        </td>
        <td>
          <div>
            {vehicleObj.get('isHidden') && <button className="buttonDefault" onClick={() => this.props.hideUnhideEquipment(vehicleObj, 'Vehicle', false)}>Show Vehicle</button>}
            {!vehicleObj.get('isHidden') && <button className="buttonDefault yellowButton" onClick={() => this.props.hideUnhideEquipment(vehicleObj, 'Vehicle', true)}>Hide Vehicle</button>}
          </div>
        </td>
        {this.state.error &&
          <ErrorModal show={!!this.state.error} handleModalChoice={() => this.setState({ ...this.state, error: '' })} handleClose={() => this.setState({ ...this.state, error: '' })}>
            <div>{this.state.error}</div>
          </ErrorModal>
        }
      </tr>
    );
  }
}

VehicleRow.propTypes = {
  vehicleObj: PropTypes.object.isRequired,
  hideUnhideEquipment: PropTypes.func.isRequired,
};

export default VehicleRow;
