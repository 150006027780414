import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import TripInspectionType from 'api/Lists/TripInspectionType';

// Components
import DatePicker from 'components/DatePicker/container/DatePicker';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './EditTripInspectionModal.module.scss';

const initialState = () => ({
  date: moment().toDate(),
  tripInspections: [],
  loading: false,
});

class EditTripInspectionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState();
    this.handleDateChange = this.handleDateChange.bind(this);
    this.editTripInspection = this.editTripInspection.bind(this);
    this.cloneTripInspection = this.cloneTripInspection.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.show && nextProps.show) {
      this.handleDateChange(this.state.date);
    } else if (!nextProps.show && this.props.show) {
      this.setState(initialState());
    }
  }

  handleDateChange(date) {
    this.setState({ ...this.state, date: moment(date), loading: true }, () => {
      if (date) {
        Getters.getTripInspectionsForDriver(this.props.driver, moment(date).toDate()).then((tripInspections) => {
          let error = false;
          this.setState({ ...this.state, tripInspections, error, loading: false });
        }, (error) => {
          console.log(error);
          this.setState({ ...this.state, loading: false });
        });
      } else {
        this.setState({ ...this.state, loading: false });
      }
    });
  }

  editTripInspection(tripInspection, odometerKm, date) {
    this.setState({ ...this.state, loading: true }, () => {
      Setters.editTripInspection(tripInspection.id, parseFloat(odometerKm), date).then(() => {
        this.setState({ ...this.state, loading: false }, () => {
          this.handleDateChange(this.state.date);
        });
      }, (error) => {
        console.log(error);
        this.setState({ ...this.state, loading: false });
      });
    });
  }

  cloneTripInspection(tripInspection) {
    this.setState({ ...this.state, loading: true }, () => {
      Setters.cloneTripInspection(tripInspection.id).then(() => {
        this.setState({ ...this.state, loading: false }, () => {
          this.handleDateChange(this.state.date);
        });
      }, (error) => {
        console.log(error);
        this.setState({ ...this.state, loading: false });
      });
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Trip Inspections
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <div>
              <DatePicker
                className="input"
                selected={moment(this.state.date).toDate()}
                placeholderText="Choose Date"
                onChange={(date) => this.handleDateChange(date)}
              />
            </div>

            <br />

            <div>
              {this.state.loading &&
                <tr className={styles.loadingIconNoHover}>
                  <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
                </tr>
              }
              {!this.state.loading && this.state.tripInspections.length === 0 &&
                <div className={styles.error}>
                  <div>No Trip Inspections</div>
                </div>
              }
              {!this.state.loading && this.state.tripInspections.length !== 0 &&
                <div className={this.state.error ? styles.error : ''}>
                  <div>
                    {this.state.tripInspections.map((tripInspection) => (
                      <div key={tripInspection.id}>
                        <div><b>{`${moment(tripInspection.get('dateTime')).format('LL')} - ${TripInspectionType[tripInspection.get('type')]} Inspection`}</b></div>
                        <br />
                        <div>
                          <DatePicker
                            className="input"
                            selected={moment(tripInspection.get('dateTime')).toDate()}
                            placeholderText="Choose Date"
                            onChange={(date) => {
                              tripInspection.set('dateTime', date.toDate());
                              this.setState({ ...this.state, tripInspections: this.state.tripInspections });
                            }}
                          />
                          &nbsp;
                          <button
                            className={'buttonDefault'}
                            onClick={() => this.editTripInspection(tripInspection, tripInspection.get('odometerKm'), tripInspection.get('dateTime'))}
                          >
                            Save
                          </button>
                          <br /><br />
                          <input
                            value={tripInspection.get('odometerKm')}
                            type="number"
                            onChange={(e) => {
                              tripInspection.set('odometerKm', e.target.value);
                              this.setState({ ...this.state, tripInspections: this.state.tripInspections });
                            }}
                          />
                          &nbsp;
                          <button
                            className={'buttonDefault'}
                            onClick={() => this.editTripInspection(tripInspection, tripInspection.get('odometerKm'))}
                          >
                            Save
                          </button>
                        </div>
                        <br />
                        <button
                          className={'buttonDefault'}
                          onClick={() => this.cloneTripInspection(tripInspection)}
                        >
                          Clone Trip Inspection
                        </button>
                        <hr />
                      </div>
                    ))}
                  </div>
                </div>
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

EditTripInspectionModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  driver: PropTypes.object,
  show: PropTypes.bool,
};

export default EditTripInspectionModal;
