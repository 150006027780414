import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// API
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import DatePicker from 'components/DatePicker/container/DatePicker';

// CSS
import './VehicleOdometersRow';

class DriverOdometersRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment().toDate(),
      driver: props.driver,
      odometerReadings: [],
      loadingOdos: false,
    };

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    this.handleDateChange(moment());
  }

  handleDateChange(dateMoment) {
    this.setState({ ...this.state, date: moment(dateMoment).toDate(), loadingOdos: true }, () => {
      if (dateMoment) {
        Getters.getOdometersForDriver(this.props.driver, moment(dateMoment).toDate()).then((odometerReadings) => {
          let error = false;
          if (odometerReadings.length > 3 && odometerReadings[0] === odometerReadings[Math.round(odometerReadings.length / 2, 0)] && odometerReadings[Math.round(odometerReadings.length / 2, 0)] === odometerReadings[odometerReadings.length - 1]) {
            error = true;
          }
          this.setState({ ...this.state, odometerReadings, error, loadingOdos: false });
        }, (error) => {
          this.setState({ ...this.state, loadingOdos: false });
        });
      } else {
        this.setState({ ...this.state, loadingOdos: false });
      }
      Getters.getDriverByUserId(this.props.driver.get('user').id).then((driverArr) => {
        if (driverArr && driverArr.length > 0) {
          this.setState({ ...this.state, driver: driverArr[0] });
        }
      });
    });
  }

  render() {
    return (
      <tr>
        <td>{Helpers.toTitleCase(this.props.driver.get('user_fullName'))}<br />{this.props.driver.get('user').get('phoneNumber')}</td>
        <td>
          {this.state.driver &&
            <div>
              <div>
                Switchboard Version:&nbsp;
                {this.state.driver && this.state.driver.get('currentELDVersion') && <b>{this.state.driver.get('currentELDVersion')}</b>}
                {this.state.driver && !this.state.driver.get('currentELDVersion') && <b>N/A</b>}
              </div>
              {this.state.driver && this.state.driver.get('vehicle') && this.state.driver.get('vehicle').get('eldHardware') &&
                <div>
                  Engine Module:&nbsp;
                  <b>{this.state.driver.get('vehicle').get('eldHardware').get('softwareVersion')}</b>
                  {` (${this.state.driver.get('vehicle').get('eldHardware').get('serial')})`}
                </div>
              }
              {this.state.driver && this.state.driver.get('vehicle') && this.state.driver.get('vehicle').get('eldHardware') &&
                <div>
                  Target Engine Module Version:&nbsp;
                  <b>{this.state.driver.get('vehicle').get('eldHardware').get('targetSoftwareVersion')}</b>
                </div>
              }
              <div>
                ELD or AOBRD:&nbsp;
                {this.state.driver && this.state.driver.get('vehicle') && this.state.driver.get('vehicle').get('eldHardware') &&
                  <b>
                    {this.state.driver.get('vehicle').get('eldHardware').get('aobrdEnabled') && 'AOBRD Mode'}
                    {!this.state.driver.get('vehicle').get('eldHardware').get('aobrdEnabled') && 'ELD Mode'}
                  </b>
                }
              </div>
              <div>
                IMEI:&nbsp;
                {this.state.driver && this.state.driver.get('deviceIMEI') && <b>{this.state.driver.get('deviceIMEI')}</b>}
                {this.state.driver && !this.state.driver.get('deviceIMEI') && <b>N/A</b>}
              </div>
              <div>
                Vehicle:&nbsp;
                {this.state.driver && !this.state.driver.get('vehicle') && <b>No Vehicle Found</b>}
                {this.state.driver && this.state.driver.get('vehicle') && <b>{this.state.driver.get('vehicle').get('unitId')}, {this.state.driver.get('vehicle').get('vin')}</b>}
              </div>
            </div>
          }
        </td>
        <td>{this.props.driver.get('belongsToCompany').get('name')}</td>
        <td>
          <div className={styles.alignMiddle}>
            <DatePicker className="input" selected={moment(this.state.date).toDate()} placeholderText="Choose Date" onChange={(date) => this.handleDateChange(date)} />
          </div>
          &nbsp;
          <div className={styles.alignMiddle}>
            {this.state.loadingOdos &&
              <tr className={styles.loadingIconNoHover}>
                <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
              </tr>
            }
            {!this.state.loadingOdos && this.state.odometerReadings.length < 3 &&
              <div className={styles.error}>
                <div>No Odometers</div>
              </div>
            }
            {!this.state.loadingOdos && this.state.odometerReadings.length > 3 &&
              <div className={this.state.error ? styles.error : ''}>
                <div>{this.state.odometerReadings[0]}</div>
                <div>{this.state.odometerReadings[Math.round(this.state.odometerReadings.length / 2, 0)]}</div>
                <div>{this.state.odometerReadings[this.state.odometerReadings.length - 1]}</div>
              </div>
            }
          </div>
        </td>
      </tr>
    );
  }
}

DriverOdometersRow.propTypes = {
  driver: PropTypes.object.isRequired,
};

export default DriverOdometersRow;

