import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, Toggle, TextField } from 'material-ui';

import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// API
import * as Getters from 'api/Getters';

class VehiclesListRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      vehicleActivityForDateRange: [],
    };

    this.refresh = this.refresh.bind(this);
  }

  async componentDidMount() {
    this.refresh(this.props);
  }

  async componentWillReceiveProps(nextProps) {
    if (
      !moment(nextProps.startDate).isSame(moment(this.props.startDate))
      || !moment(nextProps.endDate).isSame(moment(this.props.endDate))
    ) {
      this.refresh(nextProps);
    }
  }

  async refresh(props) {
    this.setState({ ...this.state, loading: true }, async () => {
      try {
        const vehicleActivityForDateRange = await Getters.getVehicleActivityForDateRange(
          props.vehicleObj.get('unitId'),
          props.vehicleObj.get('belongsToCompany').id,
          props.startDate,
          props.endDate,
        );
        this.setState({ ...this.state, vehicleActivityForDateRange, loading: false }, () => {
          this.props.setVehicleAndActivityObjData(props.vehicleObj.get('unitId'), vehicleActivityForDateRange.startDate, vehicleActivityForDateRange.endDate);
        });
      } catch (e) {
        this.setState({ ...this.state, vehicleActivityForDateRange: [], loading: false });
      }
    });
  }

  render() {
    const obj = this.props.vehicleObj;
    const weighStationBypassVehicle = obj && obj.get('weighStationBypassVehicle');

    return (
      <TableRow>
        <TableRowColumn style={{ width: '13%' }}>
          {obj.get('unitId')}
        </TableRowColumn>
        <TableRowColumn style={{ width: '22%' }}>
          {/* {weighStationBypassVehicle && weighStationBypassVehicle.get('enabled') && weighStationBypassVehicle.get('isSubscriptionProduction') &&
            <button className="buttonDefault yellowButton" disabled={this.state.loadingWSB} onClick={() => this.toggleBypass(obj, false)}>Disable Bypass</button>
          }
          {(!weighStationBypassVehicle || weighStationBypassVehicle.get('disabled') || !weighStationBypassVehicle.get('isSubscriptionProduction')) &&
            <button className="buttonDefault" disabled={this.state.loadingWSB} onClick={() => this.toggleBypass(obj, true)}>Enable Bypass</button>
          } */}
        </TableRowColumn>

        <TableRowColumn style={{ width: '16%' }}>
          {obj.get('vehicleLocation') && (obj.get('vehicleLocation').get('eldHardwareSerial') || obj.get('vehicleLocation').get('vehicleSerial'))}
        </TableRowColumn>
        <TableRowColumn style={{ width: '49%' }}>
          {!this.state.loading && this.state.vehicleActivityForDateRange.active &&
            (
              <div>
                {moment(this.state.vehicleActivityForDateRange.startDate).format('lll')} (first activity seen for selected month)
                <br />
                {moment(this.state.vehicleActivityForDateRange.endDate).format('lll')} (latest activity seen for selected month)
              </div>
            )
          }
          {!this.state.loading && !this.state.vehicleActivityForDateRange.active &&
            (
              <div>
                No activity for selected month
              </div>
            )
          }
          {this.state.loading &&
            (
              <div>
                <LoadingIcon />
              </div>
            )
          }
          {/* {obj.get('vehicleLocation') && obj.get('vehicleLocation').get('dateTime') && moment(obj.get('vehicleLocation').get('dateTime')).format('lll')} */}
        </TableRowColumn>
      </TableRow>
    );
  }

}

VehiclesListRow.propTypes = {
  vehicleObj: PropTypes.object.isRequired,
};

export default VehiclesListRow;
