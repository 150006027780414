import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// API
import * as Helpers from 'api/Helpers';
import * as Setters from 'api/Setters';
import * as File from 'api/File';
import * as ParseAPI from 'api/Parse';

// Actions
import { destroyCompany } from 'actions/Company';

// Components
import DeleteXButton from 'components/DeleteXButton/container/DeleteXButton';
import LoadingModal from 'components/LoadingModal/container/LoadingModal';

// CSS
import styles from './CompanyTableRow.module.scss';

class CompanyTableRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loadingModalShow: false,
      destroyFinished: false,
    };
    this.destroyCompany = this.destroyCompany.bind(this);
    this.nameSort = this.nameSort.bind(this);
    this.uploadTrailersCsv = this.uploadTrailersCsv.bind(this);
  }

  destroyCompany(confirmBool) {
    if (confirmBool) {
      this.state.loadingModalShow = true;
      this.setState(this.state);
      destroyCompany(this.props.company).then(() => {
        this.state.destroyFinished = true;
        this.setState(this.state);
      });
    }
  }

  nameSort(company, nextCompany) {
    const comparatorA = company.name.toLowerCase();
    const comparatorB = nextCompany.name.toLowerCase();
    if (comparatorA > comparatorB) {
      return 1;
    } else if (comparatorA < comparatorB) {
      return -1;
    }
    return 0;
  }

  uploadTrailersCsv(file) {
    File.readCsvFileAsObject(file).then(trailerDataArr => {
      for (let i = 0; i < trailerDataArr.length; i++) {
        const trailerObj = trailerDataArr[i];
        const trailerInputObj = {
          unitId: trailerObj.unitId.toLowerCase(),
          vin: trailerObj.vin,
          plate: trailerObj.plate,
          belongsToCompany: this.props.company.id,
          stateProvince: trailerObj.stateProvince,
          enabled: true,
        };
        Setters.addTrailer(trailerInputObj);
      }
    });
  }

  render() {
    return (
      <tr className={styles.companyRow}>
        <td>{this.props.company.get('name')}</td>
        <td>{moment(this.props.company.get('createdAt')).format('LL')}</td>
        <td>
          {/* <DeleteXButton handleChoice={this.destroyCompany} /> */}
          <button className="buttonDefault" onClick={() => document.getElementById('trailerCsvInput').click()}>Upload Trailer Csv</button>
          <input
            type="file"
            id="trailerCsvInput"
            accept=".csv"
            onChange={(file) => this.uploadTrailersCsv(file.target.files[0])}
            style={{ display: 'none' }}
          />
          &nbsp;
          <button className="buttonDefault" onClick={() => { this.props.showResellerCompanyModal(this.props.company) }}>Reseller Options</button>

          <LoadingModal show={this.state.loadingModalShow} handleClose={() => { this.state.loadingModalShow = false; this.setState(this.state); }} finished={this.state.destroyFinished} />
        </td>
      </tr>
    );
  }
}

CompanyTableRow.propTypes = {
  company: PropTypes.object.isRequired,
  showResellerCompanyModal: PropTypes.func.isRequired,
};

export default CompanyTableRow;

