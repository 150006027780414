import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

// Components
import EditDriverOdometersModal from 'components/EditDriverOdometersModal/container/EditDriverOdometersModal';
import EditTripInspectionModal from 'components/EditTripInspectionModal/container/EditTripInspectionModal';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import * as Auth from 'api/Auth';

const initialState = {
  driver: undefined,
  showEditDriverOdometersModal: false,
  showEditTripInspectionsModalModal: false,
  savingELDHardwareTargetSoftwareVersion: false,
  savingForcedELD: false,
}

class OfficeUserDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.setUserEnabled = this.setUserEnabled.bind(this);
  }

  setUserEnabled(enabledBool) {
    this.setState({ ...this.state, loadingSetUserEnabled: true });
    Auth.callSecuredParseFunction('_internal:setUserEnabled', {
      returnAsParse: true,
      userId: this.props.user.id,
      enabled: enabledBool,
    }).then((asdf) => {
      this.props.user.set('enabled', enabledBool);
      this.setState({ ...this.state, loadingSetUserEnabled: false });
    }, (error) => {
      this.setState({ ...this.state, error: error.message });
      console.log(error);
    });
  }

  render() {
    return (
      <div>
        <Modal
          show={this.props.show}
          keyboard={false}
          backdrop="static"
          bsSize="lg"
          onHide={this.props.hideModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.user &&
                `${this.props.user.get('firstName')} ${this.props.user.get('lastName')}`
              }
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <i>userId: {this.props.user && this.props.user.id}</i>
              <br />
              <i>belongsToCompanyId: {this.props.user && this.props.user.get('belongsToCompany').id}</i>

              <br />
              <br />
              Enabled: <b>{this.props.user && this.props.user.get('enabled') ? 'True' : 'False'}</b> &nbsp;
              {this.props.user && this.props.user.get('enabled') &&
                <button
                  className="buttonDefault yellowButton"
                  onClick={() => this.setUserEnabled(false)}
                  disabled={this.state.loadingSetUserEnabled}
                >Disable User</button>
              }
              {this.props.user && !this.props.user.get('enabled') &&
                <button
                  className="buttonDefault"
                  onClick={() => this.setUserEnabled(true)}
                  disabled={this.state.loadingSetUserEnabled}
                >Enable User</button>
              }

              <br />
              <br />
              {this.props.user &&
                <button className="buttonDefault" onClick={() => this.props.showChangeEmailModal(this.props.user)}>Change Email</button>
              }

              <br />
              <br />

              {this.props.user &&
                <button className="buttonDefault" onClick={() => this.props.showResetPasswordModal(this.props.user)}>Reset Password</button>
              }

              <br />
              <br />

              {this.props.user &&
                <button className="buttonDefault" onClick={() => this.props.showResellerModal(this.props.user)}>Reseller Details</button>
              }

            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

}

OfficeUserDetailsModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  user: PropTypes.object,
  show: PropTypes.bool,
};

export default OfficeUserDetailsModal;
