import React from 'react';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import { RaisedButton, CircularProgress } from 'material-ui';

import Parse from 'parse';
import { connect } from 'react-redux';
import store from '../../store';

// Components
import VehicleMap from 'components/Map/container/BasicVehicleMap';

// CSS
import styles from './MapLayout.module.scss';

class MapLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleArr: [],
      companyArr: [],
      vehicleLocationArr: [],
      loading: false,
    };
    this.refresh = this.refresh.bind(this);
    this.handleLocationSelect = this.handleLocationSelect.bind(this);
    this.handleGetVehicles = this.handleGetVehicles.bind(this);
    this.getVehicleLocations = this.getVehicleLocations.bind(this);
  }

  refresh(date) {
    if (this.state.date) {
      Auth.callSecuredParseFunction('internal:getVehicles', { returnAsParse: true, parseClassName: '', date: moment(this.state.date).add(Math.random() * 24, 'hours').toDate() }).then((vehicleArr) => {
        console.log(vehicleArr.length);
        this.setState({ ...this.state, vehicleArr: [].concat(...this.state.vehicleArr, vehicleArr) });
        console.log([].concat(...this.state.vehicleArr, vehicleArr).length);
      }, (error) => {
        console.log('done');
        console.log(error);
      });
      // Auth.callSecuredParseFunction('internal:getUsers', { returnAsParse: true, parseClassName: '', date: this.state.date.add(Math.random() * 24, 'hours').toDate() }).then((userArr) => {
      //   console.log(userArr.length);
      // }, (error) => {
      //   console.log('done');
      //   console.log(error);
      // });
    }
  }

  handleLocationSelect(lng, lat) {
    this.setState({ ...this.state, companyArr: [], vehicleLocationArr: [], vehicleArr: [], loading: true }, () => {
      Auth.callSecuredParseFunction('_internal:getCompaniesWithinLatLng', {
        returnAsParse: true,
        lat,
        lng,
      }).then((companyArr) => {
        this.setState({ ...this.state, companyArr, loading: false });
      }, (error) => {
        console.error(error);
        this.setState({ ...this.state, loading: false });
      });
    });
  }

  handleGetVehicles(companyId) {
    this.setState({ ...this.state, vehicleArr: [], vehicleLocationArr: [], loading: true }, () => {
      Auth.callSecuredParseFunction('_internal:getVehiclesForCompany', {
        returnAsParse: true,
        companyId,
      }).then((vehicleArr) => {
        this.setState({ ...this.state, vehicleArr, loading: false });
      }, (error) => {
        console.error(error);
        this.setState({ ...this.state, loading: false });
      });
    });
  }

  getVehicleLocations(vehicle) {
    console.log(vehicle);
    this.setState({ ...this.state, vehicleLocationArr: [], loading: true }, () => {
      Auth.callSecuredParseFunction('_internal:getVehicleLocationsForVehicle', {
        returnAsParse: true,
        vehicleId: vehicle.id,
      }).then((vehicleLocationArr) => {
        console.log(vehicleLocationArr);
        this.setState({ ...this.state, vehicleLocationArr, loading: false });
      }, (error) => {
        console.error(error);
        this.setState({ ...this.state, loading: false });
      });
    });
  }

  render() {
    return (
      <div className={styles.mapAndDetails}>
        <button onClick={this.refresh}>boop</button>

        <Table
          height="20em"
          fixedHeader={false}
          multiSelectable={false}
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          >
            <TableRow>
              <TableHeaderColumn>Name</TableHeaderColumn>
              <TableHeaderColumn />
              <TableHeaderColumn />
              {/* <TableHeaderColumn>Name</TableHeaderColumn>
              <TableHeaderColumn>Status</TableHeaderColumn> */}
            </TableRow>
          </TableHeader>
          <TableBody
            displayRowCheckbox={false}
          >
            {this.state.loading && this.state.companyArr.length === 0 &&
              <div styles={{ width: '100%', margin: '0 auto' }}>
                <CircularProgress size={80} thickness={5} />
              </div>
            }
            {this.state.companyArr.map((company, index) => (
              <TableRow key={`${company.id}${index}`}>
                <TableRowColumn>{company.get('name')}</TableRowColumn>
                <TableRowColumn>
                  <RaisedButton
                    primary
                    disabled={this.state.loading}
                    onClick={() => this.handleGetVehicles(company.id)}
                    label="Get Vehicles"
                  />
                </TableRowColumn>
                <TableRowColumn>
                  {company.get('address') && `${company.get('address').get('stateProvince')}, ${company.get('address').get('country')}`}
                </TableRowColumn>
                {/* <TableRowColumn>Randal White</TableRowColumn>
                <TableRowColumn>Unemployed</TableRowColumn> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <ReactDatePicker
          className="input"
          showMonthDropdown
          showYearDropdown
          dateFormat="LL"
          selected={this.state.date || null}
          placeholderText="....."
          onChange={(date) => this.setState({ ...this.state, date })}
        />

        <div className={styles.mapView}>
          <VehicleMap
            vehicleArr={this.state.vehicleArr}
            vehicleLocationArr={this.state.vehicleLocationArr}
            handleLocationSelect={this.handleLocationSelect}
            getVehicleLocations={this.getVehicleLocations}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { Main } = state;
  return ({
    Main,
  });
};

export default connect(mapStateToProps)(MapLayout);
