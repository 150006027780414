import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import * as Auth from 'api/Auth';
import * as Helpers from 'api/Helpers';
import * as Setters from 'api/Setters';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import { DatePicker, Toggle, SelectField, TextField, MenuItem } from 'material-ui';

// styles
import styles from './ResellerCompanyModal.module.scss';

function getInitialState() {
  return {
    resellerCompanyObj: null,
    loading: false,
    promoCodeInput: '',
    error: '',
  };
}

class ResellerCompanyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.refreshInfo = this.refreshInfo.bind(this);
    this.createResellerCompany = this.createResellerCompany.bind(this);
    this.editResellerCompany = this.editResellerCompany.bind(this);
  }

  componentDidMount() {
    console.log('loaded');
  }

  componentWillReceiveProps(nextProps) {
    console.log('wot u doin');
    if (!nextProps.show && this.props.show) {
      this.setState(getInitialState());
    } else if (nextProps.show && !this.props.show && nextProps.company) {
      this.refreshInfo(nextProps);
    }
  }

  refreshInfo(props) {
    this.setState({ ...this.state, loading: true });
    Auth.callSecuredParseFunction('_internal:getResellerCompany', {
      returnAsParse: true,
      parseClassName: 'ResellerCompany',
      companyId: props.company.id,
    }).then((resellerCompanyObj) => {
      console.log(resellerCompanyObj);
      this.setState({ ...this.state, resellerCompanyObj, loading: false });
    }).catch((error) => {
      this.setState({ ...this.state, loading: false });
    });
  }

  createResellerCompany() {
    if (!this.state.promoCodeInput) {
      this.setState({ ...this.state, error: 'Need Reseller Code/ID'});
    } else {
      this.setState({ ...this.state, loading: true, error: '' });
      Auth.callSecuredParseFunction('_internal:createResellerCompany', {
        returnAsParse: true,
        parseClassName: 'ResellerCompany',
        companyId: this.props.company.id,
        promoCode: this.state.promoCodeInput,
        name: this.props.company.get('name'),
      }).then((resellerCompanyObj) => {
        this.setState({ ...this.state, resellerCompanyObj, loading: false });
        Setters.setResellerForCompany(this.props.company.id, resellerCompanyObj && resellerCompanyObj.id);
      }).catch((error) => {
        this.setState({ ...this.state, loading: false });
      });
    }
  }

  editResellerCompany(property, value) {
    this.setState({ ...this.state, loading: true }, (newState) => {
      Auth.callSecuredParseFunction('_internal:editResellerCompany', {
        returnAsParse: true,
        parseClassName: 'ResellerCompany',
        resellerCompanyId: this.state.resellerCompanyObj.id,
        property,
        value,
      }).then((resellerCompanyObj) => {
        this.setState({ ...this.state, resellerCompanyObj, loading: false });
      }).catch((error) => {
        this.setState({ ...this.state, loading: false });
      });
    });
  }

  render() {
    console.log(this.state);
    return (
      <Modal
        show={this.props.show}
        // keyboard={false}
        // backdrop="static"
        // bsSize="lg"
        onHide={this.props.hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{ this.props.company && (this.props.company.get('name') || 'Reseller Company')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          { this.state.loading && 
              <LoadingIcon />
            }
          { !this.state.loading && this.state.resellerCompanyObj &&
            (
              <div>
                <table>
                  <tr>
                    <td>Name: </td>       <td>{ this.state.resellerCompanyObj.get('name') }</td>
                  </tr>
                  <tr>
                    <td>Promo Code:</td> <td>{ this.state.resellerCompanyObj.get('promoCode') }</td>
                  </tr>
                </table>
                <div style={{ width: '50%' }}>
                  <Toggle
                    label="Hide Company"
                    onToggle={(event, newValue) => {
                      this.setState({ ...this.state, loading: true });
                      this.editResellerCompany('isHidden', newValue);
                    }}
                    toggled={this.state.resellerCompanyObj && this.state.resellerCompanyObj.get('isHidden')}
                  />
                </div>
              </div>
            )
          }
          { !this.state.loading && !this.state.resellerCompanyObj &&
            (
              <div>
                Reseller Code / ID: <input onChange={(e) => this.setState({ ...this.state, promoCodeInput: Helpers.compressWhitespace(e.target.value) })} value={this.state.promoCodeInput} />
                <br />
                <br />
                <button className="buttonDefault" onClick={() => this.createResellerCompany()} >Enable Reseller For Company</button>
              </div>
            )
          }
        </Modal.Body>
      </Modal>
    );
  }

}

ResellerCompanyModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  user: PropTypes.object,
  show: PropTypes.bool,
};

export default ResellerCompanyModal;
