import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// API
import * as Getters from 'api/Getters';

// Components
import SwitchboardUsageDetails from '../../SwitchboardUsageDetails/SwitchboardUsageDetails';

class TrialSubscriptionRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };

  }

  async componentDidMount() {
    Getters.getSubscriptionObjByCustomerId(this.props.trialSubscriptionsCustomerObj.id).then((_subscriptionObj) => {
      if (_subscriptionObj && !Array.isArray(_subscriptionObj)) {
        this.setState({ ...this.state, subscriptionObj: _subscriptionObj, loading: false });
      } else {
        this.setState({ ...this.state, subscriptionObj: undefined, loading: false });
      }
    });
  }

  render() {
    return (
      <tr>
        <td>
          <div>
            <div>
              <b>{this.props.trialSubscriptionsCustomerObj.description}</b>
              <div style={{ overflow: 'auto' }}>
                {
                  (this.props.trialSubscriptionsCustomerObj.email)
                }
              </div>
            </div>
            <div>
            </div>
          </div>
        </td>
        <td>
          <SwitchboardUsageDetails switchboardSubscriptionObj={this.state.subscriptionObj} />
        </td>
        <td>
          <div>
            {this.props.trialSubscriptionsCustomerObj && `Account Created: ${moment.unix(this.props.trialSubscriptionsCustomerObj.created).format('ll')}`} ({this.props.trialSubscriptionsCustomerObj.created && moment().diff(moment.unix(this.props.trialSubscriptionsCustomerObj.created), 'days')} days ago)
            <br />
            {this.props.trialSubscriptionsCustomerObj.id}
            <br />
            {this.props.trialSubscriptionsCustomerObj.subscription && this.props.trialSubscriptionsCustomerObj.subscription.cancel_at_period_end &&
              <span className="errorText">Cancelled Subscription</span>
            }
            {/* &nbsp; {this.props.trialSubscriptionsCustomerObj.subscription} */}
          </div>
        </td>
      </tr>
    );
  }
}

TrialSubscriptionRow.propTypes = {
  trialSubscriptionsCustomerObj: PropTypes.object.isRequired,
};

export default TrialSubscriptionRow;

