import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, TextField, Toggle } from 'material-ui';
import { Modal } from 'react-bootstrap';

// Components
import EditDriverOdometersModal from 'components/EditDriverOdometersModal/container/EditDriverOdometersModal';
import EditTripInspectionModal from 'components/EditTripInspectionModal/container/EditTripInspectionModal';
import EditUnitIdModal from 'components/EditUnitIdModal/container/EditUnitIdModal';
import ChooseDateModal from 'components/ChooseDateModal/container/ChooseDateModal';
import ELDDailyCertificationsModal from 'components/UsersTable/container/ELDDailyCertificationsModal';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';

const initialState = {
  driver: undefined,
  showEditDriverOdometersModal: false,
  showEditTripInspectionsModalModal: false,
  showEditUnitIdModal: false,
  savingELDHardwareTargetSoftwareVersion: false,
  showELDDailyCertificationsModal: false,
  savingForcedELD: false,
  driverModule: undefined,
  pt30FirmwareArr: [],
}

class DriverDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.toggleEditDriverOdometerModal = this.toggleEditDriverOdometerModal.bind(this);
    this.toggleEditTripInspectionsModal = this.toggleEditTripInspectionsModal.bind(this);
    this.toggleEditUnitIdModal = this.toggleEditUnitIdModal.bind(this);
    this.saveELDHardwareTargetSoftwareVersion = this.saveELDHardwareTargetSoftwareVersion.bind(this);
    this.toggleDriverModule = this.toggleDriverModule.bind(this);
    this.saveForcedELD = this.saveForcedELD.bind(this);
  }

  componentDidMount() {
    Getters.getAllPT30Firmware().then((pt30FirmwareArr) => {
      this.setState({ ...this.state, pt30FirmwareArr });
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show && !this.props.show) {
      Getters.getDriverByUserId(nextProps.user.id).then((driverArr) => {
        if (driverArr && driverArr.length > 0) {
          const driver = driverArr[0];
          let eldHardwareTargetSoftwareVersion = 0;
          let forcedELD = 0;
          if (driver && driver.get('vehicle') && driver.get('vehicle').get('eldHardware')) {
            eldHardwareTargetSoftwareVersion = driver.get('vehicle').get('eldHardware').get('targetSoftwareVersion');
            forcedELD = !!driver.get('vehicle').get('eldHardware').get('forcedELD');
          }
          Getters.getDriverModuleByUserId(driver.id).then((driverModule) => {
            this.setState({ ...this.state, driver, eldHardwareTargetSoftwareVersion, forcedELD, driverModule });
          });
        }
      });
    } else if (!nextProps.show && this.props.show) {
      this.setState({ ...initialState, pt30FirmwareArr: this.state.pt30FirmwareArr });
    }
  }

  toggleEditTripInspectionsModal(showBool) {
    this.setState({ ...this.state, showEditTripInspectionsModalModal: showBool });
  }

  toggleEditDriverOdometerModal(showBool) {
    this.setState({ ...this.state, showEditDriverOdometersModal: showBool });
  }

  toggleEditUnitIdModal(showBool) {
    this.setState({ ...this.state, showEditUnitIdModal: showBool });
  }

  saveELDHardwareTargetSoftwareVersion() {
    this.setState({ ...this.state, savingELDHardwareTargetSoftwareVersion: true });
    Setters.saveELDHardwareTargetSoftwareVersion(this.state.driver.get('vehicle').get('eldHardware').id, parseInt(this.state.eldHardwareTargetSoftwareVersion, 10)).then(() => {
      this.state.driver.get('vehicle').get('eldHardware').set('targetSoftwareVersion', parseInt(this.state.eldHardwareTargetSoftwareVersion, 10));
      this.setState({ ...this.state, savingELDHardwareTargetSoftwareVersion: false });
    });
  }

  toggleDriverModule(moduleProperty, value) {
    this.state.driverModule.set(moduleProperty, value);
    this.setState({ ...this.state });
    Setters.updateDriverModule(this.state.driver.id, moduleProperty, value);
  }

  saveForcedELD() {
    this.setState({ ...this.state, savingForcedELD: true });
    Setters.saveForcedELD(this.state.driver.get('vehicle').get('eldHardware').id, this.state.forcedELD == 'true').then(() => {
      this.state.driver.get('vehicle').get('eldHardware').set('forcedEld', this.state.forcedELD);
      this.setState({ ...this.state, savingForcedELD: false });
    });
  }

  render() {
    return (
      <div>
        {this.state.driver &&
          <EditDriverOdometersModal
            show={this.state.showEditDriverOdometersModal}
            hideModal={() => this.toggleEditDriverOdometerModal(false)}
            driver={this.state.driver}
          />
        }
        {this.state.driver &&
          <EditTripInspectionModal
            show={this.state.showEditTripInspectionsModalModal}
            hideModal={() => this.toggleEditTripInspectionsModal(false)}
            driver={this.state.driver}
          />
        }
        {this.state.driver &&
          <EditUnitIdModal
            show={this.state.showEditUnitIdModal}
            hideModal={() => this.toggleEditUnitIdModal(false)}
            driver={this.state.driver}
          />
        }

        <Modal
          show={this.props.show}
          keyboard={false}
          backdrop="static"
          bsSize="lg"
          onHide={this.props.hideModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.user &&
                `${this.props.user.get('firstName')} ${this.props.user.get('lastName')}`
              }
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <i>driverId: {this.state.driver && this.state.driver.id}</i>
              <br />
              <i>belongsToCompanyId: {this.state.driver && this.state.driver.get('belongsToCompany').id}</i>
              <br />
              <br />

              IMEI:
              <br />
              {this.state.driver && this.state.driver.get('deviceIMEI') && <b>{this.state.driver.get('deviceIMEI')}</b>}
              {this.state.driver && !this.state.driver.get('deviceIMEI') && <b>No IMEI Found</b>}
              <br />
              <br />
              Switchboard Version:
              <br />
              {this.state.driver && this.state.driver.get('currentELDVersion') && <b>{this.state.driver.get('currentELDVersion')}</b>}
              {this.state.driver && !this.state.driver.get('currentELDVersion') && <b>No Current ELD Version Found</b>}
              {this.state.driver && this.state.driver.get('deviceIMEI') &&
                (
                  <div>
                    <button
                      className="buttonDefault"
                      disabled={this.state.loading}
                      onClick={async () => {
                        this.setState({ ...this.state, updateError: null, loading: true });
                        try {
                          await Setters.publishUpdateForScalefusionDevice(this.state.driver.get('deviceIMEI'));
                        } catch (e) {
                          this.setState({ ...this.state, updateError: e.data });
                        }
                        this.setState({ ...this.state, loading: false });
                      }}
                    >
                      Prompt Update
                    </button>
                  </div>
                )
              }
              <div className="errorText">{this.state.updateError}</div>
              <br />
              <br />
              {this.state.driver && this.state.driver.get('vehicle') && this.state.driver.get('vehicle').get('eldHardware') &&
                (
                  <div>
                    Engine Module:
                    <br />
                    ELD / AOBRD:
                    <br />
                    {this.state.driver && this.state.driver.get('vehicle') && this.state.driver.get('vehicle').get('eldHardware') &&
                      <b>
                        <select value={this.state.forcedELD} onChange={(e) => this.setState({ ...this.state, forcedELD: e.target.value })}>
                          <option value={true}>ELD</option>
                          <option value={false}>AOBRD</option>
                        </select>
                        <button className="buttonDefault" disabled={this.state.savingForcedELD} onClick={() => this.saveForcedELD()}>Save</button>
                        {/* { this.state.driver.get('vehicle').get('eldHardware').get('aobrdEnabled') && 'AOBRD Mode' } */}
                        {/* { !this.state.driver.get('vehicle').get('eldHardware').get('aobrdEnabled') && 'ELD Mode' } */}
                      </b>
                    }
                    <br />
                    Version:
                    <br />
                    <b>
                      {this.state.driver.get('vehicle').get('eldHardware').get('softwareVersion')}
                    </b>
                    <br />
                    <br />
                    Target Module Version:
                    <br />
                    <b>
                      <select
                        value={this.state.eldHardwareTargetSoftwareVersion}
                        onChange={(e) => {
                          this.setState({ ...this.state, eldHardwareTargetSoftwareVersion: e.target.value })
                        }}
                      >
                        <option value={undefined}></option>
                        {this.state.pt30FirmwareArr.map((pt30FirmwareObj) => (
                          <option value={pt30FirmwareObj.get('versionCode')}>{pt30FirmwareObj.get('versionCode')}</option>
                        ))}
                      </select>
                      {/* <input type="number" className="buttonSpacedRight" onChange={(e) => {
                      this.setState({ ...this.state, eldHardwareTargetSoftwareVersion: e.target.value })
                    }} value={this.state.eldHardwareTargetSoftwareVersion} /> */}
                      <button
                        className="buttonDefault"
                        disabled={
                          this.state.savingELDHardwareTargetSoftwareVersion ||
                          this.state.pt30FirmwareArr.map((pt30FirmwareObj) => pt30FirmwareObj.get('versionCode')).indexOf(this.state.eldHardwareTargetSoftwareVersion && parseInt(this.state.eldHardwareTargetSoftwareVersion)) === -1
                        }
                        onClick={() => this.saveELDHardwareTargetSoftwareVersion()}
                      >Save</button>
                    </b>
                    <br />
                    <br />
                  </div>
                )
              }
              Vehicle:
              <br />
              {this.state.driver && !this.state.driver.get('vehicle') && <b>No Vehicle Found</b>}
              {this.state.driver && this.state.driver.get('vehicle') && <b>{this.state.driver.get('vehicle').get('unitId')}</b>}
              <br />
              <br />
              {this.state.driver &&
                <span>
                  <button className="buttonDefault" onClick={() => this.setState({ ...this.state, showChooseDateModal: true })}>Reset Autogenerated Driving</button>
                  <ChooseDateModal
                    show={this.state.showChooseDateModal}
                    handleChooseDate={(date) => {
                      // Setters.setELDEventsToAOBRD(this.state.driver.id, date);
                      Setters.setAutogeneratedForDriver(this.state.driver.id);
                      this.setState({ ...this.state, showChooseDateModal: false });
                    }}
                    handleCloseModal={() => this.setState({ ...this.state, showChooseDateModal: false })}
                  />
                </span>
              }
              <br />
              <br />
              {this.state.driver &&
                <span>
                  <button className="buttonDefault" onClick={() => this.setState({ ...this.state, showELDDailyCertificationsModal: true })}>View ELD Daily Certifications</button>
                  <ELDDailyCertificationsModal
                    show={this.state.showELDDailyCertificationsModal}
                    driver={this.state.driver}
                    handleCloseModal={() => this.setState({ ...this.state, showELDDailyCertificationsModal: false })}
                  />
                </span>
              }
              <br />
              <br />
              {this.state.driver &&
                <button className="buttonDefault" onClick={() => this.props.showResetPasswordModal(this.props.user)}>Reset Password</button>
              }
              <br />
              <br />
              {this.state.driver &&
                <button className={'buttonDefault'} onClick={() => this.toggleEditDriverOdometerModal(true)}>Edit Odometer Readings</button>
              }
              <br />
              <br />
              {this.state.driver &&
                <button className={'buttonDefault'} onClick={() => this.toggleEditTripInspectionsModal(true)}>Edit Trip Inspections</button>
              }
              <br />
              <br />
              {this.state.driver &&
                <button className={'buttonDefault'} onClick={() => this.toggleEditUnitIdModal(true)}>Change ELDEvent Unit ID</button>
              }
              <br />
              <br />
              {this.state.driverModule &&
                <div style={{ width: '50%' }}>
                  <Toggle
                    label="Smooth Odometers (Experimental)"
                    toggled={this.state.driverModule.get('filterOdometers')}
                    onToggle={(e, value) => this.toggleDriverModule('filterOdometers', value)}
                  />
                </div>
              }
              {this.state.driverModule &&
                <div style={{ width: '50%' }}>
                  <Toggle
                    label="Enable Auto Login (Skip Login Page)"
                    toggled={this.state.driverModule.get('enabledAutoLogin')}
                    onToggle={(e, value) => this.toggleDriverModule('enabledAutoLogin', value)}
                  />
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

}

DriverDetailsModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  user: PropTypes.object,
  show: PropTypes.bool,
};

export default DriverDetailsModal;
