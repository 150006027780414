import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// API
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import DatePicker from 'components/DatePicker/container/DatePicker';

// CSS
import styles from './InvoiceRow.module.scss';
import SwitchboardUsageDetails from '../../SwitchboardUsageDetails/SwitchboardUsageDetails';

class InvoicesRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usageRecord: undefined,
      loading: false,
      voidInvoice: {
        invoiceId: null,
        showConfirmModal: false,
      },
    };

  }

  async componentDidMount() {
    try {
      const subscriptionObj = await Getters.getSubscriptionObjBySubscriptionId(this.props.invoiceObj.subscription.id);
      if (subscriptionObj && !Array.isArray(subscriptionObj)) {
        Getters.getCustomerBillingInfo(subscriptionObj.id).then((billingInfo) => {
          this.setState({ ...this.state, subscriptionObj, billingInfo, loading: false });
        });
      }
    } catch (e) {
      Getters.getSubscriptionObjByCustomerId(this.props.invoiceObj.customer.id).then((_subscriptionObj) => {
        if (_subscriptionObj && !Array.isArray(_subscriptionObj)) {
          // console.log(this.props.invoiceObj, subscriptionObj);
          Getters.getCustomerBillingInfo(_subscriptionObj.id).then((billingInfo) => {
            this.setState({ ...this.state, subscriptionObj: _subscriptionObj, billingInfo, loading: false });
          });
        } else {
          // console.log(this.props.invoiceObj, subscriptionObj);
          this.setState({ ...this.state, subscriptionObj: undefined, loading: false });
        }
      });
    }
  }

  render() {
    return (
      <tr>
        <td>
          <div>
            <div>
              <b>{this.state.subscriptionObj && this.state.subscriptionObj.get('company') && this.state.subscriptionObj.get('company').get('name')}</b>
              <div style={{ overflow: 'auto' }}>
                {
                  (this.props.invoiceObj.customer && this.props.invoiceObj.customer.email)
                  || (this.state.billingInfo && this.state.billingInfo.billingEmail)
                  || this.props.invoiceObj.customer_email
                }
              </div>
            </div>
            <div>
            </div>
          </div>
        </td>
        <td>
          <SwitchboardUsageDetails switchboardSubscriptionObj={this.state.subscriptionObj} />
        </td>
        <td>
          <div>
            Invoice #: {this.props.invoiceObj.number}
            <br />
            {this.props.invoiceObj.customer && `Account Created: ${moment.unix(this.props.invoiceObj.customer.created).format('ll')}`}
            <br />
            <b>Invoiced {this.props.invoiceObj.created && moment().diff(moment.unix(this.props.invoiceObj.created), 'days')} days ago</b>
            <br />
            {this.props.invoiceObj.customer.id}
            <br />
            {this.props.invoiceObj.subscription && this.props.invoiceObj.subscription.cancel_at_period_end &&
              <span className="errorText">Cancelled Subscription</span>
            }
            {/* &nbsp; {this.props.invoiceObj.subscription} */}
          </div>
          {this.props.invoiceObj.currency && this.props.invoiceObj.currency.toUpperCase()} ${this.props.invoiceObj.amount_due && (this.props.invoiceObj.amount_due / 100)}
        </td>
        <td>
          <div>
            <button
              className="buttonDefault"
              onClick={() => window.open(this.props.invoiceObj.hosted_invoice_url, '_blank')}
            >
              Details
            </button> &nbsp;
            {this.state.subscriptionObj && this.state.subscriptionObj.get('company') && this.state.subscriptionObj.get('company').get('name') &&
              (
                <button
                  className="buttonDefault"
                  onClick={() => this.props.showConfirmVoidInvoiceModal(
                    {
                      email: (this.state.billingInfo && this.state.billingInfo.billingEmail) || this.props.invoiceObj.customer_email,
                      companyName: this.state.subscriptionObj && this.state.subscriptionObj.get('company') && this.state.subscriptionObj.get('company').get('name'),
                      invoiceDate: this.props.invoiceObj.created && moment.unix(this.props.invoiceObj.created).toDate(),
                      amountDue: this.props.invoiceObj.amount_due && (this.props.invoiceObj.amount_due / 100).toFixed(2),
                      invoiceId: this.props.invoiceObj.id,
                    },
                  )}
                >
                  Void Request
                </button>
              )
            }
          </div>
        </td>
      </tr>
    );
  }
}

InvoicesRow.propTypes = {
  invoiceObj: PropTypes.object.isRequired,
};

export default InvoicesRow;

