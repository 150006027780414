import PropTypes from 'prop-types';
import React from 'react';
import Parse from 'parse';
import { Modal } from 'react-bootstrap';
import { DatePicker, Toggle, SelectField, TextField, MenuItem } from 'material-ui';

import * as ParseAPI from 'api/Parse';
import * as Auth from 'api/Auth';

import store from '../../../store';


// Components

// styles
import styles from './ResetPasswordModal.module.scss';

function getInitialState() {
  return {
    accountInfo: {
      emailAddress: '',
      emailAddressConfirm: '',
      error: undefined,
    },
  };
}

class ChangeEmailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.handleChangeInfo = this.handleChangeInfo.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.setState(getInitialState());
    }
  }

  handleChangeInfo(property, value) {
    const newState = { ...this.state };
    newState.accountInfo[property] = value;
    this.setState(newState);
  }

  handleReset() {
    this.setState({ ...this.state, error: undefined }, (newState) => {
      if (this.state.accountInfo.emailAddress !== this.state.accountInfo.emailAddressConfirm) {
        this.setState({ ...this.state, error: 'email does not match' });
        return;
      }
      if (this.props.user) {
        Auth.callSecuredParseFunction('_internal:changeEmail', {
          returnAsParse: true,
          userId: this.props.user.id,
          emailAddress: this.state.accountInfo.emailAddress,
          changeUsername: true,
        }).then((asdf) => {
          this.props.user.set('emailAddress',)
          this.props.hideModal();
        }, (error) => {
          this.setState({ ...this.state, error: error.message });
          console.log(error);
        });
      }
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={styles.inputs}>
            <TextField
              floatingLabelText="Email"
              onChange={(event, newValue) => this.handleChangeInfo('emailAddress', newValue)}
              value={this.state.accountInfo.emailAddress}
            />
            <br />
            <TextField
              floatingLabelText="Confirm Email"
              onChange={(event, newValue) => this.handleChangeInfo('emailAddressConfirm', newValue)}
              value={this.state.accountInfo.emailAddressConfirm}
            />
          </div>
          {this.state.error &&
            <div className="errorText">
              {this.state.error}
            </div>
          }
          <div className={styles.selection}>
            <button className={`${styles.cancelButton} buttonDefault`} onClick={this.props.hideModal} >Cancel</button>
            <button className={`${styles.resetButton} buttonDefault`} onClick={this.handleReset}>Change Email</button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

ChangeEmailModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  user: PropTypes.object,
  show: PropTypes.bool,
};

export default ChangeEmailModal;
