import PropTypes from 'prop-types';
import React from 'react';
import history from 'sbHistory';
import { connect } from 'react-redux';

// Actions
import { fetchUsersForState, deleteUsersForState } from 'actions/User';
import * as Getters from 'api/Getters';

// Components
import Title from 'components/LayoutTitle/view/Title';
import UsersTable from 'components/UsersTable/container/UsersTable';

// CSS
import styles from './UsersLayout.module.scss';

class UsersLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        hideDisabled: true,
      },
      resellerCompanyArr: [],
      displayFilterForm: false,
      userArr: [],
      eldSoftwareVersion: '',
      loading: false,
    };
    this.handleSubViewClick = this.handleSubViewClick.bind(this);
    this.handleUserEntry = this.handleUserEntry.bind(this);
  }

  async componentDidMount() {
    const query = this.props.location.search;
    this.setState({ ...this.state, query });
    const queryProperties = Object.keys(query);
    if (queryProperties.length > 0) fetchUsersForState(decodeURIComponent(query[queryProperties[0]]), queryProperties[0]);
    Getters.getResellerCompanies().then((resellerCompanyArr) => {
      this.setState({ ...this.state, resellerCompanyArr });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.User.fetched) {
      this.setState({ ...this.state, userArr: nextProps.User.users, loading: false });
    }
  }


  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.User !== this.props.User || nextState !== this.state) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    deleteUsersForState();
  }

  handleSubViewClick(subview) {
    let tempDriver = getQueryParameter(this.props.location.search, 'driver');
    history.push({ pathname: 'driver', search: "driver=" + tempDriver + "&view=" + subview });
  }

  handleUserEntry(e, property) {
    this.setState({ ...this.state, loading: true, userArr: [] });
    if (property === 'outOfDateEngineModule') {
      Getters.getOutdatedUsers('eldHardwareVersion').then((userArr) => {
        this.setState({ ...this.state, loading: false, userArr });
      });
    } else if (property === 'outOfDateSoftware') {
      Getters.getOutdatedUsers('eldSoftwareVersion').then((userArr) => {
        this.setState({ ...this.state, loading: false, userArr });
      });
    } else if (property === 'delayedUploadUsers') {
      Getters.getDelayedUploadUsers(this.state.eldSoftwareVersion).then((userArr) => {
        this.setState({ ...this.state, loading: false, userArr });
      });
    } else {
      if (e.key === 'Enter') {
        const query = {};
        if (property === 'phoneNumberCleaned') {
          fetchUsersForState(e.target.value && e.target.value.replace(/\D/g, ''), property, this.state.filter.hideDisabled);
          query[property] = encodeURIComponent(e.target.value && e.target.value.replace(/\D/g, ''));
        } else if (property === 'eldHardwareVersion') {
          fetchUsersForState(parseFloat(e.target.value), property, this.state.filter.hideDisabled);
          query[property] = encodeURIComponent(e.target.value);
        } else {
          fetchUsersForState(e.target.value, property, this.state.filter.hideDisabled);
          query[property] = encodeURIComponent(e.target.value);
        }
        history.push({ pathname: this.props.location.pathname, query });
      }
    }
  }

  render() {
    return (
      <div className={styles.usersLayout}>
        <Title title="Users">
          <span className="inlineBlock">
            <input
              type="text"
              className="buttonSpacedRight"
              onKeyPress={(event) => this.handleUserEntry(event, 'firstName')}
              placeholder="Enter User First Name"
            />
            <input
              type="text"
              className="buttonSpacedRight"
              onKeyPress={(event) => this.handleUserEntry(event, 'username')}
              placeholder="Enter User Username"
            />
            <input
              type="text"
              className="buttonSpacedRight"
              onKeyPress={(event) => this.handleUserEntry(event, 'belongsToCompany')}
              placeholder="Enter Company"
            />
            <input
              type="text"
              className="buttonSpacedRight"
              onKeyPress={(event) => this.handleUserEntry(event, 'dotNumber')}
              placeholder="Enter DOT Number"
            />
            <input
              type="text"
              className="buttonSpacedRight"
              onKeyPress={(event) => this.handleUserEntry(event, 'email')}
              placeholder="Enter User Email"
            />
            <input
              type="text"
              className="buttonSpacedRight"
              onKeyPress={(event) => this.handleUserEntry(event, 'phoneNumberCleaned')}
              placeholder="Enter User Phone #"
            />
            <input
              type="text"
              className="buttonSpacedRight"
              onChange={(e) => this.setState({ ...this.state, eldSoftwareVersion: e.target.value })}
              onKeyPress={(event) => this.handleUserEntry(event, 'eldSoftwareVersion')}
              placeholder="Enter Software Version"
            />
            <input
              type="number"
              className="buttonSpacedRight"
              onKeyPress={(event) => this.handleUserEntry(event, 'eldHardwareVersion')}
              placeholder="Enter Engine Module Version"
            />
            <select value={this.state.filter.hideDisabled} onChange={(e) => { this.setState({ ...this.state, filter: { ...this.state.filter, hideDisabled: e.target.value } }) }}>
              <option value={true}>Hide Disabled Users</option>
              <option value={false}>Show Disabled Users</option>
            </select>
            <button className="buttonDefault buttonSpacedRight" onClick={() => this.handleUserEntry(null, 'outOfDateEngineModule')} >Engine Module Outdated</button>
            <button className="buttonDefault" onClick={() => this.handleUserEntry(null, 'outOfDateSoftware')} >Software Outdated</button>
            <button className="buttonDefault" onClick={() => this.handleUserEntry(null, 'delayedUploadUsers')} >Delayed Upload</button>
          </span>
        </Title>
        <UsersTable
          resellerCompanyArr={this.state.resellerCompanyArr}
          fetched={!this.state.loading} // pass it User state to get action state
          users={this.state.userArr}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { User } = state;
  return {
    User,
  };
};

UsersLayout.propTypes = {
  User: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(UsersLayout);
