import PropTypes from 'prop-types';
import React from 'react';

// API
import * as Helpers from 'api/Helpers';
import * as Getters from 'api/Getters';

// Components
import DeleteXButton from 'components/DeleteXButton/container/DeleteXButton';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import LoadingModal from 'components/LoadingModal/container/LoadingModal';

// CSS
import styles from './UserTableRow.module.scss';

class UserTableRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loadingModalShow: false,
      destroyFinished: false,
      showResetPasswordModal: false,
      driver: null,
    };
    this.nameSort = this.nameSort.bind(this);
  }

  componentDidMount() {
    if (this.props.user.get('userType') && this.props.user.get('userType').indexOf(4) !== -1) {
      Getters.getDriverByUserId(this.props.user.id).then((driverArr) => {
        if (driverArr && driverArr.length > 0) {
          this.setState({ ...this.state, driver: driverArr[0] });
        }
      });
    }
  }

  nameSort(user, nextUser) {
    const comparatorA = user.name.toLowerCase();
    const comparatorB = nextUser.name.toLowerCase();
    if (comparatorA > comparatorB) {
      return 1;
    } else if (comparatorA < comparatorB) {
      return -1;
    }
    return 0;
  }

  render() {
    const user = this.props.user;
    return (
      <tr className={styles.userRow}>
        <td>
          <div>
            <b>{user.get('firstName')} {user.get('lastName')}</b>
            <br />
            {user.get('username')}
          </div>
        </td>
        <td>
          <div>
            {user.get('email')}
            <br />
            {user.get('phoneNumber')}
          </div>
        </td>
        <td>
          <div>
            {user.get('belongsToCompany') && <div>{user.get('belongsToCompany').get('name')} (companyId: {user.get('belongsToCompany').id})</div>}
            {this.props.resellerCompanyName &&
              <div>
                <b><u>Reseller:</u> {this.props.resellerCompanyName}</b>
              </div>
            }
            <div>
              {user.get('belongsToCompany') && <div>{user.get('belongsToCompany').get('dotNumber')}</div>}
            </div>
            <div>
              <b>Company Phone #: </b>
              {user.get('belongsToCompany') && user.get('belongsToCompany').get('phoneNumber') && <span>{user.get('belongsToCompany').get('phoneNumber')}</span>}
              {user.get('belongsToCompany') && !user.get('belongsToCompany').get('phoneNumber') && <span>Need to enter</span>}
            </div>
          </div>
        </td>
        <td>
          {this.state.driver &&
            <div>
              <div>
                Switchboard Version:&nbsp;
                {this.state.driver && this.state.driver.get('currentELDVersion') && <b>{this.state.driver.get('currentELDVersion')}</b>}
                {this.state.driver && !this.state.driver.get('currentELDVersion') && <b>N/A</b>}
              </div>
              {this.state.driver && this.state.driver.get('vehicle') && this.state.driver.get('vehicle').get('eldHardware') &&
                <div>
                  Engine Module:&nbsp;
                  <b>{this.state.driver.get('vehicle').get('eldHardware').get('softwareVersion')}</b>
                  {` (${this.state.driver.get('vehicle').get('eldHardware').get('serial')})`}
                </div>
              }
              {this.state.driver && this.state.driver.get('vehicle') && this.state.driver.get('vehicle').get('eldHardware') &&
                <div>
                  Target Engine Module Version:&nbsp;
                  <b>{this.state.driver.get('vehicle').get('eldHardware').get('targetSoftwareVersion')}</b>
                </div>
              }
              <div>
                ELD or AOBRD:&nbsp;
                {this.state.driver && this.state.driver.get('vehicle') && this.state.driver.get('vehicle').get('eldHardware') &&
                  <b>
                    {this.state.driver.get('vehicle').get('eldHardware').get('aobrdEnabled') && 'AOBRD Mode'}
                    {!this.state.driver.get('vehicle').get('eldHardware').get('aobrdEnabled') && 'ELD Mode'}
                  </b>
                }
              </div>
              <div>
                IMEI:&nbsp;
                {this.state.driver && this.state.driver.get('deviceIMEI') && <b>{this.state.driver.get('deviceIMEI')}</b>}
                {this.state.driver && !this.state.driver.get('deviceIMEI') && <b>N/A</b>}
              </div>
              <div>
                Vehicle:&nbsp;
                {this.state.driver && !this.state.driver.get('vehicle') && <b>No Vehicle Found</b>}
                {this.state.driver && this.state.driver.get('vehicle') && <b>{this.state.driver.get('vehicle').get('unitId')}</b>}
              </div>
            </div>
          }
        </td>
        <td>
          {user.get('userType') && user.get('userType').indexOf(4) === -1 &&
            <div>
              <button className="buttonDefault" onClick={() => this.props.showOfficeUserDetailsModal(this.props.user)}>View User Details</button>
              <br />
              <br />
            </div>
          }

          {user.get('userType') && user.get('userType').indexOf(4) !== -1 &&
            <div>
              <button className="buttonDefault" onClick={() => this.props.showDriverDetailsModal(this.props.user)}>View Driver Details</button>
              <br />
              <br />
            </div>
          }

          {user.get('userType') && user.get('userType').indexOf(4) === -1 &&
            <div>
              {this.props.logInInProgress &&
                <LoadingIcon />
              }
              {!this.props.logInInProgress &&
                <button className="buttonDefault greenButton" onClick={() => this.props.logInAsCompany(this.props.user.id)}>Log In Cloud</button>
              }
              <br />
              <br />
            </div>
          }

          <div>
            {this.props.creatingSupportReport &&
              <LoadingIcon />
            }
            {!this.props.creatingSupportReport &&
              <button className="buttonDefault yellowButton" onClick={() => this.props.createSupportReport(this.props.user)}>Create Support Report</button>
            }
          </div>
        </td>
      </tr>
    );
  }
}

UserTableRow.propTypes = {
  user: PropTypes.object.isRequired,
  logInInProgress: PropTypes.bool.isRequired,
  logInAsCompany: PropTypes.func.isRequired,
  showOfficeUserDetailsModal: PropTypes.func.isRequired,
  showDriverDetailsModal: PropTypes.func.isRequired,
  createSupportReport: PropTypes.func.isRequired,
  creatingSupportReport: PropTypes.bool,
};

export default UserTableRow;
