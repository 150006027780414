import React from 'react';
import PropTypes from 'prop-types';

// CSS
import styles from './LoginForm.module.css';


function LoginForm(props) {
  return (
    <div className={styles.loginForm} onKeyPress={(e) => { if (e.key === 'Enter') props.handleSubmit(); }} >
      {!props.error &&
        <div className={styles.error}>&nbsp;</div>
      }
      {props.error &&
        <div className={styles.error}>{props.error}</div>
      }

      <div className={styles.loginFormInputContainer}>
        <div>
          <input
            placeholder="Email Address"
            type="email"
            name="email"
            className={styles.loginInput}
            value={props.fields.email}
            onChange={(e) => props.handleFormChange('email', e.target.value)}
          />
        </div>
      </div>
      <div className={styles.loginFormInputContainer}>
        <div>
          <input
            placeholder="Password"
            type="password"
            name="password"
            className={styles.loginInput}
            value={props.fields.password}
            onChange={(e) => props.handleFormChange('password', e.target.value)}
          />
        </div>
      </div>
      <div className={styles.loginButtonContainer}>
        {/* <button className="buttonDefault" onClick={() => this.handleUserEntry(null, 'outOfDateSoftware')} >Software Outdated</button> */}
        <button
          className={`buttonDefault ${styles.loginButton}`}
          onClick={props.handleSubmit}
          disabled={props.disableLogin}
          type="submit"
          color="primary"
          size="sm"
        >
          Login
        </button>
      </div>
      {/* <br /><br /> */}
      {/* <div className={styles.additional}>
        Forgot your password? &nbsp;
        {props.fields.email ?
          <a onClick={() => props.emailHandler(props.fields.email)}>Reset your password ►</a>
          :
          <a onClick={() => props.emailHandler()}>Reset your password ►</a>
        }
      </div>
      <div className={styles.additional}>
        Don’t have an account? &nbsp;
        <a onClick={() => props.handleSignup()}>Sign up ►</a>
      </div> */}
    </div>
  );
}

LoginForm.propTypes = {
  error: PropTypes.string,
  emailHandler: PropTypes.func,
  fields: PropTypes.object,
  disableLogin: PropTypes.bool,
  handleFormChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default LoginForm;
