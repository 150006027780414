import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, Toggle, TextField } from 'material-ui';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import * as Helpers from 'api/Helpers';

// Components
// import SimRow from './SimRow';
// import TwilioInfo from '../../TwilioInfo/container/TwilioInfo';

// CSS

class ELDHardwareList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      objArr: [],
      countArr: {
        createdLastMonthArr: [],
        totalUniqueELDHardwareSerialArr: [],
        lastMonthUniqueELDHardwareSerialArr: [],
        lastThreeMonthsUniqueELDHardwareSerialArr: [],
      },
      loading: false,
    };
    this.refreshArr = this.refreshArr.bind(this);
  }

  componentDidMount() {
    this.refreshArr();
  }


  refreshArr() {
    this.setState({
      ...this.state,
      loading: true,
    });
    Getters.getELDHardwareAndELDEventByCompany(this.props.companyId, true).then((objArr) => {
      const createdLastMonthArr = [];
      const totalUniqueELDHardwareSerialArr = [];
      const lastMonthUniqueELDHardwareSerialArr = [];
      const lastThreeMonthsUniqueELDHardwareSerialArr = [];

      const sortedObjArr = objArr.sort((a, b) => {
        const aDateIso = a?.eldEvent?.eventDateTime?.iso ? moment(a?.eldEvent?.eventDateTime?.iso).format('x') : 0;
        const bDateIso = b?.eldEvent?.eventDateTime?.iso ? moment(b?.eldEvent?.eventDateTime?.iso).format('x') : 0;
        return bDateIso - aDateIso;
      });
      console.log(sortedObjArr);

      for (let i = 0; i < sortedObjArr.length; i++) {
        const obj = sortedObjArr[i];

        if (moment(obj.eldHardware.createdAt).isAfter(moment().subtract(1, 'month').startOf('month'))) {
          createdLastMonthArr.push(obj);
        }

        if (totalUniqueELDHardwareSerialArr.indexOf(obj.eldEvent.eldHardwareSerial) === -1) {
          totalUniqueELDHardwareSerialArr.push(obj.eldEvent.eldHardwareSerial);
        }

        if (
          moment(obj.eldEvent.eventDateTime).isAfter(moment().subtract(1, 'months').startOf('month')) &&
          lastMonthUniqueELDHardwareSerialArr.indexOf(obj.eldEvent.eldHardwareSerial) === -1
        ) {
          lastMonthUniqueELDHardwareSerialArr.push(obj.eldEvent.eldHardwareSerial);
        }

        if (
          moment(obj.eldEvent.eventDateTime).isAfter(moment().subtract(3, 'months').startOf('month')) &&
          lastThreeMonthsUniqueELDHardwareSerialArr.indexOf(obj.eldEvent.eldHardwareSerial) === -1
        ) {
          lastThreeMonthsUniqueELDHardwareSerialArr.push(obj.eldEvent.eldHardwareSerial);
        }
      }
      this.setState({
        ...this.state,
        objArr: sortedObjArr,
        countArr: {
          createdLastMonthArr,
          totalUniqueELDHardwareSerialArr,
          lastMonthUniqueELDHardwareSerialArr,
          lastThreeMonthsUniqueELDHardwareSerialArr,
        },
        loading: false,
      });
    });
  }

  generateCsv() {
    const lineArray = ['Vehicle,ELD Hardware ID,Last Active'];

    for (let i = 0; i < this.state.objArr.length; i++) {
      const obj = this.state.objArr[i].eldEvent;
      const vehicleUnitId = obj.vehicleUnitId;
      const eldHardwareSerial = obj && obj.eldHardwareSerial;
      const lastActive = obj && obj?.eventDateTime?.iso && moment(obj.eventDateTime.iso).format('YYYY-MMMM-DD');
      lineArray.push([vehicleUnitId, eldHardwareSerial, lastActive].join(','));
    }

    const csvContent = lineArray.join('\n');
    Helpers.createCsvFile('ELD Modules - ' + moment().format('YYYY-MM-DD'), csvContent, true);
  }

  render() {
    return (
      <div>
        <button className={`buttonDefault`} onClick={() => this.generateCsv()}>Download CSV</button>
        <br />
        <Table
          selectable={false}
          multiSelectable={false}
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          ><TableRow>
              <TableRowColumn style={{ width: '25%' }}>
                <b>ELD Hardware</b>
                <br />
                Created Last Month: {this.state.countArr.createdLastMonthArr.length}
                <br />
                Total (removed duplicates): {this.state.countArr.totalUniqueELDHardwareSerialArr.length}
                <br />
                Last Month (removed duplicates): {this.state.countArr.lastMonthUniqueELDHardwareSerialArr.length}
                <br />
                Last 3 Months (removed duplicates): {this.state.countArr.lastThreeMonthsUniqueELDHardwareSerialArr.length}
              </TableRowColumn>
              <TableRowColumn style={{ width: '25%' }}>
                <b>Vehicle</b>
              </TableRowColumn>
              <TableRowColumn style={{ width: '25%' }}>
                <b>Latest Activity</b>
                <br />
                Last Month (can have duplicates):
                {
                  this.state.objArr.filter((obj) => (
                    obj && obj.eldEvent && moment(obj.eldEvent.eventDateTime).isAfter(moment().subtract(1, 'months').startOf('month'))
                  )).length
                }
                <br />
                Last 3 Months (can have duplicates):
                {
                  this.state.objArr.filter((obj) => (
                    obj && obj.eldEvent && moment(obj.eldEvent.eventDateTime).isAfter(moment().subtract(3, 'months').startOf('month'))
                  )).length
                }
              </TableRowColumn>
            </TableRow>
          </TableHeader>
          <TableBody
            displayRowCheckbox={false}
          >
            {this.state.objArr.map((obj) => (
              <TableRow>
                <TableRowColumn style={{ width: '25%' }}>
                  {obj && obj.eldEvent && obj.eldEvent.eldHardwareSerial}
                </TableRowColumn>
                <TableRowColumn style={{ width: '25%' }}>
                  {obj && obj.eldEvent && obj.eldEvent.vehicleUnitId}
                </TableRowColumn>
                <TableRowColumn style={{ width: '25%' }}>
                  {obj && obj.eldEvent && obj.eldEvent && obj.eldEvent.eventDateTime && obj.eldEvent.eventDateTime.iso &&
                    moment(obj.eldEvent.eventDateTime.iso).format('lll')
                  }
                </TableRowColumn>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {this.state.loading &&
          <LoadingIcon />
        }
      </div>
    );
  }

}

ELDHardwareList.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default ELDHardwareList;
