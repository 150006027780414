import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
// CSS
import styles from './ConfirmModal.module.scss';

function ConfirmModal(props) {
  const [reason, setReason] = useState('');

  const onSetReason = (value) => {
    setReason(value);
  };

  return (
    <Modal backdrop="static" show={props.show} onHide={props.handleClose}>

      <Modal.Header closeButton>
        <Modal.Title>Confirm</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={styles.modalBody}>
          Are you sure?

          {props.showReason &&
            (
              <div>
                <br />
                Reason:
                <textarea
                  value={reason}
                  onChange={(e) => onSetReason(e.target.value)}
                  style={{ minHeight: '10em' }}
                />
              </div>
            )
          }
        </div>
        <div className={styles.choices}>
          <button className="closeButton" onClick={() => { onSetReason(''); props.handleModalChoice(false) }}>No</button>
          <button className="buttonDefault" disabled={props.showReason && !reason} onClick={() => { onSetReason(''); props.handleModalChoice(true, reason) }}>Yes</button>
        </div>
      </Modal.Body>

    </Modal>
  );
}

ConfirmModal.propTypes = {
  handleModalChoice: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  showReason: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
};

export default ConfirmModal;
