import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// Actions

// API
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';

// Components
import Title from 'components/LayoutTitle/view/Title';
import VehiclesTable from 'components/CompanyOdometersTable/container/VehiclesTable';
import DriversTable from 'components/CompanyOdometersTable/container/DriversTable';

// CSS
import styles from './OdometersLayout.module.scss';

class OdometersLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      loading: false,
      vehicles: [], // holds companies (all or active) based on filter dropdown view
      drivers: [], // holds companies (all or active) based on filter dropdown view
    };
    this.handleCompanyEntry = this.handleCompanyEntry.bind(this);
    this.fetchOdometerReadingIssueVehicles = this.fetchOdometerReadingIssueVehicles.bind(this);
  }

  componentDidMount() {
    this.fetchOdometerReadingIssueVehicles();
  }

  handleCompanyEntry(e) {
    // if (e.key === 'Enter') {
    //   const companyName = e.target.value;
    //   this.setState({ ...this.state, loading: true }, () => {
    //     Getters.fetchCompanyVehiclesForState(companyName).then((vehicles) => {
    //       this.setState({ ...this.state, loading: false, vehicles });
    //     });
    //   });
    // }

  }

  fetchOdometerReadingIssueVehicles() {
    this.setState({ ...this.state, loading: true }, () => {
      Getters.fetchOdometerReadingIssueDrivers().then((drivers) => {
        console.log(drivers);
        this.setState({ ...this.state, loading: false, drivers });
      });
    });
  }

  render() {
    return (
      <div className={styles.OdometersLayout}>
        <Title title="Odometers">
          {/* <span className="inlineBlock">
            <input type="text" className="buttonSpacedRight" onKeyPress={this.handleCompanyEntry} placeholder="Enter Company Name" />
          </span> */}
        </Title>
        {/* <VehiclesTable
          loading={this.state.loading} // pass it Company state to get action state
          vehicles={this.state.vehicles}
        /> */}
        <DriversTable
          loading={this.state.loading} // pass it Company state to get action state
          drivers={this.state.drivers}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { Company } = state;
  return {
    Company,
  };
};

OdometersLayout.propTypes = {
  Company: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(OdometersLayout);
