import * as ActionConstants from 'actions/ActionConstants';

const initialState = {
  isProduction: false,
};

export function Main(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_USER_GLOBALS_INPROGRESS:
      return {
        ...state,
        status: action.type,
        error: {},
      };
    case ActionConstants.FETCH_USER_GLOBALS_SUCCESS:
      return {
        ...state,
        status: action.type,
        error: {},
      };
    case ActionConstants.FETCH_USER_GLOBALS_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    case ActionConstants.INITIALIZE_MAPBOX:
      return {
        ...state,
        status: action.status,
        mapboxToken: action.token,
        error: undefined,
      };
    case ActionConstants.INITIALIZE:
      return {
        ...state,
        status: action.type,
        isProduction: action.isProduction,
        serverURL: action.serverURL,
        error: {},
      };
    default:
      return state;
  }
}
