import PropTypes from 'prop-types';
import React from 'react';
import history from 'sbHistory';
import LazyLoad from 'react-lazy-load';

import { Table } from 'react-bootstrap';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';

// Components
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import ExpiredSubscriptionRow from 'components/ExpiredSubscriptionsTable/container/ExpiredSubscriptionRow';

// CSS
import styles from './ExpiredSubscriptionsTable.module.scss';

class ExpiredSubscriptionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expiredSubscriptionsCustomerArr: [],
      voidInvoice: {
        invoiceDataObj: {},
        showConfirmModal: false,
      },
      loading: false,
    };

    this.refreshExpiredSubscriptionsCustomers = this.refreshExpiredSubscriptionsCustomers.bind(this);
  }

  componentDidMount() {
    this.refreshExpiredSubscriptionsCustomers();
  }

  refreshExpiredSubscriptionsCustomers() {
    this.setState({ ...this.state, loading: true });
    Getters.getExpiredSubscriptionsCustomers().then((expiredSubscriptionsCustomerArr) => {
      this.setState({ ...this.state, loading: false, expiredSubscriptionsCustomerArr });
    });
  }

  render() {
    return (
      <div className={`defaultTable ${styles.expiredSubscriptionsTable}`}>
        <Table
          responsive
          hover
        >
          <thead>
            <tr>
              <th>Company</th>
              <th>Billing Info</th>
              <th>Days Since Creation</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loading &&
              <tr className={styles.loadingIconNoHover}>
                <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
              </tr>
            }
            {!this.state.loading && this.state.expiredSubscriptionsCustomerArr.length === 0 &&
              <tr>
                <td colSpan="7" className="emptyRowMessage">No Customers to Display</td>
              </tr>
            }
            {!this.state.loading && this.state.expiredSubscriptionsCustomerArr.length > 0 &&
              this.state.expiredSubscriptionsCustomerArr.map((expiredSubscriptionsCustomerObj) => (
                <ExpiredSubscriptionRow
                  key={expiredSubscriptionsCustomerObj.id}
                  expiredSubscriptionsCustomerObj={expiredSubscriptionsCustomerObj}
                  showConfirmVoidInvoiceModal={this.showConfirmVoidInvoiceModal}
                />
              ))
            }
          </tbody>
        </Table>
        <ConfirmModal
          show={this.state.voidInvoice.showConfirmModal}
          handleModalChoice={(confirmBool, reason) => confirmBool ? this.voidInvoice(reason) : this.setState({ ...this.state, voidInvoice: { showConfirmModal: false } })}
          handleClose={() => this.setState({ ...this.state, voidInvoice: { showConfirmModal: false } })}
          showReason
        />
      </div>
    );
  }
}

ExpiredSubscriptionsTable.propTypes = {
};

export default ExpiredSubscriptionsTable;

