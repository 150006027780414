import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

// API
import * as Setters from 'api/Setters';

// Styles
import styles from './styles.module.scss';

import { Table, TableHeader, TableRow, TableBody, TableRowColumn, TextField, Toggle } from 'material-ui';

const initialState = () => ({
  invoiceItems: [],
  draftInvoiceItem: {
    id: Math.random().toString(16).substr(2, 14),
    description: '',
    currency: 'cad',
    dollar_amount: 0,
    quantity: 0,
    error: '',
  },
  disableButton: false,
  payingInvoice: false,
  error: '',
});

class ChargeSingleInvoiceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState();

    this.handleChangeAddInvoiceItem = this.handleChangeAddInvoiceItem.bind(this);
    this.handleAddInvoiceItem = this.handleAddInvoiceItem.bind(this);
    this.handleDeleteInvoiceItem = this.handleDeleteInvoiceItem.bind(this);
    this.payInvoice = this.payInvoice.bind(this);
  }


  handleChangeAddInvoiceItem(property, value) {
    const draftInvoiceItem = { ...this.state.draftInvoiceItem };
    draftInvoiceItem[property] = value;
    this.setState({
      ...this.state,
      draftInvoiceItem,
    });
  }

  handleAddInvoiceItem() {
    const invoiceItems = [].concat(...this.state.invoiceItems);
    invoiceItems.push(this.state.draftInvoiceItem);
    this.setState({
      ...this.state,
      draftInvoiceItem: {
        id: Math.random().toString(16).substr(2, 14),
        description: '',
        currency: 'cad',
        dollar_amount: 0,
        quantity: 0,
      },
      invoiceItems,
      savingInvoiceItem: false,
    });
  }

  handleDeleteInvoiceItem(invoiceItem) {
    this.setState({ ...this.state, savingInvoiceItem: true }, () => {
      const invoiceItems = [].concat(...this.state.invoiceItems);
      for (let i = 0; i < invoiceItems.length; i++) {
        if (invoiceItems[i].id === invoiceItem.id) {
          invoiceItems.splice(i, 1);
        }
      }
      this.setState({
        ...this.state,
        invoiceItems,
        savingInvoiceItem: false,
      });
    });
  }

  payInvoice() {
    this.setState({ ...this.state, disableButton: true, error: '' }, () => {
      Setters.createInvoice(this.props.subscriptionObj.id, this.state.invoiceItems).then(() => {
        this.setState(initialState());
        this.props.handleClose();
      }, (error) => {
        console.log(error);
        this.setState({ ...this.state, disableButton: false, error: error.message });
      });
    });
  }

  render() {
    const { handleModalChoice, handleClose, show } = this.props;
    return (
      <Modal bsSize="lg" backdrop="static" show={show} onHide={handleClose}>

        <Modal.Header closeButton>
          <Modal.Title>Charge Instant Invoice</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table
            selectable={false}
            multiSelectable={false}
          >
            <TableHeader
              displaySelectAll={false}
              adjustForCheckbox={false}
              enableSelectAll={false}
            ><TableRow>
                <TableRowColumn style={{ width: '30%' }}><b>Description</b></TableRowColumn>
                <TableRowColumn style={{ width: '15%' }}><b>Quantity</b></TableRowColumn>
                <TableRowColumn style={{ width: '25%' }}><b>Unit Amount</b></TableRowColumn>
                <TableRowColumn style={{ width: '25%' }}><b>Total Amount</b></TableRowColumn>
                <TableRowColumn style={{ width: '15%' }}></TableRowColumn>
              </TableRow></TableHeader>
            <TableBody
              displayRowCheckbox={false}
            >
              <TableRow>
                <TableRowColumn style={{ width: '30%' }}>
                  <input value={this.state.draftInvoiceItem.description} onChange={(e) => this.handleChangeAddInvoiceItem('description', e.target.value)} />
                </TableRowColumn>
                <TableRowColumn style={{ width: '15%' }}>
                  <input style={{ width: '100%' }} type="number" value={this.state.draftInvoiceItem.quantity} onChange={(e) => this.handleChangeAddInvoiceItem('quantity', e.target.value)} />
                </TableRowColumn>
                <TableRowColumn style={{ width: '25%' }}>
                  <select value={this.state.draftInvoiceItem.currency} onChange={(e) => this.handleChangeAddInvoiceItem('currency', e.target.value)}>
                    <option value="cad">CAD</option>
                    <option value="usd">USD</option>
                  </select>
                  <input
                    style={{ width: '50%' }}
                    type="number"
                    value={this.state.draftInvoiceItem.dollar_amount}
                    onChange={(e) => this.handleChangeAddInvoiceItem('dollar_amount', parseFloat(e.target.value))}
                  />
                </TableRowColumn>
                <TableRowColumn style={{ width: '25%' }}>
                  {this.state.draftInvoiceItem.currency.toUpperCase()} ${((this.state.draftInvoiceItem.dollar_amount * this.state.draftInvoiceItem.quantity)).toFixed(2)}
                </TableRowColumn>
                <TableRowColumn style={{ width: '15%' }} >
                  <button
                    className={'buttonDefault'}
                    onClick={() => {
                      this.handleAddInvoiceItem();
                    }}
                    disabled={this.state.disableButton}
                  >
                    Add
                  </button>
                </TableRowColumn>
              </TableRow>
              {this.state.invoiceItems &&
                this.state.invoiceItems.filter((upcomingInvoiceItem) =>
                  // if invoiceItem is for another subscription, don't show (for customers with multiple subscriptions, ie. Vecima reseller)
                  // If no plan, then is a general invoice item for the customer
                  (!upcomingInvoiceItem.plan || (upcomingInvoiceItem.subscription === (props.subscriptionObj && props.subscriptionObj.get('subscriptionId'))))
                ).map((invoiceItem) => {
                  return (
                    <TableRow key={invoiceItem.id}>
                      <TableRowColumn style={{ width: '30%' }}>{invoiceItem.description}</TableRowColumn>
                      <TableRowColumn style={{ width: '15%' }}>{invoiceItem.quantity}</TableRowColumn>
                      <TableRowColumn style={{ width: '25%' }}>{invoiceItem.currency.toUpperCase()} ${(invoiceItem.dollar_amount).toFixed(2)}</TableRowColumn>
                      <TableRowColumn style={{ width: '25%' }}>{invoiceItem.currency.toUpperCase()} ${(invoiceItem.dollar_amount * invoiceItem.quantity).toFixed(2)}</TableRowColumn>
                      <TableRowColumn style={{ width: '15%' }}>
                        <button
                          className={'buttonDefault'}
                          onClick={() => {
                            this.handleDeleteInvoiceItem(invoiceItem);
                          }}
                          disabled={this.state.disableButton}
                        >
                          Delete
                        </button>
                      </TableRowColumn>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>

          <br />
          <br />

          <div className="errorText">
            {this.state.error}
          </div>

          <div className={styles.choices}>
            <button
              className="closeButton"
              onClick={() => {
                handleClose(false, this.state.reason, this.state.name);
              }}
              disabled={this.state.disableButton}
            >
              Cancel
            </button>
            <button
              className="buttonDefault"
              onClick={() => {
                this.payInvoice();
              }}
              disabled={this.state.disableButton}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>

      </Modal>
    );
  }
}

ChargeSingleInvoiceModal.propTypes = {
  handleModalChoice: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  subscriptionObj: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
};

// const mapDispatchToProps = (dispatch) => ({
//   actions: bindActionCreators({
//     // fetchJobsIfNeeded,
//     fetchDocumentsForState,
//   }, dispatch),
// });

export default connect(undefined, undefined)(ChargeSingleInvoiceModal);
