import PropTypes from 'prop-types';
import React from 'react';
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, TextField, Toggle } from 'material-ui';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import * as Helpers from 'api/Helpers';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS

class Integrations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorIntegrationObj: null,
      p44: {
        saveBool: false,
      },
      macropoint: {
        saveBool: false,
        requesterIdsString: '',
      },
      fourkites: {
        saveBool: false,
      },
    };
  }

  componentDidMount() {
    this.setState({ ...this.state, loading: true });
    Getters.getVendorIntegrationFromCompanyId(this.props.companyId).then((vendorIntegrationObj) => {
      this.setState({
        ...this.state,
        loading: false,
        vendorIntegrationObj,
        macropoint: { ...this.state.macropoint, requesterIdsString: vendorIntegrationObj.get('macropointRequesters') && vendorIntegrationObj.get('macropointRequesters').join(',') }
      });
    });
  }

  render() {
    return (
      <div>
        {this.state.loading &&
          <LoadingIcon />
        }
        {!this.state.loading && this.state.vendorIntegrationObj &&
          <div>
            {(
              this.props.subscriptionObj?.get('isClosed')
              || (
                !this.props.subscriptionObj?.get('eldModule')
                && !this.props.subscriptionObj?.get('gpsModule')
              )
            ) &&
              <div>
                Customer must have <b>Cloud Software</b> or <b>Map/GPS Module</b> enabled for Integration options below. Also, customer must not be cancelled.
              </div>
            }
            {!(
              this.props.subscriptionObj?.get('isClosed')
              || (
                !this.props.subscriptionObj?.get('eldModule')
                && !this.props.subscriptionObj?.get('gpsModule')
              )
            ) &&
              <div>
                <div>Make sure you ask the carrier directly for consent before changing these settings!</div>
                <hr />
                <div style={{ width: '20em' }} className="center centerText">
                  <Toggle
                    label="Project44 Integration Enabled"
                    onToggle={(event, newValue) => {
                      Setters.setVendorIntegrationProperty(this.state.vendorIntegrationObj.id, this.props.companyId, 'p44', newValue).then(() => {
                        this.state.vendorIntegrationObj.set('p44', newValue);
                        this.setState({ ...this.state });
                      });
                    }}
                    toggled={this.state.vendorIntegrationObj && this.state.vendorIntegrationObj.get('p44')}
                  />
                  <div>
                    <Table
                      selectable={false}
                      multiSelectable={false}
                    >
                      <TableHeader
                        displaySelectAll={false}
                        adjustForCheckbox={false}
                        enableSelectAll={false}
                      ><TableRow>
                          <TableRowColumn>Owner Id</TableRowColumn>
                          <TableRowColumn></TableRowColumn>
                        </TableRow></TableHeader>
                      <TableBody
                        displayRowCheckbox={false}
                      >
                        <TableRow key={module.property}>
                          <TableRowColumn>
                            <TextField
                              hintText="Owner Id"
                              onChange={(event, newValue) => {
                                this.state.vendorIntegrationObj.set('p44OwnerId', Helpers.compressWhitespace(newValue));
                                this.setState({ ...this.state });
                              }}
                              value={this.state.vendorIntegrationObj && this.state.vendorIntegrationObj.get('p44OwnerId')}
                            />
                          </TableRowColumn>
                          <TableRowColumn>
                            <button
                              className="buttonDefault"
                              disabled={this.state.p44.saveBool}
                              onClick={() => {
                                this.setState({ ...this.state, p44: { ...this.state.p44, saveBool: true } });
                                Setters.setVendorIntegrationProperty(this.state.vendorIntegrationObj.id, this.props.companyId, 'p44OwnerId', Helpers.compressWhitespace(this.state.vendorIntegrationObj.get('p44OwnerId'))).then(() => {
                                  this.setState({ ...this.state, p44: { ...this.state.macrop44point, saveBool: false } });
                                });
                              }}
                            >
                              Save
                            </button>
                          </TableRowColumn>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
                <hr />
                <div style={{ width: '20em' }} className="center centerText">
                  <Toggle
                    label="TruckerTools Integration Enabled"
                    onToggle={(event, newValue) => {
                      Setters.setVendorIntegrationProperty(this.state.vendorIntegrationObj.id, this.props.companyId, 'truckerTools', newValue).then(() => {
                        this.state.vendorIntegrationObj.set('truckerTools', newValue);
                        this.setState({ ...this.state });
                      });
                    }}
                    toggled={this.state.vendorIntegrationObj && this.state.vendorIntegrationObj.get('truckerTools')}
                  />
                </div>
                <hr />
                <div style={{ width: '20em' }} className="center centerText">
                  <Toggle
                    label="ISG Integration Enabled"
                    onToggle={(event, newValue) => {
                      Setters.setVendorIntegrationProperty(this.state.vendorIntegrationObj.id, this.props.companyId, 'isg', newValue).then(() => {
                        this.state.vendorIntegrationObj.set('isg', newValue);
                        this.setState({ ...this.state });
                      });
                    }}
                    toggled={this.state.vendorIntegrationObj && this.state.vendorIntegrationObj.get('isg')}
                  />
                </div>
                <hr />
                <div>
                  <h3>Macropoint</h3>
                  <div>
                    <Table
                      selectable={false}
                      multiSelectable={false}
                    >
                      <TableHeader
                        displaySelectAll={false}
                        adjustForCheckbox={false}
                        enableSelectAll={false}
                      ><TableRow>
                          <TableRowColumn>Credentials</TableRowColumn>
                          <TableRowColumn></TableRowColumn>
                          <TableRowColumn>Requester IDs</TableRowColumn>
                          <TableRowColumn></TableRowColumn>
                        </TableRow></TableHeader>
                      <TableBody
                        displayRowCheckbox={false}
                      >
                        <TableRow key={module.property}>
                          <TableRowColumn>
                            <TextField
                              hintText="Username"
                              onChange={(event, newValue) => {
                                Setters.setVendorIntegrationProperty(this.state.vendorIntegrationObj.id, this.props.companyId, 'macropointUsername', Helpers.compressWhitespace(newValue)).then(() => {
                                  this.state.vendorIntegrationObj.set('macropointUsername', Helpers.compressWhitespace(newValue));
                                  this.setState({ ...this.state });
                                });
                              }}
                              value={this.state.vendorIntegrationObj && this.state.vendorIntegrationObj.get('macropointUsername')}
                            />
                          </TableRowColumn>
                          <TableRowColumn>
                            <TextField
                              hintText="Password"
                              onChange={(event, newValue) => {
                                Setters.setVendorIntegrationProperty(this.state.vendorIntegrationObj.id, this.props.companyId, 'macropointPassword', Helpers.compressWhitespace(newValue)).then(() => {
                                  this.state.vendorIntegrationObj.set('macropointPassword', Helpers.compressWhitespace(newValue));
                                  this.setState({ ...this.state });
                                });
                              }}
                              value={this.state.vendorIntegrationObj && this.state.vendorIntegrationObj.get('macropointPassword')}
                            />
                          </TableRowColumn>
                          <TableRowColumn>
                            <TextField
                              type="Requester IDs"
                              onChange={(event, newValue) => {
                                this.setState({ ...this.state, macropoint: { ...this.state.macropoint, requesterIdsString: newValue } });
                              }}
                              value={this.state.vendorIntegrationObj && this.state.macropoint.requesterIdsString}
                            />
                          </TableRowColumn>
                          <TableRowColumn>
                            <button
                              className="buttonDefault"
                              disabled={this.state.macropoint.saveBool}
                              onClick={() => {
                                this.setState({ ...this.state, macropoint: { ...this.state.macropoint, saveBool: true } });
                                const array = this.state.macropoint.requesterIdsString.trim().split(',') || [];
                                for (let i = 0; i < array.length; i++) {
                                  if (!array[i]) array.splice(i, 1);
                                }
                                Promise.all([
                                  Setters.setVendorIntegrationProperty(this.state.vendorIntegrationObj.id, this.props.companyId, 'macropointUsername', this.state.vendorIntegrationObj.get('macropointUsername')),
                                  Setters.setVendorIntegrationProperty(this.state.vendorIntegrationObj.id, this.props.companyId, 'macropointPassword', this.state.vendorIntegrationObj.get('macropointPassword')),
                                  Setters.setVendorIntegrationProperty(this.state.vendorIntegrationObj.id, this.props.companyId, 'macropointRequesters', array),
                                ]).then(() => {
                                  this.setState({ ...this.state, macropoint: { ...this.state.macropoint, saveBool: false } });
                                });
                              }}
                            >
                              Save
                            </button>
                          </TableRowColumn>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
                <hr />
                <div>
                  <div style={{ width: '20em' }} className="center centerText">
                    <Toggle
                      label="Four Kites Integration Enabled"
                      onToggle={(event, newValue) => {
                        Setters.setVendorIntegrationProperty(this.state.vendorIntegrationObj.id, this.props.companyId, 'apiVehicleLocation', newValue).then((vendorIntegrationObj) => {
                          if (newValue === true) {
                            this.state.vendorIntegrationObj.set('apiVehicleLocation', vendorIntegrationObj.get('apiVehicleLocation'));
                          } else {
                            this.state.vendorIntegrationObj.unset('apiVehicleLocation');
                          }
                          this.setState({ ...this.state });
                        });
                      }}
                      toggled={this.state.vendorIntegrationObj && !!this.state.vendorIntegrationObj.get('apiVehicleLocation')}
                    />
                  </div>
                  {this.state.vendorIntegrationObj && this.state.vendorIntegrationObj.get('apiVehicleLocation') &&
                    <div>
                      <Table
                        selectable={false}
                        multiSelectable={false}
                      >
                        <TableHeader
                          displaySelectAll={false}
                          adjustForCheckbox={false}
                          enableSelectAll={false}
                        ><TableRow>
                            <TableRowColumn>Username</TableRowColumn>
                            <TableRowColumn>API Key</TableRowColumn>
                          </TableRow></TableHeader>
                        <TableBody
                          displayRowCheckbox={false}
                        >
                          <TableRow key={module.property}>
                            <TableRowColumn>
                              <TextField
                                hintText="Enter Username"
                                onChange={(event, newValue) => {
                                  this.state.vendorIntegrationObj.get('apiVehicleLocation').set('username', newValue);
                                  this.setState({ ...this.state });
                                }}
                                value={this.state.vendorIntegrationObj && this.state.vendorIntegrationObj.get('apiVehicleLocation').get('username')}
                              />
                              <button
                                className="buttonDefault"
                                disabled={this.state.fourkites.saveBool}
                                onClick={() => {
                                  this.setState({ ...this.state, fourkites: { ...this.state.fourkites, saveBool: true } });
                                  Setters.setVendorIntegrationProperty(this.state.vendorIntegrationObj.id, this.props.companyId, 'apiVehicleLocation.username', this.state.vendorIntegrationObj.get('apiVehicleLocation').get('username')).then(() => {
                                    this.setState({ ...this.state, fourkites: { ...this.state.fourkites, saveBool: false } });
                                  });
                                }}
                              >
                                Save
                              </button>
                            </TableRowColumn>
                            <TableRowColumn>
                              {this.state.vendorIntegrationObj.get('apiVehicleLocation') && this.state.vendorIntegrationObj.get('apiVehicleLocation').get('apiKey')}
                            </TableRowColumn>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  }
                </div>
                <hr />
                <div style={{ width: '20em' }} className="center centerText">
                  <Toggle
                    label="Rolls Right"
                    onToggle={(event, newValue) => {
                      Setters.setVendorIntegrationProperty(this.state.vendorIntegrationObj.id, this.props.companyId, 'rollsRight', newValue).then((vendorIntegrationObj) => {
                        this.state.vendorIntegrationObj.set('rollsRight', newValue);
                        this.setState({ ...this.state });
                      });
                    }}
                    toggled={this.state.vendorIntegrationObj && this.state.vendorIntegrationObj.get('rollsRight')}
                  />
                </div>
              </div>
            }
            {/* <div>
              <h3>Bison</h3>
              <Toggle
                onToggle={(event, newValue) => {
                }}
                toggled={true}
              />
              <TextField
                type="Carrier ID"
                // id={}
                // value={}
              />
            </div> 
          */}
          </div >
        }
      </div>
    );
  }
}

Integrations.propTypes = {
  companyId: PropTypes.string.isRequired,
  subscriptionObj: PropTypes.object.isRequired,
};

export default Integrations;
