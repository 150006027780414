import PropTypes from 'prop-types';
import React from 'react';
import history from 'sbHistory';
import LazyLoad from 'react-lazy-load';

import { Table } from 'react-bootstrap';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';

// Components
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import TrialSubscriptionRow from 'components/TrialSubscriptionsTable/container/TrialSubscriptionRow';

// CSS
import styles from './TrialSubscriptionsTable.module.scss';

class TrialSubscriptionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trialSubscriptionsCustomerArr: [],
      voidInvoice: {
        invoiceDataObj: {},
        showConfirmModal: false,
      },
      loading: false,
    };

    this.refreshTrialSubscriptionsCustomers = this.refreshTrialSubscriptionsCustomers.bind(this);
  }

  componentDidMount() {
    this.refreshTrialSubscriptionsCustomers();
  }

  refreshTrialSubscriptionsCustomers() {
    this.setState({ ...this.state, loading: true });
    Getters.getTrialSubscriptionsCustomers().then((trialSubscriptionsCustomerArr) => {
      this.setState({ ...this.state, loading: false, trialSubscriptionsCustomerArr });
    });
  }

  render() {
    return (
      <div className={`defaultTable ${styles.subscriptionsTable}`}>
        <Table
          responsive
          hover
        >
          <thead>
            <tr>
              <th>Company</th>
              <th>Billing Info</th>
              <th>Days Since Creation</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loading &&
              <tr className={styles.loadingIconNoHover}>
                <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
              </tr>
            }
            {!this.state.loading && this.state.trialSubscriptionsCustomerArr.length === 0 &&
              <tr>
                <td colSpan="7" className="emptyRowMessage">No Customers to Display</td>
              </tr>
            }
            {!this.state.loading && this.state.trialSubscriptionsCustomerArr.length > 0 &&
              this.state.trialSubscriptionsCustomerArr.map((trialSubscriptionsCustomerObj) => (
                <TrialSubscriptionRow
                  key={trialSubscriptionsCustomerObj.id}
                  trialSubscriptionsCustomerObj={trialSubscriptionsCustomerObj}
                  showConfirmVoidInvoiceModal={this.showConfirmVoidInvoiceModal}
                />
              ))
            }
          </tbody>
        </Table>
        <ConfirmModal
          show={this.state.voidInvoice.showConfirmModal}
          handleModalChoice={(confirmBool, reason) => confirmBool ? this.voidInvoice(reason) : this.setState({ ...this.state, voidInvoice: { showConfirmModal: false } })}
          handleClose={() => this.setState({ ...this.state, voidInvoice: { showConfirmModal: false } })}
          showReason
        />
      </div>
    );
  }
}

TrialSubscriptionsTable.propTypes = {
};

export default TrialSubscriptionsTable;

