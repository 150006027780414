import PropTypes from 'prop-types';
import React from 'react';
import history from 'sbHistory';
import LazyLoad from 'react-lazy-load';

import { Table } from 'react-bootstrap';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';

// Components
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import InvoiceRow from 'components/InvoicesTable/container/InvoiceRow';

// CSS
import styles from './InvoicesTable.module.scss';

class InvoicesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceArr: [],
      voidInvoice: {
        invoiceDataObj: {},
        showConfirmModal: false,
      },
      loading: false,
    };

    this.refreshInvoices = this.refreshInvoices.bind(this);
    this.getInvoices = this.getInvoices.bind(this);
    this.voidInvoice = this.voidInvoice.bind(this);
    this.showConfirmVoidInvoiceModal = this.showConfirmVoidInvoiceModal.bind(this);
  }

  componentDidMount() {
    this.refreshInvoices();
  }

  getInvoices(e, property, value) {
    if (e && ((e.key === 'Enter'))) {
      this.setState({ ...this.state, loading: true, invoiceArr: [] });
      if (!value) {
        this.refreshInvoices();
      } else {
        Getters.getInvoices(property, value).then((invoiceArr) => {
          this.setState({ ...this.state, loading: false, invoiceArr });
        });
      }
    }
  }

  refreshInvoices() {
    this.setState({ ...this.state, loading: true });
    Getters.getOutstandingInvoices().then((invoiceArr) => {
      const sortedInvoiceArr = invoiceArr.sort((a, b) => b.created - a.created);
      this.setState({ ...this.state, loading: false, invoiceArr: sortedInvoiceArr });
    });
  }

  showConfirmVoidInvoiceModal(invoiceDataObj) {
    this.setState({ ...this.state, voidInvoice: { showConfirmModal: true, invoiceDataObj } });
  }

  voidInvoice(reason) {
    this.setState({ ...this.state, voidInvoice: { ...this.state.voidInvoice, showConfirmModal: false } }, () => {
      Setters.sendVoidSlackMessage(
        this.state.voidInvoice.invoiceDataObj.companyName,
        this.state.voidInvoice.invoiceDataObj.invoiceDate,
        this.state.voidInvoice.invoiceDataObj.invoiceId,
        this.state.voidInvoice.invoiceDataObj.amountDue,
        reason,
      ).then(() => {
        this.setState({ ...this.state, voidInvoice: { ...this.state.voidInvoice, showConfirmModal: false, invoiceDataObj: {} } });
        // Setters.voidInvoice(this.state.accountInfo && this.state.accountInfo.subscriptionObj.id, this.state.voidInvoice.invoiceId).then(() => {
        // this.refreshInvoices();
      });
    });
  }

  render() {
    return (
      <div className={`defaultTable ${styles.invoicesTable}`}>
        <Table
          responsive
          hover
        >
          <thead>
            <tr>
              <th>Company</th>
              <th>Usage Info</th>
              <th>Billing Info</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.loading &&
              <tr className={styles.loadingIconNoHover}>
                <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
              </tr>
            }
            {!this.state.loading && this.state.invoiceArr.length === 0 &&
              <tr>
                <td colSpan="7" className="emptyRowMessage">No Invoices to Display</td>
              </tr>
            }
            {!this.state.loading && this.state.invoiceArr.length > 0 &&
              this.state.invoiceArr.map((invoiceObj) =>
                <InvoiceRow
                  key={invoiceObj.id}
                  invoiceObj={invoiceObj}
                  showConfirmVoidInvoiceModal={this.showConfirmVoidInvoiceModal}
                />
              )
            }
          </tbody>
        </Table>
        <ConfirmModal
          show={this.state.voidInvoice.showConfirmModal}
          handleModalChoice={(confirmBool, reason) => confirmBool ? this.voidInvoice(reason) : this.setState({ ...this.state, voidInvoice: { showConfirmModal: false } })}
          handleClose={() => this.setState({ ...this.state, voidInvoice: { showConfirmModal: false } })}
          showReason
        />
      </div>
    );
  }
}

InvoicesTable.propTypes = {
};

export default InvoicesTable;

