import PropTypes from 'prop-types';
import React from 'react';
import Parse from 'parse';
import axios from 'axios';
import { Modal } from 'react-bootstrap';

import * as ParseAPI from 'api/Parse';
import * as Auth from 'api/Auth';
import * as Setters from 'api/Setters';
import * as Helpers from 'api/Helpers';

import store from '../../../store';

// Components
import StartPaymentModal from '../view/StartPaymentModal';

// CSS
// import './PaymentModal.module.scss';

function checkAccountInfo(accountInfo) {
  const promise = new Promise((resolve, reject) => {
    let error = '';
    if ((!accountInfo.dotNumber && !accountInfo.nscNumber) || !accountInfo.companyName || !accountInfo.firstName || !accountInfo.lastName || !accountInfo.emailAddress || !accountInfo.password) {
      error = 'missing information';
    }
    const re = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!re.test(accountInfo.emailAddress)) {
      error = 'email format isn\'t correct';
    }
    if (accountInfo.emailAddress !== accountInfo.emailAddressConfirm) {
      error = 'email does not match';
    }
    if (accountInfo.password !== accountInfo.passwordConfirm) {
      error = 'password does not match';
    }
    // const activeSubscriptions = accountInfo.subscriptionPlans.filter((subscriptionPlan) => subscriptionPlan.active);
    // if (activeSubscriptions.length > 0) {
    //   const currency = activeSubscriptions[0].currency;
    //   for (let i = 0; i < activeSubscriptions.length; i++) {
    //     if (activeSubscriptions[i].currency !== currency) {
    //       error = 'plans can only be in one currency';
    //     }
    //   }
    // }
    if (!accountInfo.dotNumber && !accountInfo.nscNumber) {
      error = 'need dot or nsc number';
      reject(error);
    } else if (accountInfo.dotNumber) {
      Auth.callSecuredParseFunction('internal:getCompanyFromDOT', { returnAsParse: true, parseClassName: '', dotNumber: accountInfo.dotNumber }).then((companyArr) => {
        if (companyArr.length > 0) {
          error = 'dotNumber already exists';
        }
        if (error !== '') reject(error);
        resolve(true);
      });
    } else if (accountInfo.nscNumber) {
      Auth.callSecuredParseFunction('internal:getCompanyFromNSC', { returnAsParse: true, parseClassName: '', nscNumber: accountInfo.nscNumber }).then((companyArr) => {
        if (companyArr.length > 0) {
          error = 'nscNumber already exists';
        }
        if (error !== '') reject(error);
        resolve(true);
      });
    }
  });
  return promise;
}

function getInitialState() {
  const companyObj = new Parse.Object('Company');
  const subscriptionObj = new Parse.Object('Subscription');
  subscriptionObj.set('company', companyObj);
  return {
    showStripeCheckout: false,
    disableButton: false,
    loadingProgress: 0,
    error: '',
    accountInfo: {
      dotNumber: '',
      companyName: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      emailAddressConfirm: '',
      password: '',
      passwordConfirm: '',
      trialEnd: null,
      companyIdForSubscription: undefined,
      subscriptionPlans: [],
      isAOBRD: false,
    },
    subscriptionObj,
  };
}

class PaymentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.closeModal = this.closeModal.bind(this);
    this.checkAndRegisterAccount = this.checkAndRegisterAccount.bind(this);
    this.handleChangeInfo = this.handleChangeInfo.bind(this);
    // this.handleChangeSubscriptionPlan = this.handleChangeSubscriptionPlan.bind(this); // don't need when changing module
    this.addMailchimp = this.addMailchimp.bind(this);
    this.saveUpdatedSubscription = this.saveUpdatedSubscription.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.setState(getInitialState());
    }
  }

  closeModal() {
    this.setState({ ...this.state, paymentModalType: undefined });
  }

  checkAndRegisterAccount() {
    const { accountInfo } = this.state;
    this.setState({ ...this.state, disableButton: true, error: '' }, () => {
      checkAccountInfo(accountInfo).then(() => {
        this.setState({ ...this.state, loadingProgress: 10 });
        const user = new Parse.User();
        user.set('username', accountInfo.emailAddress.toLowerCase());
        user.set('password', accountInfo.password);
        user.signUp().then(parseUser => {
          this.setState({ ...this.state, loadingProgress: 20 });
          Auth.callSecuredParseFunction('registerNewCompanyAndUser', {
            ...accountInfo,
            enableCompanyLinks: this.state.subscriptionObj.get('company').get('enableCompanyLinks'),
            userId: parseUser.id
          }).then(updatedParseUser => {
            this.setState({ ...this.state, loadingProgress: 40 });
            this.addMailchimp();
            Auth.callSecuredParseFunction('createStripeCustomer', {
              email: accountInfo.emailAddress.toLowerCase(),
              companyName: accountInfo.companyName,
              companyObjectId: updatedParseUser.get('belongsToCompany').id,
            }).then(updatedSubscriptionObj => {
              this.setState({ ...this.state, loadingProgress: 70 });
              // Auth.callSecuredParseFunction('subscribeCustomerToPlans', {
              //   returnAsParse: true,
              //   customerId: updatedSubscriptionObj.get('customerId'),
              //   planItemArray: accountInfo.subscriptionPlans,
              //   trialEnd: accountInfo.trialEnd,
              //   companyObjectId: updatedParseUser.get('belongsToCompany').id,
              // }).then(() => {
              //   this.setState({ ...this.state, loadingProgress: 90 });
              //   ParseAPI.logout();
              //   this.props.hideModal();
              // }, error => this.setState({ ...this.state, disableButton: false, error: error.message }));
              this.saveUpdatedSubscription(updatedSubscriptionObj, updatedParseUser.get('belongsToCompany')).then(() => {
                this.setState({ ...this.state, loadingProgress: 90 });
                ParseAPI.logout();
                this.props.hideModal();
              });
            }, error => this.setState({ ...this.state, disableButton: false, error: error.message }));
          }, error => this.setState({ ...this.state, disableButton: false, error: error.message }));
        }, error => this.setState({ ...this.state, disableButton: false, error: error.message }));
      }, error => this.setState({ ...this.state, disableButton: false, error }));
    });
  }

  saveUpdatedSubscription(updatedSubscriptionObj, companyObj) {
    updatedSubscriptionObj.set('eldModule', this.state.subscriptionObj.get('eldModule'));
    updatedSubscriptionObj.set('iftaModule', this.state.subscriptionObj.get('iftaModule'));
    updatedSubscriptionObj.set('mapModule', this.state.subscriptionObj.get('mapModule'));
    updatedSubscriptionObj.set('driverBehaviourModule', this.state.subscriptionObj.get('driverBehaviourModule'));
    updatedSubscriptionObj.set('dispatchModule', this.state.subscriptionObj.get('dispatchModule'));
    updatedSubscriptionObj.set('startSubscription', !!this.state.subscriptionObj.get('startSubscription'));
    return Setters.setAOBRDForCompany(companyObj.id, this.state.accountInfo.isAOBRD).then(() => {
      return Setters.saveSubscriptionModules(updatedSubscriptionObj).then(() => {
        this.setState({ ...this.state, loadingProgress: 80 });
        return Setters.saveStartSubscription(updatedSubscriptionObj).then(() => {
          return;
        });
      });
    });
  }

  handleChangeInfo(property, value) {
    const newState = { ...this.state };
    newState.accountInfo[property] = value;
    this.setState(newState);
  }

  addMailchimp() {
    if (Helpers.getEnv == 'prod') {
      axios.post('/mailchimp/addSubscriber', {
        data: {
          listId: '4243258532',
          subscriber: {
            emailAddress: this.state.accountInfo.emailAddress,
            firstName: this.state.accountInfo.firstName,
            lastName: this.state.accountInfo.lastName,
          },
        },
      });
    }
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Register New Account</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <StartPaymentModal
              disableButton={this.state.disableButton}
              error={this.state.error}
              handleStart={() => this.checkAndRegisterAccount()}
              accountInfo={this.state.accountInfo}
              handleChangeInfo={this.handleChangeInfo}
              handleChangeSubscriptionPlan={this.handleChangeSubscriptionPlan}
              subscriptionPlanArr={this.state.subscriptionPlanArr}
              loadingProgress={this.state.loadingProgress}
              handleUpdateModule={(moduleProperty, value) => {
                if (this.state.subscriptionObj) {
                  this.state.subscriptionObj.set(moduleProperty, value);
                  this.setState(this.state);
                }
              }}
              isAOBRD={this.state.accountInfo.isAOBRD}
              handleChangeIsAOBRD={(e, isAOBRD) => {
                this.setState({ ...this.state, accountInfo: { ...this.state.accountInfo, isAOBRD } });
              }}
              subscriptionObj={this.state.subscriptionObj}
              handleChangeStartSubscription={(e, startSubscription) => {
                this.state.subscriptionObj.set('startSubscription', startSubscription); // for ui updating purposes
                this.setState(this.state);
              }}
              handleChangeEnableCompanyLinks={(e, enableCompanyLinksBool) => {
                this.state.subscriptionObj.get('company').set('enableCompanyLinks', enableCompanyLinksBool); // for ui updating purposes
                this.setState(this.state);
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

PaymentModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  Subscription: PropTypes.object.isRequired,
  subscriptionPlans: PropTypes.array,
  show: PropTypes.bool,
};

export default PaymentModal;
