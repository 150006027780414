/*
 * This file contains general getter functions useful throughout the app
 * Please list functions in alphabetical order and comment well unless
 * the function listing order depends on requisite functions (ex. define function before used)
 */

import Parse from 'parse';
import store from '../store';
import * as Helpers from './Helpers';
import * as Auth from '../api/Auth';

function destroyCompany(companyParseObj) {
  return Auth.callSecuredParseFunction('internal:destroyCompany', { returnAsParse: true, parseClassName: '', companyObjectId: companyParseObj.id });
}

function destroyDriver(driverParseObj) {
  return Auth.callSecuredParseFunction('internal:destroyDriver', { returnAsParse: true, parseClassName: '', driverObjectId: driverParseObj.id });
}

export {
  destroyCompany,
  destroyDriver,
};
