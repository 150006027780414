import axios from 'axios';
import * as ActionConstants from './ActionConstants';
import * as ParseAPI from 'api/Parse';
import Parse from 'parse';
import store from 'store';
import * as Auth from '../api/Auth';

import { initializeMapbox } from 'api/Mapbox';

function initializeStripe(storeObj) {
  Auth.callSecuredParseFunction('getStripeKey').then((response) => {
    storeObj.dispatch({ type: ActionConstants.INITIALIZE_STRIPE, stripePublishableKey: response.key });
  });
}

export function initializeParse() {
  const promise = new Promise((resolve, reject) => {
    const _configObj = {
      appId: 'vmK26CRUofZpUdlbk080PHZscMGhGqToMCaGeLCh',
      jsKey: 'VKPfQvd1oldMyhfrhQckRLg4tB85SPGcGQa3yid9',
      serverURL: 'https://sbdb-staging.onswitchboard.com/parse',
    };

    // const _configObj = {
    //   appId: 'vmK26CRUofZpUdlbk080PHZscMGhGqToMCaGeLCh',
    //   jsKey: 'VKPfQvd1oldMyhfrhQckRLg4tB85SPGcGQa3yid9',
    //   serverURL: 'http://localhost:1337/parse',
    // };

    // const _configObj = {
    //   appId: 'M3YQ673ktsl74AehJ5YHGWBw17oEMo6huIAOdv6m',
    //   jsKey: 'y6ALJWIegCceWt3SqVJLY80h8XTxaCjfVkCEw30l',
    //   serverURL: 'https://sbdb-dev.onswitchboard.com/parse',
    // };

    // const _configObj = {
    //   appId: 'M3YQ673ktsl74AehJ5YHGWBw17oEMo6huIAOdv6m',
    //   jsKey: 'y6ALJWIegCceWt3SqVJLY80h8XTxaCjfVkCEw30l',
    //   serverURL: 'http://localhost:1337/parse',
    // };

    ParseAPI.start(_configObj.appId, _configObj.jsKey, _configObj.serverURL);
    store.dispatch({ type: ActionConstants.INITIALIZE, serverURL: _configObj.serverURL });
    resolve();
  });
  return promise;
}

export function initializeServices(isProd) {
  // Set parse keys and initialize
  const promise = new Promise((resolve, reject) => {
    initializeStripe(store);
    initializeMapbox(store);
    resolve();
  });
  return promise;
}

export function checkUserGlobalsInState() {
  return new Promise((resolve) => {
    const checkStore = store.subscribe(() => {
      if (store.getState().Main.status === ActionConstants.FETCH_USER_GLOBALS_SUCCESS) {
        store.subscribe(checkStore); // unsubscribe
        resolve(true);
      }
    });
  });
}

export function clearState() {
  return {
    type: ActionConstants.CLEAR_STATE,
  };
}
