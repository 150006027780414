import PropTypes from 'prop-types';
import React from 'react';
import history from 'sbHistory';
import LazyLoad from 'react-lazy-load';

import { Table } from 'react-bootstrap';

// API
import * as Helpers from 'api/Helpers';
import * as Parse from 'api/Parse';
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';

// Actions
import * as Auth from 'actions/Auth';

// Components
import DriverDetailsModal from 'components/UsersTable/container/DriverDetailsModal';
import OfficeUserDetailsModal from 'components/UsersTable/container/OfficeUserDetailsModal';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import UserTableRow from 'components/UsersTable/container/UserTableRow';
import ResetPasswordModal from './ResetPasswordModal';
import ResellerModal from './ResellerModal';
import ChangeEmailModal from './ChangeEmailModal';

// CSS
import styles from './UsersTable.module.scss';

class UsersTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      processing: true,
      logInInProgress: false,
      showResetPasswordModal: false,
      showResellerModal: false,
      showDriverDetailsModal: false,
      showOfficeUserDetailsModal: false,
      showChangeEmailModal: false,
      creatingSupportReport: false,
    };
    this.nameSort = this.nameSort.bind(this);
    this.logInAsCompany = this.logInAsCompany.bind(this);
    this.showResetPasswordModal = this.showResetPasswordModal.bind(this);
    this.hideResetPasswordModal = this.hideResetPasswordModal.bind(this);
    this.showChangeEmailModal = this.showChangeEmailModal.bind(this);
    this.hideChangeEmailModal = this.hideChangeEmailModal.bind(this);
    this.showDriverDetailsModal = this.showDriverDetailsModal.bind(this);
    this.hideDriverDetailsModal = this.hideDriverDetailsModal.bind(this);
    this.showOfficeUserDetailsModal = this.showOfficeUserDetailsModal.bind(this);
    this.hideOfficeUserDetailsModal = this.hideOfficeUserDetailsModal.bind(this);
    this.showResellerModal = this.showResellerModal.bind(this);
    this.hideResellerModal = this.hideResellerModal.bind(this);
    this.createSupportReport = this.createSupportReport.bind(this);
  }

  showDriverDetailsModal(user) {
    this.setState({ ...this.state, selectedUser: user, showDriverDetailsModal: true });
  }

  hideDriverDetailsModal() {
    this.setState({ ...this.state, selectedUser: undefined, showDriverDetailsModal: false });
  }

  showOfficeUserDetailsModal(user) {
    this.setState({ ...this.state, selectedUser: user, showOfficeUserDetailsModal: true });
  }

  hideOfficeUserDetailsModal() {
    this.setState({ ...this.state, selectedUser: undefined, showOfficeUserDetailsModal: false });
  }

  showChangeEmailModal(user) {
    this.setState({ ...this.state, editUser: user, showChangeEmailModal: true });
  }

  hideChangeEmailModal() {
    this.setState({ ...this.state, editUser: undefined, showChangeEmailModal: false });
  }

  showResetPasswordModal(user) {
    this.setState({ ...this.state, editUser: user, showResetPasswordModal: true });
  }

  hideResetPasswordModal() {
    this.setState({ ...this.state, editUser: undefined, showResetPasswordModal: false });
  }

  showResellerModal(user) {
    this.setState({ ...this.state, editUser: user, showResellerModal: true });
  }

  hideResellerModal() {
    this.setState({ ...this.state, editUser: undefined, showResellerModal: false });
  }

  logInAsCompany(userId) {
    this.setState({ ...this.state, logInInProgress: true }, () => {
      Getters.getSessionToken(userId).then((token) => {
        this.setState({ ...this.state, logInInProgress: false });
        console.log(`https://app-support.onswitchboard.com/login?auth=${token}&user=${Parse.getCurrentUser() && Parse.getCurrentUser().id}&id=${userId}`);
        window.open(`https://app-support.onswitchboard.com/login?auth=${token}&user=${Parse.getCurrentUser() && Parse.getCurrentUser().id}&id=${userId}`, '_blank');
      }, (error) => {
        this.setState({ ...this.state, logInInProgress: false });
        console.log('error');
        console.log(error);
      });
    });
  }

  createSupportReport(userObj) {
    this.setState({ ...this.state, creatingSupportReport: true });
    Setters.createSupportReport(userObj).then((supportReportObj) => {
      history.push({ pathname: 'supportReport', query: { companyId: supportReportObj.get('company').id } });
    });
  }

  nameSort(user, nextUser) {
    const comparatorA = user.name.toLowerCase();
    const comparatorB = nextUser.name.toLowerCase();
    if (comparatorA > comparatorB) {
      return 1;
    } else if (comparatorA < comparatorB) {
      return -1;
    }
    return 0;
  }

  render() {
    return (
      <div className={`defaultTable ${styles.table}`}>
        <Table hover>
          <thead>
            <tr>
              <th>User</th>
              <th>Contact Info</th>
              <th>Company</th>
              <th>Driver Details</th>
              <th />
              {
                // <th>Admin</th>
                // <th>Number of Users</th>
              }
            </tr>
          </thead>
          <tbody>
            {!this.props.fetched &&
              <tr className={styles.loadingIconNoHover}>
                <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
              </tr>
            }
            {this.props.fetched && this.props.users.length === 0 &&
              <tr>
                <td colSpan="7" className="emptyRowMessage">No Users to Display</td>
              </tr>
            }
            {this.props.fetched && this.props.users.length > 0 &&
              this.props.users.map((user) => {
                let resellerCompanyName = '';
                if (user && user.get('belongsToCompany') && user.get('belongsToCompany').get('promoCode')) {
                  for (let i = 0; i < this.props.resellerCompanyArr.length; i++) {
                    const resellerCompanyObj = this.props.resellerCompanyArr[i];
                    if (resellerCompanyObj.get('promoCode') === user.get('belongsToCompany').get('promoCode')) {
                      resellerCompanyName = resellerCompanyObj.get('name');
                    }
                  }
                }
                return (
                  <UserTableRow
                    key={user.id}
                    user={user}
                    resellerCompanyName={resellerCompanyName}
                    logInInProgress={this.state.logInInProgress}
                    logInAsCompany={this.logInAsCompany}
                    showDriverDetailsModal={this.showDriverDetailsModal}
                    showOfficeUserDetailsModal={this.showOfficeUserDetailsModal}
                    createSupportReport={this.createSupportReport}
                    creatingSupportReport={this.state.creatingSupportReport}
                  />
                );
              }
              )
            }
          </tbody>
        </Table>
        <ResetPasswordModal
          user={this.state.editUser}
          hideModal={this.hideResetPasswordModal}
          show={this.state.showResetPasswordModal}
        />
        <ChangeEmailModal
          user={this.state.editUser}
          hideModal={this.hideChangeEmailModal}
          show={this.state.showChangeEmailModal}
        />
        <ResellerModal
          company={this.state.editUser?.get('belongsToCompany')}
          user={this.state.editUser}
          hideModal={this.hideResellerModal}
          show={this.state.showResellerModal}
        />
        <DriverDetailsModal
          user={this.state.selectedUser}
          hideModal={this.hideDriverDetailsModal}
          show={this.state.showDriverDetailsModal}
          showResetPasswordModal={this.showResetPasswordModal}
        />
        <OfficeUserDetailsModal
          user={this.state.selectedUser}
          hideModal={this.hideOfficeUserDetailsModal}
          show={this.state.showOfficeUserDetailsModal}
          showResetPasswordModal={this.showResetPasswordModal}
          showResellerModal={this.showResellerModal}
          showChangeEmailModal={this.showChangeEmailModal}
        />
      </div>
    );
  }
}

UsersTable.propTypes = {
  resellerCompanyArr: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  fetched: PropTypes.bool.isRequired,
};

export default UsersTable;
