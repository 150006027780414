import PropTypes from 'prop-types';
import React from 'react';
import history from 'sbHistory';
import LazyLoad from 'react-lazy-load';

import { Table } from 'react-bootstrap';

// API
import * as Helpers from 'api/Helpers';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import SubscriptionsRow from 'components/SubscriptionsTable/container/SubscriptionsRow';

// CSS
import styles from 'components/SubscriptionsTable/container/SubscriptionsTable.module.scss';

class SubscriptionsTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      processing: true,
    };
  }

  render() {
    return (
      <div className="defaultTable">
        <Table responsive hover>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Company / DOT Number</th>
              <th>Modules</th>
              <th>Billing</th>
              <th>Active Count</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {!this.props.fetched &&
              <tr className={styles.loadingIconNoHover}>
                <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
              </tr>
            }
            {this.props.fetched && this.props.subscriptions.length === 0 &&
              <tr>
                <td colSpan="7" className="emptyRowMessage">No Subscriptions with at least one driver</td>
              </tr>
            }
            {this.props.fetched && this.props.subscriptions.length > 0 &&
              this.props.subscriptions.map((subscription) =>
                <SubscriptionsRow
                  subscription={subscription}
                  editSubscription={this.props.editSubscription}
                  hideUnhideCompany={this.props.hideUnhideCompany}
                  key={subscription.id}
                />
              )
            }
          </tbody>
        </Table>
      </div>
    );
  }
}

SubscriptionsTable.propTypes = {
  editSubscription: PropTypes.func.isRequired,
  subscriptions: PropTypes.array.isRequired,
  fetched: PropTypes.bool,
};

export default SubscriptionsTable;
