import React from 'react';
import {
  DatePicker,
  Toggle,
  SelectField,
  TextField,
  MenuItem,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableRowColumn,
} from 'material-ui';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Line } from 'rc-progress';

// CSS
import styles from './StartPaymentModal.module.scss';

// Components
import DeleteXButton from 'components/DeleteXButton/container/DeleteXButton';
import Modules from './Modules';

function StartPaymentModal(props) {
  return (
    <div>
      <div className={styles.monthlyFee}>
        <div className={styles.options}>
          <TextField
            floatingLabelText="DOT #"
            onChange={(event, newValue) => props.handleChangeInfo('dotNumber', newValue)}
            value={props.accountInfo.dotNumber}
          />
          <TextField
            floatingLabelText="NSC #"
            onChange={(event, newValue) => props.handleChangeInfo('nscNumber', newValue)}
            value={props.accountInfo.nscNumber}
          />
          <br />
          <TextField
            floatingLabelText="Company Name"
            onChange={(event, newValue) => props.handleChangeInfo('companyName', newValue)}
            value={props.accountInfo.companyName}
          />
          <br />
          <TextField
            floatingLabelText="First Name"
            onChange={(event, newValue) => props.handleChangeInfo('firstName', newValue)}
            value={props.accountInfo.firstName}
          />
          <br />
          <TextField
            floatingLabelText="Last Name"
            onChange={(event, newValue) => props.handleChangeInfo('lastName', newValue)}
            value={props.accountInfo.lastName}
          />
          <br />
          <TextField
            floatingLabelText="Email Address"
            onChange={(event, newValue) => props.handleChangeInfo('emailAddress', newValue)}
            value={props.accountInfo.emailAddress}
          />
          <TextField
            floatingLabelText="Re-Enter Email"
            onChange={(event, newValue) => props.handleChangeInfo('emailAddressConfirm', newValue)}
            value={props.accountInfo.emailAddressConfirm}
          />
          <br />
          <TextField
            floatingLabelText="Password"
            onChange={(event, newValue) => props.handleChangeInfo('password', newValue)}
            type="password"
            value={props.accountInfo.password}
          />
          <TextField
            floatingLabelText="Confirm Password"
            onChange={(event, newValue) => props.handleChangeInfo('passwordConfirm', newValue)}
            type="password"
            value={props.accountInfo.passwordConfirm}
          />
          <br />
          <br />
          {/* <Table
            selectable={false}
            multiSelectable={false}
          >
            <TableHeader
              displaySelectAll={false}
              adjustForCheckbox={false}
              enableSelectAll={false}
            ><TableRow>
              <TableRowColumn>Plan</TableRowColumn>
              <TableRowColumn>Enable</TableRowColumn>
              <TableRowColumn>Quantity</TableRowColumn>
            </TableRow></TableHeader>

            <TableBody
              displayRowCheckbox={false}
            >
              { props.accountInfo.subscriptionPlans && props.accountInfo.subscriptionPlans.map((subscriptionPlan) => (
                <TableRow key={subscriptionPlan.stripePlanId}>
                  <TableRowColumn>{ subscriptionPlan.name }</TableRowColumn>
                  <TableRowColumn>
                    <Toggle
                      onToggle={(event, newValue) => props.handleChangeSubscriptionPlan(subscriptionPlan.stripePlanId, 'active', newValue)}
                      toggled={subscriptionPlan.active}
                    />
                  </TableRowColumn>
                  <TableRowColumn>
                    <TextField
                      id={`${subscriptionPlan.stripePlanId}Quantity`}
                      onChange={(event, newValue) => props.handleChangeSubscriptionPlan(subscriptionPlan.stripePlanId, 'quantity', parseInt(newValue, 10))}
                      value={subscriptionPlan.quantity}
                    />
                  </TableRowColumn>
                </TableRow>
              ))}
            </TableBody>


          </Table> */}
          <Modules
            subscriptionObj={props.subscriptionObj}
            handleChangeSubscriptionObj={props.handleUpdateModule}
            isAOBRD={props.accountInfo.isAOBRD}
            handleChangeIsAOBRD={props.handleChangeIsAOBRD}
            handleChangeStartSubscription={props.handleChangeStartSubscription}
            handleChangeEnableCompanyLinks={props.handleChangeEnableCompanyLinks}
          />
          {props.subscriptionObj.get('startSubscription') &&
            <DatePicker
              autoOk
              floatingLabelText="Billing Date"
              onChange={(event, newValue) => props.handleChangeInfo('trialEnd', newValue)}
              value={moment(props.accountInfo.trialEnd).toDate()}
            />
          }
          <br />
          <br />
          {props.error &&
            <div className={styles.error}>
              {props.error}
            </div>
          }
          <br />
          <button className={`${styles.startButton} buttonDefault`} onClick={props.handleStart} disabled={props.disableButton}>Register</button>
          {props.disableButton &&
            <div style={{ margin: '2em' }}>
              <Line
                percent={props.loadingProgress}
                strokeWidth="1"
                trailWidth="1"
                strokeColor="#56d8d8"
                trailColor="#D3D3D3"
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

StartPaymentModal.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  handleChangeInfo: PropTypes.func.isRequired,
  handleStart: PropTypes.func.isRequired,
  disableButton: PropTypes.bool.isRequired,
  loadingProgress: PropTypes.number,
};

export default StartPaymentModal;
