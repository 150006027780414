/*
 * This file contains general getter functions useful throughout the app
 * Please list functions in alphabetical order and comment well unless
 * the function listing order depends on requisite functions (ex. define function before used)
 */

import Parse from 'parse';
import { getPointer } from 'api/Parse';
import store from '../store';
import * as Helpers from './Helpers';
import * as Auth from './Auth';

function getActiveSubscriptions() {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('internal:getActiveSubscriptions', { returnAsParse: true, parseClassName: 'Subscription' }).then(
      subscriptionParseObjArr => resolve(subscriptionParseObjArr),
      error => reject(error),
    );
  });
  return promise;
}

const getAllPT30Firmware = async () => {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('support:getAllPT30Firmware', { returnAsParse: true, parseClassName: 'PT30Firmware' }).then(
      pt30FirmwareArr => resolve(pt30FirmwareArr),
      error => reject(error),
    );
  });
  return promise;
}

function getSubscriptionsWithIfta() {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('internal:getSubscriptionsWithIfta', { returnAsParse: true, parseClassName: 'Subscription' }).then(
      subscriptionParseObjArr => resolve(subscriptionParseObjArr),
      error => reject(error),
    );
  });
  return promise;
}

function getCompanyByName(companyName) {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('internal:getCompanyFromName', { returnAsParse: true, parseClassName: 'Company', companyName }).then(
      companyParseObjArr => {
        console.log(companyParseObjArr);
        resolve(companyParseObjArr);
      }
    ).then((error) => {
      console.log(error);
    });
  });
  return promise;
}

function getDriverByUserId(userId) {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('internal:getDriverByUserId', { returnAsParse: true, parseClassName: 'Driver', userId }).then(
      driverParseObjectArr => resolve(driverParseObjectArr),
      err => reject(err)
    );
  });
  return promise;
}

function getDriverModuleByUserId(driverId) {
  return Auth.callSecuredParseFunction('_internal:getDriverModuleByUserId', { returnAsParse: true, parseClassName: 'DriverModule', driverId });
}

function getDriversByCompany(companyId) {
  return Auth.callSecuredParseFunction('_internal:getDriversByCompany', { returnAsParse: true, parseClassName: 'Driver', companyId }).then((driverArr) => {
    const activeDriverArr = driverArr.filter((obj) => !obj.get('isHidden'));
    return activeDriverArr;
  });
}

function getDriverByIMEI(imei) {
  return Auth.callSecuredParseFunction('_internal:getDriverByIMEI', { returnAsParse: true, parseClassName: 'Driver', imei }).then((driverObj) => {
    return driverObj;
  });
}

function getVehiclesByCompany(companyId) {
  return Auth.callSecuredParseFunction('_internal:getVehiclesByCompany', { returnAsParse: true, parseClassName: 'Vehicle', companyId }).then((vehicleArr) => {
    return vehicleArr;
  });
}

function getVehicleActivityForDateRange(vehicleUnitId, companyId, startDate, endDate) {
  return Auth.callSecuredParseFunction('_internal:getVehicleActivityForDateRange', { vehicleUnitId, companyId, startDate, endDate }).then((vehicleActivityObj) => {
    return vehicleActivityObj;
  });
}

function getEldDailyCertificationsForDriver(driverId) {
  return Auth.callSecuredParseFunction('_internal:getEldDailyCertificationsForDriver', {
    returnAsParse: true,
    parseClassName: 'ELDDailyCertification',
    driverId,
  });
}

function getELDHardwareAndELDEventByCompany(companyId, loosenDateLimit) {
  return Auth.callSecuredParseFunction('_internal:getActiveEldHardwareForCompany',
    { returnAsParse: true, parseClassName: 'ELDHardware', companyId, loosenDateLimit, returnLatestEldEventBool: true }
  );
}

function getUsersByProperty(value, property, hideDisabled) {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('internal:getUserByProperty', { returnAsParse: true, parseClassName: '_User', property, value, hideDisabled }).then(
      userParseObjArr => {
        resolve(userParseObjArr);
      },
      err => {
        reject(err);
      }
    );
  });
  return promise;
}

function getOutdatedUsers(type) {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('internal:getOutdatedUsers', { returnAsParse: true, parseClassName: '_User', type }).then(
      userParseObjArr => {
        resolve(userParseObjArr);
      },
      err => {
        console.log(err);
        reject(err);
      }
    );
  });
  return promise;
}

function getDelayedUploadUsers(eldSoftwareVersion) {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('_internal:getDriversWithDelayedUpload', { returnAsParse: true, parseClassName: '_User', eldSoftwareVersion }).then(
      userParseObjArr => {
        resolve(userParseObjArr);
      },
      err => {
        console.log(err);
        reject(err);
      }
    );
  });
  return promise;
}

function checkStripeSubscription(customerId, parseSubscriptionId) {
  return Auth.callSecuredParseFunction('checkSubscription', {
    customerId,
    parseSubscriptionId,
  });
}

function getSubscriptionPlans() {
  return Auth.callSecuredParseFunction('internal:getSubscriptionPlans');
}

function getStripeProduct(productId) {
  return Auth.callSecuredParseFunction('internal:getStripeProduct', { productId });
}

async function getSubscriptionObjCountAndArr(property, value, skipInt, getMismatchesBool) {
  const subscriptionObjCountAndArr = await Auth.callSecuredParseFunction('internal:getSubscriptionObjCountAndArr', {
    returnAsParse: true,
    parseClassName: 'Subscription',
    property,
    value,
    skipInt,
    getMismatchesBool,
    limit: 20,
  });

  subscriptionObjCountAndArr.subscriptionParseObjectArr = subscriptionObjCountAndArr.subscriptionParseObjectArr.map((_obj, index) => {
    return Parse.Object.fromJSON(Object.assign({ className: 'Subscription' }, _obj));
  });

  return subscriptionObjCountAndArr;
}

function getSubscriptionObjByObjectId(objectId) {
  return Auth.callSecuredParseFunction('_internal:getSubscriptionObjByObjectId', {
    returnAsParse: true,
    parseClassName: 'Subscription',
    objectId,
  });
}

function getSubscriptionObjByCustomerId(customerId) {
  return Auth.callSecuredParseFunction('_internal:getSubscriptionObjByCustomerId', {
    returnAsParse: true,
    parseClassName: 'Subscription',
    customerId,
  });
}

function getSubscriptionObjBySubscriptionId(subscriptionId) {
  return Auth.callSecuredParseFunction('_internal:getSubscriptionObjBySubscriptionId', {
    returnAsParse: true,
    parseClassName: 'Subscription',
    subscriptionId,
  });
}

function getCustomerSubscriptions(subscriptionId) {
  if (!subscriptionId) {
    return [];
  } else {
    return Auth.callSecuredParseFunction('getCustomerSubscriptions', {
      switchboardSubscriptionId: subscriptionId,
    });
  }
}

function getCustomerSubscriptionDate(subscriptionId) {
  return Auth.callSecuredParseFunction('getCustomerSubscriptionDate', {
    switchboardSubscriptionId: subscriptionId,
  });
}

function getCustomerInvoices(subscriptionId, lastInvoiceId) {
  return Auth.callSecuredParseFunction('getCustomerInvoices', {
    switchboardSubscriptionId: subscriptionId, paginationInvoiceId: lastInvoiceId,
  });
}

function getUpcomingInvoiceItems(subscriptionId) {
  return Auth.callSecuredParseFunction('getUpcomingInvoiceItems', {
    switchboardSubscriptionId: subscriptionId,
  });
}

function getCustomerLastFourCC(subscriptionId) {
  return Auth.callSecuredParseFunction('getCustomerLastFourCC', {
    switchboardSubscriptionId: subscriptionId,
  });
}

function getCustomerBillingInfo(subscriptionId) {
  return Auth.callSecuredParseFunction('getCustomerBillingInfo', {
    switchboardSubscriptionId: subscriptionId,
  });
}

function getCustomerAccountBalance(subscriptionId) {
  return Auth.callSecuredParseFunction('getCustomerAccountBalance', {
    switchboardSubscriptionId: subscriptionId,
  });
}

function getCustomerSubscriptionContracts(subscriptionId) {
  return Auth.callSecuredParseFunction('_internal:getCustomerSubscriptionContracts', {
    returnAsParse: true,
    parseClassName: 'SubscriptionContract',
    switchboardSubscriptionId: subscriptionId,
  });
}

function getSessionToken(userId) {
  return Auth.callSecuredParseFunction('internal:getUserSessionToken', { userId });
}

function getCompanySims(companyId) {
  return Auth.callSecuredParseFunction('internal:getCompanySims', { returnAsParse: true, parseClassName: 'Sim', companyId });
}

function fetchCompanyVehiclesForState(companyName) {
  return Auth.callSecuredParseFunction('_internal:fetchCompanyVehiclesForState', { returnAsParse: true, parseClassName: 'Vehicle', companyName });
}

function getVehicleLastActiveDate(vehicle) {
  return Auth.callSecuredParseFunction('_internal:getVehicleLastActiveDate', { vehicleId: vehicle.id });
}

function getOdometersForVehicle(vehicle, date) {
  return Auth.callSecuredParseFunction('_internal:getOdometersForVehicle', { vehicleId: vehicle.id, date });
}

function getOdometersForDriver(driver, date) {
  return Auth.callSecuredParseFunction('_internal:getOdometersForDriver', { driverId: driver.id, date });
}

function getEldEventsForDriver(driver, date) {
  return Auth.callSecuredParseFunction('_internal:getEldEventsForDriver', { returnAsParse: true, parseClassName: 'ELDEvent', driverId: driver.id, date });
}

function getAndRefreshSimsForCompany(company) {
  return Auth.callSecuredParseFunction('_internal:getAndRefreshSimsForCompany', { returnAsParse: true, parseClassName: 'Sim', companyId: company.id });
}

function checkActiveCountBillingMatchBool(companyId) {
  return Auth.callSecuredParseFunction('_internal:checkActiveCountBillingMatchBool', { companyId });
}

function getVehicles(filter, showDisabled, showHidden) {
  return Auth.callSecuredParseFunction('_internal:getVehicles', { returnAsParse: true, parseClassName: 'Vehicle', filter, showDisabled, showHidden });
}

function getTrailers(filter, showDisabled, showHidden) {
  return Auth.callSecuredParseFunction('_internal:getTrailers', { returnAsParse: true, parseClassName: 'Trailer', filter, showDisabled, showHidden });
}

function getEldHardwareForCompany(company) {
  return Auth.callSecuredParseFunction('_internal:getEldHardwareForCompany', { returnAsParse: true, parseClassName: 'ELDHardware', companyId: company.id });
}

function getActiveEldHardwareForCompany(company) {
  return Auth.callSecuredParseFunction('_internal:getActiveEldHardwareForCompany', { returnAsParse: true, parseClassName: 'ELDHardware', companyId: company.id });
}

function checkAndGetActiveVehiclesCountObjForCompany(company) {
  return Auth.callSecuredParseFunction('_internal:checkAndGetActiveVehiclesCountObjForCompany', { companyId: company.id });
}

function getLocalGeocode(longitude, latitude) {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('localReverseGeocode', { coordinatesArray: [[latitude, longitude]] }).then((reverseGeocodeArr) => {
      if (reverseGeocodeArr && reverseGeocodeArr.length > 0) {
        resolve(reverseGeocodeArr[0]);
      }
      resolve(null);
    });
  });
  return promise;
}

function getSubscriptionCustomPlans() {
  return Auth.callSecuredParseFunction('_internal:getSubscriptionCustomPlans');
}

function getSubscriptionResellerPlanAllowances(subscriptionId) {
  return Auth.callSecuredParseFunction('_internal:getSubscriptionResellerPlanAllowances', { subscriptionId });
}

function getSimArr(valueType, value) {
  return Auth.callSecuredParseFunction('_internal:getSimArr', { returnAsParse: true, parseClassName: 'Sim', valueType, value });
}

function getSimUsageRecordsForMonth(date, iccid) {
  if (!iccid) return null;
  return Auth.callSecuredParseFunction('_internal:getSimUsageRecordsForMonth', { date, iccid });
}

function getSimsForPlanChange(companyName) {
  return Auth.callSecuredParseFunction('_internal:getSimsForPlanChange', { returnAsParse: true, parseClassName: 'Sim', companyName });
}

function getOutstandingInvoices() {
  return Auth.callSecuredParseFunction('_internal:getOutstandingInvoices', { returnAsParse: true });
}

function getInvoices(property, value) {
  return Auth.callSecuredParseFunction('_internal:getInvoices', { property, value });
}

function getExpiredSubscriptionsCustomers(property, value) {
  return Auth.callSecuredParseFunction('_internal:getExpiredSubscriptionsCustomers', { property, value });
}

function getTrialSubscriptionsCustomers(property, value) {
  return Auth.callSecuredParseFunction('_internal:getTrialSubscriptionsCustomers', { property, value });
}

function getIncorrectlyBilledSubscriptions() {
  return Auth.callSecuredParseFunction('internal:getIncorrectlyBilledSubscriptions', {
    returnAsParse: true,
    parseClassName: 'Subscription',
  });
}

function getSupportReports(filterArr) {
  return Auth.callSecuredParseFunction('_internal:querySupportReport', { returnAsParse: true, parseClassName: 'SupportReport', filterArr });
}

function getResellerCompanies(includeHiddenBool) {
  return Auth.callSecuredParseFunction('_internal:queryResellerCompany', { returnAsParse: true, parseClassName: 'ResellerCompany', includeHiddenBool });
}

function fetchOdometerReadingIssueVehicles() {
  return Auth.callSecuredParseFunction('_internal:fetchOdometerReadingIssueVehicles', { returnAsParse: true, parseClassName: 'Vehicle' });
}

function fetchOdometerReadingIssueDrivers() {
  return Auth.callSecuredParseFunction('_internal:fetchOdometerReadingIssueDrivers', { returnAsParse: true, parseClassName: 'Driver' });
}

function getTripInspectionsForDriver(driver, date) {
  return Auth.callSecuredParseFunction('_internal:getTripInspectionsForDriver', { returnAsParse: true, parseClassName: 'TripInspection', driverId: driver.id, date });
}

function getVendorIntegrationFromCompanyId(companyId) {
  return Auth.callSecuredParseFunction('internal:getVendorIntegrationFromCompanyId', { returnAsParse: true, parseClassName: 'VendorIntegration', companyId });
}

const getVendorIntegrationUserFromUserId = (userId) => {
  return Auth.callSecuredParseFunction('internal:getVendorIntegrationUser', { returnAsParse: true, parseClassName: 'VendorIntegration', userId });
}

function getReseller(userId) {
  return Auth.callSecuredParseFunction('_internal:getReseller', { returnAsParse: true, parseClassName: 'Reseller', userId });
}

const getTrailersByCompany = (companyId) => {
  return Auth.callSecuredParseFunction('_internal:getTrailersByCompany', { returnAsParse: true, parseClassName: 'Trailer', companyId });
}

const getEquipmentWithInactiveDashcam = (companyId) => {
  return Auth.callSecuredParseFunction('_internal:getEquipmentWithInactiveDashcam', { returnAsParse: true, parseClassName: 'Vehicle' });
}

const getVehicleLocationArrForVehicle = (vehicleId, dateStart, dateEnd) => {
  return Auth.callSecuredParseFunction('_internal:getVehicleLocationArrForVehicle', { vehicleId, dateStart, dateEnd, returnAsParse: true, parseClassName: 'VehicleLocation' });
}

export {
  getActiveSubscriptions,
  getAllPT30Firmware,
  getSubscriptionsWithIfta,
  getCompanyByName,
  getDriverByUserId,
  getDriverModuleByUserId,
  getDriversByCompany,
  getDriverByIMEI,
  getVehiclesByCompany,
  getVehicleActivityForDateRange,
  getEldDailyCertificationsForDriver,
  getELDHardwareAndELDEventByCompany,
  getUsersByProperty,
  getOutdatedUsers,
  getDelayedUploadUsers,
  getSubscriptionPlans,
  getStripeProduct,
  getSubscriptionObjCountAndArr,
  getSubscriptionObjByObjectId,
  getSubscriptionObjByCustomerId,
  getSubscriptionObjBySubscriptionId,
  getCustomerSubscriptions,
  getCustomerSubscriptionDate,
  getCustomerInvoices,
  getCustomerBillingInfo,
  getCustomerLastFourCC,
  getCustomerAccountBalance,
  getUpcomingInvoiceItems,
  getCustomerSubscriptionContracts,
  getSessionToken,
  getCompanySims,
  getVehicles,
  getTrailers,
  fetchCompanyVehiclesForState,
  getVehicleLastActiveDate,
  getOdometersForVehicle,
  getOdometersForDriver,
  getEldEventsForDriver,
  getAndRefreshSimsForCompany,
  checkActiveCountBillingMatchBool,
  getActiveEldHardwareForCompany,
  checkAndGetActiveVehiclesCountObjForCompany,
  getEldHardwareForCompany,
  getLocalGeocode,
  getSubscriptionCustomPlans,
  getSubscriptionResellerPlanAllowances,
  getSimArr,
  getSimUsageRecordsForMonth,
  getSimsForPlanChange,
  getOutstandingInvoices,
  getInvoices,
  getExpiredSubscriptionsCustomers,
  getTrialSubscriptionsCustomers,
  getIncorrectlyBilledSubscriptions,
  getSupportReports,
  getResellerCompanies,
  checkStripeSubscription,
  fetchOdometerReadingIssueVehicles,
  fetchOdometerReadingIssueDrivers,
  getTripInspectionsForDriver,
  getVendorIntegrationFromCompanyId,
  getVendorIntegrationUserFromUserId,
  getVehicleLocationArrForVehicle,
  getReseller,
  getTrailersByCompany,
  getEquipmentWithInactiveDashcam,
};
