import React from 'react';
import Parse from 'parse';
import moment from 'moment';
import store from '../../store';

// API
import * as Auth from 'api/Auth';
import * as Setters from 'api/Setters';
import * as Getters from 'api/Getters';
import * as Sim from 'api/Sim';
import * as Helpers from 'api/Helpers';

// Components
import Title from 'components/LayoutTitle/view/Title';

class ScriptsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
      testInput: '',
      savingELDHardwareTargetSoftwareVersion: false,
      pubnub: {
        channel: '',
        type: '',
        sending: false,
      },
    };
    this.runScript = this.runScript.bind(this);
    this.runTestScript = this.runTestScript.bind(this);
    this.saveELDHardwareTargetSoftwareVersion = this.saveELDHardwareTargetSoftwareVersion.bind(this);
    this.sendPubNubMessage = this.sendPubNubMessage.bind(this);
    this.downloadAllDrivewyzeVehicles = this.downloadAllDrivewyzeVehicles.bind(this);
    this.refreshBillingCount = this.refreshBillingCount.bind(this);
  }

  saveELDHardwareTargetSoftwareVersion() {
    this.setState({ ...this.state, savingELDHardwareTargetSoftwareVersion: true });
    Setters.saveELDHardwareTargetSoftwareVersion(null, parseInt(this.state.eldHardwareTargetSoftwareVersion, 10), true).then(() => {
      this.setState({ ...this.state, savingELDHardwareTargetSoftwareVersion: false });
    });
  }

  sendPubNubMessage() {
    this.setState({ ...this.state, pubnub: { ...this.state.pubnub, sending: true } });
    Auth.callSecuredParseFunction('internal:sendPubNubMessage', {
      returnAsParse: true,
      channel: this.state.pubnub.channel,
      messageObj: { type: this.state.pubnub.type },
    }).then(() => {
      this.setState({ ...this.state, pubnub: { ...this.state.pubnub, sending: false } });
    });
  }

  downloadAllDrivewyzeVehicles() {
    this.setState({ ...this.state });
    Auth.callSecuredParseFunction('_internal:downloadAllDrivewyzeVehicles', {
      returnAsParse: true,
      parseClassName: 'WeighStationBypassVehicle',
    }).then((weighStationBypassVehicleArr) => {
      const lineArray = ['Vehicle,Company'];

      const companyNameArr = [];
      const wsbVehiclesCountMap = {}; // { companyName<string>:enabledWsbVehicleCount<int> }
      for (let i = 0; i < weighStationBypassVehicleArr.length; i++) {
        const obj = weighStationBypassVehicleArr[i];
        const vehicle = obj.get('vehicle').get('unitId');
        const companyName = obj.get('belongsToCompany').get('name').replace(/,/g, '');
        if (companyNameArr.indexOf(companyName) !== -1) {
          wsbVehiclesCountMap[companyName] += 1;
        } else {
          companyNameArr.push(companyName);
          wsbVehiclesCountMap[companyName] = 1;
        }
      }

      const companyNameKeyArr = Object.keys(wsbVehiclesCountMap);
      for (let i = 0; i < companyNameKeyArr.length; i++) {
        const companyName = companyNameKeyArr[i];
        console.log(companyName, wsbVehiclesCountMap[companyName])

        lineArray.push([companyName, wsbVehiclesCountMap[companyName]].join(','));
      }

      console.log(lineArray);
      const csvContent = lineArray.join('\n');
      console.log(csvContent);
      Helpers.createCsvFile('Weigh Station Bypass Vehicles - ' + moment().format('YYYY-MM-DD'), csvContent, true);
      this.setState({ ...this.state });
    });
  }

  refreshBillingCount() {
    Auth.callSecuredParseFunction('internal:refreshBillingCountForSubscriptions', {
      returnAsParse: true,
    }).then((asdf) => {
      console.log('doing internal:refreshBillingCountForSubscriptions');
      console.log(asdf);
    }, (error) => {
      console.log('error');
      console.log(error);
    });
  }

  runFileUploadScript(file) {
    // function arrayBufferToBase64(buffer) {
    //   var binary = '';
    //   var bytes = new Uint8Array(buffer);
    //   var len = bytes.byteLength;
    //   for (var i = 0; i < len; i++) {
    //     binary += String.fromCharCode(bytes[i]);
    //   }
    //   return window.btoa(binary);
    // }

    // const fileReader = new FileReader();
    // fileReader.readAsDataURL(file);
    // fileReader.onload = function () {
    //   console.log(fileReader.result);
    //   const tempFile = new Parse.File('combined_documents.pdf', { base64: fileReader.result });
    //   const tempFileObj = new Parse.Object('TempFile');
    //   tempFileObj.set('file', tempFile).save();
    // };
  }

  async runScript(pageSkipInt) {
    // fetch('https://sbdb-staging.onswitchboard.com/parse/files/vmK26CRUofZpUdlbk080PHZscMGhGqToMCaGeLCh/6f6e35d0c4f716026bb93680d98e52d5_combined_documents.pdf').then((response) => {
    //   response.arrayBuffer().then((buffer) => {
    //     fetch('../../combined_documents.pdf').then((response) => {
    //       response.arrayBuffer().then((localBuffer) => {
    //         console.log(arrayBufferToBase64(buffer));
    //         console.log(arrayBufferToBase64(localBuffer))
    //       });
    //     });
    //     // const tempFile = new Parse.File('combined_documents.pdf', { base64: arrayBufferToBase64(buffer) });
    //     // const tempFileObj = new Parse.Object('TempFile');
    //     // tempFileObj.set('file', tempFile).save();
    //   });
    // });

    // Auth.callSecuredParseFunction('_internal:moveDriversVehicleForELDEvents', {
    //   returnAsParse: true,
    //   driverId: 'NIhTyyfLX4',
    //   vehicleId: 'FHGdmkrnHI',
    //   vehicleUnitId: '1815',
    //   // vehicleId: 'wUuUJyFlxO',
    //   // vehicleUnitId: '1803',
    //   startDate: moment('2019-09-24').toDate(),
    //   endDate: moment('2019-09-26').toDate(),
    // }).then((asdf) => {
    //   console.log(asdf);
    // }, (error) => {
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:registerUsersToMailchimp', {
    //   returnAsParse: true,
    // }).then((result) => console.log(result));

    // Auth.callSecuredParseFunction('_internal:setVehicleUnitIdForVehicleLocations', {
    //   returnAsParse: true,
    // }).then((result) => console.log(result));


    // Auth.callSecuredParseFunction('_internal:getVehicleOdometersPerDayAndState', {
    //   // input: vehicleId
    //   // output: { dayStart, odometerKmStart, dayEnd, odometerKmEnd, gpsCalculationKm }
    // });

    // Auth.callSecuredParseFunction('_internal:fillIFTA', {
    //   // input: vehicleId
    //   // output: { dayStart, odometerKmStart, dayEnd, odometerKmEnd, gpsCalculationKm }
    // });

    // Auth.callSecuredParseFunction('_internal:fillVehicleLocationGeocode', {
    //   returnAsParse: true,
    //   // vehicleId: 'NI4OaJGBJR',
    //   fromDate: moment('2019-04-01').toDate(),
    //   toDate: moment('2019-06-30').toDate(),
    //   // companyId: 'K7oPdtIAcu', // T&P
    //   // vehicleId: 'qkxBA8xZDE', // SOD 399
    //   // vehicleId: 'dPSMygzpQs', // T&P, 210
    //   force: true,
    //   useSave: true,
    //   useSkip: true,
    //   clearIFTA: true,
    //   vehicleId: 'oidwErOH6z', // Richmond Transport: 76 = 39t5a6O8NA, 85 = p2yqlW2ZR5, 116 = cGE8AXRnl4, 335 = MIjceSN6EV, 111 = eET3vFDb7Q, 1313 = cDHFREBn16, 209 = hKHxgAQnu1
    //   // startFromVehicleIndex: 50,
    //   // companyId: 'UXxMFrCxfI',
    //   // companyId: 'AkSjgBxQ0g',
    //   // vehicleId: 'QhrwWMy4CM', // 1126181, 135
    //   // companyIdArray: ['qwS86UjM6B', 'AkSjgBxQ0g', 'ienuZQoRaB', 'B70ltFyAFF', 'LQuwxaGSHz'],
    // }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:migrateDrivers', {
    //   returnAsParse: true,
    //   originalCompanyId: 'uzOiAlgoGk',
    //   newCompanyId: '5xn96MEV6k',
    // }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:migrateCompanyEquipment', {
    //   returnAsParse: true,
    //   originalCompanyId: 'uzOiAlgoGk', // tp trucking: XUfBqndBTK
    //   newCompanyId: '5xn96MEV6k', // 1126181 bc ltd: 5xn96MEV6k
    //   // newCompanyId: 'jyQnNym6kq', // american bc ltd: jyQnNym6kq
    //   className: 'Trailer',
    //   createNew: false,
    // }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:migrateDriverPhoneNumber', {
    //   returnAsParse: true,
    //   originalCompanyId: 'uzOiAlgoGk', // tp trucking: XUfBqndBTK
    //   // newCompanyId: '5xn96MEV6k', // 1126181 bc ltd: 5xn96MEV6k
    //   newCompanyId: 'jyQnNym6kq', // american bc ltd: jyQnNym6kq
    // }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:addSIMIMEISidLinks', {
    //   returnAsParse: true,
    //   // force: true,
    // }).then((asdf) => {
    //   console.log('done addSIMIMEISidLinks');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:destroyInvalidSIMIMEISid', { returnAsParse: true }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:autofillMissingFieldsForELDEvent', { returnAsParse: true }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:addSIMESNSidLinks', {
    //   returnAsParse: true,
    //   // force: true,
    // }).then((asdf) => {
    //   console.log('done addSIMESNSidLinks');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:enableDriverBehaviourModule', { returnAsParse: true }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:findBorderCrossings', { returnAsParse: true }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:checkBorderCrossings', { returnAsParse: true }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:uploadBorderCrossingsToDB', { returnAsParse: true }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:checkIFTAObjectsForBorderError', {
    //   returnAsParse: true,
    //   vehicleId: 'i4vWeXC59G',
    // }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:setELDEventsToAOBRDEnabled', {
    //   returnAsParse: true,
    //   driverId: 'dv0QtpCEVD',
    //   startDate: '2020-01-07 00:00:00',
    //   endDate: '2020-01-07 23:59:59',
    // }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('getMapboxReverse', {
    //   longitude: -120.13001722018481,
    //   latitude: 43.38551981258931,
    // }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:assignVehicleTrailerLicensePlates', { returnAsParse: true }).then((asdf) => {
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('internal:countActiveELDHardwareForCompany', {
    //   returnAsParse: true,
    //   // companyId: 'OUGz6VpHZU'
    // }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:fixCompanySoftwareVersion', { returnAsParse: true, parseClassName: '', version: '1.54' }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:fixELDHardwareTargetSoftwareVersion', { returnAsParse: true, parseClassName: '', version: 66 }).then((asdf) => {
    //   console.log('done fixELDHardwareTargetSoftwareVersion');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // console.log(Parse);
    // Auth.callSecuredParseFunction('_internal:hideLogsBeforeDate', { returnAsParse: true, parseClassName: '', companyId: 'K7oPdtIAcu', startDate: moment('2017-12-01').startOf('day').toDate() }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:setDevicetimeCorrectedForTcPositions', { returnAsParse: true }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:queryTripInspection', { returnAsParse: true }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:fillTripInspections', { returnAsParse: true }).then((asdf) => {
    // Auth.callSecuredParseFunction('_internal:fillStateProvinceVehicleLocations', { returnAsParse: true }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:runMapboxFixer', { returnAsParse: true }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:deleteChatForCompany', { returnAsParse: true, parseClassName: '', companyId: 'bhCBOMMVnO' }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:getCustomersWithoutSubscription', { returnAsParse: true }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('parseEdi', { returnAsParse: true, parseClassName: '', companyId: 'bhCBOMMVnO' }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:addDriverModuleForDriver', { returnAsParse: true }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:processIFTARouteDriverPeriod', { returnAsParse: true }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('getBluJay204sForCompany', {
    //   returnAsParse: true,
    //   companyId: '99c7HmXLCo',
    // }).then((asdf) => {
    //   console.log('done getBluJay204sForCompany');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:updateUserEmail', {
    //   returnAsParse: true,
    //   userId: 'fkH5yVAuBn',
    // }).then((asdf) => {
    //   console.log('done updateUserEmail');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:setELDForAllCompanies', {
    //   returnAsParse: true,
    // }).then((asdf) => {
    //   console.log('doing setELDForAllCompanies');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:checkSubscriptionForCompany', {
    //   returnAsParse: true,
    // }).then((asdf) => {
    //   console.log('doing setELDForAllCompanies');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:turnOffLogsViewViolation', {
    //   returnAsParse: true,
    // }).then((asdf) => {
    //   console.log('doing turnOffLogsViewViolation');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // const query = new Parse.Query('Driver');
    // query.descending('updatedAt');
    // query.greaterThanOrEqualTo('updatedAt', moment().subtract(1, 'months').toDate());
    // // query.equalTo('eldDailyCertification', eldDailyCertificationObj);
    // console.log('_internal:createCSVFromQuery');
    // Auth.callSecuredParseFunction('_internal:createCSVFromQuery', {
    //   returnAsParse: true,
    //   className: 'Driver',
    //   query: query.toJSON(),
    //   columnArr: [
    //     'objectId',
    //     'deviceIMEI',
    //     'currentELDVersion',
    //     'updatedAt',
    //   ],
    //   emailTo: 'michael@onswitchboard.com',
    // }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Sim.getSimInfo('89410034184519864414').then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:repairELDVehicleUnitId', {
    //   returnAsParse: true,
    //   queryAllCompanies: true,
    //   tableReferenceInt: 1,
    //   companyObjectId: 'q97BaiOkxI',
    //   dateTimeInterval: [moment('2020-09-12').toDate(), moment('2020-09-14').toDate()],
    //   vehicleUnitId: '100',
    //   newVehicleUnitId: '120',
    //   ignoreMismatchedUnitIds: true,
    //   driverObjectId: 'YSvRbuRQJJ',
    //   unsetIsOverridden: true,
    //   // returnLogging: true,
    // }).then((asdf) => {
    //   console.log('doing _internal:repairELDVehicleUnitId');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:driverModuleSet', {
    //   returnAsParse: true,
    //   // companyId: 'UXxMFrCxfI',
    //   // companyId: 'BoV32KaoSD',
    //   // property: 'enabledSplitSleeperModuleOne',
    //   // property: 'enabledParseUploadDataReduction',
    //   property: 'enabledAutoLogin',
    //   // value: false,
    //   queryAll: true,
    //   fixUndefined: true,
    // }).then((asdf) => {
    //   console.log('doing driverModuleSet');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:destroyDuplicateHosViolationsForDriver', {
    //   returnAsParse: true,
    //   // companyId: 'UXxMFrCxfI',
    //   driverId: 'ZrvAPv4DAf',
    //   date: moment('2021-02-15').toDate(),
    // }).then((asdf) => {
    //   console.log('doing destroyDuplicateHosViolationsForDriver');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:setBillingAddressForSubscription', {
    //   returnAsParse: true,
    // }).then((asdf) => {
    //   console.log('doing setBillingAddressForSubscription');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:setCompanyAnalytics', {
    //   returnAsParse: true,
    // }).then((asdf) => {
    //   console.log('doing internal:setCompanyAnalytics');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:refreshBillingCountForSubscriptions', {
    //   returnAsParse: true,
    // }).then((asdf) => {
    //   console.log('doing internal:refreshBillingCountForSubscriptions');
    // });

    // Auth.callSecuredParseFunction('_migration:setGpsPingFrequecy', {
    //   returnAsParse: true,
    // }).then((asdf) => {
    //   console.log('doing _migration:setGpsPingFrequecy');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('internal:setStartSubscriptionForAllCompanies', {
    //   returnAsParse: true,
    // }).then((asdf) => {
    //   console.log('doing internal:setStartSubscriptionForAllCompanies');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:setUserAnalyticsForAllCompanies', {
    //   returnAsParse: true,
    // }).then((asdf) => {
    //   console.log('doing _migration:setUserAnalyticsForAllCompanies');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('internal:test', {
    //   returnAsParse: true,
    // }).then((asdf) => {
    //   console.log('doing test');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('installHexnodeApplication', {
    //   // Auth.callSecuredParseFunction('getSwitchboardHexnodeApplications', {
    //   imeiArr: ['860492046646954'],
    //   versionFilterString: 'dev-1',
    // }).then((asdf) => {
    //   console.log('doing getHexnodeDeviceDetails');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('internal:uploadEquipmentCsv', {
    //   companyId: '749YjPncIU'
    // }).then((asdf) => {
    //   console.log('doing uploadEquipmentCsv');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('internal:uploadTrailerCsv', {
    //   companyId: '749YjPncIU'
    // }).then((asdf) => {
    //   console.log('doing uploadTrailerCsv');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('internal:uploadDriverCsv', {
    //   companyId: '749YjPncIU',
    // }).then((asdf) => {
    //   console.log('doing uploadDriverCsv');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:setTimezoneForAllDrivers', {
    //   companyId: 'yPe2Ndvu39',
    //   timezoneOffsetFromUTC: 'America/Edmonton',
    // }).then((asdf) => {
    //   console.log('doing setTimezoneForAllDrivers');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:setStreamlineVendorIntegration', {
    //   promoCode: 'stti'
    // }).then((asdf) => {
    //   console.log('doing setStreamlineVendorIntegration');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:fillPrimaryDriverForVehicleLocation', {
    //   queryAllCompanies: true,
    // }).then((asdf) => {
    //   console.log('doing fillPrimaryDriverForVehicleLocation');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:findOldInactiveSims', {
    // }).then((asdf) => {
    //   console.log('doing findOldInactiveSims');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:terminateOldInactiveSims', {
    // }).then((asdf) => {
    //   console.log('doing terminateOldInactiveSims');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:getActiveSimsLastThreeMonths', {
    // }).then((asdf) => {
    //   console.log('doing getActiveSimsLastThreeMonths');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // const eldEventParseQuery = new Parse.Query('ELDEvent');
    // eldEventParseQuery.matches('vehicleUnitId', '123', 'i');
    // eldEventParseQuery.equalTo('unidentifiedDriver', true);
    // const eldEvent = await eldEventParseQuery.find()
    // console.log(eldEvent);

    // Auth.callSecuredParseFunction('_migration:attachEldDailyCertificationsToEldEvent', {
    //   returnAsParse: true,
    //   driverId: 'pli7KKPKXb',
    //   dateTime: '2023-06-02T23:18:46.315Z',
    // }).then((asdf) => {
    //   console.log('done attachEldDailyCertificationsToEldEvent');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:disableEldDailyCertificationsForDriver', {
    //   returnAsParse: true,
    //   driverId: 'NDkz4CeyaC',
    //   startDate: '2023-01-02T23:18:46.315Z',
    //   endDate: '2024-01-01T23:18:46.315Z',
    // }).then((asdf) => {
    //   console.log('done disableEldDailyCertificationsForDriver');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:fillDataForPacificTrackSpamming', {
    // }).then((asdf) => {
    //   console.log('done fillDataForPacificTrackSpamming');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:setCompanyModuleForAllCompanies', {
    //   value: true,
    //   companyModuleProperty: 'enabledPlatePrompt',
    // }).then((asdf) => {
    //   console.log('done setCompanyModuleForAllCompanies');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // const query = new Parse.Query('Subscription');
    // query.descending('createdAt');
    // console.log('doing!');
    // Auth.callSecuredParseFunction('_internal:createCSVFromQuery', {
    //   returnAsParse: true,
    //   className: 'Subscription',
    //   query: query.toJSON(),
    //   columnArr: [
    //     'objectId',
    //     'company',
    //     'customerId',
    //   ],
    //   // emailTo: 'michael@onswitchboard.com',
    // }).then((asdf) => {
    //   console.log('done');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('done');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:setPromoCodeAnalyticsForAllCompanies', {
    // }).then((asdf) => {
    //   console.log('done setPromoCodeAnalyticsForAllCompanies');
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:setUseOdometerOffset', {
    // }).then((asdf) => {
    //   console.log('done setUseOdometerOffset');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:checkActiveCountBillingMatchBool', {
    //   companyId: 'SwnozcmKIf',
    // }).then((asdf) => {
    //   console.log('done checkActiveCountBillingMatchBool');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:setGeofenceCenterLocation', {
    // }).then((asdf) => {
    //   console.log('done _migration:setGeofenceCenterLocation');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:fixIncorrectRawTotalVehicleKm', {
    //   eldDailyCertificationId: 'lbjCRf0jI0', //direct way Harkomal
    //   ignoreOdometerOffset: true,
    // }).then((asdf) => {
    //   console.log('done _migration:fixIncorrectRawTotalVehicleKm');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:pullP44Companies', {
    // }).then((asdf) => {
    //   console.log('done _migration:pullP44Companies');
    //   console.log(asdf.sort((a, b) => (
    //     a.belongsToCompany.subscription.activeVehicleLastThreeMonthsCount - b.belongsToCompany.subscription.activeVehicleLastThreeMonthsCount
    //   )).map((obj) => (
    //     {
    //       activeVehicles: obj.belongsToCompany.subscription.activeVehicleLastThreeMonthsCount,
    //       name: obj.belongsToCompany.name,
    //       dotNumber: obj.belongsToCompany.dotNumber,
    //     }
    //   )));
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('getExpiredSubscriptionsCustomers', {
    // }).then((asdf) => {
    //   console.log('done getExpiredSubscriptionsCustomers');
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:findTwilioSimsForReset', {
    // }).then((asdf) => {
    //   console.log('done findTwilioSimsForReset');
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:migrationTcDeviceFromOneTrailerToAnother', {
    //   oldTrailerId: 'wDdk51cnFq',
    //   newTrailerId: 'IznWzpv3tq',
    // }).then((asdf) => {
    //   console.log('done migrationTcDeviceFromOneTrailerToAnother');
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:saveAllSimsToCsv', {
    //   type: 'soracom',
    //   // simIdArr: null,
    //   // simIdArr: ['8942310222007038573'],
    // }).then((asdf) => {
    //   console.log('doing saveAllSimsToCsv');
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:setGroupForSoracomSimsInCsv', {
    // }).then((asdf) => {
    //   console.log('doing setGroupForSoracomSimsInCsv');
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:suspendSoracomSimsInCsv', {
    // }).then((asdf) => {
    //   console.log('doing suspendSoracomSimsInCsv');
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:suspendTwilioSimsInCsv', {
    // }).then((asdf) => {
    //   console.log('doing suspendTwilioSimsInCsv');
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:suspendTwilioSimsInCsv', {
    // }).then((asdf) => {
    //   console.log('doing suspendTwilioSimsInCsv');
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:attachSimImeisToCompanyFromCsv', {
    // }).then((asdf) => {
    //   console.log('doing _migration:attachSimImeisToCompanyFromCsv');
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_migration:setIsClosedTrueForAnalytics', {
    // }).then((asdf) => {
    //   console.log('doing _migration:setIsClosedTrueForAnalytics');
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('internal:sendCommandToIccidFromCsv', {
    // }).then((asdf) => {
    //   console.log('doing internal:sendCommandToIccidFromCsv');
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('internal:createObjectsFromCsv', {
    //   className: 'TripDefectType',
    //   parserOptions: {
    //     referenceInt: 'number',
    //   },
    // }).then((asdf) => {
    //   console.log('doing TripDefectType');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('migration:fromCsvDeactivateStripePlans', {
    // }).then((asdf) => {
    //   console.log('doing fromCsvDeactivateStripePlans');
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('_internal:migrateInactiveSoracomSimsToSuspend', {
    //   lastEvaluatedKey: '8942310222006946594',
    // }).then((asdf) => {
    //   console.log('doing migrateInactiveSoracomSimsToSuspend');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('migration:fixEldDailyCertificationshiftEndEldEvent', {
    //   // eldDailyCertificationId: 'E4QFBpBb3Z',
    // }).then((asdf) => {
    //   console.log('doing fixEldDailyCertificationshiftEndEldEvent');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('getAndRecordSwitchboardScalefusionDeviceInfo', {
    //   // eldDailyCertificationId: 'E4QFBpBb3Z',
    // }).then((asdf) => {
    //   console.log('doing getAndRecordSwitchboardScalefusionDeviceInfo');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('publishUpdateForScalefusionDevice', {
    //   imei: '358510072321546',
    // }).then((asdf) => {
    //   console.log('doing publishUpdateForScalefusionDevice');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('migration:resetIftaFreeForCompanies', {
    // }).then((asdf) => {
    //   console.log('doing resetIftaFreeForCompanies');
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('migration:archiveProductsWithoutActivePrices', {
    // }).then((asdf) => {
    //   console.log('doing migration:archiveProductsWithoutActivePrices');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

    // Auth.callSecuredParseFunction('migration:saveDriverImeiToCsv', {
    // }).then((asdf) => {
    //   console.log('doing migration:saveDriverImeiToCsv');
    //   console.log(asdf);
    // }, (error) => {
    //   console.log('error');
    //   console.log(error);
    // });

  }

  runTestScript() {
    console.log(this.state.testInput);
  }

  render() {
    return (
      <div>
        <Title title="Scripts" />
        <div>
          <input
            value={this.state.input}
            onChange={(e) => this.setState({ ...this.state, input: e.target.value })}
          />
          <button className="buttonDefault" onClick={() => this.runScript()}>Run Script</button>
          <hr />
          <b>Test Function</b>
          <br />
          <input
            value={this.state.testInput}
            onChange={(e) => this.setState({ ...this.state, testInput: e.target.value })}
          />
          <button className="buttonDefault" onClick={() => this.runTestScript()}>Test Function</button>
          <br />
          <br />
          <br />
          {/* <b>Global Target Engine Module Version:</b>
          <br />
          <b>
            <input
              type="number"
              className="buttonSpacedRight"
              onChange={(e) => {
                this.setState({ ...this.state, eldHardwareTargetSoftwareVersion: e.target.value })
              }}
              value={this.state.eldHardwareTargetSoftwareVersion} />
            <button
              className="buttonDefault"
              disabled={this.state.savingELDHardwareTargetSoftwareVersion}
              onClick={() => this.saveELDHardwareTargetSoftwareVersion()}
            >
              Save
            </button>
          </b> */}

          <br />
          <br />

          <b>PubNub Message:</b>
          <br />
          <b>
            <input
              className="buttonSpacedRight"
              placeholder="channel (companyId or 'Switchboard')"
              onChange={(e) => {
                this.setState({ ...this.state, pubnub: { ...this.state.pubnub, channel: e.target.value } })
              }}
              value={this.state.pubnub.channel}
            />
            <input
              className="buttonSpacedRight"
              placeholder="type (ie. refresh)"
              onChange={(e) => {
                this.setState({ ...this.state, pubnub: { ...this.state.pubnub, type: e.target.value } })
              }}
              value={this.state.pubnub.type}
            />
            <button
              className="buttonDefault"
              disabled={this.state.pubnub.sending}
              onClick={() => this.sendPubNubMessage()}
            >
              Send
            </button>
          </b>

          <br />
          <br />
          <b>Upload File Script:</b>
          <br />

          <input type="file" onChange={(e) => this.runFileUploadScript(e.target.files[0])}></input>

          <br />
          <br />
          <b>Download Weigh Station Bypass Vehicles:</b>
          <br />

          <button
            className="buttonDefault"
            onClick={() => this.downloadAllDrivewyzeVehicles()}
          >
            Download
          </button>

          <br />
          <br />
          <b>Refresh Billing Count for Subscriptions:</b>
          <br />
          <span>This will take a while, come back in about an hour!</span>
          <br />
          <button
            className="buttonDefault"
            onClick={() => {
              this.refreshBillingCount();
              this.setState({ ...this.state, disableRefreshBillingCountButton: true });
            }}
            disabled={this.state.disableRefreshBillingCountButton}
          >
            Refresh
          </button>
        </div>
      </div>
    );
  }
}

export default ScriptsLayout;
