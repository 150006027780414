import React from 'react';
import PropTypes from 'prop-types';

import { Table, TableHeader, TableRow, TableBody, TableRowColumn, TextField, Toggle } from 'material-ui';

// API
import * as Setters from 'api/Setters';

// Components
import CreatePlanModal from './CreatePlanModal';
import ActivePlansList from './ActivePlansList';

class PlansList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        name: '',
        currency: '',
        amount: '',
      },
      showCreatePlanModalBool: false,
      disablePlanButtoms: false,
    };

    this.deactivatePlan = this.deactivatePlan.bind(this);
  }

  deactivatePlan(stripePlanId) {
    this.setState({ ...this.state, disablePlanButtons: true });
    Setters.editStripePlan(stripePlanId, {
      active: false,
    }).then(() => {
      this.props.refreshSubscriptionPlans()
      this.setState({ ...this.state, disablePlanButtons: false });
    }, (error) => {
      console.log(error);
      this.setState({ ...this.state, disablePlanButtons: false });
    });
  }

  render() {
    return (
      <div>
        {/* <div>
          <h4>Active Plans</h4>
          {this.props.subscriptionPlans.length > 0 &&
            <ActivePlansList
              handleChangeSubscription={this.props.handleChangeSubscription}
              activePlans={this.props.subscriptionPlans.filter((subscriptionPlan) => {
                return subscriptionPlan.active;
              })}
            />
          }

        </div>

        <br />
        <br /> */}

        <div>
          <h4>Plans</h4>
          <table>
            <tr>
              <td>
                <div className="inlineBlock">
                  FILTER: &nbsp;
                </div>
                <div className="inlineBlock">
                  <input
                    type="text"
                    className="buttonSpacedRight"
                    value={this.state.filter.name}
                    onChange={(e) => { this.setState({ ...this.state, filter: { ...this.state.filter, name: e.target.value } }) }}
                    placeholder="Internal Name"
                  />
                  <input
                    type="number"
                    className="buttonSpacedRight"
                    value={this.state.filter.amount}
                    onChange={(e) => { this.setState({ ...this.state, filter: { ...this.state.filter, amount: e.target.value } }) }}
                    placeholder="Price"
                  />
                  <select value={this.state.filter.currency} onChange={(e) => this.setState({ ...this.state, filter: { ...this.state.filter, currency: e.target.value } })}>
                    <option value=""></option>
                    <option value="cad">CAD</option>
                    <option value="usd">USD</option>
                  </select>
                </div>
              </td>
              <td>
                {/* <button
                  className={`buttonDefault floatRight`}
                  onClick={() => this.setState({ ...this.state, showCreatePlanModalBool: true })}
                >
                  Create Custom Plan
                </button> */}
              </td>
            </tr>
          </table>
        </div>
        <div>
          <Table
            selectable={false}
            multiSelectable={false}
            height="300px"
          >
            <TableHeader
              displaySelectAll={false}
              adjustForCheckbox={false}
              enableSelectAll={false}
            ><TableRow>
                <TableRowColumn style={{ width: '25%' }}>Plan</TableRowColumn>
                <TableRowColumn style={{ width: '15%' }}>Interval</TableRowColumn>
                <TableRowColumn style={{ width: '15%' }}>Price (Currency)</TableRowColumn>
                <TableRowColumn style={{ width: '40%' }}></TableRowColumn>
              </TableRow></TableHeader>
            <TableBody
              displayRowCheckbox={false}
            >
              {this.props.subscriptionPlans.filter((subscriptionPlan) => {
                if (!subscriptionPlan.name) return false;
                if (subscriptionPlan.active) return false;
                if (this.state.filter.name && !subscriptionPlan.name.toLowerCase().includes(this.state.filter.name.toLowerCase())) return false;
                if (this.state.filter.currency && !subscriptionPlan.currency.includes(this.state.filter.currency)) return false;
                if (this.state.filter.amount && subscriptionPlan.amount !== (parseFloat(this.state.filter.amount) * 100)) return false;
                return true;
              }).map((subscriptionItem) => (
                <TableRow key={subscriptionItem.stripePlanId}>
                  <TableRowColumn style={{ width: '25%' }}>{subscriptionItem.name}</TableRowColumn>
                  <TableRowColumn style={{ width: '15%' }}>{subscriptionItem.intervalCount} {subscriptionItem.interval}</TableRowColumn>
                  <TableRowColumn style={{ width: '15%' }}>${subscriptionItem.amount / 100} {subscriptionItem.currency.toUpperCase()}</TableRowColumn>
                  <TableRowColumn style={{ width: '40%' }}>
                    <button
                      className={`buttonDefault`}
                      onClick={() => this.props.handleChangeSubscription(subscriptionItem.stripePlanId, 'active', true)}
                      disabled={this.state.disablePlanButtons}
                    >
                      Add For Customer
                    </button>
                    &nbsp;
                    <button
                      className={`buttonDefault redButton`}
                      onClick={() => this.deactivatePlan(subscriptionItem.stripePlanId)}
                      disabled={this.state.disablePlanButtons}
                    >
                      Delete Plan
                    </button>
                  </TableRowColumn>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <CreatePlanModal
          hideModal={() => {
            this.props.refreshSubscriptionPlans();
            this.setState({ ...this.state, showCreatePlanModalBool: false })
          }}
          show={this.state.showCreatePlanModalBool}
        />
      </div>
    );
  }
}


PlansList.propTypes = {
  handleChangeSubscription: PropTypes.func.isRequired,
  subscriptionPlans: PropTypes.array,
  refreshSubscriptionPlans: PropTypes.array,
};

export default PlansList;
