import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardActions, CardHeader, CardText, CardTitle, Table, TableHeader, TableRow, TableBody, TableRowColumn, TextField, Toggle } from 'material-ui';

// API
import * as Getters from 'api/Getters';

// Components
import CustomerSubscriptionCard from './CustomerSubscriptionCard';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

class CustomerSubscriptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      customerSubscriptionsArr: [],
    };

    this.refreshCustomerSubscriptions = this.refreshCustomerSubscriptions.bind(this);
    this.createLocalSubscription = this.createLocalSubscription.bind(this);
    this.updateLocalSubscription = this.updateLocalSubscription.bind(this);
  }

  async componentDidMount() {
    this.refreshCustomerSubscriptions();
  }

  async refreshCustomerSubscriptions() {
    this.setState({ ...this.state, loading: true, customerSubscriptionsArr: [] });
    if (this.props.subscriptionObj) {
      const customerSubscriptionsArr = await Getters.getCustomerSubscriptions(this.props.subscriptionObj.id);
      this.setState({ ...this.state, loading: false, customerSubscriptionsArr });
    }
  }

  createLocalSubscription() {
    /*
    {
      subscriptionId,
      cancelAtPeriodEnd,
      status,
      planArr: [
        {
          name,
          stripePlanId,
          currency,
          active,
          amount,
          metadata,
          intervalCount,
          interval,
          productId,
          amount,
          quantity,
        }
      ],
    }
  */
    const { customerSubscriptionsArr } = this.state;
    const newCustomerSubscriptionsArr = [...customerSubscriptionsArr];

    newCustomerSubscriptionsArr.unshift({
      planArr: [],
    });

    this.setState({ ...this.state, customerSubscriptionsArr: newCustomerSubscriptionsArr });
  }

  updateLocalSubscription(modifiedSubscriptionDataObj) {
    /*
    {
      subscriptionId,
      cancelAtPeriodEnd,
      status,
      planArr: [
        {
          name,
          stripePlanId,
          currency,
          active,
          amount,
          metadata,
          intervalCount,
          interval,
          productId,
          amount,
          quantity,
        }
      ],
    }
  */
    const { customerSubscriptionsArr } = this.state;
    const newCustomerSubscriptionsArr = [...customerSubscriptionsArr];
    for (let i = 0; i < customerSubscriptionsArr.length; i++) {
      const subscriptionDataObj = customerSubscriptionsArr[i];
      if (subscriptionDataObj.subscriptionId === modifiedSubscriptionDataObj.subscriptionId) {
        newCustomerSubscriptionsArr[i] = modifiedSubscriptionDataObj;
        break;
      }
    }
    this.setState({ ...this.state, customerSubscriptionsArr: newCustomerSubscriptionsArr });
  }

  render() {
    return (
      <div>
        <div>
          Subscriptions
          &nbsp;
          <button
            className={`buttonDefault`}
            onClick={() => this.createLocalSubscription()}
          >
            Create New Subscription
          </button>
        </div>
        <br />
        {this.state.loading &&
          <LoadingIcon />
        }
        {this.state.customerSubscriptionsArr && this.state.customerSubscriptionsArr.map((stripeSubscriptionDataObj) => (
          <div>
            <CustomerSubscriptionCard
              handleSaveSubscriptionObjProperty={this.props.handleSaveSubscriptionObjProperty}
              refreshCustomerSubscriptions={this.refreshCustomerSubscriptions}
              subscriptionObj={this.props.subscriptionObj}
              currency={this.props.currency}
              stripeSubscriptionDataObj={stripeSubscriptionDataObj}
              updateLocalSubscription={this.updateLocalSubscription}
            />
            <br />
          </div>
        ))}
      </div>
    );
  }
}


CustomerSubscriptions.propTypes = {
  subscriptionObj: PropTypes.object,
};

export default CustomerSubscriptions;
