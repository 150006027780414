import React from 'react';

// Actions
import * as Getters from 'api/Getters';

// Components
import Title from 'components/LayoutTitle/view/Title';
import EquipmentTable from 'components/EquipmentTable/EquipmentTable';

// CSS
import styles from './EquipmentLayout.module.scss';

class EquipmentLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledAndHiddenFilterInt: 0, // disabled,hidden; 0 = false,false; 1 = true,false; 2 = false,true; 3 = true,true
      duplicateFilterInt: 1, // duplicateUnitId; 0 = show duplicates; 1 = hide duplicates
      vehicleArr: [],
      trailerArr: [],
      filter: {
        companyName: '',
        unitId: '',
        dotNumber: '',
      },
      loading: false,
    };
    this.filter = this.filter.bind(this);
    this.getEquipmentWithInactiveDashcam = this.getEquipmentWithInactiveDashcam.bind(this);
  }

  componentDidMount() {
    // const query = this.props.location.search;
    // this.setState({ ...this.state, query });
    // const queryProperties = Object.keys(query);
    // if (queryProperties.length > 0) fetchUsersForState(decodeURIComponent(query[queryProperties[0]]), queryProperties[0]);
    // Getters.getResellerCompanies().then((resellerCompanyArr) => {
    //   this.setState({ ...this.state, resellerCompanyArr });
    // });
  }

  //   handleSubViewClick(subview) {
  //     let tempDriver = getQueryParameter(this.props.location.search, 'driver');
  //     history.push({ pathname: 'driver', search: "driver=" + tempDriver + "&view=" + subview });
  //   }

  async filter(e, type) {
    this.setState({ ...this.state, loading: true });
    const promises = [];

    if (type === 'tc_devices_uniqueid') {
      promises.push(
        Getters.getTrailers(
          this.state.filter,
          this.state.disabledAndHiddenFilterInt === 1 || this.state.disabledAndHiddenFilterInt === 3,
          this.state.disabledAndHiddenFilterInt === 2 || this.state.disabledAndHiddenFilterInt === 3,
        ),
      );
      const responses = await Promise.all(promises);
      this.setState({ ...this.state, vehicleArr: [], trailerArr: responses[0], loading: false });
    } else {
      promises.push(
        Getters.getVehicles(
          this.state.filter,
          this.state.disabledAndHiddenFilterInt === 1 || this.state.disabledAndHiddenFilterInt === 3,
          this.state.disabledAndHiddenFilterInt === 2 || this.state.disabledAndHiddenFilterInt === 3,
        ),
      );
      promises.push(
        Getters.getTrailers(
          this.state.filter,
          this.state.disabledAndHiddenFilterInt === 1 || this.state.disabledAndHiddenFilterInt === 3,
          this.state.disabledAndHiddenFilterInt === 2 || this.state.disabledAndHiddenFilterInt === 3,
        ),
      );
      const responses = await Promise.all(promises);
      this.setState({ ...this.state, vehicleArr: responses[0], trailerArr: responses[1], loading: false });
    }
  }

  getEquipmentWithInactiveDashcam() {
    this.setState({ ...this.state, loading: true });
    Getters.getEquipmentWithInactiveDashcam().then((vehicleArr) => {
      this.setState({ ...this.state, vehicleArr: vehicleArr.filter((vehicle) => !!vehicle.get('unitId')), loading: false });
    });
  }

  render() {
    const sortedTrailerArr = this.state.trailerArr.sort((a, b) => b.get('isHidden') - a.get('isHidden')).sort((a, b) => b.get('updatedAt') - a.get('updatedAt'));
    const sortedVehicleArr = this.state.vehicleArr.sort((a, b) => b.get('isHidden') - a.get('isHidden')).sort((a, b) => b.get('updatedAt') - a.get('updatedAt'));

    const trailerUnitIdArr = sortedTrailerArr.map((trailerObj) => trailerObj.get('unitId'));
    const vehicleUnitIdArr = sortedVehicleArr.map((vehicleObj) => vehicleObj.get('unitId'));
    const filteredSortedTrailerArr = this.state.duplicateFilterInt === 1 ? sortedTrailerArr.filter((item, index) => trailerUnitIdArr.indexOf(item.get('unitId')) === index) : sortedTrailerArr;
    const filteredSortedVehicleArr = this.state.duplicateFilterInt === 1 ? sortedVehicleArr.filter((item, index) => vehicleUnitIdArr.indexOf(item.get('unitId')) === index) : sortedVehicleArr;

    return (
      <div className={styles.equipmentLayout}>
        <Title title="Equipment">
          <span className="inlineBlock">
            <input
              type="text"
              value={this.state.filter.companyName}
              className="buttonSpacedRight"
              onChange={(e) => this.setState({ ...this.state, filter: { ...this.state.filter, companyName: e.target.value } })}
              onKeyPress={(e) => (e.key === 'Enter' && this.filter(e, 'companyName'))}
              placeholder="Enter Company"
            />
            <input
              type="text"
              value={this.state.filter.dotNumber}
              className="buttonSpacedRight"
              onChange={(e) => this.setState({ ...this.state, filter: { ...this.state.filter, dotNumber: e.target.value } })}
              onKeyPress={(e) => (e.key === 'Enter' && this.filter(e, 'dotNumber'))}
              placeholder="Enter DOT Number"
            />
            <input
              type="text"
              value={this.state.filter.unitId}
              className="buttonSpacedRight"
              onChange={(e) => this.setState({ ...this.state, filter: { ...this.state.filter, unitId: e.target.value } })}
              onKeyPress={(e) => (e.key === 'Enter' && this.filter(e, 'unitId'))}
              placeholder="Enter Unit Id"
            />
            <input
              type="text"
              value={this.state.filter.tc_devices_uniqueid}
              className="buttonSpacedRight"
              onChange={(e) => this.setState({ ...this.state, filter: { ...this.state.filter, tc_devices_uniqueid: e.target.value } })}
              onKeyPress={(e) => (e.key === 'Enter' && this.filter(e, 'tc_devices_uniqueid'))}
              placeholder="Enter Tracker uniqueid"
            />
            <select value={this.state.disabledAndHiddenFilterInt} onChange={(e) => { this.setState({ ...this.state, disabledAndHiddenFilterInt: parseInt(e.target.value) }) }}>
              <option value={0}>Hide Hidden & Disabled</option>
              <option value={1}>Show Disabled</option>
              <option value={3}>Show Hidden & Disabled</option>
            </select>
            <select value={this.state.duplicateFilterInt} onChange={(e) => { this.setState({ ...this.state, duplicateFilterInt: parseInt(e.target.value) }) }}>
              <option value={0}>Show Duplicate Unit Id</option>
              <option value={1}>Hide Duplicate Unit Id</option>
            </select>
            <button
              className="buttonDefault buttonSpacedLeft"
              onClick={() => this.getEquipmentWithInactiveDashcam()}
            >
              Inactive Dashcam
            </button>

          </span>
        </Title>
        <EquipmentTable
          fetched={!this.state.loading}
          trailerArr={filteredSortedTrailerArr}
          vehicleArr={filteredSortedVehicleArr}
        />
      </div>
    );
  }
}

export default EquipmentLayout;
