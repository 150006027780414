import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store';
import router from 'router';
import { initializeParse } from 'actions/Main';

import { Auth0Provider } from "@auth0/auth0-react";

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import * as Auth from './api/Auth';

require('styles/master.scss');
require('styles/_modal.scss');
require('styles/_table.scss');

initializeParse(true).then(() => {
  // Provider is a top-level component that wrapps our entire application, including
  // the Router. We pass it a reference to the store so we can use react-redux's
  // connect() method for Component Containers.

  // ReactDOM.render(
  //   <Auth0Provider
  //     domain="dev-kxof9dtc.us.auth0.com"
  //     clientId="hmAh9c4lDyzv0F71PRLjjqKVFllnIXb3"
  //     redirectUri={window.location.origin}
  //   >
  //     <Provider store={store}>
  //       <MuiThemeProvider>
  //         {router}
  //       </MuiThemeProvider>
  //     </Provider>
  //   </Auth0Provider>
  //   ,
  //   document.getElementById('root')
  // );

  ReactDOM.render(
    <Provider store={store}>
      {/* <Auth0Provider
        domain={Auth.getInitOptions.domain}
        clientId={Auth.getInitOptions.client_id}
        redirectUri={window.location.origin}
      > */}
      <MuiThemeProvider>
        {router}
      </MuiThemeProvider>
      {/* </Auth0Provider> */}
    </Provider >,
    document.getElementById('root')
  );
});
