import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import history from 'sbHistory';

// Components
import FeatureHeaderBar from '../view/FeatureHeaderBar';
import UserHeaderBar from '../view/UserHeaderBar';

// CSS
import styles from './Header.module.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.changeRoute = this.changeRoute.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.User && nextProps.User.fetched !== this.props.User.fetched) {
      return true;
    }
    if (nextProps.pageLocation !== this.props.pageLocation) {
      return true;
    }
    if (nextProps.Main.isProduction !== this.props.Main.isProduction) {
      return true;
    }
    return false;
  }

  changeRoute(route) {
    history.push(`/${route}`);
  }

  render() {
    return (
      <div className={styles.header}>
        <UserHeaderBar isProduction={this.props.Main.isProduction} />
        <FeatureHeaderBar changeRoute={this.changeRoute} pageLocation={this.props.pageLocation.pathname} />
      </div>
    );
  }
}

Header.propTypes = {
  Main: PropTypes.object.isRequired,
  User: PropTypes.object.isRequired,
  pageLocation: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Main, User } = state;
  return {
    Main,
    User,
  };
};

export default withRouter(connect(mapStateToProps)(Header));
