import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, TextField, Toggle } from 'material-ui';

import * as Helpers from 'api/Helpers';

function Modules(props) {
  return (
    <div>
      <Table
        selectable={false}
        multiSelectable={false}
      >
        <TableHeader
          displaySelectAll={false}
          adjustForCheckbox={false}
          enableSelectAll={false}
        ><TableRow>
            <TableRowColumn>Module</TableRowColumn>
            <TableRowColumn>Active</TableRowColumn>
          </TableRow></TableHeader>
        <TableBody
          displayRowCheckbox={false}
        >
          {Helpers.getModules().map((module) => (
            <TableRow key={module.property}>
              <TableRowColumn>{module.label}</TableRowColumn>
              <TableRowColumn>
                <Toggle
                  onToggle={(event, newValue) => {
                    props.handleChangeSubscriptionObj(module.property, newValue);
                  }}
                  toggled={props.subscriptionObj.get(module.property)}
                />
              </TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <table style={{ width: '100%' }}><tbody>
        <tr>
          <td style={{ padding: '2em' }}>
            <Toggle
              label="AOBRD Enabled"
              toggled={props.isAOBRD}
              onToggle={props.handleChangeIsAOBRD}
            />
          </td>
          <td style={{ padding: '2em' }}>
            {!props.subscriptionObj.get('subscriptionId') &&
              <button
                className={`buttonDefault`}
                onClick={() =>
                  props.handleSaveSubscriptionObj('ignoreSubscriptionUntil', moment().add(1, 'day').toDate())
                }
              >
                Extend Trial One Day
              </button>
            }
            <br />
            {!props.subscriptionObj.get('subscriptionId') &&
              <button
                className={`buttonDefault`}
                onClick={() =>
                  props.handleSaveSubscriptionObj('ignoreSubscriptionUntil', moment().add(10, 'day').toDate())
                }
              >
                Extend Trial Ten Days
              </button>
            }
            <div>
              Trial End:&nbsp;
              {props.subscriptionObj.get('ignoreSubscriptionUntil') && moment(props.subscriptionObj.get('ignoreSubscriptionUntil')).format('ll')}
            </div>

            {/* <Toggle
              label="Start Subscription"
              toggled={props.subscriptionObj.get('startSubscription')}
              onToggle={props.handleChangeStartSubscription}
            /> */}
          </td>
          <td style={{ padding: '2em' }}>
            <Toggle
              label="Company Links"
              toggled={props.subscriptionObj.get('company') && props.subscriptionObj.get('company').get('enableCompanyLinks')}
              onToggle={props.handleChangeEnableCompanyLinks}
            />
          </td>
        </tr>
        <tr>
          <td style={{ padding: '2em' }}>
            <Toggle
              label="Allow CSV Logs Download"
              toggled={props.showEldCsvDownload}
              onToggle={props.handleChangeShowEldCsvDownload}
            />
          </td>
          <td style={{ padding: '2em' }}>
            <Toggle
              label="Allow Odometer Offset Entry"
              toggled={props.subscriptionObj.get('company') && props.subscriptionObj.get('company').get('useOdometerOffset')}
              onToggle={props.handleChangeUseOdometerOffset}
            />
          </td>
          <td style={{ padding: '2em' }}>
            <Toggle
              label="Allow Automatic SIM Reset"
              toggled={props.subscriptionObj.get('company') && props.subscriptionObj.get('company').get('allowAutomatedSIMReset')}
              onToggle={props.handleChangeAllowAutomatedSIMReset}
            />
          </td>
        </tr>
        <tr>
          <td style={{ padding: '2em' }}>
            <Toggle
              label="C-TPAT Enabled"
              toggled={props.ctpatEnabled}
              onToggle={props.handleChangeCtpat}
            />
          </td>
          <td style={{ padding: '2em' }}>
            <div>
              <label>GPS Ping Frequency (Minutes)</label>
              <br />
              <input
                type="number"
                step="1"
                value={props.subscriptionObj.get('company') &&
                  (props.subscriptionObj.get('company').get('gpsFrequencyMillis') ?
                    (props.subscriptionObj.get('company').get('gpsFrequencyMillis') / 1000 / 60)
                    :
                    5
                  )
                }
                onChange={(e) => props.handleChangeGpsFrequencyMillis(e.target.value * 1000 * 60)}
              />
            </div>
          </td>
          <td style={{ padding: '2em' }}>
            <Toggle
              label="Cancelled Company"
              toggled={props.subscriptionObj.get('isClosed')}
              onToggle={props.handleChangeIsClosed}
            />
          </td>
        </tr>
      </tbody></table>

    </div>
  );
}

Modules.propTypes = {
  subscriptionObj: PropTypes.object.isRequired,
  handleChangeSubscriptionObj: PropTypes.array,
  isAOBRD: PropTypes.bool,
  handleChangeIsAOBRD: PropTypes.func.isRequired,
};

export default Modules;
