import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { connect } from 'react-redux';
import history from "sbHistory";
import { withRouter } from "react-router";

// Actions
import { tryLogin, verifyAuth } from 'actions/Auth';

// API
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL'

// Helpers
import { getCurrentUser } from 'api/Parse';

// Components
import LoginForm from '../view/LoginForm';
import ForgotPasswordModal from '../view/ForgotPasswordModal';

function getUsername() {
  const user = getCurrentUser();
  if (user) {
    return user.get('username');
  }
  return null;
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgotPasswordModal: false,
      fields: {
        email: getUsername() ? getUsername() : '',
        password: '',
      },
      error: '',
      disableLogin: false,
    };
    this.tryLogin = this.tryLogin.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  componentDidMount() {
    let error = getQueryParameter(this.props.location.search, "error");
    if (verifyAuth() && this.props.location.pathname !== '/login') {
      history.push('/users');
    }
  }

  tryLogin() {
    this.setState({ ...this.state, disableLogin: true, error: '' });
    tryLogin(this.state.fields).then(() => {
      history.push('/users');
      this.setState({ ...this.state, disableLogin: false });
    }, () => {
      this.setState({ ...this.state, disableLogin: false });
    });
  }

  handleFormChange(property, value) {
    const newState = { ...this.state };
    newState.fields[property] = value;
    this.setState(newState);
  }

  render() {
    let error = this.state.error;
    if (this.props.Auth && this.props.Auth.error) {
      error = this.props.Auth.error.message;
    }
    return (
      <div>
        <LoginForm
          error={error}
          disableLogin={this.state.disableLogin}
          emailHandler={this.emailHandler}
          handleSubmit={this.tryLogin}
          handleFormChange={this.handleFormChange}
          fields={this.state.fields}
          handleSignup={this.handleSignup}
        />
      </div>
    );
  }
}

Login.propTypes = {
  location: PropTypes.object,
  Auth: PropTypes.object,
};

const mapStateToProps = (state) => {
  if (state) {
    const { Auth } = state;
    return {
      Auth,
    };
  }
  return 0;
};

export default withRouter(connect(mapStateToProps)(Login));
