import React, { useState, useEffect, useContext } from 'react';
import Parse from 'parse';
import axios from 'axios';
import history from 'sbHistory';
import store from '../store';

/* Stopped auth0 stuff because it was too tough to get access tokens without reverting to hooks (probably some security thing) */
// import createAuth0Client from '@auth0/auth0-spa-js'
// // const domain = 'dev-kxof9dtc.us.auth0.com';
// let _initOptions = {
//   domain: 'dev-kxof9dtc.us.auth0.com',
//   client_id: 'hmAh9c4lDyzv0F71PRLjjqKVFllnIXb3',
//   // audience: 'https://api.onswitchboard.com/',
// }
// let _client;

// export const Auth0Context = React.createContext()


// const getInitOptions = _initOptions;

// const getAuth0Client = () => {
//   return new Promise(async (resolve, reject) => {
//     let client
//     if (!client) {
//       try {
//         client = await createAuth0Client(_initOptions)
//         resolve(client)
//       } catch (e) {
//         reject(new Error('getAuth0Client Error', e))
//       }
//     }
//   })
// }

// const getAuth0AccessToken = async (usePopup, ...p) => {
//   // const useAuth0 = () => useContext(Auth0Context)
//   // const { getAccessTokenSilently } = useAuth0();
//   // const accessToken = await getAccessTokenSilently({
//   //   audience: `https://${domain}/api/v2/`,
//   //   // scope: "read:current_user",
//   // });
//   // return accessToken;

//   if (!_client) {
//     _client = await getAuth0Client()
//   }
//   if (usePopup) {
//     return await _client.getTokenWithPopup(...p)
//   } else {
//     return await _client.getTokenSilently(...p)
//   }

//   // const options = {
//   //   method: 'POST',
//   //   url: `https://${_initOptions.domain}/oauth/token`,
//   //   headers: { 'content-type': 'application/x-www-form-urlencoded' },
//   //   data: new URLSearchParams({
//   //     grant_type: 'client_credentials',
//   //     client_id: _initOptions.client_id,
//   //     client_secret: _initOptions.client_secret,
//   //     audience: 'YOUR_API_IDENTIFIER'
//   //   })
//   // };

//   // return axios.request(options);
// }

const callSecuredParseFunction = async (functionName, options) => {
  let serverURL = store.getState().Main.serverURL.substr(0, store.getState().Main.serverURL.length - 6); // remove /parse

  // let token = await getAuth0AccessToken(false);
  // console.log(token.length);
  // if (token.length < 500) token = await getAuth0AccessToken(true);
  // const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkZoVmdTZmJoLTZpYzk4MG5relNiTSJ9.eyJpc3MiOiJodHRwczovL2Rldi1reG9mOWR0Yy51cy5hdXRoMC5jb20vIiwic3ViIjoiNWgwdzg0bk9VSWVBaU5xSnBrNXRpclpYeHZmbHZrV0ZAY2xpZW50cyIsImF1ZCI6Imh0dHBzOi8vYXBpLm9uc3dpdGNoYm9hcmQuY29tLyIsImlhdCI6MTY1Nzc2MjExNSwiZXhwIjoxNjU3ODQ4NTE1LCJhenAiOiI1aDB3ODRuT1VJZUFpTnFKcGs1dGlyWlh4dmZsdmtXRiIsImd0eSI6ImNsaWVudC1jcmVkZW50aWFscyJ9.qDZQCnz0BvSrkcxzJMgb7I83HoLRQroZLgIA2ssXJYNyUT3vHy_p0Tf3NOPVmkB1h-vGahHFqWfIT9CMFQpyHQgL2KRa3x50TxGuu7-S0E17pmqWfLupzZ3rQpuRzubQ-W43QGGh_AnSwbkVOmxY0skCTeIOd0jTS2SrnKx6_XYogFGv4_-5DFnEyWeT46nXMit9NIHWOVbIhRG-igbmUgtwgOPqZ2fVGlACM0_APr1dzKT77oR6dvTi3u6UBjbzGKYZJZgGtOjdVvO-5NbQWK_Q_tyHEtt8fkUNnr_INT9QEBXU-OgBkhiVtrpeCAp9Ts5LcLer_mJg_h7c6tHCvw';
  // console.log(token);


  if (!Parse.User.current() || !Parse.User.current().get('allowInternalToolUsage')) {
    history.push('/login');
    return Promise.reject(new Error('Unauthorized'));
  }

  try {
    return axios.post(
      `${serverURL}/api/internalTool/${functionName}`,
      {
        ...options,
        authReqUserId: Parse.User.current().id,
        authReqSessionToken: Parse.User.current().getSessionToken(),
      },
      // { headers: { Authorization: `Bearer ${token}` } },
    ).then((response) => {
      // need to turn into parse objects sometimes
      if (options && options.returnAsParse && options.parseClassName) {
        const data = response.data;
        if (Array.isArray(data) && data.length > 0 && data[0] && data[0].objectId && data.status !== 400) {
          // array
          const result = data.map((_obj, index) => {
            return Parse.Object.fromJSON(Object.assign({ className: options.parseClassName }, _obj));
          });
          return result;
        } else if (data.objectId) {
          // object
          return Parse.Object.fromJSON(Object.assign({ className: options.parseClassName }, data));
        }
      }

      return response.data;
    }).catch(e => {
      console.log(e);
      const error = {};
      if (e.response) {
        error.data = e.response.data;
        error.status = e.response.status;
        error.headers = e.response.headers;
        error.message = e.response.data;
      }
      return Promise.reject(error.message ? error : e);
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

export {
  // getAuth0AccessToken,
  // getInitOptions,
  callSecuredParseFunction,
}