/*
 * This file contains general getter functions useful throughout the app
 * Please list functions in alphabetical order and comment well unless
 * the function listing order depends on requisite functions (ex. define function before used)
 */

import Parse from 'parse';
import moment from 'moment';
import store from '../store';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import * as TextService from 'api/TextService';
import * as Auth from '../api/Auth';

import { initialize } from 'actions/Main';

function convertDriversEnabled() {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('internal:convertDriversEnabled', { returnAsParse: true }).then((asdf) => {
      console.log(asdf);
    });
  });
  return promise;
}

function setAllEventsAndLocationsToACL(eldDailyCertificationIdArr, companyId) {
  const promise = new Promise((resolve) => {
    const promises = [];
    for (let i = 0; i < eldDailyCertificationIdArr.length; i++) {
      promises.push(Auth.callSecuredParseFunction('_internal:setAllEventsAndLocationsToACL', { returnAsParse: true, parseClassName: '', eldDailyCertificationId: eldDailyCertificationIdArr[i], companyId }));
      Promise.all(promises).then(() => {
        resolve();
      }, (error) => {
        console.log(error);
        resolve();
      });
    }
  });
  return promise;
}

function createStripeCustomer(companyObj, token, force) {
  const promise = new Promise((resolve) => {
    Auth.callSecuredParseFunction('internal:getMainUserForCompany', { returnAsParse: true, parseClassName: '_User', companyId: companyObj.id }).then((userObj) => {
      Auth.callSecuredParseFunction('createStripeCustomer', {
        email: userObj.get('email'),
        companyName: companyObj.get('name'),
        token,
        companyObjectId: companyObj.id,
        force,
        returnAsParse: true,
        parseClassName: 'Subscription',
      }).then((updatedSubscription) => {
        resolve(updatedSubscription);
      });
    });
  });
  return promise;
}

function addCCToCustomer(companyObj, token) {
  return Auth.callSecuredParseFunction('addCardTokenToCustomer', {
    returnAsParse: true,
    token,
    companyObjectId: companyObj.id,
  });
}

function updateCustomerBillingEmail(companyObj, email) {
  return Auth.callSecuredParseFunction('updateCustomerBillingEmail', {
    returnAsParse: true,
    email,
    companyObjectId: companyObj.id,
  });
}

function saveSubscriptionModules(subscriptionObj) {
  const moduleObjArr = Helpers.getSubscriptionModuleObjArr(subscriptionObj);

  return Auth.callSecuredParseFunction('internal:saveSubscriptionModules', {
    returnAsParse: true,
    subscriptionId: subscriptionObj.id,
    moduleObjArr,
  });
}

function saveSubscriptionTaxPercent(subscriptionObj, taxPercent) {
  return Auth.callSecuredParseFunction('updateTaxPercentageForCustomerSubscription', {
    returnAsParse: true,
    switchboardSubscriptionId: subscriptionObj.id,
    taxPercent,
  });
}

function saveSubscriptionObjProperty(subscriptionObj, property, value) {
  return Auth.callSecuredParseFunction('_internal:saveSubscriptionObjProperty', {
    returnAsParse: true,
    subscriptionId: subscriptionObj.id,
    property,
    value,
  });
}

function uploadSubscriptionContract(subscriptionObj, parseFile) {
  return Auth.callSecuredParseFunction('_internal:uploadSubscriptionContract', {
    returnAsParse: true,
    subscriptionId: subscriptionObj.id,
    parseFile,
  });
}

function saveStartSubscription(subscriptionObj) {
  return Auth.callSecuredParseFunction('internal:saveStartSubscription', {
    returnAsParse: true,
    subscriptionId: subscriptionObj.id,
    startSubscription: subscriptionObj.get('startSubscription'),
  });
}

function createPlan(planName, internalName, currency, amount, interval, intervalCount) {
  return Auth.callSecuredParseFunction('internal:createPlan', {
    returnAsParse: true,
    planName,
    internalName,
    currency,
    amount,
    interval,
    intervalCount,
  });
}

function editStripePlan(stripePlanId, editObj) {
  return Auth.callSecuredParseFunction('internal:editStripePlan', {
    returnAsParse: true,
    stripePlanId,
    editObj,
  });
}

function saveEnableCompanyLinks(companyId, enableCompanyLinksBool) {
  return Auth.callSecuredParseFunction('internal:saveEnableCompanyLinks', {
    returnAsParse: true,
    companyId,
    enableCompanyLinksBool,
  });
}

function saveGpsFrequencyMillis(companyId, gpsFrequencyMillis) {
  return Auth.callSecuredParseFunction('internal:saveGpsFrequencyMillis', {
    returnAsParse: true,
    companyId,
    gpsFrequencyMillis,
  });
}

function saveIsClosed(companyId, isClosed) {
  console.log(companyId);
  console.log(isClosed);
  return Auth.callSecuredParseFunction('internal:saveSubscriptionIsClosed', {
    returnAsParse: true,
    companyId,
    isClosed,
  });
}

function saveIsClosedReason(companyId, isClosedReason) {
  return Auth.callSecuredParseFunction('internal:saveSubscriptionIsClosedReason', {
    returnAsParse: true,
    companyId,
    isClosedReason,
  });
}

function activateSimForCompany(iccid, companyId, imei, esn, reason, ratePlanSid) {
  console.log(iccid, companyId, imei, esn, reason, ratePlanSid);
  if (imei) {
    return Auth.callSecuredParseFunction('_internal:toggleSimStateByImei', {
      returnAsParse: true,
      imei,
      isActive: true,
      reason,
      ratePlanSid
    }).then(() => Auth.callSecuredParseFunction('_internal:setSimForCompany', {
      returnAsParse: true,
      imei,
      companyId,
      isActive: true,
      reason
    })).catch((err) => {
      Auth.callSecuredParseFunction('_internal:setSimForCompany', {
        returnAsParse: true,
        imei,
        companyId,
        reason
      });
      return Promise.reject(err);
    });
  }
  return Auth.callSecuredParseFunction('_internal:activateICCID', {
    returnAsParse: true,
    iccid,
    reason,
    ratePlanSid,
  }).then(() => Auth.callSecuredParseFunction('_internal:setSimForCompany', {
    returnAsParse: true,
    iccid,
    companyId,
    isActive: true,
    reason,
  })).catch((err) => {
    console.log(err);
    Auth.callSecuredParseFunction('_internal:setSimForCompany', {
      returnAsParse: true,
      iccid,
      companyId,
      reason,
    });
    return Promise.reject(err);
  });

}

function deactivateSimForCompany(iccid, companyId, imei, esn, reason) {
  if (imei) {
    return Auth.callSecuredParseFunction('_internal:toggleSimStateByImei', {
      returnAsParse: true,
      imei,
      isActive: false,
      reason,
    }).then(() => Auth.callSecuredParseFunction('_internal:setSimForCompany', {
      returnAsParse: true,
      imei,
      companyId,
      isActive: false,
      reason,
    })).catch((err) => {
      Auth.callSecuredParseFunction('_internal:setSimForCompany', {
        returnAsParse: true,
        imei,
        companyId,
        reason,
      });
      return Promise.reject(err);
    });
  }
  return Auth.callSecuredParseFunction('_internal:deactivateICCID', {
    returnAsParse: true,
    iccid,
  }).then(() => Auth.callSecuredParseFunction('_internal:setSimForCompany', {
    returnAsParse: true,
    iccid,
    companyId,
    isActive: false,
  })).catch((err) => {
    Auth.callSecuredParseFunction('_internal:setSimForCompany', {
      returnAsParse: true,
      iccid,
      companyId,
    });
    return Promise.reject(err);
  });
}

const toggleSimForCompany = async (companyId, isActive, reason, ratePlanSid, iccid, imei, trackerEsn, tabletUuid) => (
  Auth.callSecuredParseFunction('_internal:setSimForCompany', {
    returnAsParse: true,
    companyId,
    reason,
    iccid,
    imei,
    trackerEsn,
    tabletUuid,
  }).then(() => {
    console.log({
      returnAsParse: true,
      value: iccid,
      valueType: 'iccid',
      isActive,
      companyId,
      ratePlanSid
    });
    return Auth.callSecuredParseFunction('_internal:toggleSimState', {
      returnAsParse: true,
      value: iccid,
      valueType: 'iccid',
      isActive,
      companyId,
      ratePlanSid
    }).then(() => {
      return Auth.callSecuredParseFunction('_internal:setSimForCompany', {
        returnAsParse: true,
        companyId,
        isActive,
        reason,
        iccid,
        imei,
        trackerEsn,
        tabletUuid,
      });
    }).catch((err) => {
      console.log(err);
      Auth.callSecuredParseFunction('_internal:setSimForCompany', {
        returnAsParse: true,
        companyId,
        reason,
        iccid,
        imei,
        trackerEsn,
        tabletUuid,
      });
      return Promise.reject(err);
    });
  })
);

function setSimForCompany(value, valueType, iccid, companyId, _reason) {
  return Auth.callSecuredParseFunction('_internal:setSimForCompany', {
    returnAsParse: true,
    iccid,
    value,
    valueType,
    companyId,
    reason: _reason,
  })
}

function destroyDuplicateSim(simId) {
  return Auth.callSecuredParseFunction('_internal:destroyDuplicateSim', {
    returnAsParse: true,
    simId
  })
}

function updateSimRatePlan(companyId, value, valueType, ratePlanSid) {
  return Auth.callSecuredParseFunction('_internal:changeSimRatePlan', {
    returnAsParse: true,
    value,
    valueType,
    companyId,
    ratePlanSid
  });
}

function addTrailer(trailerInputObj) {
  return Auth.callSecuredParseFunction('_internal:addTrailerForCompany', {
    returnAsParse: true,
    trailerInputObj,
  });
}

function setIsActiveForSim(simObj) {
  return Auth.callSecuredParseFunction('_internal:setIsActiveForSim', {
    returnAsParse: true,
    simId: simObj.id,
  });
}

function setIsActiveForEldHardware(eldHardwareObj, companyObj) {
  return Auth.callSecuredParseFunction('_internal:setIsActiveForEldHardware', {
    returnAsParse: true,
    eldHardwareId: eldHardwareObj.id,
    companyId: companyObj.id,
  });
}

function setActiveSimCountForSubscription(subscriptionObj, activeSimCount) {
  return Auth.callSecuredParseFunction('_internal:setActiveSimCountForSubscription', {
    returnAsParse: true,
    subscriptionId: subscriptionObj.id,
    activeSimCount,
  });
}

function setAOBRDForCompany(companyId, isAOBRD) {
  return Auth.callSecuredParseFunction('_internal:setAOBRDForCompany', {
    returnAsParse: true,
    companyId,
    isAOBRD,
  });
}

function setCtpatForCompany(companyId, ctpatEnabled) {
  return Auth.callSecuredParseFunction('_internal:setCtpatForCompany', {
    returnAsParse: true,
    companyId,
    ctpatEnabled,
  });
}

function setBoolForCompany(companyId, property, bool) {
  return Auth.callSecuredParseFunction('_internal:setBoolForCompany', {
    returnAsParse: true,
    companyId,
    property,
    bool,
  });
}

function setResellerForCompany(companyId, resellerCompanyId) {
  return Auth.callSecuredParseFunction('_internal:setResellerForCompany', {
    returnAsParse: true,
    companyId,
    resellerCompanyId,
  });
}

function setActiveEldHardwareCountForSubscription(subscriptionObj, activeEldHardwareCount) {
  return Auth.callSecuredParseFunction('_internal:setActiveEldHardwareCountForSubscription', {
    returnAsParse: true,
    subscriptionId: subscriptionObj.id,
    activeEldHardwareCount,
  });
}

function editEldEventOdometer(eldEventId, odometer, type, allDayBool, odometerStart, odometerEnd) {
  return Auth.callSecuredParseFunction('_internal:editEldEventOdometer', {
    returnAsParse: true,
    eldEventId,
    odometer,
    type,
    allDay: allDayBool,
    odometerStart,
    odometerEnd
  });
}

function setCustomerAccountBalance(subscriptionId, accountBalance) {
  return Auth.callSecuredParseFunction('setCustomerAccountBalance', {
    returnAsParse: true,
    switchboardSubscriptionId: subscriptionId,
    accountBalance: Math.round(accountBalance),
  });
}

function payInvoice(subscriptionId, invoiceId) {
  return Auth.callSecuredParseFunction('payInvoice', {
    returnAsParse: true,
    switchboardSubscriptionId: subscriptionId,
    invoiceId,
  });
}

function voidInvoice(subscriptionId, invoiceId) {
  return Auth.callSecuredParseFunction('voidInvoice', {
    returnAsParse: true,
    switchboardSubscriptionId: subscriptionId,
    invoiceId,
  });
}

function sendVoidSlackMessage(companyName, invoiceDate, invoiceId, invoiceAmount, reason) {
  return Auth.callSecuredParseFunction('_internal:sendSlackNotification', {
    text: `Void Invoice request: *${companyName}*\n${invoiceId} (${invoiceDate && moment(invoiceDate).format('LL')}): $${invoiceAmount}\n\nReason: ${reason}`,
    channel: 'billing-voidrequest',
  });
}

const createInvoice = (subscriptionId, invoiceItemObjArr) => {
  return Auth.callSecuredParseFunction('createInvoice', {
    returnAsParse: true,
    switchboardSubscriptionId: subscriptionId,
    invoiceItemObjArr,
  });
}

function addSubscriptionItem(subscriptionId, currency, description, quantity, unit_amount) {
  return Auth.callSecuredParseFunction('addSubscriptionItem', {
    returnAsParse: true,
    switchboardSubscriptionId: subscriptionId,
    currency,
    description,
    quantity,
    unit_amount,
  });
}

function deleteSubscriptionItem(invoiceItem) {
  return Auth.callSecuredParseFunction('deleteSubscriptionItem', {
    returnAsParse: true,
    invoiceItemId: invoiceItem && (invoiceItem.invoice_item || invoiceItem.id),
  });
}

function updateSubscriptionResellerPlanAllowanceAmountAvailable(subscriptionResellerPlanAllowanceObj, amountAvailable) {
  return Auth.callSecuredParseFunction('saveSubscriptionResellerPlanAllowance', {
    returnAsParse: true,
    subscriptionResellerPlanAllowanceObj,
  });
}


function editVendorIntegrationUser(userId, property, value) {
  return Auth.callSecuredParseFunction('internal:editVendorIntegrationUser', {
    returnAsParse: true,
    userId,
    property,
    value
  });
}

function createSupportReport(userObj) {
  const company = userObj.get('belongsToCompany');
  const supportReport = new Parse.Object('SupportReport');
  supportReport.set('user', userObj);
  supportReport.set('company', company);
  supportReport.set('dateTime', new Date());
  return supportReport.save();
}

function saveELDHardwareTargetSoftwareVersion(eldHardwareId, targetSoftwareVersion, allBool) {
  return Auth.callSecuredParseFunction('_internal:fixELDHardwareTargetSoftwareVersion', {
    returnAsParse: true,
    eldHardwareId,
    version: targetSoftwareVersion,
    strict: allBool ? false : true,
  });
}

function saveForcedELD(eldHardwareId, forcedELD) {
  return Auth.callSecuredParseFunction('_internal:saveForcedELD', {
    returnAsParse: true,
    eldHardwareId,
    forcedELD,
  });
}

function registerNewUserToCompany(firstName, lastName, emailAddress, password, companyId) {
  return Auth.callSecuredParseFunction('registerNewUserToCompany', {
    returnAsParse: true,
    firstName,
    lastName,
    emailAddress,
    password,
    companyId,
  });
}

function editTripInspection(tripInspectionId, odometerKm, dateTime) {
  return Auth.callSecuredParseFunction('_internal:editTripInspection', {
    returnAsParse: true,
    tripInspectionId,
    odometerKm,
    dateTime
  });
}

function cloneTripInspection(tripInspectionId) {
  return Auth.callSecuredParseFunction('_internal:cloneTripInspection', {
    returnAsParse: true,
    tripInspectionId,
  });
}

function sendToContactAutomation(supportReportId, phoneNumber) {
  return Auth.callSecuredParseFunction('support:sendToContactAutomation', {
    returnAsParse: true,
    supportReportId,
    phoneNumber
  });
}

function saveSubscriptionInvoiceFooter(switchboardSubscriptionId, invoiceFooter) {
  return Auth.callSecuredParseFunction('updateCustomerInvoiceFooter', {
    returnAsParse: true,
    switchboardSubscriptionId,
    invoiceFooter
  });
}

function uploadSIMIMEISidLinks(file) {
  const tempFile = new Parse.Object('TempFile');
  const cleanedFileName = file.name.substring(0, file.name.length - 4).replace(/\W/g, '').concat('.csv');
  const parseFile = new Parse.File(cleanedFileName, file);
  tempFile.set('file', parseFile);
  return tempFile.save().then((savedTempFile) => {
    return Auth.callSecuredParseFunction('_internal:uploadSIMIMEISidLinks', {
      returnAsParse: true,
      tempFileId: savedTempFile.id,
    });
  });
}

function deleteCustomerSources(switchboardSubscriptionId) {
  return Auth.callSecuredParseFunction('deleteCustomerSources', {
    returnAsParse: true,
    switchboardSubscriptionId,
  });
}

function payUpcomingInvoice(customerId) {
  return Auth.callSecuredParseFunction('payUpcomingInvoice', {
    returnAsParse: true,
    customerId,
  });
}

function hideUnhideEquipment(equipmentObjId, className, hideBool) {
  return Auth.callSecuredParseFunction('_internal:hideUnhideEquipment', {
    returnAsParse: true,
    equipmentObjId,
    className,
    hideBool,
  });
}

function updateDriverModule(driverId, valueType, value) {
  return Auth.callSecuredParseFunction('_internal:updateDriverModule', {
    returnAsParse: true,
    driverId,
    valueType,
    value
  });
}

function setELDEventsToAOBRD(driverId, date) {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('support:setDayELDEventsToAOBRDEnabled', { driverId, date }).then(
      response => {
        console.log(response);
        resolve(response);
      },
      error => reject(error)
    );
  });
  return promise;
}

function setDisableEldDailyCertification(eldDailyCertificationId, disableBool) {
  return Auth.callSecuredParseFunction('_internal:setDisableEldDailyCertification', {
    returnAsParse: true,
    parseClassName: 'ELDDailyCertification',
    eldDailyCertificationId,
    disableBool,
  });
}

function setAutogeneratedForDriver(driverId) {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('internal:setAutogeneratedForDriver', {
      returnAsParse: true,
      driverId
    }).then(
      response => {
        resolve(response);
      },
      error => reject(error)
    );
  });
  return promise;
}

function setVendorIntegrationProperty(vendorIntegrationId, companyId, property, value) {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('internal:setVendorIntegrationProperty', {
      returnAsParse: true,
      vendorIntegrationId,
      companyId,
      property,
      value,
    }).then(
      response => {
        resolve(response);
      },
      error => reject(error)
    );
  });
  return promise;
}

function repairELDVehicleUnitId(companyObjectId, startDate, endDate, driverObjectId, oldUnitId, newUnitId) {
  const promises = [];

  promises.push(Auth.callSecuredParseFunction('_internal:repairELDVehicleUnitId', {
    returnAsParse: true,
    tableReferenceInt: 0,
    companyObjectId,
    driverObjectId,
    dateTimeInterval: [startDate, endDate],
    vehicleUnitId: oldUnitId ? oldUnitId : undefined,
    newVehicleUnitId: newUnitId ? newUnitId : undefined,
    ignoreMismatchedUnitIds: !newUnitId ? false : true,
    overrideUndefinedVehicles: !oldUnitId ? true : false,
  }));

  promises.push(Auth.callSecuredParseFunction('_internal:repairELDVehicleUnitId', {
    returnAsParse: true,
    tableReferenceInt: 1,
    companyObjectId,
    driverObjectId,
    dateTimeInterval: [startDate, endDate],
    vehicleUnitId: oldUnitId ? oldUnitId : undefined,
    newVehicleUnitId: newUnitId ? newUnitId : undefined,
    ignoreMismatchedUnitIds: !newUnitId ? false : true,
    overrideUndefinedVehicles: !oldUnitId ? true : false,
  }));

  return Promise.all(promises);
}

function handleWSBVehicleSubscription(companyId, subscriptionId, addressId, vehicleId, enabled, preclearConfig) {
  return Auth.callSecuredParseFunction('handleWSBVehicleSubscription',
    {
      companyId,
      subscriptionId,
      addressId,
      vehicleId,
      enabled,
      preclearConfig
    }
  );
}

function toggleResellerForUser(userId, isActiveBool) {
  return Auth.callSecuredParseFunction('internal:toggleResellerForUser',
    {
      returnAsParse: true,
      parseClassName: 'Reseller',
      userId,
      isActiveBool
    }
  );
}

function replaceIccidForSim(simObjId, iccid) {
  return Auth.callSecuredParseFunction('_internal:replaceIccidForSim',
    {
      returnAsParse: true,
      simObjId,
      iccid
    }
  );
}

function sendDeviceCommand(uniqueid, iccid, command) {
  return Auth.callSecuredParseFunction('sendDeviceCommand',
    {
      returnAsParse: true,
      uniqueid,
      iccid,
      command
    }
  );
}

const setResellerProperty = (resellerId, property, value) => {
  return Auth.callSecuredParseFunction('_internal:setResellerProperty',
    {
      returnAsParse: true,
      parseClassName: 'Reseller',
      resellerId,
      property,
      value
    }
  );
};

const publishUpdateForScalefusionDevice = (imei) => {
  return Auth.callSecuredParseFunction('publishUpdateForScalefusionDevice',
    {
      imei
    }
  );
}

export {
  addTrailer,
  convertDriversEnabled,
  setAllEventsAndLocationsToACL,
  createStripeCustomer,
  addCCToCustomer,
  updateCustomerBillingEmail,
  createPlan,
  editStripePlan,
  saveSubscriptionModules,
  saveSubscriptionTaxPercent,
  saveSubscriptionInvoiceFooter,
  saveSubscriptionObjProperty,
  uploadSubscriptionContract,
  saveStartSubscription,
  saveEnableCompanyLinks,
  saveGpsFrequencyMillis,
  saveIsClosed,
  saveIsClosedReason,
  activateSimForCompany,
  deactivateSimForCompany,
  updateSimRatePlan,
  toggleSimForCompany,
  setSimForCompany,
  destroyDuplicateSim,
  setIsActiveForSim,
  setIsActiveForEldHardware,
  setActiveSimCountForSubscription,
  setActiveEldHardwareCountForSubscription,
  setAOBRDForCompany,
  setCtpatForCompany,
  setBoolForCompany,
  editEldEventOdometer,
  setCustomerAccountBalance,
  payInvoice,
  voidInvoice,
  sendVoidSlackMessage,
  createInvoice,
  addSubscriptionItem,
  deleteSubscriptionItem,
  updateSubscriptionResellerPlanAllowanceAmountAvailable,
  editVendorIntegrationUser,
  createSupportReport,
  saveELDHardwareTargetSoftwareVersion,
  saveForcedELD,
  registerNewUserToCompany,
  editTripInspection,
  cloneTripInspection,
  setResellerForCompany,
  sendToContactAutomation,
  uploadSIMIMEISidLinks,
  deleteCustomerSources,
  payUpcomingInvoice,
  hideUnhideEquipment,
  updateDriverModule,
  setELDEventsToAOBRD,
  setDisableEldDailyCertification,
  setAutogeneratedForDriver,
  setVendorIntegrationProperty,
  repairELDVehicleUnitId,
  handleWSBVehicleSubscription,
  toggleResellerForUser,
  replaceIccidForSim,
  sendDeviceCommand,
  setResellerProperty,
  publishUpdateForScalefusionDevice,
};
