import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Chip, TableRow, TableRowColumn } from 'material-ui';

import store from '../../../store';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import * as Sim from 'api/Sim';

// Components

// CSS
// import './EditSubscriptionModal.module.scss';

class EditSubscriptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    console.log(this.props.eldHardware.get('lastCheckIn'));
    console.log(moment(this.props.eldHardware.get('lastCheckIn')).isAfter(moment().subtract(3, 'months')));
  }

  render() {
    return (
      <TableRow>
        <TableRowColumn>{this.props.eldHardware.get('serial')}</TableRowColumn>
        <TableRowColumn>
          <div>
            {this.props.eldHardware.get('lastCheckIn') &&
              <div>
                {moment(this.props.eldHardware.get('lastCheckIn')).isAfter(moment().subtract(3, 'months')) &&
                  <Chip size="small" backgroundColor={'#cefdea'}>
                    {moment(this.props.eldHardware.get('lastCheckIn')).format('LL')}
                  </Chip>
                }
                {moment(this.props.eldHardware.get('lastCheckIn')).isSameOrBefore(moment().subtract(3, 'months')) &&
                  <Chip size="small" backgroundColor={'#ff6666'}>
                    {moment(this.props.eldHardware.get('lastCheckIn')).format('LL')}
                  </Chip>
                }
              </div>
            }
          </div>
        </TableRowColumn>
      </TableRow>
    );
  }

}

EditSubscriptionModal.propTypes = {
  eldHardware: PropTypes.object.isRequired,
};

export default EditSubscriptionModal;
