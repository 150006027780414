import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Reducers from 'reducers/Reducers';

// Prod
// const store = createStore(Reducers, compose(applyMiddleware(thunk)));

// Dev
const store = createStore(Reducers, compose(applyMiddleware(thunk), window.devToolsExtension ? window.devToolsExtension() : f => f));

export default store;
