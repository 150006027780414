import PropTypes from 'prop-types';
import React from 'react';
import history from 'sbHistory';
import LazyLoad from 'react-lazy-load';

import { Table } from 'react-bootstrap';

// API
import * as Helpers from 'api/Helpers';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import DriverOdometersRow from 'components/CompanyOdometersTable/container/DriverOdometersRow';

// CSS
import styles from './VehiclesTable.module.scss';

class DriversTable extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="defaultTable">
        <Table responsive hover>
          <thead>
            <tr>
              <th>Driver</th>
              <th>Driver Details</th>
              <th>Company</th>
              <th>Odometers</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.loading &&
              <tr className={styles.loadingIconNoHover}>
                <td colSpan="7" className="loadingIconPadding"><LoadingIcon /></td>
              </tr>
            }
            {!this.props.loading && this.props.drivers.length === 0 &&
              <tr>
                <td colSpan="7" className="emptyRowMessage">No Drivers to Display</td>
              </tr>
            }
            {!this.props.loading && this.props.drivers.length > 0 &&
              this.props.drivers.map((driver) =>
                <DriverOdometersRow key={driver.id} driver={driver} />
              )
            }
          </tbody>
        </Table>
      </div>
    );
  }
}

DriversTable.propTypes = {
  drivers: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default DriversTable;

