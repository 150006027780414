import Parse from 'parse';
import moment from 'moment';
import store from '../store';
import * as Helpers from './Helpers';

function readCsvFileAsObject(file, format = 'UTF-8') {
  if (typeof window.FileReader !== 'function') {
    return console.log('The File API is not supported in this browser version');
  }
  const promise = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file, format);
    reader.onload = (e) => {
      const csvString = e.target.result;
      const lines = csvString.split('\n');
      const result = [];

      const headers = lines[0].split(',');

      for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentline = lines[i].split(',');
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        result.push(obj);
      }
      resolve(result); // JSON
    };
    reader.onerror = (e) => {
      alert('Error with Reading File');
      reject('Error with Reading File');
    };
  });
  return promise;
}

export {
  readCsvFileAsObject,
};
// var reader = new FileReader();
// reader.readAsText(file, "UTF-8");
// reader.onload = function (evt) {
//     console.log(evt.target.result);
// }
// reader.onerror = function (evt) {
//   alert('blegh');
// }
