import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, Toggle, TextField } from 'material-ui';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import * as Helpers from 'api/Helpers';

// Components
// import SimRow from './SimRow';
// import TwilioInfo from '../../TwilioInfo/container/TwilioInfo';

// CSS

class TrailersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      objArr: [],
    };
    this.refreshArr = this.refreshArr.bind(this);
  }

  componentDidMount() {
    this.refreshArr();
  }

  refreshArr() {
    Getters.getTrailersByCompany(this.props.companyId).then((objArr) => {
      const sortedObjArr = objArr.sort((a, b) => {
        if (!b.get('tc_devices') || !b.get('tc_devices').get('uniqueid')) return -1;
        if (!a.get('tc_devices') || !a.get('tc_devices').get('uniqueid')) return 1;
        return 0;
      });
      this.setState({ ...this.state, objArr: sortedObjArr });
    });
  }

  generateCsv() {
    var lineArray = ['Trailer,ESN,Last Active'];

    for (let i = 0; i < this.state.objArr.length; i++) {
      const obj = this.state.objArr[i];
      const unitId = obj.get('unitId');
      const hardwareId = obj.get('tc_devices') && obj.get('tc_devices').get('uniqueid');
      const latestEldEvent = obj.get('tc_devices') && moment(obj.get('tc_devices').get('lastupdate')).format('YYYY-MMMM-DD');
      lineArray.push([unitId, hardwareId, latestEldEvent].join(','));
    }

    var csvContent = lineArray.join("\n");
    Helpers.createCsvFile('Trailers - ' + moment().format('YYYY-MM-DD'), csvContent, true);
  }

  render() {
    const trailersCreatedLastMonth = this.state.objArr.filter((obj) => moment(obj.get('createdAt')).isAfter(moment().subtract(1, 'month').startOf('month')))

    const totalUniqueHardwareIdArr = [...new Set(this.state.objArr.filter((obj) => obj.get('tc_devices') && obj.get('tc_devices').get('uniqueid')).map((obj) => obj.get('tc_devices').get('uniqueid')))];
    const lastThreeMonthsHardwareIdArr = this.state.objArr.filter((obj) => (
      obj.get('tc_devices') && obj.get('tc_devices').get('uniqueid') &&
      obj.get('tc_devices') && moment(obj.get('tc_devices').get('lastupdate')).isAfter(moment().subtract(3, 'months').startOf('month'))
    )).map((obj) => obj.get('tc_devices') && obj.get('tc_devices').get('uniqueid'));
    const lastThreeMonthsUniqueHardwareIdArr = [...new Set(lastThreeMonthsHardwareIdArr)];

    const lastMonthHardwareIdArr = this.state.objArr.filter((obj) => (
      obj.get('tc_devices') && obj.get('tc_devices').get('uniqueid') &&
      obj.get('tc_devices') && moment(obj.get('tc_devices').get('lastupdate')).isAfter(moment().subtract(1, 'months').startOf('month'))
    )).map((obj) => obj.get('tc_devices') && obj.get('tc_devices').get('uniqueid'));
    const lastMonthUniqueHardwareIdArr = [...new Set(lastMonthHardwareIdArr)];


    return (
      <div>
        <button className={`buttonDefault`} onClick={() => this.generateCsv()}>Download CSV</button>
        <br />
        <Table
          selectable={false}
          multiSelectable={false}
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          ><TableRow>
              <TableRowColumn style={{ width: '34%' }}>
                <b>Trailer</b>
                <br />
                Created Last Month: {trailersCreatedLastMonth.length}
              </TableRowColumn>
              <TableRowColumn style={{ width: '33%' }}>
                <b>Hardware ID</b>
                <br />
                Total: {totalUniqueHardwareIdArr.length}
                <br />
                Last Month: {lastMonthUniqueHardwareIdArr.length}
                <br />
                Last 3 Months: {lastThreeMonthsUniqueHardwareIdArr.length}
              </TableRowColumn>
              <TableRowColumn style={{ width: '33%' }}>
                <b>Last Update</b>
                <br />
                Last Month:
                {
                  this.state.objArr.filter((obj) => (
                    obj.get('tc_devices') && moment(obj.get('tc_devices').get('lastupdate')).isAfter(moment().subtract(1, 'months').startOf('month'))
                  )).length
                }
                <br />
                Last 3 Months:
                {
                  this.state.objArr.filter((obj) => (
                    obj.get('tc_devices') && moment(obj.get('tc_devices').get('lastupdate')).isAfter(moment().subtract(3, 'months').startOf('month'))
                  )).length
                }
              </TableRowColumn>
            </TableRow></TableHeader>
          <TableBody
            displayRowCheckbox={false}
          >
            {this.state.objArr.map((obj) => (
              <TableRow key={obj.id}>
                <TableRowColumn style={{ width: '34%' }}>
                  {obj.get('unitId')}
                </TableRowColumn>
                <TableRowColumn style={{ width: '33%' }}>
                  {obj.get('tc_devices') && obj.get('tc_devices').get('uniqueid')}
                </TableRowColumn>
                <TableRowColumn style={{ width: '33%' }}>
                  {obj.get('tc_devices') && obj.get('tc_devices').get('lastupdate') && moment(obj.get('tc_devices').get('lastupdate')).format('YYYY-MMMM-DD')}
                </TableRowColumn>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

}

TrailersList.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default TrailersList;
