/* global StripeCheckout */
import PropTypes from 'prop-types';

import React from 'react';
import { Modal } from 'react-bootstrap';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

import styles from './styles.module.scss';

class StripeCheckoutModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card: null,
      error: '',
    }
    this.showCheckout = this.showCheckout.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showCheckout && !this.state.card) {
      this.setState({ ...this.state }, () => {
        // Set up Stripe.js and Elements to use in checkout form
        const stripe = Stripe(this.props.Subscription.stripePublishableKey);
        const elements = stripe.elements();

        const style = {
          base: {
            iconColor: '#666EE8',
            color: '#31325F',
            lineHeight: '40px',
            fontWeight: 300,
            fontSize: '15px',

            '::placeholder': {
              color: '#CFD7E0',
            },
          },
        };

        const card = elements.create('card', { style });
        card.mount("#card-element");
        this.setState({ ...this.state, card, stripe });
      });
    }
  }

  showCheckout() {
    this.handler = StripeCheckout.configure({
      key: this.props.Subscription.stripePublishableKey,
      locale: 'auto',
      token: (token) => {
        this.props.handleStripeToken(token).catch(error => {
          this.setState({ error: error.message, loading: false });
        });
      },
    });

    // Open Checkout with further options:
    this.handler.open({
      name: 'Switchboard',
      description: 'Recurring Charges',
      panelLabel: 'Confirm',
      zipCode: true,
      billingAddress: true,
    });
    // e.preventDefault();
  }

  handleCheckout() {
    this.setState({ ...this.state, error: '', loading: true }, () => {
      this.state.stripe.createToken(this.state.card).then((result) => {
        // Handle result.error or result.token
        if (result.token) {
          this.props.handleStripeToken(result.token).then(() => {
            this.setState({ ...this.state, error: '', loading: false });
          }, error => {
            this.setState({ error: error.message, loading: false });
          });
        } else {
          this.setState({ ...this.state, error: result.error, loading: false });
          console.log(result.error);
        }
      }, (error) => {
        console.log(error);
      });
    });
  }

  render() {
    // if (this.props.showCheckout) {
    //   this.showCheckout();
    // }
    return (
      <Modal
        backdrop="static"
        show={this.props.showCheckout}
        onHide={this.props.closeCCModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Credit Card Entry</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={styles.modalBody}>
            <div id="card-element" />
            <div id="card-errors" role="alert" />
            <div className="errorText">
              {this.state.error}
              <br />
            </div>
            {!this.state.loading &&
              <button onClick={() => this.handleCheckout()}>Save CC</button>
            }
            {this.state.loading &&
              <LoadingIcon />
            }
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

StripeCheckoutModal.propTypes = {
  Subscription: PropTypes.object.isRequired,
  showCheckout: PropTypes.bool.isRequired,
  handleStripeToken: PropTypes.func.isRequired,
  closeCCModal: PropTypes.func.isRequired,
};

export default StripeCheckoutModal;
