import React from 'react';
import moment from 'moment';
import {
  DatePicker,
  Toggle,
  Tabs,
  Tab,
  SelectField,
  TextField,
  MenuItem,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableRowColumn,
} from 'material-ui';
import PropTypes from 'prop-types';

// Components
import PlansList from '../container/PlansList';
import ContractsList from '../container/ContractsList';
import Subscription from './Subscription';
import Modules from './Modules';
import ResellerList from '../container/ResellerList';
import SimsList from '../container/SimsList';
import ELDList from '../container/ELDList';
import DriversList from '../container/DriversList';
import VehiclesList from '../container/VehiclesList';
import ELDHardwareList from '../container/ELDHardwareList';
import TrailersList from '../container/TrailersList';
import Integrations from '../container/Integrations';
import CustomerSubscriptions from '../container/CustomerSubscriptions';
import RegisterCloudUser from '../container/RegisterCloudUser';
import SubscriptionNote from '../container/SubscriptionNote';
import ContractFieUploader from '../container/ContractFileUploader';

// CSS
import styles from './EditSubscriptionModal.module.scss';

const placeholderContractNotes = (companyObj, resellerCompanyObj) => {
  return (
    ((resellerCompanyObj && resellerCompanyObj.get('notes')) ? resellerCompanyObj.get('notes') : '') +
    `
Type of Contract (Yearly/Monthly/etc.): 
Term of Contract + Start Date: 
Enterprise Package Pricing: 
Software Pricing: 
Discount Notes: 
Termination Fee: `
  );
};

function EditSubscriptionModal(props) {
  return (
    <div>
      <Tabs
        value={props.activeTab}
        onChange={props.handleChangeTab}
      >
        <Tab label="Plans" value="subscriptionPlans" />
        <Tab label="Subscription" value="subscription" />
        <Tab label="Modules" value="modules" />
        <Tab label="SIMs" value="sims" />
        {/* { props.accountInfo.subscriptionObj && props.accountInfo.subscriptionObj.get('company') && props.accountInfo.subscriptionObj.get('company').get('promoCode') &&
          <Tab label="Reseller" value="reseller" />
        } */}
        <Tab label="Drivers" value="drivers" />
        <Tab label="ELD Hardware" value="eldHardware" />
        <Tab label="Vehicles" value="vehicles" />
        <Tab label="Trailers" value="trailers" />
        {/* <Tab label="ELD" value="elds" /> */}
        <Tab label="Integrations" value="integrations" />
        {/* <Tab label="Register" value="registerCloudUser" /> */}
      </Tabs>
      <div className={styles.monthlyFee}>
        <div className={styles.options}>
          {props.activeTab === 'subscriptionPlans' &&
            (
              <div>
                {props.accountInfo.subscriptionObj &&
                  <SubscriptionNote
                    subscriptionObj={props.accountInfo.subscriptionObj}
                    companyReseller={props.accountInfo.companyReseller}
                  />
                }
                <br />
                {props.accountInfo.subscriptionObj &&
                  <ContractsList
                    subscriptionContractArr={props.accountInfo.subscriptionContractArr}
                    subscriptionObj={props.accountInfo.subscriptionObj}
                    refreshSubscription={props.refreshSubscription}
                  />
                }
                <br />
                <CustomerSubscriptions
                  subscriptionObj={props.accountInfo.subscriptionObj}
                  currency={props.accountInfo.currency}
                  handleSaveSubscriptionObjProperty={props.handleSaveSubscriptionObjProperty}
                />
                <br />
                {/* <PlansList
                  subscriptionPlans={props.accountInfo.subscriptionPlans}
                  handleChangeSubscription={props.handleChangeSubscription}
                  refreshSubscriptionPlans={props.refreshSubscriptionPlans}
                />
                <br /> */}
                <div>
                  Tax percent &nbsp;
                  <input
                    type="number"
                    step="1"
                    value={props.accountInfo.taxPercent}
                    onChange={(e) => props.handleChangeSubscriptionBillingInfo('taxPercent', e.target.value)}
                  />
                  <button
                    className={`${styles.startButton} buttonDefault`}
                    onClick={() => props.handleSaveSubscriptionBillingInfo('taxPercent')}
                    disabled={props.disableButton}
                  >
                    <span>Save</span>
                  </button>
                </div>
              </div>
            )
          }
          {props.activeTab === 'subscription' && props.accountInfo.subscriptionObj &&
            <Subscription
              currency={props.accountInfo.currency}
              subscriptionObj={props.accountInfo.subscriptionObj}
              subscriptionEndDate={props.accountInfo.subscriptionEndDate}
              subscriptionInvoices={props.accountInfo.subscriptionInvoices}
              subscriptionAddInvoiceItem={props.accountInfo.subscriptionAddInvoiceItem}
              subscriptionUpcomingInvoiceItems={props.accountInfo.subscriptionUpcomingInvoiceItems}
              subscriptionCCLastFour={props.accountInfo.subscriptionCCLastFour}
              subscriptionCCexpMonth={props.accountInfo.subscriptionCCexpMonth}
              subscriptionCCexpYear={props.accountInfo.subscriptionCCexpYear}
              ccCountry={props.accountInfo.ccCountry}
              ccStateProvince={props.accountInfo.ccStateProvince}
              ccPostalCode={props.accountInfo.ccPostalCode}
              subscriptionAccountBalance={props.accountInfo.subscriptionAccountBalance}
              subscriptionBillingDescription={props.accountInfo.subscriptionBillingDescription}
              subscriptionBillingEmail={props.accountInfo.subscriptionBillingEmail}
              invoicePrefix={props.accountInfo.invoicePrefix}
              invoiceFooter={props.accountInfo.invoiceFooter}
              handleChangeAddInvoiceItem={props.handleChangeAddInvoiceItem}
              handleAddInvoiceItem={props.handleAddInvoiceItem}
              handleDeleteInvoiceItem={props.handleDeleteInvoiceItem}
              disableButton={props.disableButton}
              openCCModal={props.openCCModal}
              showCCModal={props.showCCModal}
              handleChangeSubscriptionBillingInfo={props.handleChangeSubscriptionBillingInfo}
              handleSaveSubscriptionBillingInfo={props.handleSaveSubscriptionBillingInfo}
              savingInvoiceItem={props.savingInvoiceItem}
              payInvoice={props.payInvoice}
              showConfirmVoidInvoiceModal={props.showConfirmVoidInvoiceModal}
              payingVoidingInvoice={props.payingVoidingInvoice}
              payInvoiceError={props.payInvoiceError}
              resellerCompanyArr={props.resellerCompanyArr}
              handleChangeReseller={props.handleChangeReseller}
              handleShowConfirmDeleteCCModal={() => props.handleShowConfirmDeleteCCModal()}
              handleShowConfirmPayUpcomingInvoiceModal={() => props.handleShowConfirmPayUpcomingInvoiceModal()}
              updateCustomerBillingEmail={props.updateCustomerBillingEmail}
              getMoreInvoices={props.getMoreInvoices}
              refreshSubscription={props.refreshSubscription}
            />
          }
          {props.activeTab === 'modules' && props.accountInfo.subscriptionObj &&
            <Modules
              moduleArr={props.moduleArr}
              subscriptionObj={props.accountInfo.subscriptionObj}
              handleChangeSubscriptionObj={props.handleChangeSubscriptionObj}
              isAOBRD={props.accountInfo.isAOBRD}
              ctpatEnabled={props.accountInfo.ctpatEnabled}
              showEldCsvDownload={props.accountInfo.showEldCsvDownload}
              handleChangeShowEldCsvDownload={props.handleChangeShowEldCsvDownload}
              handleChangeIsAOBRD={props.handleChangeIsAOBRD}
              handleChangeCtpat={props.handleChangeCtpat}
              handleChangeStartSubscription={props.handleChangeStartSubscription}
              handleChangeEnableCompanyLinks={props.handleChangeEnableCompanyLinks}
              handleChangeGpsFrequencyMillis={props.handleChangeGpsFrequencyMillis}
              handleChangeIsClosed={props.handleChangeIsClosed}
              handleChangeIsClosedReason={props.handleChangeIsClosedReason}
              handleSaveSubscriptionObj={props.handleSaveSubscriptionObj}
              handleChangeUseOdometerOffset={props.handleChangeUseOdometerOffset}
              handleChangeAllowAutomatedSIMReset={props.handleChangeAllowAutomatedSIMReset}
              startSubscription={props.accountInfo.subscriptionObj.get('startSubscription')}
            />
          }
          {/* { props.activeTab === 'reseller' && props.accountInfo.subscriptionObj && props.accountInfo.subscriptionObj.get('company').id &&
            <ResellerList
              subscriptionObj={props.accountInfo.subscriptionObj}
              companyId={props.accountInfo.subscriptionObj.get('company').id}
            />
          } */}
          {props.activeTab === 'sims' && props.accountInfo.subscriptionObj && props.accountInfo.subscriptionObj.get('company').id &&
            <SimsList
              companyId={props.accountInfo.subscriptionObj.get('company').id}
            />
          }
          {props.activeTab === 'drivers' && props.accountInfo.subscriptionObj && props.accountInfo.subscriptionObj.get('company').id &&
            <DriversList
              companyId={props.accountInfo.subscriptionObj.get('company').id}
            />
          }
          {props.activeTab === 'vehicles' && props.accountInfo.subscriptionObj && props.accountInfo.subscriptionObj.get('company').id &&
            <VehiclesList
              companyId={props.accountInfo.subscriptionObj.get('company').id}
            />
          }
          {props.activeTab === 'eldHardware' && props.accountInfo.subscriptionObj && props.accountInfo.subscriptionObj.get('company').id &&
            <ELDHardwareList
              companyId={props.accountInfo.subscriptionObj.get('company').id}
            />
          }
          {props.activeTab === 'trailers' && props.accountInfo.subscriptionObj && props.accountInfo.subscriptionObj.get('company').id &&
            <TrailersList
              companyId={props.accountInfo.subscriptionObj.get('company').id}
            />
          }
          {/* { props.activeTab === 'drivers' && props.accountInfo.subscriptionObj && props.accountInfo.subscriptionObj.get('company').id &&
            <VehiclesList
              companyId={props.accountInfo.subscriptionObj.get('company').id}
            />
          }
          { props.activeTab === 'drivers' && props.accountInfo.subscriptionObj && props.accountInfo.subscriptionObj.get('company').id &&
            <TrailersList
              companyId={props.accountInfo.subscriptionObj.get('company').id}
            />
          } */}
          {/* { props.activeTab === 'elds' && props.accountInfo.subscriptionObj && props.accountInfo.subscriptionObj.get('company') &&
            <ELDList
              company={props.accountInfo.subscriptionObj.get('company')}
            />
          } */}
          {props.activeTab === 'integrations' && props.accountInfo.subscriptionObj && props.accountInfo.subscriptionObj.get('company').id &&
            <Integrations
              subscriptionObj={props.accountInfo.subscriptionObj}
              companyId={props.accountInfo.subscriptionObj.get('company').id}
            />
          }
          {/* { props.activeTab === 'registerCloudUser' && props.accountInfo.subscriptionObj && props.accountInfo.subscriptionObj.get('company') &&
            <RegisterCloudUser
              company={props.accountInfo.subscriptionObj.get('company')}
            />
          } */}
        </div>
        <br />
        <br />
        {props.error &&
          <div className={styles.error}>
            {props.error}
          </div>
        }
        <br />

      </div>
    </div>
  );
}

EditSubscriptionModal.propTypes = {
  activeTab: PropTypes.string.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
  accountInfo: PropTypes.object.isRequired,
  loadingProgress: PropTypes.number,
  error: PropTypes.string.isRequired,
  handleChangeSubscription: PropTypes.func.isRequired,
  handleUpdateModule: PropTypes.func.isRequired,
  handleSaveSubscription: PropTypes.func.isRequired,
  showCCModal: PropTypes.bool.isRequired,
  disableButton: PropTypes.bool.isRequired,
  handleChangeIsAOBRD: PropTypes.func.isRequired,
  handleChangeSubscriptionBillingInfo: PropTypes.func.isRequired,
  handleSaveSubscriptionBillingInfo: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  payInvoice: PropTypes.func.isRequired,
  voidInvoice: PropTypes.func.isRequired,
  payingVoidingInvoice: PropTypes.bool,
  resellerCompanyArr: PropTypes.array,
  handleChangeReseller: PropTypes.func.isRequired,
};

export default EditSubscriptionModal;
