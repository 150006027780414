import store from 'store';
import * as Destroyers from 'api/Destroyers';
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import * as Helpers from 'api/Helpers';
import * as Parse from 'api/Parse';
import * as ActionConstants from './ActionConstants';

function fetchingSubscriptions() {
  return {
    type: ActionConstants.FETCH_SUBSCRIPTIONS_INPROGRESS,
  };
}

function fetchSubscriptionsSuccess(subscriptions) {
  return {
    type: ActionConstants.FETCH_SUBSCRIPTIONS_SUCCESS,
    subscriptions,
  };
}

function fetchSubscriptionsError(error) {
  return {
    type: ActionConstants.FETCH_SUBSCRIPTIONS_ERROR,
    error,
  };
}

function deleteSubscriptions() {
  return {
    type: ActionConstants.DELETE_SUBSCRIPTIONS,
  };
}

export function fetchActiveSubscriptionsForState() {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingSubscriptions());
    Getters.getActiveSubscriptions().then(
      subscriptions => {
        resolve(subscriptions);
        store.dispatch(fetchSubscriptionsSuccess(subscriptions));
      },
      error => {
        reject(error);
        store.dispatch(fetchSubscriptionsError(error));
      }
    );
  });
  return promise;
}

export function deleteSubscriptionsForState() {
  store.dispatch(deleteSubscriptions());
}
