import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

// API
import * as Helpers from 'api/Helpers';
import * as Setters from 'api/Setters';

// Actions

// Components
import ErrorModal from 'components/ErrorModal/ErrorModal';
import DeleteXButton from 'components/DeleteXButton/container/DeleteXButton';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import LoadingModal from 'components/LoadingModal/container/LoadingModal';

// CSS
import styles from './EquipmentRow.module.scss';

class TrailerRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commandString: '',
      error: '',
      loading: false,
    };

    this.sendCommand = this.sendCommand.bind(this);
  }

  componentDidMount() {

  }

  sendCommand(uniqueid) {
    this.setState({ ...this.state, loading: true }, () => {
      // AT+GTRTO=gv600mg,9,,3,,,,FFFF$ (for Queclink status update)
      // AT+GTRTO=gv600mg,30,,3,,,,FFFF$ (for Queclink temp sensor)
      Setters.sendDeviceCommand(uniqueid, null, this.state.commandString).then(() => {
        this.setState({ ...this.state, loading: false });
      }, (error) => {
        console.log(error);
        console.log(error.message);
        this.setState({ ...this.state, loading: false, error: error.message });
      });
    });
  }

  render() {
    const trailerObj = this.props.trailerObj;
    return (
      <tr className={styles.userRow}>
        <td>
          {trailerObj.get('unitId')}
        </td>
        <td>
          {trailerObj.get('belongsToCompany') && trailerObj.get('belongsToCompany').get('name')}
        </td>
        <td>
          {trailerObj.get('tc_devices') &&
            <div>
              {trailerObj.get('tc_devices').get('uniqueid')}
            </div>
          }
        </td>
        <td>
          {moment(trailerObj.get('updatedAt')).format('LLL')}
        </td>
        <td>
          <span>
            {trailerObj.get('isHidden') && <button className="buttonDefault" onClick={() => this.props.hideUnhideEquipment(trailerObj, 'Trailer', false)}>Show Trailer</button>}
            {!trailerObj.get('isHidden') && <button className="buttonDefault yellowButton" onClick={() => this.props.hideUnhideEquipment(trailerObj, 'Trailer', true)}>Hide Trailer</button>}
            &nbsp;
          </span>
          {trailerObj.get('tc_devices') &&
            <span>
              <input placeholder="Send Command to Asset Tracker" onChange={(e) => this.setState({ ...this.state, commandString: e.target.value })} value={this.state.commandString} />
              <button className="buttonDefault" disabled={this.state.loading} onClick={() => this.sendCommand(trailerObj.get('tc_devices').get('uniqueid'))}>Send</button>
            </span>
          }
        </td>
        {this.state.error &&
          <ErrorModal show={!!this.state.error} handleModalChoice={() => this.setState({ ...this.state, error: '' })} handleClose={() => this.setState({ ...this.state, error: '' })}>
            <div>{this.state.error}</div>
          </ErrorModal>
        }
      </tr>
    );
  }
}

TrailerRow.propTypes = {
  trailerObj: PropTypes.object.isRequired,
  hideUnhideEquipment: PropTypes.func.isRequired,
};

export default TrailerRow;
