import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { TableRow, TableRowColumn } from 'material-ui';

import store from '../../../store';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import * as Sim from 'api/Sim';

// Components

// CSS
import styles from './styles.module.scss';
import AssignDeviceToAnotherCompanyModal from './AssignDeviceToAnotherCompanyModal';

class SimRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infoObj: {
        status: 'N/A',
        ratePlanName: null,
      },
      usageRecord: undefined,
      notes: props.simObj.get('notes'),
      saving: false,
      simIdentifier: {
        entry: '',
        type: 'imei',
      },
      editIccid: {
        editing: false,
        iccid: props.simObj.get('iccid'),
        saving: false,
      },
      showAssignDeviceToAnotherCompanyModal: false,
    };

    this.saveIccid = this.saveIccid.bind(this);
  }

  componentDidMount() {
    // Promise.all([
    //   Sim.getSimInfo(this.props.simObj.get('iccid')),
    // ]).then((responses) => {
    //   const infoObj = responses[0];
    //   const usageRecord = responses[1];
    //   this.setState({ ...this.state, usageRecord, loading: false, infoObj });
    //   if (infoObj.status === 'active') {
    //     this.props.setActiveCheck(true, false, this.props.simObj.get('iccid'));
    //   } else {
    //     this.props.setActiveCheck(false, false, this.props.simObj.get('iccid'));
    //   }
    // });
    // // Sim.getSimInfo(this.props.simObj.get('iccid')).then((infoObj) => {
    // //   this.setState({ ...this.state, infoObj });
    // // });
  }

  saveIccid() {
    this.props.simObj.set('iccid', this.state.editIccid.iccid);
    const newState = { ...this.state };
    newState.editIccid.editing = false;
    newState.editIccid.saving = true;
    this.setState(newState);
    Setters.replaceIccidForSim(this.props.simObj.id, newState.editIccid.iccid).then(() => {
      newState.editIccid.saving = false;
      this.setState(newState);
    })
  }

  render() {
    return (
      <TableRow>
        <TableRowColumn style={{ width: '37%' }}>
          <table>
            <tr>
              <td>
                {this.props.simObj.get('imei')}
                {this.props.simObj.get('trackerEsn')}
                {!this.props.simObj.get('trackerEsn') && !this.props.simObj.get('imei') &&
                  (
                    <input
                      style={{ width: '10em' }}
                      value={this.state.simIdentifier.entry}
                      onChange={(e) => this.setState({ ...this.state, simIdentifier: { ...this.state.simIdentifier, entry: e.target.value } })}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') this.props.setSimIdentifier(this.props.simObj, this.state.simIdentifier.type, this.state.simIdentifier.entry);
                      }}
                    />
                  )
                }
              </td>
              <td>
                {!this.props.simObj.get('trackerEsn') && !this.props.simObj.get('imei') &&
                  (
                    <div>
                      <select value={this.state.simIdentifier.type} onChange={(e) => this.setState({ ...this.state, simIdentifier: { ...this.state.simIdentifier, type: e.target.value } })}>
                        <option value="imei">IMEI</option>
                        <option value="tabletUuid">Tablet Uuid</option>
                        <option value="trackerESN">Calamp ESN</option>
                      </select>
                      &nbsp;
                      <button
                        className="buttonDefault"
                        disabled={this.props.saving}
                        onClick={() => this.props.setSimIdentifier(this.props.simObj, this.state.simIdentifier.type, this.state.simIdentifier.entry)}
                      >
                        Save
                      </button>
                    </div>
                  )
                }
                {(this.props.simObj.get('imei') || this.props.simObj.get('trackerEsn')) &&
                  (
                    <div>
                      &nbsp;
                      <button
                        className="buttonDefault yellowButton inlineBlock"
                        onClick={() => this.setState({ ...this.state, showAssignDeviceToAnotherCompanyModal: true })}
                        disabled={this.state.editIccid.saving}
                      >
                        Assign
                      </button>
                    </div>
                  )
                }
              </td>
            </tr>
            <tr>
              <td>
                {this.props.simObj.get('iccid')}
              </td>
              <td>
                {!this.state.editIccid.editing &&
                  (
                    <div>
                      &nbsp;
                      <button
                        className="buttonDefault yellowButton inlineBlock"
                        onClick={() => this.setState({ ...this.state, editIccid: { ...this.state.editIccid, editing: true } })}
                        disabled={this.state.editIccid.saving}
                      >
                        Edit
                      </button>
                    </div>
                  )
                }
                {this.state.editIccid.editing &&
                  (
                    <div>
                      &nbsp;
                      <input
                        value={this.state.editIccid.iccid}
                        onChange={(e) => this.setState({ ...this.state, editIccid: { ...this.state.editIccid, iccid: e.target.value } })}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') this.saveIccid();
                        }}
                      />  &nbsp;
                      <button
                        className="buttonDefault inlineBlock"
                        onClick={() => this.saveIccid()}
                        disabled={this.state.editIccid.saving}
                      >
                        Save
                      </button>
                    </div>
                  )
                }

              </td>
            </tr>
          </table>

          <AssignDeviceToAnotherCompanyModal
            show={this.state.showAssignDeviceToAnotherCompanyModal}
            handleClose={() => { this.setState({ ...this.state, showAssignDeviceToAnotherCompanyModal: false }); this.props.refreshCompanySims(); }}
            simObj={this.props.simObj}
          />
        </TableRowColumn>
        <TableRowColumn style={{ width: '33%' }}>
          <b>{this.props.simDataObj.status}</b> <kbd>{this.props.simDataObj.ratePlanName} {this.props.simDataObj.simVendor}</kbd>
          <br />
          Last Online {this.props.simDataObj.imei ? `(IMEI: ${this.props.simDataObj.simObj.imei})` : ''}: {this.props.simDataObj.lastOnline ? moment(this.props.simDataObj.lastOnline).format('YYYY-MM-DD HH:mm') : ''}
        </TableRowColumn>
        <TableRowColumn style={{ width: '20%' }}>
          <textarea
            value={this.state.notes}
            onChange={(e) => {
              this.setState({ ...this.state, notes: e.target.value });
            }}
          />
        </TableRowColumn>
        <TableRowColumn style={{ width: '10%' }}>
          <button
            className="buttonDefault yellowButton"
            onClick={() => {
              this.setState({ ...this.state, saving: true });
              this.props.simObj.save().then(() => {
                this.setState({ ...this.state, saving: false });
              })
            }}
            disabled={this.state.saving}
          >
            Save
          </button>
        </TableRowColumn>
      </TableRow>
    );
  }

}

SimRow.propTypes = {
  simObj: PropTypes.object.isRequired,
  simDataObj: PropTypes.object.isRequired,
  showTwilioInfo: PropTypes.func.isRequired,
};

export default SimRow;
