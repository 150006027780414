import PropTypes from 'prop-types';
import React from 'react';
// Components
import { Button } from 'react-bootstrap';
// Assets
const whiteLogo = require('./white-logo.png');
// CSS
import styles from './UserHeaderBar.module.scss';

function UserHeaderBar(props) {
  let environment;
  if (props.isProduction) {
    environment = 'Production';
  } else {
    environment = 'Development';
  }
  return (
    <table className={styles.userHeaderBar}>
      <tbody><tr>
        <td><img height="30em" role="presentation" src={whiteLogo} /></td>
        <td></td>
        <td>Internal Tool</td>
      </tr></tbody>
    </table>
  );
}

UserHeaderBar.propTypes = {
  initialize: PropTypes.func.isRequired,
  isProduction: PropTypes.bool.isRequired,
};

export default UserHeaderBar;
