import Parse from 'parse';
import Promise from 'promise';
import store from '../store';
import * as Auth from './Auth';

function getSimState(iccid) {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('_internal:getSimStateByIccid', {
      returnAsParse: true,
      iccid,
    }).then((status) => {
      resolve(status);
    }, (error) => {
      reject(error);
    });
  });
  return promise;
}

function getSimInfo(iccid) {
  if (!iccid) return null;

  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('_internal:getSimInfoByIccid', {
      returnAsParse: true,
      iccid,
    }).then((infoObj) => {
      resolve(infoObj || {});
    }, (error) => {
      reject(error);
    });
  });
  return promise;
}

function toggleSimState(imei, isActive) {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('_internal:toggleSimStateByImei', {
      returnAsParse: true,
      imei,
      isActive,
    }).then((confirm) => {
      resolve(confirm);
    }, (error) => {
      reject(error);
    });
  });
  return promise;
}

function getSimSessionInfo(iccid) {
  const promise = new Promise((resolve, reject) => {
    Auth.callSecuredParseFunction('_internal:getSimSessionInfo', {
      returnAsParse: true,
      iccid,
    }).then((status) => {
      resolve(status);
    }, (error) => {
      reject(error);
    });
  });
  return promise;
}

export {
  getSimState,
  getSimInfo,
  toggleSimState,
  getSimSessionInfo,
};
