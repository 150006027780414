import PropTypes from 'prop-types';
import React from 'react';
import history from 'sbHistory';
import LazyLoad from 'react-lazy-load';
import { connect } from 'react-redux';

// import { Table } from 'react-bootsTableRowap';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// API
import * as Auth from 'api/Auth';
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';

// Components
import EditSubscriptionModal from 'components/PaymentModal/container/EditSubscriptionModal';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import SubscriptionIssuesTableRow from 'components/SubscriptionIssuesTable/SubscriptionIssuesTableRow';
import TablePaginationActions from './TablePaginationActions';

// CSS
import styles from './SubscriptionIssuesTable.module.scss';

class SubscriptionIssuesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionArr: [],
      count: 0,
      skipInt: 0,
      getMismatchesBool: false,
      editSubscription: undefined,
      // voidInvoice: {
      //   invoiceId: null,
      //   showConfirmModal: false,
      // },
      loading: false,
    };

    this.getSubscriptionObjCountAndArr = this.getSubscriptionObjCountAndArr.bind(this);
    this.hideUnhideCompany = this.hideUnhideCompany.bind(this);
  }

  componentDidMount() {
    this.getSubscriptionObjCountAndArr();
  }

  async getSubscriptionObjCountAndArr(skipInt) {
    this.setState({
      ...this.state,
      skipInt: skipInt || 0,
      loading: true,
    });
    const subscriptionObjCountAndArr = await Getters.getSubscriptionObjCountAndArr(null, null, skipInt, this.state.getMismatchesBool);
    this.setState({
      ...this.state,
      count: subscriptionObjCountAndArr.count,
      subscriptionArr: subscriptionObjCountAndArr.subscriptionParseObjectArr,
      loading: false,
    });
    // const subscriptionArr = await Getters.getIncorrectlyBilledSubscriptions();
  }

  hideUnhideCompany(companyObj, hideBool) {
    this.setState({ ...this.state, fetched: false }, () => {
      Auth.callSecuredParseFunction('_internal:hideUnhideCompany',
        {
          returnAsParse: true,
          parseClassName: 'Company',
          companyId: companyObj.id,
          hideBool,
        }
      ).then(() => {
        companyObj.set('isHidden', hideBool);
        this.getSubscriptionObjCountAndArr(this.state.skipInt);
      });
    });
  }

  render() {
    return (
      <div>
        {!this.state.getMismatchesBool &&
          <button className="buttonDefault" onClick={async (e) => { await this.setState({ ...this.state, getMismatchesBool: true }); this.getSubscriptionObjCountAndArr(0); }}>Get Only Billing Mismatches</button>
        }
        {this.state.getMismatchesBool &&
          <button className="buttonDefault" onClick={async (e) => { await this.setState({ ...this.state, getMismatchesBool: false }); this.getSubscriptionObjCountAndArr(0); }}>Get All Subscriptions</button>
        }

        <br />
        <TableContainer className="defaultTable">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '20%' }}>Company / DOT Number</TableCell>
                <TableCell>Billing</TableCell>
                <TableCell>Active Count</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.loading &&
                (
                  <TableRow className={styles.loadingIconNoHover}>
                    <TableCell colSpan="7" className="loadingIconPadding"><LoadingIcon /></TableCell>
                  </TableRow>
                )
              }
              {!this.state.loading && this.state.subscriptionArr.length === 0 &&
                (
                  <TableRow>
                    <TableCell colSpan="7" className="emptyRowMessage">No Subscriptions to Display</TableCell>
                  </TableRow>
                )
              }
              {!this.state.loading && this.state.subscriptionArr.length > 0 &&
                this.state.subscriptionArr.filter((subscriptionObj) => {
                  let shouldShow = true;
                  if (subscriptionObj.get('company').get('isHidden')) shouldShow = false;
                  // console.log(
                  //   subscriptionObj.get('company').get('name'),
                  //   {
                  //     activeVehicleLastMonthCount: subscriptionObj.get('activeVehicleLastMonthCount'),
                  //     billingEldHardwareCount: (subscriptionObj.get('billingEldHardwareCount') || 0),
                  //     activeTabletSimCount: (subscriptionObj.get('activeTabletSimCount') || 0),
                  //     billingTabletCount: (subscriptionObj.get('billingTabletCount') || 0),
                  //     billingAssetTrackerCount: (subscriptionObj.get('billingAssetTrackerCount') || 0)
                  //   },
                  //   (
                  //     (subscriptionObj.get('activeVehicleLastMonthCount') || 0) < (subscriptionObj.get('billingEldHardwareCount') || 0) ||
                  //     (subscriptionObj.get('activeTabletSimCount') || 0) < ((subscriptionObj.get('billingTabletCount') || 0) + (subscriptionObj.get('billingAssetTrackerCount') || 0))
                  //   ),
                  // );
                  // if (
                  //   this.state.getMismatchesBool === true &&
                  //   (
                  //     (subscriptionObj.get('activeVehicleLastMonthCount') || 0) < (subscriptionObj.get('billingEldHardwareCount') || 0) ||
                  //     (subscriptionObj.get('activeTabletSimCount') || 0) < ((subscriptionObj.get('billingTabletCount') || 0) + (subscriptionObj.get('billingAssetTrackerCount') || 0))
                  //   )
                  // ) {
                  //   shouldShow = false;
                  // }
                  return shouldShow;
                }).map((subscriptionObj) => (
                  <SubscriptionIssuesTableRow
                    key={subscriptionObj.id}
                    subscriptionObj={subscriptionObj}
                    editSubscription={(subscriptionObj) => this.setState({ ...this.state, showEditModal: true, editSubscription: subscriptionObj })}
                    hideUnhideCompany={this.hideUnhideCompany}
                  />
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={this.state.count}
                  rowsPerPage={20}
                  page={this.state.skipInt}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={(e, newPage) => this.getSubscriptionObjCountAndArr(newPage)}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <EditSubscriptionModal
          show={this.state.showEditModal}
          Subscription={this.props.Subscription}
          subscription={this.state.editSubscription}
          hideModal={(refresh) =>
            this.setState({ ...this.state, showEditModal: false }, () => {
              if (refresh) {
                this.handleCompanyEntry(undefined, undefined, true);
              }
            })
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { Main, Subscription } = state;
  return {
    Main,
    Subscription,
  };
};

SubscriptionIssuesTable.propTypes = {
};

export default connect(mapStateToProps)(SubscriptionIssuesTable);

