import PropTypes from 'prop-types';
import React from 'react';
import store from '../../store';
import Parse from 'parse';
import moment from 'moment';
import { connect } from 'react-redux';

// Actions
import { fetchActiveSubscriptionsForState, deleteSubscriptionsForState } from 'actions/Subscription';

// API
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import * as Auth from 'api/Auth';

// Components
import Title from 'components/LayoutTitle/view/Title';
import PaymentModal from 'components/PaymentModal/container/paymentModal';
import EditSubscriptionModal from 'components/PaymentModal/container/EditSubscriptionModal';
import SubscriptionsTable from 'components/SubscriptionsTable/container/SubscriptionsTable';

// CSS
import styles from './SubscriptionsLayout.module.scss';
import { compressWhitespace } from '../../api/Helpers';

class SubscriptionsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        companyName: '',
        dotNumber: '',
        promoCode: null,
        hideHidden: false,
        customerId: '',
        subscriptionId: '',
      },
      fetched: true,
      displayFilterForm: false,
      tableView: 'allSubscriptions',
      subscriptions: [],
      resellerCompanyArr: [],
      editSubscription: undefined,
      showRegisterModal: false,
      showEditModal: false,
    };

    this.handleCompanyEntry = this.handleCompanyEntry.bind(this);
    this.filter = this.filter.bind(this);
    this.hideUnhideCompany = this.hideUnhideCompany.bind(this);
    this.generateCsv = this.generateCsv.bind(this);
  }

  componentDidMount() {
    Getters.getResellerCompanies().then((resellerCompanyArr) => {
      this.setState({ ...this.state, resellerCompanyArr });
    });
    Getters.getSubscriptionPlans().then((subscriptionPlanArr) => {
      const subscriptionPlans = [];
      for (let i = 0; i < subscriptionPlanArr.length; i++) {
        subscriptionPlans.push({
          ...subscriptionPlanArr[i],
          quantity: 0,
          active: false,
        });
      }
      this.setState({ ...this.state, subscriptionPlans });
    });
  }

  componentWillUnmount() {
    deleteSubscriptionsForState();
  }

  handleCompanyEntry(e, type, force) {
    if (force) {
      this.filter(type);
    }
    if (e && ((e.key === 'Enter'))) {
      const newState = { ...this.state };
      if (type) {
        newState.filter[type] = e.target.value;
        this.setState(newState, () => {
          this.filter(type);
        });
      } else {
        this.filter(type);
      }
    }
  }

  filter(type) {
    this.setState({ ...this.state, fetched: false }, () => {
      if (!type || type === 'companyName') {
        Auth.callSecuredParseFunction('internal:getSubscriptionByCompanyName',
          {
            returnAsParse: true,
            parseClassName: 'Subscription',
            companyName: this.state.filter.companyName,
            promoCode: this.state.filter.promoCode,
            hideHidden: this.state.filter.hideHidden,
            activeCountBillingMatchBool: this.state.filter.activeCountBillingMatchBool,
          }
        ).then((subscriptionArr) => {
          this.setState({ ...this.state, subscriptions: subscriptionArr, fetched: true });
        });
      } else if (type === 'email') {
        Auth.callSecuredParseFunction('internal:getSubscriptionByEmail',
          {
            returnAsParse: true,
            parseClassName: 'Subscription',
            email: this.state.filter.email,
            promoCode: this.state.filter.promoCode,
          }
        ).then((subscriptionArr) => {
          this.setState({ ...this.state, subscriptions: subscriptionArr, fetched: true });
        });
      } else if (type === 'dotNumber') {
        Auth.callSecuredParseFunction('internal:getSubscriptionByDotNumber',
          {
            returnAsParse: true,
            parseClassName: 'Subscription',
            dotNumber: this.state.filter.dotNumber,
            promoCode: this.state.filter.promoCode,
            activeCountBillingMatchBool: this.state.filter.activeCountBillingMatchBool,
          }
        ).then((subscriptionArr) => {
          this.setState({ ...this.state, subscriptions: subscriptionArr, fetched: true });
        });
      } else if (type === 'incorrectBilling') {
        Auth.callSecuredParseFunction('internal:getIncorrectlyBilledSubscriptions',
          {
            promoCode: this.state.filter.promoCode,
            returnAsParse: true,
            parseClassName: 'Subscription',
          }
        ).then((subscriptionArr) => {
          this.setState({ ...this.state, subscriptions: subscriptionArr, fetched: true });
        });
      } else if (type === 'customerId') {
        Auth.callSecuredParseFunction('_internal:getSubscriptionObjByCustomerId',
          {
            returnAsParse: true,
            parseClassName: 'Subscription',
            customerId: this.state.filter.customerId,
          }
        ).then((subscriptionObj) => {
          this.setState({ ...this.state, subscriptions: [subscriptionObj], fetched: true });
        });
      } else if (type === 'subscriptionId') {
        Auth.callSecuredParseFunction('_internal:getSubscriptionObjBySubscriptionId',
          {
            returnAsParse: true,
            parseClassName: 'Subscription',
            subscriptionId: this.state.filter.subscriptionId,
          }
        ).then((subscriptionObj) => {
          this.setState({ ...this.state, subscriptions: [subscriptionObj], fetched: true });
        });
      }
    });
  }

  hideUnhideCompany(companyObj, hideBool) {
    this.setState({ ...this.state, fetched: false }, () => {
      Auth.callSecuredParseFunction('_internal:hideUnhideCompany',
        {
          returnAsParse: true,
          parseClassName: 'Company',
          companyId: companyObj.id,
          hideBool,
        }
      ).then(() => {
        companyObj.set('isHidden', hideBool);
        this.setState({ ...this.state, fetched: true });
      });
    });
  }

  generateCsv() {
    const lineArray = ['Company,ELD Hardware Count,Tablet Count'];

    for (let i = 0; i < this.state.subscriptions.length; i++) {
      const obj = this.state.subscriptions[i];

      if (obj && obj.get('company') && obj.get('company').get('name')) {
        const company = Helpers.toTitleCase(obj.get('company').get('name').replace(/,/g, ''));
        const eldHardwareCount = obj.get('activeEldHardwareCount');
        const tabletCount = obj.get('activeTabletSimCount');
        lineArray.push([company, eldHardwareCount, tabletCount].join(','));
      }
    }

    const csvContent = lineArray.join('\n');
    Helpers.createCsvFile('Billing Mismatches - ' + moment().format('YYYY-MM-DD'), csvContent, true);
  }

  render() {
    return (
      <div className={styles.SubscriptionsLayout}>
        <Title title="Subscriptions">
          <span className="inlineBlock">
            <input
              type="text"
              className="buttonSpacedRight"
              onKeyPress={(e) => { this.handleCompanyEntry(e, 'companyName'); }}
              placeholder="Enter Company Name"
            />
            <input
              type="text"
              className="buttonSpacedRight"
              onKeyPress={(e) => { this.handleCompanyEntry(e, 'email'); }}
              placeholder="Enter Email"
            />
            <input
              type="text"
              className="buttonSpacedRight"
              onKeyPress={(e) => { this.handleCompanyEntry(e, 'dotNumber'); }}
              placeholder="Enter DOT Number"
            />
            <input
              type="text"
              className="buttonSpacedRight"
              onKeyPress={(e) => { this.handleCompanyEntry(e, 'customerId'); }}
              placeholder="Enter Customer ID"
            />
            <input
              type="text"
              className="buttonSpacedRight"
              onKeyPress={(e) => { this.handleCompanyEntry(e, 'subscriptionId'); }}
              placeholder="Enter Subscription ID"
            />
            {this.state.resellerCompanyArr.length > 0 &&
              (
                <select value={this.state.filter.promoCode} onChange={(e) => { this.setState({ ...this.state, filter: { ...this.state.filter, promoCode: e.target.value } }, () => this.filter()); }}>
                  <option>Select Partner</option>
                  {this.state.resellerCompanyArr.map((resellerCompany) =>
                    <option key={resellerCompany.id} value={resellerCompany.get('promoCode')}>{resellerCompany.get('name')}</option>
                  )}
                </select>
              )
            }
            <select value={this.state.filter.hideHidden} onChange={(e) => { this.setState({ ...this.state, filter: { ...this.state.filter, hideHidden: e.target.value === 'true' } }, () => this.filter()); }}>
              <option value>Hide Hidden Companies</option>
              <option value={false}>Show Hidden Companies</option>
            </select>
          </span>
          <button className="buttonDefault" onClick={(e) => { this.setState({ ...this.state, filter: { ...this.state.filter, activeCountBillingMatchBool: true } }, () => this.filter('incorrectBilling')); }}>Get Billing Mismatches</button>
          <button className="buttonDefault" onClick={() => this.generateCsv()}>Export CSV</button>
          {/* <button className="buttonDefault" onClick={() => this.setState({ ...this.state, showRegisterModal: true })}>Register New Company</button> */}
        </Title>
        <SubscriptionsTable
          subscriptions={this.state.subscriptions.filter((subscriptionObj) => subscriptionObj.get('company')
            && ((this.state.filter.hideHidden === true || this.state.filter.hideHidden === 'true')
              ? !subscriptionObj.get('company').get('isHidden')
              : true
            )
          )}
          fetched={this.state.fetched}
          editSubscription={(subscriptionObj) => this.setState({ ...this.state, showEditModal: true, editSubscription: subscriptionObj })}
          hideUnhideCompany={this.hideUnhideCompany}
        />
        <PaymentModal
          show={this.state.showRegisterModal}
          Subscription={this.props.Subscription}
          hideModal={(refresh) =>
            this.setState({ ...this.state, showRegisterModal: false }, () => {
              if (refresh) {
                this.handleCompanyEntry(undefined, undefined, true);
              }
            })
          }
        />
        <EditSubscriptionModal
          show={this.state.showEditModal}
          Subscription={this.props.Subscription}
          subscription={this.state.editSubscription}
          hideModal={(refresh) =>
            this.setState({ ...this.state, showEditModal: false }, () => {
              if (refresh) {
                this.handleCompanyEntry(undefined, undefined, true);
              }
            })
          }
          resellerCompanyArr={this.state.resellerCompanyArr}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { Main, Subscription } = state;
  return {
    Main,
    Subscription,
  };
};

SubscriptionsLayout.propTypes = {
  Main: PropTypes.object.isRequired,
  Subscription: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(SubscriptionsLayout);
