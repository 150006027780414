import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import DatePicker from 'material-ui/DatePicker';
// import DatePicker from 'components/DatePicker/container/DatePicker';
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, Toggle, TextField } from 'material-ui';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import * as Helpers from 'api/Helpers';
import VehiclesListRow from './VehiclesListRow';

// Components
// import SimRow from './SimRow';
// import TwilioInfo from '../../TwilioInfo/container/TwilioInfo';

// CSS

class VehiclesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      objArr: [],
      dateMoment: moment(),
      countArr: {
        vehicleAndActivityObj: {},
        createdLastMonthArr: [],
        totalUniqueVehicleUnitIdArr: [],
        lastMonthUniqueVehicleUnitIdArr: [],
        lastThreeMonthsUniqueVehicleUnitIdArr: [],
      },
      loading: false,
    };
    this.refreshArr = this.refreshArr.bind(this);
    this.toggleBypass = this.toggleBypass.bind(this);
  }

  componentDidMount() {
    this.refreshArr();
  }


  async refreshArr() {
    this.setState({
      ...this.state,
      loading: true,
    });

    Getters.getVehiclesByCompany(this.props.companyId).then((_objArr) => {
      const objArr = _objArr
        .sort((a, b) => {
          if (a.get('eldHardware') && !b.get('eldHardware')) {
            return -1;
          } else if (!a.get('eldHardware') && b.get('eldHardware')) {
            return 1;
          }
          if (a && b && a.get('vehicleLocation') && b.get('vehicleLocation')) {
            return (b.get('vehicleLocation').get('dateTime') - a.get('vehicleLocation').get('dateTime'));
          } else if (a && a.get('vehicleLocation')) {
            return -1;
          } else if (b && b.get('vehicleLocation')) {
            return 1;
          }
          return 0;
        })
        .filter((obj1, i, arr) => obj1.get('unitId') && arr.map(obj => obj.get('unitId')).indexOf(obj1.get('unitId')) === i);

      const createdLastMonthArr = [];
      const totalUniqueVehicleUnitIdArr = [];
      const lastMonthUniqueVehicleUnitIdArr = [];
      const lastThreeMonthsUniqueVehicleUnitIdArr = [];
      const vehicleAndActivityObj = {};

      for (let i = 0; i < objArr.length; i++) {
        const obj = objArr[i];
        const vehicleLocation = objArr[i].get('vehicleLocation');

        vehicleAndActivityObj[obj.get('unitId')] = {
          eldHardware: obj?.eldHardware?.get('serial'),
          startDate: null,
          endDate: null,
        };

        if (moment(obj.get('createdAt')).isAfter(moment().subtract(1, 'month').startOf('month'))) {
          createdLastMonthArr.push(obj);
        }

        if (totalUniqueVehicleUnitIdArr.indexOf(obj.get('unitId')) === -1) {
          totalUniqueVehicleUnitIdArr.push(obj.get('unitId'));
        }

        if (
          vehicleLocation &&
          moment(vehicleLocation.get('dateTime')).isAfter(moment().subtract(1, 'months').startOf('month')) &&
          lastMonthUniqueVehicleUnitIdArr.indexOf(obj.get('unitId')) === -1
        ) {
          lastMonthUniqueVehicleUnitIdArr.push(obj.get('unitId'));
        }

        if (
          vehicleLocation &&
          moment(vehicleLocation.get('dateTime')).isAfter(moment().subtract(3, 'months').startOf('month')) &&
          lastThreeMonthsUniqueVehicleUnitIdArr.indexOf(obj.get('unitId')) === -1
        ) {
          lastThreeMonthsUniqueVehicleUnitIdArr.push(obj.get('unitId'));
        }

        //   const totalUniqueELDHardwareSerialArr = [...new Set(this.state.objArr.filter((obj) => obj && obj.eldHardware && obj.eldHardware.get('serial') !== 'NO_SERIAL' && obj.eldHardware.get('serial')).map((obj) => obj && obj.eldHardware && obj.eldHardware.get('serial')))];
        //   const lastThreeMonthsELDHardwareSerialArr = this.state.objArr.filter((obj) => (
        //     obj && obj.eldEvent && moment(obj.eldEvent.get('eventDateTime')).isAfter(moment().subtract(3, 'months').startOf('month')) &&
        //     obj.eldEvent.get('eldHardwareSerial')
        //   )).map((obj) => obj && obj.eldEvent && obj.eldEvent.get('eldHardwareSerial'));
        //   const lastThreeMonthsUniqueELDHardwareSerialArr = [...new Set(lastThreeMonthsELDHardwareSerialArr)];

        //   const lastMonthELDHardwareSerialArr = this.state.objArr.filter((obj) => (
        //     obj && obj.eldEvent && moment(obj.eldEvent.get('eventDateTime')).isAfter(moment().subtract(1, 'months').startOf('month')) &&
        //     obj.eldEvent.get('eldHardwareSerial')
        //   )).map((obj) => obj && obj.eldEvent && obj.eldEvent.get('eldHardwareSerial'));
        //   const lastMonthUniqueELDHardwareSerialArr = [...new Set(lastMonthELDHardwareSerialArr)];
        // }
      }

      this.setState({
        ...this.state,
        objArr,
        countArr: {
          vehicleAndActivityObj,
          createdLastMonthArr,
          totalUniqueVehicleUnitIdArr,
          lastMonthUniqueVehicleUnitIdArr,
          lastThreeMonthsUniqueVehicleUnitIdArr,
        },
        loading: false,
      });
    });
  }

  generateCsv() {
    const lineArray = ['Vehicle,ELD Hardware ID,Created At,Last Active,Activity Month,First Active in Month,Last Active in Month'];

    let companyName = '';

    for (let i = 0; i < this.state.objArr.length; i++) {
      const obj = this.state.objArr[i];

      if (!companyName && obj && obj.get('belongsToCompany')) companyName = obj.get('belongsToCompany').get('name');

      const vehicleUnitId = obj.get('unitId');
      const eldHardwareSerial = obj.get('vehicleLocation') && obj.get('vehicleLocation').get('eldHardwareSerial');
      const createdAt = moment(obj.get('createdAt')).format('YYYY-MMMM-DD');
      const vehicleLocation = obj.get('vehicleLocation') && obj.get('vehicleLocation').get('dateTime') && moment(obj.get('vehicleLocation').get('dateTime')).format('YYYY-MMMM-DD');
      const activityCheckDate = moment(this.state.dateMoment).format('MMMM YYYY');
      const activityStartEventDate = this.state.countArr.vehicleAndActivityObj[vehicleUnitId].startDate ? moment(this.state.countArr.vehicleAndActivityObj[vehicleUnitId].startDate).format('YYYY-MM-DD') : '';
      const activityEndEventDate = this.state.countArr.vehicleAndActivityObj[vehicleUnitId].endDate ? moment(this.state.countArr.vehicleAndActivityObj[vehicleUnitId].endDate).format('YYYY-MM-DD') : '';
      lineArray.push([vehicleUnitId, eldHardwareSerial, createdAt, vehicleLocation, activityCheckDate, activityStartEventDate, activityEndEventDate].join(','));
    }

    const csvContent = lineArray.join('\n');
    Helpers.createCsvFile(`${companyName && `${companyName} - `}Vehicles - ${moment().format('YYYY-MM-DD')}`, csvContent, true);
  }

  toggleBypass(vehicleObj, enabledBool) {
    const companyObj = vehicleObj && vehicleObj.get('belongsToCompany');
    const subscriptionObj = companyObj && companyObj.get('subscription');
    const addressObj = companyObj && companyObj.get('address');
    this.setState({ ...this.state, loadingWSB: true }, () => {
      Setters.handleWSBVehicleSubscription(companyObj && companyObj.id, subscriptionObj && subscriptionObj.id, addressObj && addressObj.id, vehicleObj && vehicleObj.id, enabledBool).then(() => {
        this.setState({ ...this.state, loadingWSB: false });
      }, (error) => {
        console.log(error);
        this.setState({ ...this.state, loadingWSB: false, error: error.message });
      });
    });
  }

  render() {
    return (
      <div>
        <button className={`buttonDefault`} onClick={() => this.generateCsv()}>Download CSV</button>
        <DatePicker
          hintText="Click to choose date"
          value={moment(this.state.dateMoment).toDate()}
          formatDate={(date) => moment(date).format('MMMM yyyy')}
          onChange={(event, date) => {
            this.setState({
              ...this.state,
              dateMoment: moment(date),
              countArr: {
                ...this.state.countArr,
                vehicleAndActivityObj: {},
              },
            });
          }}
        />
        <br />
        <Table
          selectable={false}
          multiSelectable={false}
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          ><TableRow>
              <TableRowColumn style={{ width: '35%' }} colSpan={2}>
                <b>Vehicle</b>
                <br />
                Total: {this.state.countArr.totalUniqueVehicleUnitIdArr.length}
              </TableRowColumn>
              <TableRowColumn style={{ width: '16%' }}>
                <b>ELD Hardware</b>
              </TableRowColumn>
              <TableRowColumn style={{ width: '49%' }}>
                <b>Latest Activity</b>
                <br />
                Amount active in {this.state.dateMoment.format('MMMM YYYY')}:&nbsp;
                {
                  Object.values(this.state.countArr.vehicleAndActivityObj).filter((obj) => (obj.startDate)).length
                }
                <br />
                Last 30 Days:&nbsp;
                {
                  this.state.objArr.filter((obj) => (
                    obj.get('vehicleLocation') && moment(obj.get('vehicleLocation').get('dateTime')).isAfter(moment().subtract(1, 'months').startOf('month'))
                  )).length
                }
                <br />
                Last 90 Days:&nbsp;
                {
                  this.state.objArr.filter((obj) => (
                    obj.get('vehicleLocation') && moment(obj.get('vehicleLocation').get('dateTime')).isAfter(moment().subtract(3, 'months').startOf('month'))
                  )).length
                }
              </TableRowColumn>
            </TableRow></TableHeader>
          <TableBody
            displayRowCheckbox={false}
          >
            {this.state.objArr.map((obj) => (
              <VehiclesListRow
                vehicleObj={obj}
                startDate={moment(this.state.dateMoment).startOf('month')}
                endDate={moment(this.state.dateMoment).endOf('month')}
                setVehicleAndActivityObjData={(unitId, startDate, endDate) => {
                  const vehicleAndActivityObj = this.state.countArr.vehicleAndActivityObj;
                  console.log(this.state.countArr);
                  console.log(vehicleAndActivityObj);
                  if (!vehicleAndActivityObj[unitId]) vehicleAndActivityObj[unitId] = {};
                  vehicleAndActivityObj[unitId].startDate = startDate;
                  vehicleAndActivityObj[unitId].endDate = endDate;
                  console.log(vehicleAndActivityObj);
                  this.setState({ ...this.state, countArr: { ...this.state.countArr, vehicleAndActivityObj } });
                }}
              />
            ))}
          </TableBody>
        </Table >
        {
          this.state.loading &&
          <LoadingIcon />
        }
      </div >
    );
  }

}

VehiclesList.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default VehiclesList;
